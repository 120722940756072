import { isUrl } from 'utils/string';

const REQUIRED_MESSAGE = 'Value is required';
export const requiredValidation = {
  required: {
    value: true,
    message: REQUIRED_MESSAGE,
  },
};

export const numberValidation = {
  pattern: {
    value: /^(0|[1-9]\d*)(\.\d+)?$/,
    message: 'Only numerical values are allowed',
  },
};

export const integerValidation = {
  pattern: {
    value: /^[-]{0,1}([0-9]\d*)$/,
    message: 'Only integer values are allowed',
  },
};

export const urlValidation = {
  validate: (value: string) => {
    if (isUrl(value)) {
      return true;
    }

    return 'Invalid URL';
  },
};

export const objectValidation = {
  validate: (value: unknown) => {
    if (typeof value === 'object') {
      return Object.keys(value ?? {}).length > 0;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return false;
  },
};

export const createBooleanValidation = (isRequired: boolean) => ({
  validate: (value: unknown) => {
    if (isRequired && value == null) {
      return REQUIRED_MESSAGE;
    }

    return typeof value === 'boolean';
  },
});
