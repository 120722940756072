import { SvgIcon, SvgIconProps } from '@mui/material';

const FitViewIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g mask="url(#mask0_2948_77568)">
          <path
            d="M17 23V19H7C6.45 19 5.97917 18.8042 5.5875 18.4125C5.19583 18.0208 5 17.55 5 17V7H1V5H5V1H7V17H23V19H19V23H17ZM17 15V7H9V5H17C17.55 5 18.0208 5.19583 18.4125 5.5875C18.8042 5.97917 19 6.45 19 7V15H17Z"
            fill="white"
            fillOpacity="0.56"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default FitViewIcon;
