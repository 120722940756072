import axios, { AxiosResponse } from 'axios';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';
import { PipelineResponse } from 'types/pipeline';

const PUBLISH_PIPELINE_VERSION_KEY = 'publishPipelineVersion';

interface PublishPipelineVersion {
  pipelineId: string;
  versionId: string;
}

const publishPipelineVersionFetcher: MutationFetcher<
  AxiosResponse<PipelineResponse>,
  typeof PUBLISH_PIPELINE_VERSION_KEY,
  PublishPipelineVersion
> = (__key, opts: { arg: PublishPipelineVersion }) => {
  const { pipelineId, versionId } = opts.arg;
  return axios.put(`/pipelines/${pipelineId}/versions/${versionId}/publish`);
};

const usePublishPipelineVersion = () => {
  const { isMutating: isPublishing, trigger } = useSWRMutation(
    PUBLISH_PIPELINE_VERSION_KEY,
    publishPipelineVersionFetcher,
  );

  const publishPipelineVersion = (publish: PublishPipelineVersion) => {
    return trigger(publish);
  };

  return { publishPipelineVersion, isPublishing };
};

export default usePublishPipelineVersion;
