import TextField from 'components/Inputs/TextField/TextField';
import { Controller, FieldPath, FieldPathValue, FieldValues } from 'react-hook-form';
import { RHFTextFieldProps } from './RHFTextField.types';

const RHFTextField = <T extends FieldValues>(props: RHFTextFieldProps<T>) => {
  const { control, defaultValue, name, rules, ...textFieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={(defaultValue ?? '') as FieldPathValue<T, FieldPath<T>>}
      rules={rules}
      render={({ field: { ref, ...field }, fieldState }) => {
        const helperText = fieldState.error?.message
          ? fieldState.error.message
          : textFieldProps.helperText;
        const commonProps = {
          error: fieldState.invalid,
          helperText,
        } satisfies Partial<RHFTextFieldProps<T>>;
        return <TextField {...textFieldProps} {...field} {...commonProps} inputRef={ref} />;
      }}
    />
  );
};

export default RHFTextField;
