import ClearAllRoundedIcon from '@mui/icons-material/ClearAllRounded';
import { Paper } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import { getExecutionStatus, isExecutionQueued } from 'utils/execution';
import PanelCard from '../PanelCard/PanelCard';
import JsonEditor from 'components/JsonEditor/JsonEditor';

interface InputTabPanelProps {
  status?: ExecutionStatus;
  value?: unknown;
}

export default function InputTabPanel(props: InputTabPanelProps) {
  const { value } = props;
  const status = getExecutionStatus(props.status);
  const isQueued = isExecutionQueued(status);

  if (!isQueued && value) {
    return <JsonEditor content={value} readOnly isArrayJson />;
  }

  return (
    <Paper
      elevation={2}
      sx={{
        height: '100%',
        paddingY: 3,
      }}
    >
      <PanelCard
        title={<ClearAllRoundedIcon fontSize="large" color="action" />}
        description={
          <>
            Inputs will be displayed here when this
            <br />
            job starts running.
          </>
        }
      />
    </Paper>
  );
}
