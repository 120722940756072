import React from 'react';
import { AuthContextValue } from './AuthContext.types';

const DEFAULT_AUTH_CONTEXT: AuthContextValue = {
  isUserAuthenticated: false,
  user: undefined,
};

const AuthContext = React.createContext(DEFAULT_AUTH_CONTEXT);

export default AuthContext;
