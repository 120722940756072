import { useMemo } from 'react';
import { NodeProps, useStore } from 'reactflow';
import { FlowState } from 'types/reactflow';
import { shallow } from 'zustand/shallow';

const selector = ({ executionJobs }: FlowState) => ({
  executionJobs,
});

export const useGetNodeExecution = ({ id }: Pick<NodeProps, 'id'>) => {
  const { executionJobs } = useStore(selector, shallow);

  const execution = useMemo(() => executionJobs?.find((job) => job.id === id), [executionJobs, id]);

  return {
    execution,
  };
};
