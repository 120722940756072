import { FlowNode } from 'types/reactflow';

export type NodeUpdater<TNode extends FlowNode = FlowNode> = (prev: TNode) => Partial<TNode>;

export interface NodeUpdateChange<TNode extends FlowNode = FlowNode> {
  nodeId: string;
  type: 'update';
  updater: NodeUpdater<TNode>;
}

export function createNodeUpdateChange<TNode extends FlowNode>(
  params: Omit<NodeUpdateChange<TNode>, 'type'>,
): NodeUpdateChange {
  return {
    type: 'update',
    nodeId: params.nodeId,
    updater: params.updater as unknown as NodeUpdater,
  };
}

export function applyNodeUpdateChange(change: NodeUpdateChange, nodes: FlowNode[]) {
  const { nodeId, updater } = change;

  return nodes.map((node) => {
    if (node.id !== nodeId) {
      return node;
    }

    const updates = updater(node);
    const nextNode: typeof node = {
      ...node,
      ...updates,
    };

    return nextNode;
  });
}
