import { useCallback } from 'react';
import { filterGroupChildNodes } from 'utils/group';
import { mapNodeOutsideGroup } from 'utils/mappings';
import useFlow from './useFlow';
import { useUpdateNode } from './useUpdateNode';

export default function useRemoveGroup() {
  const { getNodes } = useFlow();
  const updateNode = useUpdateNode();

  return useCallback(
    /**
     * Sets all child nodes outside of a group.
     */
    (groupId: string) => {
      const nodes = getNodes();
      const childNodes = filterGroupChildNodes(nodes, groupId);

      childNodes.forEach(({ id: nodeId }) => {
        updateNode({ nodeId }, mapNodeOutsideGroup);
      });
    },
    [getNodes, updateNode],
  );
}
