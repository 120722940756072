import { NodeType } from 'features/Flow/Flow.types';
import { Node, NodeProps } from 'reactflow';
import { FlowNode, FlowNodeData } from 'types/reactflow';

export type GroupProps = NodeProps<GroupData>;

export interface GroupData extends FlowNodeData {
  metadata: {
    type: NodeType.GROUP;
  };
  color: string;
  description?: string;
  name: string;
}

export interface GroupNode extends Node<GroupData> {
  type: NodeType.GROUP;
}

export function isGroupNode(node?: Pick<FlowNode, 'type'>): node is GroupNode {
  return node?.type === NodeType.GROUP;
}

export function isGroupChildNode(node: FlowNode, nodes: FlowNode[]) {
  return (
    !!node.parentNode &&
    nodes.some((flowNode) => flowNode.id === node.parentNode && isGroupNode(flowNode))
  );
}
