import { SvgIcon, SvgIconProps } from '@mui/material';

const ListIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M14 23C13.7167 23 13.4792 22.9042 13.2875 22.7125C13.0958 22.5208 13 22.2833 13 22C13 21.7167 13.0958 21.4792 13.2875 21.2875C13.4792 21.0958 13.7167 21 14 21H24C24.2833 21 24.5208 21.0958 24.7125 21.2875C24.9042 21.4792 25 21.7167 25 22C25 22.2833 24.9042 22.5208 24.7125 22.7125C24.5208 22.9042 24.2833 23 24 23H14ZM14 17C13.7167 17 13.4792 16.9042 13.2875 16.7125C13.0958 16.5208 13 16.2833 13 16C13 15.7167 13.0958 15.4792 13.2875 15.2875C13.4792 15.0958 13.7167 15 14 15H24C24.2833 15 24.5208 15.0958 24.7125 15.2875C24.9042 15.4792 25 15.7167 25 16C25 16.2833 24.9042 16.5208 24.7125 16.7125C24.5208 16.9042 24.2833 17 24 17H14ZM14 11C13.7167 11 13.4792 10.9042 13.2875 10.7125C13.0958 10.5208 13 10.2833 13 10C13 9.71667 13.0958 9.47917 13.2875 9.2875C13.4792 9.09583 13.7167 9 14 9H24C24.2833 9 24.5208 9.09583 24.7125 9.2875C24.9042 9.47917 25 9.71667 25 10C25 10.2833 24.9042 10.5208 24.7125 10.7125C24.5208 10.9042 24.2833 11 24 11H14ZM9 24C8.45 24 7.97917 23.8042 7.5875 23.4125C7.19583 23.0208 7 22.55 7 22C7 21.45 7.19583 20.9792 7.5875 20.5875C7.97917 20.1958 8.45 20 9 20C9.55 20 10.0208 20.1958 10.4125 20.5875C10.8042 20.9792 11 21.45 11 22C11 22.55 10.8042 23.0208 10.4125 23.4125C10.0208 23.8042 9.55 24 9 24ZM9 18C8.45 18 7.97917 17.8042 7.5875 17.4125C7.19583 17.0208 7 16.55 7 16C7 15.45 7.19583 14.9792 7.5875 14.5875C7.97917 14.1958 8.45 14 9 14C9.55 14 10.0208 14.1958 10.4125 14.5875C10.8042 14.9792 11 15.45 11 16C11 16.55 10.8042 17.0208 10.4125 17.4125C10.0208 17.8042 9.55 18 9 18ZM9 12C8.45 12 7.97917 11.8042 7.5875 11.4125C7.19583 11.0208 7 10.55 7 10C7 9.45 7.19583 8.97917 7.5875 8.5875C7.97917 8.19583 8.45 8 9 8C9.55 8 10.0208 8.19583 10.4125 8.5875C10.8042 8.97917 11 9.45 11 10C11 10.55 10.8042 11.0208 10.4125 11.4125C10.0208 11.8042 9.55 12 9 12Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default ListIcon;
