import { DataContainer } from 'features/Flow/nodes/Node/Node.styles';
import styled, { css } from 'styled-components';

export {
  NodeContainer,
  NodeDataColumn,
  NodeHeader,
  NodeDataRow,
} from 'features/Flow/nodes/Node/Node.styles';

export const Input = styled(DataContainer)(
  ({ theme }) => css`
    justify-content: start;
    padding-right: ${theme.spacing(1)};
    gap: ${theme.spacing(1)};
  `,
);
