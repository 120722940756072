import * as Styled from 'components/Dialog/Dialog.styles';
import { PipelineCompleteQuickCreateModalProps } from './PipelineCompleteQuickCreateModal.types';
import PipelineCompleteCreateForm from '../PipelineCompleteForms/PipelineCompleteCreateForm';

export default function PipelineCompleteQuickCreateModal({
  inputs,
  defaultValues,
  open,
  onCloseModal,
  onCreateInput,
}: PipelineCompleteQuickCreateModalProps) {
  return (
    <Styled.Dialog
      open={open}
      onClose={onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <PipelineCompleteCreateForm
        isPendingConnection
        nodeInputs={inputs}
        defaultValues={defaultValues}
        onConfirmCreate={onCreateInput}
        onCancelCreate={onCloseModal}
      />
    </Styled.Dialog>
  );
}
