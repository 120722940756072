import { useCallback } from 'react';
import { useReactFlow } from 'reactflow';
import { isHandleConnection } from '../Flow.types';
import { isEdgeOfNode } from '../Flow.utils';

interface UseRemoveNodeEdgesProps {
  nodeId: string;
}

export const useRemoveNodeEdges = ({ nodeId }: UseRemoveNodeEdgesProps) => {
  const { getEdges, deleteElements } = useReactFlow();

  return useCallback(
    (handleIds: string[]) => {
      const edgesToDelete = getEdges().filter((edge) => {
        if (!isEdgeOfNode(edge, nodeId)) return false;
        if (!isHandleConnection(edge)) return false;

        const matchesSourceHandle = handleIds.includes(edge.sourceHandle);
        const matchesTargetHandle = handleIds.includes(edge.targetHandle);
        const matchesHandle = matchesSourceHandle || matchesTargetHandle;

        return matchesHandle;
      });

      deleteElements({
        edges: edgesToDelete,
      });
    },
    [deleteElements, getEdges, nodeId],
  );
};
