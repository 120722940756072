import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { NodeInput } from '../Node/Node.types';
import { Edge } from 'reactflow';
import { useGetDataHandle } from 'features/Flow/Handles/handles';
import { isHandleConnection } from 'features/Flow/Flow.types';
import { useCallback } from 'react';
import { HandleKey } from 'features/Flow/Handles/handle.store';
import { PipelineCompleteData } from './PipelineComplete.types';
import { unsetDataSchema } from 'utils/dataSchema';

// Custom hook for handling pipeline complete inputs special rules
// when connecting or disconnecting to other nodes.

interface UsePipelineCompleteOutputsProps {
  nodeId: string;
  inputs: PipelineCompleteData['inputs'];
}

export const usePipelineCompleteInputs = ({ nodeId, inputs }: UsePipelineCompleteOutputsProps) => {
  const updateNodeData = useUpdateNodeData();
  const getDataHandle = useGetDataHandle();

  const updateInput = useCallback(
    (newInput: NodeInput) => {
      updateNodeData<PipelineCompleteData>(nodeId, (data) => {
        const newInputs = data.inputs.map((input) => {
          if (input.id === newInput.id) {
            return newInput;
          }

          return input;
        });

        return {
          ...data,
          inputs: newInputs,
        };
      });
    },
    [nodeId, updateNodeData],
  );

  const handleConnectedInput = useCallback(
    (input: NodeInput, inputEdge: Edge) => {
      if (!isHandleConnection(inputEdge)) return;

      const handleKey: HandleKey = {
        id: inputEdge.sourceHandle,
        nodeId: inputEdge.source,
      };

      const sourceHandle = getDataHandle(handleKey);
      const sourceSchema = sourceHandle?.schema ?? input.dataSchema;
      const sourceConfig = sourceHandle?.config ?? {};

      const newInput: NodeInput = {
        ...input,
        config: {
          ...sourceConfig,
          required: true,
        },
        dataSchema: sourceSchema,
      };

      updateInput(newInput);
    },
    [getDataHandle, updateInput],
  );

  const handleDisconnectedInput = useCallback(
    (input: NodeInput) => {
      const newInput = {
        ...input,
        config: { ...input.config, required: false },
        dataSchema: unsetDataSchema(input.dataSchema),
      };

      updateInput(newInput);
    },
    [updateInput],
  );

  const attemptAddInput = useCallback(
    (input: NodeInput, nodeId: string): boolean => {
      if (inputs.length > 0) {
        const isNewKeyInUse = inputs.some((item) => item.name === input.name);

        if (isNewKeyInUse) {
          return false;
        }
      }

      updateNodeData<PipelineCompleteData>(nodeId, (data) => ({
        ...data,
        inputs: [...inputs, input],
      }));

      return true;
    },
    [inputs, updateNodeData],
  );

  return {
    handleConnectedInput,
    handleDisconnectedInput,
    attemptAddInput,
  };
};
