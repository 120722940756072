import { XYPosition } from 'reactflow';

function setLocalStorageItem<T>(key: string, value: T): void {
  localStorage.setItem(key, JSON.stringify(value));
}

function getLocalStorageItem<T>(key: string) {
  const value = localStorage.getItem(key);

  if (value) {
    return JSON.parse(value) as T;
  }

  return null;
}

enum LocalStorageKeys {
  CURSOR_POSITION = 'cursorPosition',
}

export function setCursorPosition(cursorPosition: XYPosition) {
  setLocalStorageItem(LocalStorageKeys.CURSOR_POSITION, cursorPosition);
}

export function getCursorPosition() {
  return getLocalStorageItem<XYPosition>(LocalStorageKeys.CURSOR_POSITION);
}
