import { SvgIcon, SvgIconProps } from '@mui/material';

const NumericArrayIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M13.5964 19.75L12.9354 22.4109C12.8953 22.587 12.8085 22.7292 12.675 22.8375C12.5414 22.9458 12.3812 23 12.1942 23C11.9405 23 11.7335 22.8984 11.5733 22.6953C11.4131 22.4922 11.3663 22.2688 11.4331 22.025L11.9939 19.75H9.81064C9.54357 19.75 9.32991 19.6451 9.16967 19.4352C9.00943 19.2253 8.96269 18.9917 9.02946 18.7344C9.06952 18.5448 9.16299 18.3958 9.30988 18.2875C9.45677 18.1792 9.62369 18.125 9.81064 18.125H12.3945L13.1958 14.875H11.0125C10.7454 14.875 10.5317 14.7701 10.3715 14.5602C10.2112 14.3503 10.1645 14.1167 10.2313 13.8594C10.2713 13.6698 10.3648 13.5208 10.5117 13.4125C10.6586 13.3042 10.8255 13.25 11.0125 13.25H13.5964L14.2574 10.5891C14.2974 10.413 14.3842 10.2708 14.5178 10.1625C14.6513 10.0542 14.8115 10 14.9985 10C15.2522 10 15.4592 10.1016 15.6194 10.3047C15.7797 10.5078 15.8264 10.7313 15.7596 10.975L15.1988 13.25H18.4036L19.0646 10.5891C19.1047 10.413 19.1915 10.2708 19.325 10.1625C19.4586 10.0542 19.6188 10 19.8058 10C20.0595 10 20.2665 10.1016 20.4267 10.3047C20.5869 10.5078 20.6337 10.7313 20.5669 10.975L20.0061 13.25H22.1894C22.4564 13.25 22.6701 13.3549 22.8303 13.5648C22.9906 13.7747 23.0373 14.0083 22.9705 14.2656C22.9305 14.4552 22.837 14.6042 22.6901 14.7125C22.5432 14.8208 22.3763 14.875 22.1894 14.875H19.6055L18.8042 18.125H20.9875C21.2546 18.125 21.4683 18.2299 21.6285 18.4398C21.7888 18.6497 21.8355 18.8833 21.7687 19.1406C21.7287 19.3302 21.6352 19.4792 21.4883 19.5875C21.3414 19.6958 21.1745 19.75 20.9875 19.75H18.4036L17.7426 22.4109C17.7026 22.587 17.6158 22.7292 17.4822 22.8375C17.3487 22.9458 17.1885 23 17.0015 23C16.7478 23 16.5408 22.8984 16.3806 22.6953C16.2203 22.4922 16.1736 22.2688 16.2404 22.025L16.8012 19.75H13.5964ZM13.997 18.125H17.2018L18.003 14.875H14.7982L13.997 18.125Z"
          fill="black"
        />
        <path
          d="M5.875 25.125C6.45833 25.7083 7.16667 26 8 26H10C10.2833 26 10.5208 25.9042 10.7125 25.7125C10.9042 25.5208 11 25.2833 11 25C11 24.7167 10.9042 24.4792 10.7125 24.2875C10.5208 24.0958 10.2833 24 10 24H8C7.71667 24 7.47917 23.9042 7.2875 23.7125C7.09583 23.5208 7 23.2833 7 23V9C7 8.71667 7.09583 8.47917 7.2875 8.2875C7.47917 8.09583 7.71667 8 8 8H10C10.2833 8 10.5208 7.90417 10.7125 7.7125C10.9042 7.52083 11 7.28333 11 7C11 6.71667 10.9042 6.47917 10.7125 6.2875C10.5208 6.09583 10.2833 6 10 6H8C7.16667 6 6.45833 6.29167 5.875 6.875C5.29167 7.45833 5 8.16667 5 9V23C5 23.8333 5.29167 24.5417 5.875 25.125Z"
          fill="black"
        />
        <path
          d="M26.125 25.125C25.5417 25.7083 24.8333 26 24 26H22C21.7167 26 21.4792 25.9042 21.2875 25.7125C21.0958 25.5208 21 25.2833 21 25C21 24.7167 21.0958 24.4792 21.2875 24.2875C21.4792 24.0958 21.7167 24 22 24H24C24.2833 24 24.5208 23.9042 24.7125 23.7125C24.9042 23.5208 25 23.2833 25 23V9C25 8.71667 24.9042 8.47917 24.7125 8.2875C24.5208 8.09583 24.2833 8 24 8H22C21.7167 8 21.4792 7.90417 21.2875 7.7125C21.0958 7.52083 21 7.28333 21 7C21 6.71667 21.0958 6.47917 21.2875 6.2875C21.4792 6.09583 21.7167 6 22 6H24C24.8333 6 25.5417 6.29167 26.125 6.875C26.7083 7.45833 27 8.16667 27 9V23C27 23.8333 26.7083 24.5417 26.125 25.125Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default NumericArrayIcon;
