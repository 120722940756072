import { useState } from 'react';
import { ObjectExtractorCommitFn } from '../ObjectExtractor.types';
import { PathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { ObjectExtractorModalProps } from './ObjectExtractorModal';
import { useRemoveNodeEdges } from 'features/Flow/hooks/useRemoveNodeEdges';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { useUpdateNodeInput } from 'features/Flow/hooks/useUpdateNodeInput';
import { EXTRACT_INPUT_NAME } from '../ObjectExtractor';

interface UseObjectExtractorModalProps {
  nodeId: string;
}

type UseObjectExtractorModalReturn = Omit<ObjectExtractorModalProps, 'outputs' | 'extractions'> & {
  openModal: () => void;
};

export const useObjectExtractorModal = ({
  nodeId,
}: UseObjectExtractorModalProps): UseObjectExtractorModalReturn => {
  const [open, setOpen] = useState<boolean>(false);
  const removeNodeEdges = useRemoveNodeEdges({ nodeId });
  const updateNodeData = useUpdateNodeData();
  const updateNodeInput = useUpdateNodeInput();

  const handleCancelChanges = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleOnConfirmChanges: ObjectExtractorCommitFn = (
    updated,
    handleIdsToRemove,
    extractions,
  ) => {
    if (handleIdsToRemove.length > 0) {
      removeNodeEdges(handleIdsToRemove);
    }

    updateNodeData<PathwayNode['data']>(nodeId, () => ({
      outputs: updated,
    }));

    updateNodeInput(
      {
        nodeId,
        inputName: EXTRACT_INPUT_NAME,
      },
      () => ({
        value: extractions,
      }),
    );

    setOpen(false);
  };

  return {
    open,
    nodeId,
    openModal: handleOpenModal,
    onCloseModal: handleCancelChanges,
    onConfirm: handleOnConfirmChanges,
  };
};
