import { Layout, MuiContainer, ScrollContainer } from 'components/Layout/Layout.styles';
import PageNavigation from 'pages/ExecutionsPage/ExecutionsLayoutNavigation';
import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { ExecutionContextProvider } from './contexts/execution/ExecutionContext';
import { useExecutionContext } from './contexts/execution/useExecutionContext';
import { ExecutionCheckpointProvider } from './pages/ViewExecutionPage/components/ExecutionCheckpoint/contexts/ExecutionCheckpointContext';

export default function ExecutionsPage() {
  return (
    <ExecutionsPageProviders>
      <Layout>
        <PageNavigation />

        <ScrollContainer>
          <MuiContainer fixed>
            <Outlet />
          </MuiContainer>
        </ScrollContainer>

        <ExecutionsPageFooter />
      </Layout>
    </ExecutionsPageProviders>
  );
}

export function ExecutionsPageProviders({ children }: PropsWithChildren) {
  return (
    <ExecutionContextProvider>
      <ExecutionCheckpointProvider>{children}</ExecutionCheckpointProvider>
    </ExecutionContextProvider>
  );
}

export function ExecutionsPageFooter() {
  const context = useExecutionContext();
  return context.pageFooter;
}
