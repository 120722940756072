import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

interface DeletePipelineDialogProps extends Pick<DialogProps, 'open'> {
  onClose: () => void;
  onConfirm: () => void;
}

export default function DeletePipelineDialog(props: DeletePipelineDialogProps) {
  const titleId = 'delete-pipeline-dialog-title';

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby={titleId}
      PaperProps={{
        elevation: 24,
      }}
    >
      <DialogTitle id={titleId}>Delete Pipeline?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you’d like to delete this pipeline? All assets produced by runs of this
          pipeline will no longer be accessible and any in-progress runs will be canceled.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>
          Go Back
        </Button>
        <Button color="error" onClick={props.onConfirm}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
