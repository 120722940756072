import { useCallback } from 'react';
import { useStoreApi } from 'reactflow';
import {
  NodeOutputUpdater,
  NodeUpdateOutputChange,
  createNodeUpdateOutputChange,
} from '../changes/NodeUpdateOutputChange';
import { NodeOutput } from '../nodes/Node/Node.types';

type UseUpdateNodeOutputParams = Omit<NodeUpdateOutputChange, 'type' | 'updater'>;

export function useUpdateNodeOutput() {
  const storeApi = useStoreApi();

  return useCallback(
    <TOutput extends NodeOutput>(
      params: UseUpdateNodeOutputParams,
      updater: NodeOutputUpdater<TOutput>,
    ) => {
      storeApi.getState().onNodesChange?.([
        createNodeUpdateOutputChange<TOutput>({
          nodeId: params.nodeId,
          outputName: params.outputName,
          updater,
        }),
      ]);
    },
    [storeApi],
  );
}
