import { Stack, Switch, Typography } from '@mui/material';
import { usePipeline } from 'api/services/usePipeline/usePipeline';
import { useUpdatePipelineMetadata } from 'api/services/useUpdatePipelineMetadata/useUpdatePipelineMetadata';
import { AxiosError } from 'axios';
import useToast from 'contexts/toast/useToast';
import useJobNodes from 'features/Flow/hooks/useJobNodes';
import { isSubpipelineNode } from 'features/Flow/nodes/Subpipeline/Subpipeline.types';
import { PipelineResponse } from 'types/pipeline';

interface CallableToggleProps {
  projectId?: string;
  pipelineId: string;
  readOnly?: boolean;
}

const CallableToggle = ({ pipelineId, readOnly = false }: CallableToggleProps) => {
  const toast = useToast();
  const jobNodes = useJobNodes();
  const { updatePipeline } = useUpdatePipelineMetadata({ pipelineId });
  const { response } = usePipeline(pipelineId, {
    revalidateOnMount: false,
  });

  const hasSubpipelineNode = jobNodes.some(isSubpipelineNode);
  if (hasSubpipelineNode) return null;

  const currentType = response?.data.type ?? 'default';

  const handleToggle = async () => {
    if (!response?.data) return;
    const newType: PipelineResponse['type'] = currentType === 'callable' ? 'default' : 'callable';

    try {
      await updatePipeline({ type: newType });
    } catch (err) {
      if (err instanceof AxiosError) {
        // eslint-disable-next-line
        toast.error({ message: err.response?.data?.message || 'Failed to update pipeline type' });
      }
    }
  };

  return (
    <Stack justifyContent="space-between" alignItems="center" direction="row">
      <Typography variant="labelMedium">Callable Pipeline</Typography>
      <Switch
        data-testid="SwitchComponent"
        checked={currentType === 'callable'}
        onClick={handleToggle}
        disabled={readOnly}
      />
    </Stack>
  );
};
export default CallableToggle;
