import axios, { AxiosResponse } from 'axios';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';

const exportPipelineFetcher: MutationFetcher<
  AxiosResponse<Blob>,
  string,
  { pipelineId: string; versionId?: string }
> = (_, { arg: request }) => {
  const requestUrl = `/pipelines/${request.pipelineId}/export`;
  const queryParams = new URLSearchParams();
  if (request.versionId) {
    queryParams.append('versionId', request.versionId);
  }

  return axios.get(`${requestUrl}?${queryParams.toString()}`);
};

const useExportPipeline = () => {
  const { isMutating, trigger } = useSWRMutation('useExportPipeline', exportPipelineFetcher);

  const getFilenameFromDisposition = (disposition: string) => {
    return disposition.split('filename=')[1] || 'pipeline_export.json';
  };

  const exportPipeline = async (arg: Parameters<typeof trigger>[0]) => {
    const { data, headers } = await trigger(arg);
    const contentDisposition = (headers['content-disposition'] ?? '') as string;
    const fileName = getFilenameFromDisposition(contentDisposition);
    const blob = new Blob([JSON.stringify(data)], {
      type: 'application/json',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    link.remove();
  };

  return { exportPipeline, isLoading: isMutating };
};

export default useExportPipeline;
