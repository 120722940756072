import { useState } from 'react';
import { useRemoveNodeEdges } from 'features/Flow/hooks/useRemoveNodeEdges';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { PipelineCompleteData } from '../PipelineComplete.types';
import { InputConfigCommitFn } from './PipelineCompleteModal.types';
import { useUpdateNodeInternals } from 'reactflow';

interface UsePipelineCompleteModalProps {
  nodeId: string;
}

interface UsePipelineCompleteModalReturn {
  nodeId: string;
  open: boolean;
  onCloseModal: () => void;
  onConfirm: InputConfigCommitFn;
  openModal: () => void;
}

export const usePipelineCompleteModal = ({
  nodeId,
}: UsePipelineCompleteModalProps): UsePipelineCompleteModalReturn => {
  const [open, setOpen] = useState<boolean>(false);
  const removeNodeEdges = useRemoveNodeEdges({ nodeId });
  const updateNodeData = useUpdateNodeData();
  const updateNodeInternals = useUpdateNodeInternals();

  const handleCancelChanges = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleOnConfirmChanges: InputConfigCommitFn = (updatedInputs, handleIdsToRemove) => {
    if (handleIdsToRemove.length > 0) {
      removeNodeEdges(handleIdsToRemove);
    }

    updateNodeData<PipelineCompleteData>(nodeId, () => ({
      inputs: updatedInputs,
    }));
    updateNodeInternals(nodeId);

    setOpen(false);
  };

  return {
    open,
    nodeId,
    openModal: handleOpenModal,
    onCloseModal: handleCancelChanges,
    onConfirm: handleOnConfirmChanges,
  };
};
