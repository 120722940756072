import { SvgIcon, SvgIconProps } from '@mui/material';

const MinusIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M19 13H5V11H19V13Z" fill="white" fillOpacity="0.56" />
      </svg>
    </SvgIcon>
  );
};

export default MinusIcon;
