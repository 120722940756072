import { Sidebar } from 'components/Sidebar/Sidebar.styles';
import styled from 'styled-components';
import { Typography } from '@mui/material';

export const Container = styled(Sidebar)`
  position: absolute;
  z-index: 5;
  top: 65px;
  left: 264px;
  width: 312px;
  bottom: 0;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
`;

export const NoResultText = styled(Typography)`
  align-self: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
