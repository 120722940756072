import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import {
  NodeVariationData,
  SelectVariationField,
  VariationDataContainer,
} from './Variation.styles';
import { NodeVariationProps } from './Variation.types';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { FC } from 'react';
import { NodeData } from '../../Node.types';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { formatNodeInputs, formatNodeOutputs } from 'utils/neurons';
import { IOSchema } from 'api/services/useManifests/useManifests.types';
import { flattenSchemaVariations, JobIOSchema } from '@pathways/pipeline-schema/web';
import { useRemoveNodeEdges } from 'features/Flow/hooks/useRemoveNodeEdges';
import { createHandleId } from 'features/Flow/Flow.utils';
import { useUpdateNodeInternals } from 'reactflow';
import { keepEdgeConnection, mergeVariationInputs } from './Variation.utils';

export const NodeVariation: FC<NodeVariationProps> = ({
  schemas,
  nodeId,
  selectedVariation,
  inputs,
  outputs,
  disabled,
}) => {
  const updateNodeData = useUpdateNodeData();
  const removeNodeEdges = useRemoveNodeEdges({ nodeId });
  const updateNodeInternals = useUpdateNodeInternals();

  const refreshEdges = (inputsSchema: IOSchema, outputsSchema: IOSchema) => {
    const inputHandleIds = inputs
      .filter((input) => keepEdgeConnection(inputsSchema, input))
      .map((prop) => createHandleId('input', prop.name));

    const outputHandleIds = outputs
      .filter((output) => keepEdgeConnection(outputsSchema, output))
      .map((prop) => createHandleId('output', prop.name));

    removeNodeEdges(inputHandleIds.concat(outputHandleIds));
  };

  const onVariationChange: SelectInputProps['onChange'] = (event) => {
    const {
      target: { value },
    } = event as SelectChangeEvent;
    const flattenedSchema = flattenSchemaVariations(schemas as JobIOSchema, value);
    refreshEdges(flattenedSchema.input as IOSchema, flattenedSchema.output as IOSchema);

    updateNodeData<NodeData>(nodeId, (data) => {
      const updatedInputs = mergeVariationInputs(
        data.inputs,
        formatNodeInputs(flattenedSchema.input as IOSchema),
      );

      return {
        ...data,
        metadata: {
          ...data.metadata,
          variation: value,
        },
        inputs: updatedInputs,
        outputs: formatNodeOutputs(flattenedSchema.output as IOSchema),
      };
    });

    updateNodeInternals(nodeId);
  };

  return (
    <VariationDataContainer>
      <NodeVariationData className="nodrag">
        <TextEllipsis variant="bodyMedium">{schemas.variations.title}</TextEllipsis>
        <SelectVariationField
          size="small"
          fullWidth={true}
          value={selectedVariation}
          onChange={onVariationChange}
          autoWidth={false}
          disabled={disabled}
          inputProps={{ 'data-testid': `variation-${nodeId}` }}
          // Hide dropdown arrow if disabled
          IconComponent={disabled ? () => null : undefined}
        >
          {schemas.variations.options.map((variation) => (
            <MenuItem key={variation.title} value={variation.title}>
              {variation.title}
            </MenuItem>
          ))}
        </SelectVariationField>
      </NodeVariationData>
    </VariationDataContainer>
  );
};
