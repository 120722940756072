import GroupContainer from 'components/GroupContainer/GroupContainer';
import GroupName from 'components/GroupContainer/GroupName';
import { getGroupName } from 'utils/group';
import { GroupProps } from './Group.types';
import { useGroupDescription } from 'components/GroupContainer/GroupDescription/useGroupDescription';
import GroupDescription from 'components/GroupContainer/GroupDescription/GroupDescription';

export default function Group(props: GroupProps) {
  const {
    data: { color, name, description },
  } = props;

  const { descriptionHeight, descriptionRef } = useGroupDescription();

  return (
    <GroupContainer {...props} color={color} extraHeight={descriptionHeight}>
      <GroupName label={getGroupName(name)} />
      <GroupDescription ref={descriptionRef} description={description} />
    </GroupContainer>
  );
}
