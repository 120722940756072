import { BatchGroupNode, isBatchChildNode } from 'features/Flow/nodes/Batch/Batch.types';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { Connection, Edge, useStoreApi } from 'reactflow';
import { FlowNode } from 'types/reactflow';
import { APP_REACTFLOW_DATATRANSFER } from './Flow.consts';
import { ClipboardData, HandleConnection, NodeType } from './Flow.types';
import { NodeInput, NodeOutput, PathwayNode, isPathwayNode } from './nodes/Node/Node.types';

export const createNodeId = () => nanoid();
export type HandleType = 'input' | 'output';

// Nano ID default alphabet doesn't generate ':' characters.
const HANDLE_ID_SEPARATOR = ':';

export function createHandleId(type: HandleType, key: string) {
  return `${type}${HANDLE_ID_SEPARATOR}${key}`;
}

export function getHandleTypeFromId(handleId?: string | null): HandleType | undefined {
  return handleId?.split(HANDLE_ID_SEPARATOR)[0] as HandleType;
}

export function getHandleKeyFromId(handleId?: string | null) {
  return handleId?.split(HANDLE_ID_SEPARATOR)[1];
}

export const isEdgeOfNode = (edge: Edge, nodeId: string) => {
  return edge.source === nodeId || edge.target === nodeId;
};

export const findNodeById = (nodes: PathwayNode[], id: string) => {
  const nodeIndex = nodes.findIndex((node) => node.id === id);
  const node = nodes[nodeIndex];

  return { node, nodeIndex };
};

export const isNodeNameUnique = (edges: Edge[], inputName?: string) => {
  if (!inputName) return true;

  const countNames: Record<string, number> = {};

  edges.forEach((edge) => {
    const edgeName = getHandleKeyFromId(edge.targetHandle);
    if (!edgeName) return;

    if (countNames[edgeName]) {
      countNames[edgeName]++;
    } else {
      countNames[edgeName] = 1;
    }
  });

  return countNames[inputName] === 1;
};

export function areEdgeAndConnectionEqual(edge: Edge, connection: Connection) {
  return (
    edge.source === connection.source &&
    edge.sourceHandle === connection.sourceHandle &&
    edge.target === connection.target &&
    edge.targetHandle === connection.targetHandle
  );
}

export const useSelectNodeOnClick = (nodeId = '') => {
  const store = useStoreApi();
  const { addSelectedNodes } = store.getState();

  const selectNodeOnClick = useCallback(() => {
    addSelectedNodes([nodeId]);
  }, [addSelectedNodes, nodeId]);

  return selectNodeOnClick;
};

export function isClipboardData(data: object): data is ClipboardData {
  return 'dataType' in data && data.dataType === APP_REACTFLOW_DATATRANSFER;
}

export function canNodesBeGrouped(
  nodesToGroup: Pick<FlowNode, 'type' | 'parentNode'>[],
  allNodes: FlowNode[],
) {
  return nodesToGroup.every(
    (node) => !node.parentNode && isPathwayNode(node) && !isBatchChildNode(node, allNodes),
  );
}

/**
 * @param batchGroup The batch group that is being checked against
 * @param selectedNodes Selected nodes exlusive of the batch group tht this is checking against
 * @param edges Existing edge connections for the selected nodes
 * @returns boolean
 */
export function canNodesBeAddedToBatch(param: {
  batchGroup: BatchGroupNode;
  selectedNodes: Pick<FlowNode, 'id' | 'type' | 'parentNode'>[];
  edges: Edge[];
}) {
  const { batchGroup, selectedNodes, edges } = param;
  const nodesWithoutSelectedBatchGroup = selectedNodes.filter((node) => {
    return node.id !== batchGroup.id;
  });
  return nodesWithoutSelectedBatchGroup.every((node) => {
    const hasExpectedType = node.type === NodeType.NEURON || node.type === NodeType.FUNCTION;

    const isChild = !!node.parentNode;

    const hasConnections = edges.some((edge) => edge.source === node.id || edge.target === node.id);

    return hasExpectedType && !isChild && !hasConnections;
  });
}

type WithId<T> = T & { id: string };

export function getNodeInput(dataItem: NodeInput): WithId<NodeInput> {
  dataItem.id ??= createHandleId('input', dataItem.name);
  return dataItem as WithId<NodeInput>;
}

export function getNodeOutput(dataItem: NodeOutput): WithId<NodeOutput> {
  dataItem.id ??= createHandleId('output', dataItem.name);
  return dataItem as WithId<NodeOutput>;
}

export function getTargetInputKeyByEdge(edge: Edge | HandleConnection) {
  const targetNodeId = edge.target;
  const targetInputName = getHandleKeyFromId(edge.targetHandle) ?? '';

  return {
    nodeId: targetNodeId,
    inputName: targetInputName,
  };
}
