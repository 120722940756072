import { Alert, AlertProps, AlertTitle } from '@mui/material';

export default function ErrorAlert(props: AlertProps) {
  return (
    <Alert severity="error">
      {props.children && <AlertTitle>Error</AlertTitle>}
      {props.children ?? 'Job execution failed.'}
    </Alert>
  );
}
