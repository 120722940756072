import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 8 10">
        <path d="M0 10V0L8 5L0 10Z" />
      </svg>
    </SvgIcon>
  );
};

export default ArrowIcon;
