import { DummyDataMenuItem } from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuron.types';

// Source: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// Filtered using `mime-types` library to avoid not found extensions warning on AssetInput
export const COMMON_MIME_TYPES = [
  'application/x-abiword',
  'image/apng',
  'application/x-freearc',
  'image/avif',
  'video/x-msvideo',
  'application/vnd.amazon.ebook',
  'application/octet-stream',
  'image/bmp',
  'application/x-bzip',
  'application/x-bzip2',
  'application/x-csh',
  'text/css',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-fontobject',
  'application/epub+zip',
  'application/gzip',
  'image/gif',
  'text/html',
  'image/vnd.microsoft.icon',
  'text/calendar',
  'application/java-archive',
  'image/jpeg',
  'application/json',
  'application/ld+json',
  'audio/mpeg',
  'video/mp4',
  'video/mpeg',
  'application/vnd.apple.installer+xml',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'audio/ogg',
  'video/ogg',
  'application/ogg',
  'font/otf',
  'image/png',
  'application/pdf',
  'application/x-httpd-php',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.rar',
  'application/rtf',
  'application/x-sh',
  'image/svg+xml',
  'application/x-tar',
  'image/tiff',
  'video/mp2t',
  'font/ttf',
  'text/plain',
  'application/vnd.visio',
  'audio/wav',
  'audio/webm',
  'video/webm',
  'image/webp',
  'font/woff',
  'font/woff2',
  'application/xhtml+xml',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/xml',
  'application/vnd.mozilla.xul+xml',
  'application/zip',
  'audio/3gpp',
  'video/3gpp',
  'video/3gpp2',
  'application/x-7z-compressed',
];

export const DUMMY_NEURON_DATA_MENU_ITEMS: DummyDataMenuItem[] = [
  { schema: { type: 'integer' }, title: 'Integer' },
  { schema: { type: 'number' }, title: 'Number' },
  { schema: { type: 'text' }, title: 'Text' },
  { schema: { type: 'boolean' }, title: 'Boolean' },
  { schema: { type: 'object' }, title: 'Object' },
  {
    schema: {
      type: 'asset',
      mediaType: COMMON_MIME_TYPES,
    },
    title: 'Asset',
  },
  {
    schema: {
      type: 'array',
      items: {
        type: null,
      },
    },
    title: 'Array',
  },
];

export const DUMMY_NEURON_ARRAY_TYPE_MENU_ITEMS: DummyDataMenuItem[] = [
  { schema: { type: 'integer' }, title: 'Integer' },
  { schema: { type: 'number' }, title: 'Number' },
  { schema: { type: 'text' }, title: 'Text' },
  { schema: { type: 'object' }, title: 'Object' },
  {
    schema: {
      type: 'asset',
      mediaType: COMMON_MIME_TYPES,
    },
    title: 'Asset',
  },
];
