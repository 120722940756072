import { Box } from '@mui/material';
import {
  blue,
  cyan,
  deepPurple,
  lightGreen,
  orange,
  pink,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import { Color, ColorValue } from 'components/ColorField/ColorField.consts';
import { ElementLayerButton } from 'features/Flow/components/ElementLayerButton/ElementLayerButton.styles';
import { BATCH_NODE_COLOR } from 'features/Flow/nodes/Batch/Batch.consts';
import { ACTION_COLOR } from 'config/theme';
import styled, { css } from 'styled-components';

interface ColorConfig {
  defaultIconColor: string;
  selectedBackgroundColor: string;
  selectedTextColor?: string;
}

type ColorKeys = ColorValue | typeof BATCH_NODE_COLOR;

const COLOR_CONFIG: Record<ColorKeys, ColorConfig | undefined> = {
  [Color.ORANGE]: {
    defaultIconColor: orange[400],
    selectedBackgroundColor: orange[50],
  },
  [Color.RED]: {
    defaultIconColor: red[400],
    selectedBackgroundColor: red[50],
  },
  [Color.YELLOW]: {
    defaultIconColor: yellow[500],
    selectedBackgroundColor: yellow[50],
    selectedTextColor: yellow[900],
  },
  [Color.LIGHT_GREEN]: {
    defaultIconColor: lightGreen.A400,
    selectedBackgroundColor: lightGreen[50],
    selectedTextColor: lightGreen[800],
  },
  [Color.CYAN]: {
    defaultIconColor: cyan.A400,
    selectedBackgroundColor: cyan[50],
    selectedTextColor: cyan[700],
  },
  [Color.BLUE]: {
    defaultIconColor: blue[300],
    selectedBackgroundColor: blue[50],
    selectedTextColor: blue.A400,
  },
  [Color.DEEP_PURPLE]: {
    defaultIconColor: deepPurple[100],
    selectedBackgroundColor: deepPurple[50],
  },
  [Color.PINK]: {
    defaultIconColor: pink[200],
    selectedBackgroundColor: pink[50],
    selectedTextColor: pink.A400,
  },
  [BATCH_NODE_COLOR]: {
    defaultIconColor: BATCH_NODE_COLOR,
    selectedBackgroundColor: teal[50],
    selectedTextColor: teal[900],
  },
};

export const Container = styled(Box)<{ $expanded: boolean }>(
  ({ $expanded }) => css`
    .expand-icon {
      transform: rotate(0deg);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    ${$expanded &&
    css`
      .expand-icon {
        transform: rotate(180deg);
      }
    `}
  `,
);

interface GroupElementLayerButtonProps {
  $color: string;
  selected?: boolean;
}

export const GroupElementLayerButton = styled(ElementLayerButton)<GroupElementLayerButtonProps>(({
  theme,
  $color,
  selected,
}) => {
  const { defaultIconColor, selectedBackgroundColor, selectedTextColor } = COLOR_CONFIG[
    $color as ColorValue
  ] ?? {
    defaultIconColor: 'white',
    selectedBackgroundColor: 'white',
  };

  return css`
    width: 100%;

    .expand-icon {
      margin-left: auto;
    }

    ${() => {
      if (selected) {
        const textColor = selectedTextColor ?? $color;

        return css`
          color: ${textColor};
          background-color: ${selectedBackgroundColor};

          &:hover {
            background-color: ${selectedBackgroundColor};
          }

          .icon {
            fill: ${textColor};
          }

          .expand-icon {
            fill: ${theme.palette.common.black};
          }
        `;
      }

      return css`
        .icon {
          fill: ${defaultIconColor};
        }

        &:hover {
          background-color: ${ACTION_COLOR.hover($color)};
        }
      `;
    }}
  `;
});
