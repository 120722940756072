import { useState } from 'react';
import { getNodeOutput } from 'features/Flow/Flow.utils';
import { DEFAULT_VIEW, OutputConfigCommitFn, ViewStates } from './PipelineStartModal.types';
import { arrayMove } from '@dnd-kit/sortable';
import * as Styled from 'components/Dialog/Dialog.styles';
import { NodeOutput } from '../../Node/Node.types';
import PipelineStartCreateForm from '../PipelineStartForms/PipelineStartCreateForm';
import PipelineStartList from './PipelineStartList';
import { PipelineStartEditForm } from '../PipelineStartForms/PipelineStartEditForm';

export interface PipelineStartConfigModalProps {
  nodeId: string;
  outputs: NodeOutput[];
  open: boolean;
  onCloseModal: () => void;
  onConfirm: OutputConfigCommitFn;
}

export default function PipelineStartConfigModal({
  outputs,
  open,
  onCloseModal,
  onConfirm,
  nodeId,
}: PipelineStartConfigModalProps) {
  const [nodeOutputs, setNodeOutputs] = useState<NodeOutput[]>(outputs);
  const [removedOutputs, setRemovedOutputs] = useState<string[]>([]);
  const [viewState, setViewState] = useState<ViewStates>({ view: DEFAULT_VIEW });

  const handleCreateOutputClick = () => {
    setViewState({ view: 'create' });
  };

  const handleEditOutput = (output: NodeOutput, index: number) => {
    setViewState({ view: 'edit', outputToEdit: output, updateIndex: index });
  };

  const handleFormCancel = () => {
    setViewState({ view: DEFAULT_VIEW });
  };

  const handleConfirmCreate = (newOutput: NodeOutput) => {
    setNodeOutputs((prev) => {
      return [...prev, newOutput];
    });
    setViewState({ view: DEFAULT_VIEW });
  };

  const handleRemoveOutput = (indexToRemove: number) => {
    const outputToRemove = getNodeOutput(nodeOutputs[indexToRemove]);

    setRemovedOutputs((prev) => {
      return [...prev, outputToRemove.id];
    });
    setNodeOutputs((prev) => {
      return prev.filter((_, index) => indexToRemove !== index);
    });
  };

  const handleCommitChanges = () => {
    onConfirm(nodeOutputs, removedOutputs);
  };

  const handleReorder = (from: string, to: string) => {
    setNodeOutputs((nodeOutputs) => {
      const oldIndex = nodeOutputs.findIndex((output) => output.name === from);
      const newIndex = nodeOutputs.findIndex((output) => output.name === to);
      if (oldIndex === -1 || newIndex === -1) return nodeOutputs;
      return arrayMove(nodeOutputs, oldIndex, newIndex);
    });
  };

  const handleConfirmEdit = (update: NodeOutput) => {
    if (viewState.view !== 'edit') return;

    setNodeOutputs((prev) => {
      return prev.map((output, index) =>
        viewState.updateIndex === index ? { ...output, ...update } : output,
      );
    });
    setViewState({ view: DEFAULT_VIEW });
  };

  return (
    <Styled.Dialog
      open={open}
      onClose={onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      {viewState.view === DEFAULT_VIEW && (
        <PipelineStartList
          outputs={nodeOutputs}
          onCloseModal={onCloseModal}
          onConfirm={handleCommitChanges}
          onCreateOutput={handleCreateOutputClick}
          onEditOutput={handleEditOutput}
          onRemoveOutput={handleRemoveOutput}
          onReorder={handleReorder}
        />
      )}

      {viewState.view === 'edit' && (
        <PipelineStartEditForm
          nodeId={nodeId}
          outputs={nodeOutputs}
          outputToEdit={viewState.outputToEdit}
          onCancelEdit={handleFormCancel}
          onConfirmEdit={handleConfirmEdit}
        />
      )}

      {viewState.view === 'create' && (
        <PipelineStartCreateForm
          nodeOutputs={nodeOutputs}
          defaultValues={null}
          onCancelCreate={handleFormCancel}
          onConfirmCreate={handleConfirmCreate}
        />
      )}
    </Styled.Dialog>
  );
}
