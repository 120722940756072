import { JOB_TYPE_ICON_CONFIG } from './JobTypeIcon.consts';
import * as Styled from './JobTypeIcon.styles';
import { JobTypeIconProps } from './JobTypeIcon.types';

export default function JobTypeIcon(props: JobTypeIconProps) {
  const { type, variant = 'default', color, bgcolor, containerProps, ...iconProps } = props;
  const baseConfig = JOB_TYPE_ICON_CONFIG[type];

  if (!baseConfig) return null;

  const Icon = baseConfig.icon;

  const variantProps = baseConfig.variantProps[variant];
  const config: typeof baseConfig.variantProps = {
    default: {
      container: {
        ...variantProps.container,
        color: color ?? variantProps.container?.color,
      },
    },
    filled: {
      container: {
        ...variantProps.container,
        color: color ?? 'text.primary',
        bgcolor: bgcolor ?? variantProps.container?.bgcolor,
      },
    },
  };

  return (
    <Styled.IconContainer variant={variant} {...config[variant].container} {...containerProps}>
      <Icon fontSize="small" {...config[variant].icon} {...iconProps} />
    </Styled.IconContainer>
  );
}
