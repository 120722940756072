import { executionsAppRoutes } from 'pages/ExecutionsPage/routes';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

function createBaseUrl(workspaceSlug: string, projectId?: string): string {
  if (!projectId) {
    return `/workspaces/${workspaceSlug}`;
  }
  return `/workspaces/${workspaceSlug}/projects/${projectId}`;
}

export const useAppRoutes = () => {
  const { workspaceSlug = '', projectId = '' } = useParams();
  const baseUrl = createBaseUrl(workspaceSlug, projectId);
  const routes = useMemo(() => {
    return {
      index: createBaseUrl,
      dashboard: () => `${baseUrl}/dashboard`,
      executions: executionsAppRoutes(baseUrl),
      pipelines: (pipelineId: string) => `${baseUrl}/pipelines/${pipelineId}`,
      runtime: (pipelineId: string, pipelineExecutionId: string) => {
        return `${baseUrl}/pipelines/${pipelineId}/runtime/${pipelineExecutionId}`;
      },
      versions: (pipelineId: string) => `${baseUrl}/pipelines/${pipelineId}/versions`,
      pipelineVersion: (pipelineId: string, versionId: string) =>
        `${baseUrl}/pipelines/${pipelineId}/${versionId}`,
    };
  }, [baseUrl]);

  return routes;
};
