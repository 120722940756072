import { FlowNode, FlowNodeData } from 'types/reactflow';
import { applyNodeUpdateChange, createNodeUpdateChange } from './NodeUpdateChange';

export type NodeDataUpdater<TData extends FlowNodeData = FlowNodeData> = (
  prev: TData,
) => Partial<TData>;

export interface NodeUpdateDataChange<TData extends FlowNodeData = FlowNodeData> {
  nodeId: string;
  type: 'updateData';
  updater: NodeDataUpdater<TData>;
}

export function createNodeUpdateDataChange<TData extends FlowNodeData>(
  params: Omit<NodeUpdateDataChange<TData>, 'type'>,
): NodeUpdateDataChange {
  return {
    type: 'updateData',
    nodeId: params.nodeId,
    updater: params.updater as unknown as NodeDataUpdater,
  };
}

export function applyNodeUpdateDataChange(change: NodeUpdateDataChange, nodes: FlowNode[]) {
  const { nodeId, updater } = change;
  const updateChange = createNodeUpdateChange({
    nodeId,
    updater: (node) => {
      const updates = updater(node.data);
      return {
        data: {
          ...node.data,
          ...updates,
        },
      };
    },
  });
  return applyNodeUpdateChange(updateChange, nodes);
}
