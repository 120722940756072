import TabContainer from 'components/TabContainer/TabContainer';
import { SectionDivider } from 'features/Flow/components/RightSidebar/RightSidebar.styles';
import Inputs from 'features/Flow/components/RightSidebar/components/Inputs/Inputs';
import Outputs from 'features/Flow/components/RightSidebar/components/Outputs/Outputs';
import PanelHeader, {
  PanelHeaderProps,
} from 'features/Flow/components/RightSidebar/components/PanelHeader/PanelHeader';
import { useTableSchemaInput } from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/components/DynamicCheckpointPanel/useTableSchemaInput';
import { useEffect, useState } from 'react';
import { DynamicCheckpointPanelProps } from '../../DynamicCheckpointPanel';
import PropertiesForm from './PropertiesForm';

interface PropertiesPanelProps extends DynamicCheckpointPanelProps {
  isNodeConnected: boolean;
  onConfigureSchemaClick: PanelHeaderProps['onConfigureSchemaClick'];
}

export function PropertiesPanel(props: PropertiesPanelProps) {
  const { node, isNodeConnected } = props;
  const [canConfigureSchema, setCanConfigureSchema] = useState(false);
  const { tableSchemaInput } = useTableSchemaInput({
    node,
  });

  useEffect(() => {
    setCanConfigureSchema(!!tableSchemaInput);
  }, [tableSchemaInput]);

  return (
    <>
      <PanelHeader
        node={node}
        canConfigureSchema={canConfigureSchema}
        onConfigureSchemaClick={props.onConfigureSchemaClick}
      />
      <SectionDivider />
      <PropertiesForm node={node} isNodeConnected={isNodeConnected} />
      <SectionDivider />
      <TabContainer
        tabPanelChildren={[
          <Inputs inputs={node.data.inputs} key={node.id} selectedNode={node} />,
          <Outputs key={node.id} selectedNode={node} />,
        ]}
        tabs={['Input', 'Output']}
      />
    </>
  );
}
