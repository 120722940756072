import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Paper,
  Button,
} from '@mui/material';
import * as Styled from '../../RightSidebar.styles';
import HeaderContent from 'features/Flow/components/RightSidebar/components/HeaderContent/HeaderContent';
import { getJobDescription, getJobTitle } from 'utils/neurons';
import { PipelineCompleteNode } from 'features/Flow/nodes/PipelineComplete/PipelineComplete.types';
import {
  PipelineStartNode,
  StartOutput,
  isPipelineStartNode,
} from 'features/Flow/nodes/PipelineStart/PipelineStart.types';
import Outputs from 'features/Flow/components/RightSidebar/components/Outputs/Outputs';
import { useState } from 'react';
import { useJsonEditorState } from 'components/JsonEditor/useJsonEditorState';
import { mapInputsToAPITrigger } from 'features/Flow/components/RightSidebar/RightSidebar.utils';
import { UnfoldMoreSharp } from '@mui/icons-material';
import CopyButton from 'components/CopyButton/CopyButton';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useStartCompleteIO from 'features/Flow/hooks/useStartCompleteIO';

interface StartOrCompletePanelProps {
  selectedNode: PipelineStartNode | PipelineCompleteNode;
}

const StartOrCompletePanel = ({ selectedNode }: StartOrCompletePanelProps) => {
  const { pipelineStartOutputs } = useStartCompleteIO();

  const [openJsonEditorModal, setOpenJsonEditorModal] = useState<boolean>(false);
  const { content, setContent } = useJsonEditorState({});
  const handleCloseJsonEditorModal = () => {
    setOpenJsonEditorModal(false);
  };
  const handleExpandJsonEditorModal = (pipelineStartOutputs?: StartOutput[]) => {
    setContent({
      json: mapInputsToAPITrigger(pipelineStartOutputs),
    });
    setOpenJsonEditorModal(true);
  };

  const handleCopyJsonSchemaAsString = (pipelineStartOutputs?: StartOutput[]) => {
    void navigator.clipboard.writeText(
      JSON.stringify(mapInputsToAPITrigger(pipelineStartOutputs), null, 2),
    );
  };

  return (
    <>
      <Stack divider={<Styled.SectionDivider />}>
        <Paper elevation={2}>
          <HeaderContent
            type={selectedNode.type}
            title={getJobTitle(selectedNode)}
            description={getJobDescription(selectedNode)}
          />
        </Paper>

        <Outputs selectedNode={selectedNode} />

        {isPipelineStartNode(selectedNode) && (
          <Styled.JSONSchemaSection>
            <Typography variant="titleMedium">JSON Schema</Typography>
            <Box>
              <Tooltip title={'View JSON Schema'}>
                <IconButton
                  onClick={() => {
                    handleExpandJsonEditorModal(pipelineStartOutputs);
                  }}
                  size="small"
                >
                  <UnfoldMoreSharp sx={{ transform: 'rotate(45deg)' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Copy JSON Schema'}>
                <CopyButton
                  color="default"
                  size="small"
                  value={JSON.stringify(mapInputsToAPITrigger(pipelineStartOutputs), null, 2)}
                />
              </Tooltip>
            </Box>
          </Styled.JSONSchemaSection>
        )}
      </Stack>
      <Dialog fullWidth open={openJsonEditorModal} onClose={handleCloseJsonEditorModal}>
        <DialogTitle>Pipeline Trigger JSON Schema</DialogTitle>
        <DialogContent>
          <JsonEditor content={content} readOnly />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button
            color="primary"
            startIcon={<ContentCopyIcon />}
            onClick={() => {
              handleCopyJsonSchemaAsString(pipelineStartOutputs);
            }}
            variant="text"
          >
            Copy to Clipboard
          </Button>
          <Button color="inherit" onClick={handleCloseJsonEditorModal} variant="text">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StartOrCompletePanel;
