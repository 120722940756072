import { useCallback } from 'react';
import { getConnectedEdges } from 'reactflow';
import { FlowNode } from 'types/reactflow';
import { isHandleConnection } from '../Flow.types';
import useFlow from './useFlow';

/**
 * Removes invalid connections based on the `handleIds` parameter.
 */
export function useCleanInvalidEdges() {
  const { deleteElements, getEdges } = useFlow();

  return useCallback(
    (node: FlowNode, handleIds: string[]) => {
      const edges = getEdges();
      const nodeConnectedEdges = getConnectedEdges([node], edges);
      const invalidEdges = nodeConnectedEdges.filter((edge) => {
        if (!isHandleConnection(edge)) return true;

        const hasSourceHandle = handleIds.includes(edge.sourceHandle);
        const hasTargetHandle = handleIds.includes(edge.targetHandle);
        const invalid = !hasSourceHandle && !hasTargetHandle;

        return invalid;
      });

      deleteElements({
        edges: invalidEdges,
      });

      return invalidEdges;
    },
    [deleteElements, getEdges],
  );
}
