import { FitViewOptions, useStoreApi } from 'reactflow';
import * as Styled from './CustomControl.styles';
import PlusIcon from 'components/Icons/Plus';
import MinusIcon from 'components/Icons/Minus';
import { useState } from 'react';
import FitViewIcon from 'components/Icons/FitView';
import LockedIcon from 'components/Icons/Locked';
import UnlockedIcon from 'components/Icons/Unlocked';
import useFlow from 'features/Flow/hooks/useFlow';

interface CustomControlProps {
  fitViewOptions?: FitViewOptions;
}

export default function CustomControl({ fitViewOptions }: CustomControlProps) {
  const { zoomIn, zoomOut, fitView } = useFlow();
  const store = useStoreApi();

  const [interactive, setInteractive] = useState(true);

  const onZoomInHandler = () => {
    zoomIn();
  };
  const onZoomOutHandler = () => {
    zoomOut();
  };
  const onFitViewHandler = () => fitView();
  const onInteractiveChange = () => {
    store.setState({
      nodesDraggable: !interactive,
      nodesConnectable: !interactive,
      elementsSelectable: !interactive,
    });

    setInteractive(!interactive);
  };

  return (
    <Styled.FlowControls
      fitViewOptions={fitViewOptions}
      showZoom={false}
      showFitView={false}
      showInteractive={false}
    >
      <Styled.FlowControlButton
        className="controls-button controls-button-zoomin"
        onClick={onZoomInHandler}
      >
        <PlusIcon />
      </Styled.FlowControlButton>

      <Styled.FlowControlButton className="controls-button" onClick={onZoomOutHandler}>
        <MinusIcon />
      </Styled.FlowControlButton>

      <Styled.FlowControlButton className="controls-button" onClick={onFitViewHandler}>
        <FitViewIcon />
      </Styled.FlowControlButton>

      <Styled.FlowControlButton
        className="controls-button controls-interactive"
        onClick={onInteractiveChange}
      >
        {interactive ? <UnlockedIcon /> : <LockedIcon />}
      </Styled.FlowControlButton>
    </Styled.FlowControls>
  );
}
