import { DataSchema } from '@pathways/pipeline-schema/web';
import {
  getArrayDataTypeFieldValue,
  getDataTypeOptionKey,
  getDataTypeOptionSchema,
  getSingleDataTypeFieldValue,
} from 'features/Flow/components/DataTypeField/DataTypeField.utils';

const DEFAULT_ARRAY_OPTION_KEY = getDataTypeOptionKey({
  type: 'array',
  items: { type: 'text' },
});

interface UseDataTypeFieldProps {
  value?: DataSchema;
}

export function useDataTypeField(props: UseDataTypeFieldProps) {
  const { value } = props;
  const dataTypeValue = getSingleDataTypeFieldValue(value);
  const arrayDataTypeValue = getArrayDataTypeFieldValue(value);

  function getDataTypeFieldOptionSchema(optionValue: string) {
    const optionKey = optionValue === 'array' ? DEFAULT_ARRAY_OPTION_KEY : optionValue;
    const optionSchema = getDataTypeOptionSchema(optionKey);
    return optionSchema;
  }

  return {
    dataTypeValue,
    arrayDataTypeValue,
    getDataTypeFieldOptionSchema,
  };
}
