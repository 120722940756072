import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton, TableCell, TableRowProps, Tooltip } from '@mui/material';
import * as Styled from 'components/Table/Table.styles';
import { ColumnConfig } from 'components/Table/TableHead';
import RHFCheckbox from 'components/ui/rhf-components/RHFCheckbox/RHFCheckbox';
import { useForm } from 'react-hook-form';
import { GenericObject } from './DynamicTable';
import { getInlineInputValueAs, getNotSupportedValueProps } from './DynamicTable.utils';
import { InlineRHFTextField } from './InlineTextField';

export interface DynamicTableRowFormProps extends TableRowProps {
  columns: ColumnConfig[];
  onCancel: () => void;
  onConfirm: (values: GenericObject) => void;
}

export default function DynamicTableRowForm(props: DynamicTableRowFormProps) {
  const { columns, onCancel, onConfirm, ...tableRowProps } = props;
  const form = useForm();

  const handleConfirm = () => {
    void form.handleSubmit((values) => {
      onConfirm(values);
    })();
  };

  return (
    <Styled.TableBodyRow
      {...tableRowProps}
      data-testid="inline-form"
      onKeyUp={(event) => {
        if (event.key === 'Escape') {
          onCancel();
        }
      }}
    >
      {columns.map((column, index) => {
        const valueAs = getInlineInputValueAs(column.valueSchema);
        const notSupportedValueProps = getNotSupportedValueProps(column.valueType ?? '');
        const isFirstInput = index === 0;

        return (
          <TableCell
            key={column.id}
            sx={column.maxWidth ? { maxWidth: column.maxWidth } : undefined}
          >
            {column.valueSchema?.type === 'boolean' && (
              <RHFCheckbox control={form.control} size="small" name={column.id} checked={false} />
            )}
            {column.valueSchema?.type !== 'boolean' && (
              <InlineRHFTextField
                {...notSupportedValueProps}
                InputProps={{ autoFocus: isFirstInput }}
                control={form.control}
                fullWidth
                name={column.id}
                size="small"
                valueAs={valueAs}
              />
            )}
          </TableCell>
        );
      })}
      <TableCell align="right">
        <Tooltip title="Cancel">
          <IconButton color="error" onClick={onCancel}>
            <CloseRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Confirm">
          <IconButton
            color="primary"
            sx={{ marginRight: '-9px' }}
            type="submit"
            onClick={handleConfirm}
          >
            <CheckCircleRoundedIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </Styled.TableBodyRow>
  );
}
