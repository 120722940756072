import { SvgIcon, SvgIconProps } from '@mui/material';

const PipelineIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M15.8333 17.2913C15.3131 17.2913 14.8497 17.1351 14.4431 16.8226C14.0366 16.5101 13.7687 16.1108 13.6394 15.6247H9.16667C8.30347 15.6247 7.56681 15.3197 6.95667 14.7097C6.34667 14.0995 6.04167 13.3629 6.04167 12.4997C6.04167 11.6365 6.34667 10.8998 6.95667 10.2897C7.56681 9.67967 8.30347 9.37467 9.16667 9.37467H10.8333C11.349 9.37467 11.7904 9.19092 12.1575 8.82342C12.5247 8.45592 12.7083 8.01419 12.7083 7.49822C12.7083 6.9821 12.5247 6.54079 12.1575 6.17426C11.7904 5.80787 11.349 5.62467 10.8333 5.62467H6.36062C6.22271 6.11079 5.95264 6.51009 5.55042 6.82259C5.14819 7.13509 4.68694 7.29134 4.16667 7.29134C3.53014 7.29134 2.98903 7.06863 2.54333 6.62322C2.09778 6.1778 1.875 5.63697 1.875 5.00072C1.875 4.36433 2.09778 3.82315 2.54333 3.37717C2.98903 2.93106 3.53014 2.70801 4.16667 2.70801C4.68694 2.70801 5.14819 2.86426 5.55042 3.17676C5.95264 3.48926 6.22271 3.88856 6.36062 4.37467H10.8333C11.6965 4.37467 12.4332 4.67967 13.0433 5.28967C13.6533 5.89981 13.9583 6.63648 13.9583 7.49967C13.9583 8.36287 13.6533 9.09953 13.0433 9.70967C12.4332 10.3197 11.6965 10.6247 10.8333 10.6247H9.16667C8.65097 10.6247 8.20958 10.8084 7.8425 11.1759C7.47528 11.5434 7.29167 11.9852 7.29167 12.5011C7.29167 13.0172 7.47528 13.4586 7.8425 13.8251C8.20958 14.1915 8.65097 14.3747 9.16667 14.3747H13.6394C13.7773 13.8886 14.0474 13.4893 14.4496 13.1768C14.8518 12.8643 15.3131 12.708 15.8333 12.708C16.4699 12.708 17.011 12.9307 17.4567 13.3761C17.9022 13.8215 18.125 14.3624 18.125 14.9986C18.125 15.635 17.9022 16.1762 17.4567 16.6222C17.011 17.0683 16.4699 17.2913 15.8333 17.2913ZM4.16667 6.04134C4.45625 6.04134 4.70222 5.94009 4.90458 5.73759C5.10708 5.53523 5.20833 5.28926 5.20833 4.99967C5.20833 4.71009 5.10708 4.46412 4.90458 4.26176C4.70222 4.05926 4.45625 3.95801 4.16667 3.95801C3.87708 3.95801 3.63111 4.05926 3.42875 4.26176C3.22625 4.46412 3.125 4.71009 3.125 4.99967C3.125 5.28926 3.22625 5.53523 3.42875 5.73759C3.63111 5.94009 3.87708 6.04134 4.16667 6.04134Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default PipelineIcon;
