import { Box, FormGroup, MenuItem, Typography } from '@mui/material';
import { Control, useFormContext } from 'react-hook-form';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import { NodeOutputFormData } from '../ObjectExtractor.types';
import { ARRAY_TYPE_MENU_ITEMS, DATA_MENU_ITEMS } from '../../Function.types';
import DataSchemaIcon from 'components/DataSchemaIcon/DataSchemaIcon';

interface ObjectExtractorFormProps {
  control: Control<NodeOutputFormData>;
  isConnected: boolean;
  isPendingConnection?: boolean;
}

const ObjectExtractorForm = ({
  control,
  isConnected,
  isPendingConnection,
}: ObjectExtractorFormProps) => {
  const { formState, watch } = useFormContext<NodeOutputFormData>();

  return (
    <FormGroup>
      <RHFTextField
        name="path"
        control={control}
        fullWidth
        label="Key"
        error={!!formState.errors.path}
        helperText={formState.errors.path?.message}
        required
        rules={{
          required: 'This field is required.',
        }}
        sx={{ my: 1 }}
      />
      <RHFTextField
        name="title"
        control={control}
        fullWidth
        label="Title"
        required
        rules={{
          required: 'This field is required.',
        }}
        sx={{ my: 1 }}
      />
      <RHFTextField
        select
        name="dataType"
        control={control}
        label="Data Type"
        required
        sx={{ my: 1 }}
        fullWidth
        helperText={
          isConnected || isPendingConnection
            ? 'You cannot update the data type of an output with a connection.'
            : formState.errors.dataType?.message
        }
        rules={{ required: 'This field is required.' }}
        disabled={isConnected || isPendingConnection}
      >
        {DATA_MENU_ITEMS.map(({ schema, title }) => (
          <MenuItem key={schema.type} value={schema.type}>
            <Box display="flex" alignItems="center">
              <DataSchemaIcon size={'20px'} dataSchema={schema} />{' '}
              <Typography variant="bodyLarge" sx={{ ml: 1 }}>
                {title}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </RHFTextField>
      {watch('dataType') === 'array' && (
        <RHFTextField
          select
          name="arrayDataType"
          control={control}
          label="Array Data Type"
          required
          fullWidth
          defaultValue="text"
          helperText={
            isConnected || isPendingConnection
              ? 'You cannot update the data type of an input with a connection.'
              : formState.errors.dataType?.message
          }
          rules={{ required: 'This field is required.' }}
          disabled={isConnected || isPendingConnection}
        >
          {ARRAY_TYPE_MENU_ITEMS.map(({ schema, title }) => (
            <MenuItem key={schema.type} value={schema.type}>
              <Box display="flex" alignItems="center">
                <DataSchemaIcon size={'20px'} dataSchema={schema} />{' '}
                <Typography variant="bodyLarge" sx={{ ml: 1 }}>
                  {title}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </RHFTextField>
      )}
    </FormGroup>
  );
};

export default ObjectExtractorForm;
