import {
  isSubpipelineManifest,
  NodeManifest,
  SubpipelineNodeManifest,
} from 'features/Flow/Flow.types';
import SidebarElementCard from 'features/Flow/components/AddElementSidebar/components/SidebarElementCard/SidebarElementCard';
import * as Styled from '../AddElementSidebar.styles';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import useSearchSubpipelines from 'api/services/useSearchSubpipelines/useSearchSubpipelines';
import ElementsLoader from 'features/Flow/components/AddElementSidebar/components/ElementsLoader/ElementsLoader';
import { SubpipelineManifestResponse } from 'api/services/useSearchSubpipelines/useSearchSubpipelines.types';

interface AddCallablePipelineNodeListProps {
  searchVal: string;
  onDragStart: (e: React.DragEvent, manifest: NodeManifest) => void;
}

const AddCallablePipelineNodeList = ({
  searchVal,
  onDragStart,
}: AddCallablePipelineNodeListProps) => {
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchVal);
  const { subpipelineManifests, isLoading } = useSearchSubpipelines({
    query: debouncedSearchQuery,
  });

  const debouncedSetSearchQuery = useRef(
    debounce(
      (searchQuery: string) => {
        setDebouncedSearchQuery(searchQuery);
      },
      1000,
      {
        leading: false,
        trailing: true,
      },
    ),
  ).current;

  useEffect(() => {
    debouncedSetSearchQuery(searchVal);
  }, [searchVal, debouncedSetSearchQuery]);

  if (isLoading) {
    return <ElementsLoader quantity={5} />;
  }

  return subpipelineManifests.length > 0 && isSubpipelineNodesArray(subpipelineManifests) ? (
    subpipelineManifests.map((manifest) => (
      <SidebarElementCard
        key={manifest.pipeline.id}
        manifest={manifest}
        onDragStart={(e) => {
          onDragStart(e, manifest);
        }}
      />
    ))
  ) : (
    <Styled.NoResultText variant="bodyMedium">
      {searchVal.trim().length
        ? `No results for '${searchVal}'`
        : 'No callable pipelines available'}
    </Styled.NoResultText>
  );
};

const isSubpipelineNodesArray = (
  manifests: SubpipelineManifestResponse[],
): manifests is SubpipelineNodeManifest[] => {
  return manifests.every(isSubpipelineManifest);
};

export default AddCallablePipelineNodeList;
