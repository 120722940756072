import { Box } from '@mui/material';
import { AnimationClasses } from 'config/status';
import styled, { css } from 'styled-components';

export const Container = styled(Box)(
  () => css`
    ${ANIMATION_SPIN};
  `,
);

const ANIMATION_SPIN = css`
  @keyframes spin {
    100% {
      transform: rotateZ(360deg);
    }
  }

  .${AnimationClasses.SPIN} {
    animation: 2s linear infinite spin;
  }
`;
