import TextField, { TextFieldProps } from 'components/Inputs/TextField/TextField';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import styled, { css } from 'styled-components';

const getInlineTextFieldStyles = ({ InputProps }: TextFieldProps) => {
  if (InputProps?.readOnly)
    return css`
      && .MuiInput-root {
        background: transparent;
      }
    `;
};

export const InlineRHFTextField = styled(RHFTextField)(getInlineTextFieldStyles);

export const InlineTextField = styled(TextField)(getInlineTextFieldStyles);
