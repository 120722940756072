import { Tooltip, TypographyProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import * as Styled from './TextEllipsis.styles';

export default function TextEllipsis(props: TypographyProps) {
  const elementRef = useRef<HTMLSpanElement>(null);
  const title = typeof props.children === 'string' ? props.children : undefined;
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  useEffect(() => {
    if (!elementRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      // Only one element is observed.
      const { target } = entries[0];
      const isTextOverflowing = getIsTextOverflowing(target);

      setIsTextOverflowing(isTextOverflowing);
    });
    resizeObserver.observe(elementRef.current);
  }, []);

  return (
    <Styled.MuiTypography {...props} ref={elementRef}>
      {title && isTextOverflowing ? (
        <Tooltip title={title} placement="bottom-start">
          {/* span: holds the ref for the Tooltip target */}
          <span>{props.children}</span>
        </Tooltip>
      ) : (
        props.children
      )}
    </Styled.MuiTypography>
  );
}

function getIsTextOverflowing(element: Element) {
  return element.scrollWidth > element.clientWidth;
}
