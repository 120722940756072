import { useCallback } from 'react';
import useFlow from './useFlow';

export default function useRemoveSelectedNodes() {
  const { getNodes, deleteElements } = useFlow();

  return useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected && (node.deletable ?? true));

    deleteElements({ nodes: selectedNodes });
  }, [deleteElements, getNodes]);
}
