import styled from 'styled-components';
import { Tabs as MuiTabs, Divider as MuiDivider } from '@mui/material';

export const Tabs = styled(MuiTabs)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const Divider = styled(MuiDivider)`
  border-color: ${({ theme }) => theme.palette.surface.dividerMain};
`;
