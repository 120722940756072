import { HandleProps } from 'reactflow';
import { InputContainer, DataContent, OutputContainer } from 'features/Flow/nodes/Node/Node.styles';

import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import {
  DataPipelineStartOutputIcon,
  IconContainer,
} from 'features/Flow/nodes/Node/components/NodeDataIcon/NodeDataIcon.styles';
import { useRegisterHandle } from 'features/Flow/Handles/handles';
import { CustomHandleData } from 'features/Flow/Handles/handle.store';
import NeuronHandle from './NeuronHandle';
import { CONNECTION_COLOR_DATA_KEY } from 'features/Flow/Flow.consts';

interface Props extends Omit<HandleProps, 'isConnectable' | 'type' | 'position'> {
  handle: CustomHandleData;
  title: string;
  fullWidth?: boolean;
}

export default function CustomHandle(props: Props) {
  const { title, fullWidth, handle, ...otherHandleProps } = props;
  const { purpose } = handle;

  useRegisterHandle(props.handle);

  const Container = purpose === 'input' ? InputContainer : OutputContainer;

  const titleNode = <TextEllipsis variant="bodyMedium">{title}</TextEllipsis>;
  const colorReference = purpose === 'input' ? 'source' : 'target';
  const dataAttributes = {
    [CONNECTION_COLOR_DATA_KEY]: colorReference,
  };

  return (
    <Container>
      <DataContent $fullWidth={fullWidth}>
        {purpose === 'output' ? titleNode : null}

        <NeuronHandle handle={handle} {...otherHandleProps}>
          <IconContainer>
            <DataPipelineStartOutputIcon {...dataAttributes} />
          </IconContainer>
        </NeuronHandle>

        {purpose === 'input' ? titleNode : null}
      </DataContent>
    </Container>
  );
}
