import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Paper, Table, TableBody, TableCell, TableFooter } from '@mui/material';
import * as Styled from 'components/Table/Table.styles';
import { TableFooterRow } from 'components/Table/Table.styles';
import CustomTableHead, { CustomTableHeadProps } from 'components/Table/TableHead';
import { forwardRef } from 'react';
import { TableComponents } from 'react-virtuoso';
import { formatRequiredMessage } from 'utils/message';
import { DynamicTableProps, ROW_HEIGHT } from './DynamicTable';
import DynamicTableRow, { DynamicTableRowProps } from './DynamicTableRow';
import DynamicTableRowForm, { DynamicTableRowFormProps } from './DynamicTableRowForm';
import ReadOnlyTableRow from './ReadOnlyTableRow';
import { UseDynamicTableResult } from './useDynamicTable';

type TableData = UseDynamicTableResult['tableRows'][number];

interface TableContext {
  readOnly: DynamicTableProps['readOnly'];
  selectable: DynamicTableProps['selectable'];
  columns: UseDynamicTableResult['columns'];
  displayRowForm: UseDynamicTableResult['displayRowForm'];
  tableHeadProps: Omit<CustomTableHeadProps, 'columns'>;
  tableBodyProps: Omit<DynamicTableRowFormProps, 'columns'>;
  tableRowProps: Omit<DynamicTableRowProps, 'columns' | 'row'>;
  tableFootProps: {
    onAddRow: UseDynamicTableResult['handleAddRow'];
  };
}

export const TABLE_COMPONENTS: TableComponents<TableData, TableContext> = {
  // Using the property name as function name creates a circular reference (infinite loop).
  Scroller: forwardRef(function _Scroller({ context, ...props }, ref) {
    return <Styled.TableContainer component={Paper} elevation={4} {...props} ref={ref} />;
  }),
  Table: ({ context, ...props }) => <Table {...props} aria-label="objects" />,
  TableHead: forwardRef(function _TableHead({ context, ...props }, ref) {
    if (!context) {
      throw new Error(formatRequiredMessage('context'));
    }
    const { columns, tableHeadProps } = context;

    return <CustomTableHead columns={columns} {...tableHeadProps} {...props} ref={ref} />;
  }),
  TableBody: forwardRef(function _TableBody({ context, children, ...props }, ref) {
    if (!context) {
      throw new Error(formatRequiredMessage('context'));
    }
    const {
      columns,
      displayRowForm,
      tableBodyProps: { onCancel, onConfirm },
    } = context;

    return (
      <TableBody {...props} ref={ref}>
        {children}
        {displayRowForm && (
          <DynamicTableRowForm columns={columns} onCancel={onCancel} onConfirm={onConfirm} />
        )}
      </TableBody>
    );
  }),
  TableRow: ({ context, item: row, ...props }) => {
    if (!context) {
      throw new Error(formatRequiredMessage('context'));
    }
    const {
      readOnly,
      selectable,
      columns,
      tableRowProps: { onConfirmEditRow, onSelectRow },
    } = context;

    const rowKey = row._id;

    return readOnly ? (
      <ReadOnlyTableRow key={rowKey} row={row} columns={columns} />
    ) : (
      <DynamicTableRow
        {...props}
        key={rowKey}
        row={row}
        columns={columns}
        onSelectRow={selectable ? onSelectRow : undefined}
        onConfirmEditRow={onConfirmEditRow}
      />
    );
  },
  FillerRow: ({ context, height }) => {
    if (!context) {
      throw new Error(formatRequiredMessage('context'));
    }
    const { columns } = context;
    const computedRowCount = Math.floor(height / ROW_HEIGHT);

    return Array.from({ length: computedRowCount }).map((_, index) => (
      <Styled.TableBodyRow key={index}>
        {columns.map((column) => (
          <TableCell key={column.id} height={ROW_HEIGHT}>
            <Box
              sx={(theme) => ({
                height: 24,
                bgcolor: theme.palette.divider,
                borderRadius: theme.shape.borderRadius,
              })}
            />
          </TableCell>
        ))}
      </Styled.TableBodyRow>
    ));
  },
  TableFoot: forwardRef(function _TableFoot({ context, style: _, ...props }, ref) {
    /**
     * `style` is ignored to not render the footer with `position: fixed`.
     * It should be after the last row.
     */
    if (!context) {
      throw new Error(formatRequiredMessage('context'));
    }
    const {
      readOnly,
      columns,
      tableFootProps: { onAddRow },
    } = context;

    return readOnly ? null : (
      <TableFooter {...props} ref={ref}>
        <TableFooterRow>
          <TableCell colSpan={columns.length + 1}>
            <Button color="inherit" startIcon={<AddIcon />} variant="text" onClick={onAddRow}>
              Add new item
            </Button>
          </TableCell>
        </TableFooterRow>
      </TableFooter>
    );
  }),
};
