import { FlowNode } from 'types/reactflow';

export function filterGroupChildNodes(nodes: FlowNode[], groupId: string) {
  return nodes.filter((node) => node.parentNode === groupId);
}

export function findGroupByChildNode<TNodes extends FlowNode>(
  nodes: TNodes[],
  childNodeId: string,
) {
  const childNode = nodes.find((node) => node.id === childNodeId);

  return childNode && nodes.find((node) => node.id === childNode.parentNode);
}

export function getGroupName(name = '') {
  return name.trim() || 'Group';
}
