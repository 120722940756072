import { Accordion, AccordionDetails, AccordionSummary, Chip, IconButton } from '@mui/material';
import styled from 'styled-components';

export const MuiIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.action.active};
`;

export const RequiredFieldTitle = styled.span`
  margin-right: 5px;
`;

export const RequiredInfoChip = styled(Chip)`
  background: ${({ theme }) => theme.palette.surface.level4};
  border: 0;

  .MuiChip-label {
    padding: 4px 12px;
  }
`;

export const HiddenInputsAccordion = styled(Accordion)`
  &.MuiAccordion-root::before {
    display: none;
  }

  &.Mui-expanded {
    margin-top: 0;
    padding-top: 0;
  }

  &.Mui-disabled {
    background: transparent;
  }
`;

export const HiddenInputsAccordionSummary = styled(AccordionSummary)`
  margin: 0;
  padding: 0;
  min-height: 60;
  &.Mui-expanded {
    min-height: 60;
  }
`;

export const HiddenInputsAccordionDetails = styled(AccordionDetails)`
  margin: 0;
  padding: 0;
`;
