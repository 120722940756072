import { Stack, Button } from '@mui/material';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from 'components/Dialog/Dialog.styles';
import { useCallback } from 'react';
import { createHandleId } from 'features/Flow/Flow.utils';
import { DummyNeuronFormData } from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/DummyNeuronModalCreate';
import DummyNeuronFormFields from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/DummyNeuronModalFormFields';
import { useFindHandleEdge } from 'features/Flow/hooks/useFindHandleEdge';
import {
  createDefaultValues,
  createDummyFieldDataSchema,
} from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuron.utils';

interface DummyNeuronModalEditProps {
  nodeId: string;
  type: 'input' | 'output';
  items: NodeInput[] | NodeOutput[];
  itemToEdit: NodeInput | NodeOutput;
  onCancel: () => void;
  onConfirm: (item: NodeInput | NodeOutput) => void;
  onNameChange: (oldName: string, newName: string) => void;
}

const DummyNeuronModalEdit = ({
  nodeId,
  type,
  items,
  itemToEdit,
  onCancel,
  onConfirm,
  onNameChange,
}: DummyNeuronModalEditProps) => {
  const findEdge = useFindHandleEdge({ nodeId });
  const form = useForm<DummyNeuronFormData>({
    defaultValues: createDefaultValues(itemToEdit),
    mode: 'onBlur',
  });
  const {
    formState: { isValid },
  } = form;

  const isConnected = !!findEdge(createHandleId(type, itemToEdit.name));
  const itemsNotToEdit = items.filter((item) => item.name !== itemToEdit.name);

  const submitForm: SubmitHandler<DummyNeuronFormData> = useCallback(
    (data) => {
      if (data.name !== itemToEdit.name) {
        onNameChange(itemToEdit.name, data.name);
      }

      onConfirm({
        ...itemToEdit,
        ...data,
        id: createHandleId(type, data.name),
        dataSchema: createDummyFieldDataSchema(data),
      });
    },
    [onConfirm, itemToEdit, type, onNameChange],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  return (
    <FormProvider {...form}>
      <Styled.DialogTitle $hasBorder>Create {type}</Styled.DialogTitle>
      <Styled.DialogContent>
        <DummyNeuronFormFields items={itemsNotToEdit} isConnected={isConnected} />
      </Styled.DialogContent>
      <Styled.DialogActions>
        <Stack direction="row">
          <Button variant="text" sx={{ color: 'common.white' }} onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!isValid}>
            Confirm
          </Button>
        </Stack>
      </Styled.DialogActions>
    </FormProvider>
  );
};

export default DummyNeuronModalEdit;
