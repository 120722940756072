import useImportPipeline from 'api/services/useImportPipeline/useImportPipeline';
import * as Styled from './ImportPipeline.styles';
import { ChangeEventHandler, useRef } from 'react';
import useProjectId from 'hooks/useProjectId';
import { CircularProgress } from '@mui/material';
import useToast from 'contexts/toast/useToast';
import { useNavigate } from 'react-router-dom';
import { useAppRoutes } from 'utils/routes';
import useWorkspace from 'hooks/useWorkspace';

const ImportPipeline = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const routes = useAppRoutes();
  const projectId = useProjectId();
  const { id: workspaceId } = useWorkspace();
  const { importPipeline, isLoading } = useImportPipeline();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const jsonFile = event.target.files?.[0];

    if (!jsonFile) {
      toast.error({
        message: 'An error ocurred, please try again.',
      });
      return;
    }

    importPipeline({ projectId, workspaceId, file: jsonFile })
      .then((response) => {
        navigate(routes.pipelines(response.data.id));
        toast.success({
          message: 'Pipeline schema imported successfully.',
        });
      })
      .catch(() => {
        toast.error({
          message: 'An error ocurred, please try again.',
        });
      })
      .finally(() => {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      });
  };

  return (
    <>
      <input
        id="pipeline-import-uploader"
        aria-label="Import pipeline file"
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        accept="application/json"
        onChange={handleChange}
      />
      <Styled.ImportPipelineButton
        disabled={isLoading}
        variant="outlined"
        sx={{ mr: 2 }}
        onClick={handleClick}
      >
        {isLoading ? <CircularProgress size={20} /> : 'Import'}
      </Styled.ImportPipelineButton>
    </>
  );
};

export default ImportPipeline;
