import { RF_GROUP_NODE_CLASS_NAME } from 'features/Flow/Flow.consts';
import { HandleConnection, NodeType } from 'features/Flow/Flow.types';
import { createNodeId, getHandleKeyFromId } from 'features/Flow/Flow.utils';
import { DataHandle } from 'features/Flow/Handles/handle.store';
import useFlow from 'features/Flow/hooks/useFlow';
import {
  BATCH_ARRAY_INPUT_NAME,
  BATCH_ARRAY_OUTPUT_NAME,
  BATCH_NODE_COLOR,
} from 'features/Flow/nodes/Batch/Batch.consts';
import {
  BatchEndNode,
  BatchGroupNode,
  BatchGroupSaveData,
  BatchInput,
  BatchInputEdge,
  BatchJobNode,
  BatchOutput,
  BatchStartNode,
  NewBatchInputEdge,
  isBatchEndJob,
  isBatchEndNode,
  isBatchGroupNode,
  isBatchInputEdge,
  isBatchNeuronJob,
  isBatchStartJob,
  isBatchStartNode,
} from 'features/Flow/nodes/Batch/Batch.types';
import { useCallback } from 'react';
import { Edge, XYPosition, getConnectedEdges, useStoreApi } from 'reactflow';
import {
  PipelineBatchChildJob,
  PipelineBatchEndJob,
  PipelineBatchJob,
  PipelineBatchStartJob,
  PipelineNeuronJob,
} from 'types/pipeline';
import { FlowNode } from 'types/reactflow';
import {
  addLegacyType,
  createInputSourceValue,
  handleLegacyData,
  mapJobInputValue,
  mapNodeInsideGroup,
} from 'utils/mappings';
import { formatRequiredMessage } from 'utils/message';
import { NodeInput, PathwayNode, isDynamicValue, isPathwayNode } from '../Node/Node.types';

interface CreateNewBatchNodesOptions {
  batchId: string;
  position: XYPosition;
}

export function createNewBatchNodes({ batchId, position }: CreateNewBatchNodesOptions) {
  const nodes = [
    {
      id: batchId,
      type: NodeType.BATCH_GROUP,
      className: RF_GROUP_NODE_CLASS_NAME,
      data: {
        metadata: {
          name: 'Batch',
          description: 'Batch Function',
          type: NodeType.BATCH_GROUP,
        },
        inputs: [],
        outputs: [],
        color: BATCH_NODE_COLOR,
      },
      position,
    } satisfies BatchGroupNode,
    {
      id: createNodeId(),
      type: NodeType.BATCH_START,
      deletable: false,
      data: {
        metadata: {
          name: 'Batch Start',
          description: 'Batch Start node',
          type: NodeType.BATCH_START,
        },
        inputs: [],
      },
      position: {
        x: 0,
        y: 0,
      },
      parentNode: batchId,
    } satisfies BatchStartNode,
    {
      id: createNodeId(),
      type: NodeType.BATCH_END,
      deletable: false,
      data: {
        metadata: {
          name: 'Batch End',
          description: 'Batch End node',
          type: NodeType.BATCH_END,
        },
        outputs: [],
      },
      position: {
        x: 400,
        y: 0,
      },
      parentNode: batchId,
    } satisfies BatchEndNode,
  ];

  return nodes;
}

export function createBatchInputEdge(connection: HandleConnection, handle: DataHandle) {
  return {
    ...connection,
    updatable: 'source',
    data: {
      config: {
        hideConnectionStatus: true,
      },
      dataSchema: handle.schema,
      description: handle.description,
      id: handle.id,
      name: handle.name,
      title: handle.title || handle.name,
    },
  } satisfies NewBatchInputEdge;
}

export function mapPipelineBatchJobs(
  nodes: BatchGroupSaveData[],
  edges: Edge[],
): PipelineBatchJob[] {
  return nodes.map((batchNode) => {
    const { jobs } = batchNode.data;
    const startNode = jobs.find(isBatchStartNode);
    const endNode = jobs.find(isBatchEndNode);
    const jobNodes = jobs.filter(isPathwayNode);

    if (!startNode) throw new Error(formatRequiredMessage('startNode'));
    if (!endNode) throw new Error(formatRequiredMessage('endNode'));

    const inputs = getBatchInputConnectedEdges(startNode, edges).map((edge) =>
      addLegacyType<BatchInput>({
        ...edge.data,
        // Empty the config since it's not needed for execution
        config: {},
        value: createInputSourceValue(edge, jobs),
      }),
    );
    const outputs = getBatchInputConnectedEdges(endNode, edges).map((edge) =>
      addLegacyType<BatchOutput>({
        // Empty the config since it's not needed for execution
        config: {},
        dataSchema: {
          items: edge.data.dataSchema,
          type: 'array',
        },
        description: edge.data.description,
        id: BATCH_ARRAY_OUTPUT_NAME,
        name: BATCH_ARRAY_OUTPUT_NAME,
        title: edge.data.title,
        value: `jobs.${edge.source}.outputs.${getHandleKeyFromId(edge.sourceHandle)}`,
      }),
    );

    return {
      id: batchNode.id,
      data: {
        metadata: batchNode.data.metadata,
        inputs,
        outputs,
        jobs: [
          {
            id: startNode.id,
            data: startNode.data,
            render: {
              position: startNode.position,
            },
          } satisfies PipelineBatchStartJob,
          ...jobNodes.map<PipelineBatchChildJob>((jobNode) => {
            const data = {
              ...jobNode.data,
              inputs: jobNode.data.inputs.map((input) =>
                addLegacyType({
                  ...input,
                  // Empty the config since it's not needed for execution
                  config: {},
                  value: mapJobInputValue({
                    edges,
                    nodes,
                    targetNodeId: jobNode.id,
                    input,
                    createSourceValue: (edge) =>
                      createChildInputSourceValue(edge, input, startNode.id),
                  }),
                }),
              ),
              outputs: jobNode.data.outputs.map(addLegacyType),
            };
            return {
              id: jobNode.id,
              data,
              render: {
                position: jobNode.position,
              },
            };
          }),
          {
            id: endNode.id,
            data: endNode.data,
            render: {
              position: endNode.position,
            },
          } satisfies PipelineBatchEndJob,
        ],
      },
      render: {
        position: batchNode.position,
      },
    } satisfies PipelineBatchJob;
  });
}

function createChildInputSourceValue(edge: Edge, input: NodeInput, startNodeId: string) {
  const handleKey = getHandleKeyFromId(edge.sourceHandle) ?? 'key';

  if (handleKey === BATCH_ARRAY_INPUT_NAME) {
    return {
      source: `batch.currentItem`,
    };
  }
  if (edge.source === startNodeId) {
    return {
      source: `batch.inputs.${handleKey}`,
    };
  }
  if (isDynamicValue(input.value)) {
    return input.value;
  }

  return {
    source: input.value ?? `jobs.${edge.source}.outputs.${handleKey}`,
  };
}

export const useAddNodeToBatchGroup = () => {
  const storeApi = useStoreApi();
  const { setNodes, getNodes } = useFlow();

  return useCallback(
    (selectedNodes: FlowNode[], nodes: FlowNode[] = getNodes()) => {
      storeApi.getState().resetSelectedElements();
      storeApi.setState({
        nodesSelectionActive: false,
      });

      const batchGroupNode = selectedNodes.find(isBatchGroupNode);

      if (batchGroupNode) {
        const selectedNodeIds = selectedNodes
          .map((node) => node.id)
          .filter((id) => id !== batchGroupNode.id);

        const unselectedNodes = nodes.filter((node) => !selectedNodeIds.includes(node.id));
        const selectedChildren = nodes.filter((node) => selectedNodeIds.includes(node.id));
        const childNodes = selectedChildren.map((node) => mapNodeInsideGroup(batchGroupNode, node));

        const nextNodes = unselectedNodes.flatMap((node) => {
          const isBatchEnd = node.parentNode === batchGroupNode.id && isBatchEndNode(node);

          if (isBatchEnd) {
            return [...childNodes, node];
          }

          return [node];
        });

        setNodes(nextNodes);
      }
    },
    [getNodes, setNodes, storeApi],
  );
};

function mapBatchStartJobToNode(
  batchStartJob: PipelineBatchStartJob,
  parentNode: string,
): BatchStartNode {
  const {
    id,
    data,
    render: { position },
  } = batchStartJob;

  return {
    id,
    type: NodeType.BATCH_START,
    deletable: false,
    data,
    position,
    parentNode,
  };
}

function mapBatchEndJobToNode(batchEndJob: PipelineBatchEndJob, parentNode: string): BatchEndNode {
  const {
    id,
    data,
    render: { position },
  } = batchEndJob;

  return {
    id,
    type: NodeType.BATCH_END,
    deletable: false,
    data,
    position,
    parentNode,
  };
}

function mapBatchNeuronJobToNode(
  batchNeuronJob: PipelineNeuronJob,
  parentNode: string,
): PathwayNode {
  const {
    id,
    data: { metadata, inputs, outputs, schemas },
    render: { position },
  } = batchNeuronJob;

  return {
    id,
    type: metadata.type,
    data: {
      metadata,
      inputs: inputs ?? [],
      outputs: outputs ?? [],
      schemas,
    },
    position,
    parentNode,
  };
}

function mapBatchJobsToNodes(batchJob: PipelineBatchJob): BatchJobNode[] {
  const {
    id: batchId,
    data: { jobs },
  } = batchJob;

  return jobs.reduce<BatchJobNode[]>((nodes, job) => {
    if (isBatchStartJob(job)) {
      nodes.push(mapBatchStartJobToNode(job, batchId));
    }

    if (isBatchNeuronJob(job)) {
      nodes.push(mapBatchNeuronJobToNode(job, batchId));
    }

    if (isBatchEndJob(job)) {
      nodes.push(mapBatchEndJobToNode(job, batchId));
    }

    return nodes;
  }, []);
}

export function mapBatchJobToNode(job: PipelineBatchJob): (BatchGroupNode | BatchJobNode)[] {
  const batchJobNodes = mapBatchJobsToNodes(job);

  const batchNode: BatchGroupNode = {
    type: NodeType.BATCH_GROUP,
    id: job.id,
    className: RF_GROUP_NODE_CLASS_NAME,
    data: {
      ...handleLegacyData(job.data),
      color: BATCH_NODE_COLOR,
    },
    ...job.render,
  };

  return [batchNode, ...batchJobNodes];
}

export function getBatchInputConnectedEdges(node: FlowNode, edges: Edge[]): BatchInputEdge[] {
  return getConnectedEdges([node], edges).filter((edge): edge is BatchInputEdge =>
    isBatchInputEdge(edge, node.id),
  );
}
