import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';
import { Button, Paper } from '@mui/material';
import PanelCard from '../PanelCard/PanelCard';
import { Link } from 'react-router-dom';

export default function ReviewTabPanel(props: { executionRoute: string }) {
  const { executionRoute } = props;

  return (
    <Paper
      elevation={2}
      sx={{
        height: '100%',
        paddingY: 3,
      }}
    >
      <PanelCard
        title={<TableRowsRoundedIcon fontSize="large" color="action" />}
        description="This checkpoint can be reviewed in Summary View."
      >
        <Button component={Link} to={executionRoute} variant="outlined">
          Review in Summary View
        </Button>
      </PanelCard>
    </Paper>
  );
}
