import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { fitViewOptions } from '../Flow.consts';
import useFlow from './useFlow';

export default function useDebouncedFitView() {
  const { fitView } = useFlow();
  const [isFitViewPending, setIsFitViewPending] = useState(true);
  const debouncedFitView = useMemo(
    () =>
      debounce(() => {
        if (!isFitViewPending) return;
        fitView(fitViewOptions);
        setIsFitViewPending(false);
      }, 400),
    [fitView, isFitViewPending],
  );

  return {
    debouncedFitView,
    isFitViewPending,
  };
}
