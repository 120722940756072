import { useCallback } from 'react';
import { useStoreApi } from 'reactflow';
import { FlowNodeData } from 'types/reactflow';
import {
  NodeDataUpdater,
  NodeUpdateDataChange,
  createNodeUpdateDataChange,
} from '../changes/NodeUpdateDataChange';

export function useUpdateNodeData() {
  const storeApi = useStoreApi();

  return useCallback(
    <TNodeData extends FlowNodeData>(
      nodeId: NodeUpdateDataChange['nodeId'],
      updater: NodeDataUpdater<TNodeData>,
    ) => {
      storeApi.getState().onNodesChange?.([
        createNodeUpdateDataChange<TNodeData>({
          nodeId,
          updater,
        }),
      ]);
    },
    [storeApi],
  );
}
