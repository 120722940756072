import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import { StartOutput } from 'features/Flow/nodes/PipelineStart/PipelineStart.types';
import { DataSchema } from '@pathways/pipeline-schema';

// TODO we we might want to distribute this across each data type.
export const getExampleValue = (dataSchema: DataSchema): unknown => {
  switch (dataSchema.type) {
    case 'text':
      return 'Example text';
    case 'number':
    case 'integer':
      return 42;
    case 'asset':
      return 'asset id';
    case 'image-uri':
      return 'example.com/image.png';
    case 'enum': // TODO this one was totally wrong
    case 'object':
      return { key1: 'value1', key2: 'value2' };
    case 'array':
      switch (dataSchema.items.type) {
        case 'text':
          return ['Example text 1', 'Example text 2'];
        case 'number':
          return [42, 123];
        case 'image-uri':
          return ['example.com/image1.png', 'example.com/image2.png'];
        case 'asset':
          return ['asset id 1', 'asset id 2'];
        default:
          // TODO not sure how much sense this makes
          return [];
      }

    default:
      return 'default value';
  }
};

export const mapInputsToAPITrigger = (
  outputs?: StartOutput[],
): { inputs: Record<string, unknown> } => {
  if (!outputs || outputs.length == 0) return { inputs: {} };

  const newApiObject = outputs.reduce((acc, output) => {
    return {
      ...acc,
      [output.name]: getExampleValue(output.dataSchema),
    };
  }, {});

  return { inputs: newApiObject };
};

/**
 * Internal fields are relate to custom logic for neurons that have internally managed inputs or outputs that the end users should not be able to see.
 * It is based on the AJV extended keyword `forceHide` and mapped to `config.forceHide`
 * @param input NodeInput
 * @returns Boolean
 */
export const isForceHidden = (input: NodeInput) => {
  return !!input.config.forceHide;
};
