import { SvgIcon, SvgIconProps } from '@mui/material';

const AudioFileArrayIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <mask
          id="mask0_22_166"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#9575CD" />
        </mask>
        <g mask="url(#mask0_22_166)">
          <path
            d="M1.875 21.125C2.45833 21.7083 3.16667 22 4 22H6C6.28333 22 6.52083 21.9042 6.7125 21.7125C6.90417 21.5208 7 21.2833 7 21C7 20.7167 6.90417 20.4792 6.7125 20.2875C6.52083 20.0958 6.28333 20 6 20H4C3.71667 20 3.47917 19.9042 3.2875 19.7125C3.09583 19.5208 3 19.2833 3 19V5C3 4.71667 3.09583 4.47917 3.2875 4.2875C3.47917 4.09583 3.71667 4 4 4H6C6.28333 4 6.52083 3.90417 6.7125 3.7125C6.90417 3.52083 7 3.28333 7 3C7 2.71667 6.90417 2.47917 6.7125 2.2875C6.52083 2.09583 6.28333 2 6 2H4C3.16667 2 2.45833 2.29167 1.875 2.875C1.29167 3.45833 1 4.16667 1 5V19C1 19.8333 1.29167 20.5417 1.875 21.125Z"
            fill="black"
          />
          <path
            d="M22.125 21.125C21.5417 21.7083 20.8333 22 20 22H18C17.7167 22 17.4792 21.9042 17.2875 21.7125C17.0958 21.5208 17 21.2833 17 21C17 20.7167 17.0958 20.4792 17.2875 20.2875C17.4792 20.0958 17.7167 20 18 20H20C20.2833 20 20.5208 19.9042 20.7125 19.7125C20.9042 19.5208 21 19.2833 21 19V5C21 4.71667 20.9042 4.47917 20.7125 4.2875C20.5208 4.09583 20.2833 4 20 4H18C17.7167 4 17.4792 3.90417 17.2875 3.7125C17.0958 3.52083 17 3.28333 17 3C17 2.71667 17.0958 2.47917 17.2875 2.2875C17.4792 2.09583 17.7167 2 18 2H20C20.8333 2 21.5417 2.29167 22.125 2.875C22.7083 3.45833 23 4.16667 23 5V19C23 19.8333 22.7083 20.5417 22.125 21.125Z"
            fill="black"
          />
          <path
            d="M11.1667 16.6843C11.559 16.6843 11.89 16.5459 12.1597 16.2691C12.4293 15.9924 12.5642 15.6527 12.5642 15.25V12.6052H14.4743V11.3948H11.9743V14.1185C11.8607 14.014 11.7366 13.9375 11.602 13.8888C11.4673 13.8401 11.3222 13.8157 11.1667 13.8157C10.7743 13.8157 10.4433 13.9541 10.1737 14.2309C9.904 14.5076 9.76917 14.8473 9.76917 15.25C9.76917 15.6527 9.904 15.9924 10.1737 16.2691C10.4433 16.5459 10.7743 16.6843 11.1667 16.6843ZM8.20517 18.5C7.86839 18.5 7.58333 18.3803 7.35 18.1408C7.11667 17.9013 7 17.6088 7 17.2631V6.73688C7 6.39124 7.11667 6.09868 7.35 5.85921C7.58333 5.61974 7.86839 5.5 8.20517 5.5H13.5L17 9.09211V17.2631C17 17.6088 16.8833 17.9013 16.65 18.1408C16.4167 18.3803 16.1316 18.5 15.7948 18.5H8.20517ZM13 9.60526H16L13 6.52632V9.60526Z"
            fill="black"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default AudioFileArrayIcon;
