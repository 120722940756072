import { useRequest } from 'api/client';
import { useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { usePaginationQuery } from 'hooks/usePaginationQuery';
import { PipelinesResponse, UsePaginatedPipelinesRequest } from './usePaginatedPipelines.type';

const usePaginatedPipelines = ({ projectId, workspaceId }: UsePaginatedPipelinesRequest) => {
  const pagination = usePaginationQuery<string>({
    defaultValues: {
      sortBy: 'updated_at',
      pageNumber: 0,
      pageSize: 50,
    },
  });
  const [searchQuery, setSearchQuery] = useState('');

  const updatePage = pagination.updatePageNumber;
  const debounceSearch = useMemo(
    () =>
      debounce((query: string) => {
        setSearchQuery(query);
        updatePage(0);
      }, 400),
    [updatePage],
  );

  const queryParams = useMemo(() => {
    const query = new URLSearchParams([...pagination.paginationQuery]);
    if (projectId) {
      query.append('projectId', projectId);
    }
    if (workspaceId) {
      query.append('workspaceId', workspaceId);
    }
    if (searchQuery) {
      query.append('searchQuery', searchQuery);
    }
    return query;
  }, [projectId, workspaceId, searchQuery, pagination]);

  const { isLoading, isValidating, data, error, mutate } = useRequest<PipelinesResponse>({
    url: `/pipelines?${queryParams.toString()}`,
    method: 'GET',
  });

  return {
    searchQuery,
    pipelines: data?.items ?? [],
    pipelinesError: error,
    pagination: {
      ...pagination,
      totalCount: data?.totalCount ?? 0,
    },
    isLoading: isLoading || isValidating,
    mutatePipelines: mutate,
    onSearchChange: debounceSearch,
  };
};

export default usePaginatedPipelines;
