import useGroupDragEvents from 'features/Flow/hooks/useGroupDragEvents';
import { useCallback, useRef } from 'react';
import {
  Edge,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  getConnectedEdges,
  useEdgesState,
  useNodesState,
  useStoreApi,
} from 'reactflow';
import { FlowNodeChange, FlowNodeData, ReactFlowProp } from 'types/reactflow';
import { runNextTick } from 'utils/browser';
import { filterGroupChildNodes } from 'utils/group';
import { mapJobInputValue } from 'utils/mappings';
import { ConnectionHandlerConnection } from './EditorContext';
import { APP_REACTFLOW_DATATRANSFER } from './Flow.consts';
import { HandleConnection, NodeManifest, isHandleConnection } from './Flow.types';
import { areEdgeAndConnectionEqual, getTargetInputKeyByEdge } from './Flow.utils';
import { mapNodeData } from './FlowNodeMap.utils';
import { applyEdgeUpdateChange, createEdgeUpdateChange } from './changes/EdgeUpdateChange';
import { applyNodeUpdateChange } from './changes/NodeUpdateChange';
import { applyNodeUpdateDataChange } from './changes/NodeUpdateDataChange';
import { applyNodeUpdateInputChange } from './changes/NodeUpdateInputChange';
import { applyNodeUpdateOutputChange } from './changes/NodeUpdateOutputChange';
import { useEditorContext } from './hooks/useEditorContext';
import useFlow from './hooks/useFlow';
import useRemoveEdge from './hooks/useRemoveEdge';
import { useUpdateNodeInput } from './hooks/useUpdateNodeInput';
import { isBatchGroupNode } from './nodes/Batch/Batch.types';
import { setCursorPosition } from 'utils/localStorage';

// In the future, consider moving all event handlers for the Flow component here
export const useFlowHandlers = () => {
  const reactFlowInstance = useFlow();
  const storeApi = useStoreApi();
  const removeEdge = useRemoveEdge();
  const updateNodeInput = useUpdateNodeInput();
  const [nodes, setInternalNodes] = useNodesState<FlowNodeData>([]);
  const [edges, setInternalEdges] = useEdgesState([]);
  const { callConnectionHandler, setNewNodeType } = useEditorContext();
  const {
    onDragOver: onGroupDragOver,
    onDrop: onGroupDrop,
    onNodeDrag,
    onNodeDragStop,
    onSelectionDrag,
    onSelectionDragStop,
  } = useGroupDragEvents();

  const shouldRemoveEdgeRef = useRef(false);

  const onDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();

      const stringifiedManifest = e.dataTransfer.getData(APP_REACTFLOW_DATATRANSFER);
      const manifest = JSON.parse(stringifiedManifest) as NodeManifest;

      const position = reactFlowInstance.screenToFlowPosition({
        x: e.clientX,
        y: e.clientY,
      });

      storeApi.getState().resetSelectedElements();

      const newNodes = mapNodeData(nodes, manifest, position);

      if (!onGroupDrop(newNodes)) {
        reactFlowInstance.addNodes(newNodes);
      }

      setNewNodeType(null);
    },
    [nodes, reactFlowInstance, storeApi, onGroupDrop, setNewNodeType],
  );

  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';

      onGroupDragOver(e);
    },
    [onGroupDragOver],
  );

  const updateTargetInputValue = useCallback(
    (edge: Edge | HandleConnection, nextEdges: Edge[]) => {
      const targetInputKey = getTargetInputKeyByEdge(edge);

      updateNodeInput(targetInputKey, (input) => ({
        value: mapJobInputValue({
          edges: nextEdges,
          nodes: reactFlowInstance.getNodes(),
          targetNodeId: targetInputKey.nodeId,
          input,
        }),
      }));
    },
    [reactFlowInstance, updateNodeInput],
  );

  const clearTargetInputValue = useCallback(
    (edge?: Edge) => {
      if (!edge) return;

      const targetInputKey = getTargetInputKeyByEdge(edge);

      updateNodeInput(targetInputKey, () => ({
        value: undefined,
      }));
    },
    [updateNodeInput],
  );

  const addConnection = useCallback(
    (connection: ConnectionHandlerConnection) => {
      const newEdge = addEdge(connection, [])[0];

      reactFlowInstance.addEdges(newEdge);
    },
    [reactFlowInstance],
  );

  const onConnect: ReactFlowProp['onConnect'] = useCallback(
    (newConnection) => {
      const handledConnection = callConnectionHandler({
        connection: newConnection,
        actionCallback: (connection) => {
          addConnection(connection ?? newConnection);
        },
      });

      // Prevent the connection from being created
      if (!handledConnection) {
        return;
      }

      addConnection(handledConnection);
    },
    [addConnection, callConnectionHandler],
  );

  const onEdgeUpdateStart: ReactFlowProp['onEdgeUpdateStart'] = useCallback(
    (_, edge) => {
      shouldRemoveEdgeRef.current = true;

      storeApi.setState(() => ({
        updatingEdge: edge,
      }));
    },
    [storeApi],
  );

  const updateConnection = useCallback(
    (oldEdge: Edge, newConnection: ConnectionHandlerConnection) => {
      if (newConnection.data) {
        oldEdge.data = {
          ...newConnection.data,
        } as unknown;
      }

      storeApi.getState().onEdgesChange?.([
        createEdgeUpdateChange({
          oldEdge,
          newConnection,
        }),
      ]);

      const shouldClearInputValue =
        oldEdge.target !== newConnection.target ||
        oldEdge.targetHandle !== newConnection.targetHandle;

      if (shouldClearInputValue) {
        clearTargetInputValue(oldEdge);
      }
    },
    [clearTargetInputValue, storeApi],
  );

  const onEdgeUpdate: ReactFlowProp['onEdgeUpdate'] = useCallback(
    (oldEdge, newConnection) => {
      shouldRemoveEdgeRef.current = false;

      if (areEdgeAndConnectionEqual(oldEdge, newConnection)) return;

      const handledConnection = callConnectionHandler({
        connection: newConnection,
        actionCallback: (connection) => {
          updateConnection(oldEdge, connection ?? newConnection);
        },
      });

      // Prevent the connection from being updated
      if (!handledConnection) {
        return;
      }

      updateConnection(oldEdge, handledConnection);
    },
    [callConnectionHandler, updateConnection],
  );

  const onEdgeUpdateEnd: ReactFlowProp['onEdgeUpdateEnd'] = useCallback(
    (_, edge) => {
      if (shouldRemoveEdgeRef.current) {
        removeEdge(edge);
      }

      storeApi.setState(() => ({
        updatingEdge: undefined,
      }));
    },
    [removeEdge, storeApi],
  );

  const onEdgeDoubleClick: ReactFlowProp['onEdgeDoubleClick'] = useCallback(
    (_, edge) => {
      removeEdge(edge);
    },
    [removeEdge],
  );

  const onMouseMove: ReactFlowProp['onMouseMove'] = useCallback(
    (event) => {
      setCursorPosition(
        reactFlowInstance.screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        }),
      );
    },
    [reactFlowInstance],
  );

  const applyFlowNodeChanges: typeof applyNodeChanges<FlowNodeData> = useCallback(
    (changes, prevNodes) => {
      const hasResetChange = changes.some((c) => c.type === 'reset');
      const nextNodes = hasResetChange
        ? // Reset changes cannot be reduced.
          applyNodeChanges(changes, prevNodes)
        : changes.reduce((nextNodes, change) => {
            switch (change.type) {
              case 'add': {
                // Insert before Pipeline Complete.
                return [...nextNodes.slice(0, -1), ...[change.item], ...nextNodes.slice(-1)];
              }
              case 'update': {
                return applyNodeUpdateChange(change, nextNodes);
              }
              case 'updateData': {
                return applyNodeUpdateDataChange(change, nextNodes);
              }
              case 'updateInput': {
                return applyNodeUpdateInputChange(change, nextNodes);
              }
              case 'updateOutput': {
                return applyNodeUpdateOutputChange(change, nextNodes);
              }
              default:
                return applyNodeChanges([change], nextNodes);
            }
          }, prevNodes);

      return nextNodes;
    },
    [],
  );

  const handleNodesChange: ReactFlowProp['onNodesChange'] = useCallback(
    (changes) => {
      for (const change of changes) {
        switch (change.type) {
          case 'remove': {
            const node = reactFlowInstance.getNodeOrThrow(change.id);

            if (isBatchGroupNode(node)) {
              const childNodes = filterGroupChildNodes(nodes, node.id);
              const childNodeEdges = getConnectedEdges(childNodes, edges);

              reactFlowInstance.deleteElements({
                edges: childNodeEdges,
              });

              // Also remove non-deletable child nodes when the parent is removed.
              const handledChanges = childNodes.flatMap((childNode) => {
                const isDeletable = childNode.deletable ?? true;

                if (isDeletable) return [];

                return [
                  {
                    type: 'remove',
                    id: childNode.id,
                  },
                ] satisfies FlowNodeChange[];
              });

              changes.push(...handledChanges);
            }
            break;
          }
        }
      }

      setInternalNodes((nodes) => {
        const computedNodes = nodes.map((node) => ({
          // get computed properties.
          ...reactFlowInstance.getNode(node.id),
          ...node,
        }));
        return applyFlowNodeChanges(changes, computedNodes);
      });
    },
    [applyFlowNodeChanges, edges, nodes, reactFlowInstance, setInternalNodes],
  );

  const applyFlowEdgeChanges: typeof applyEdgeChanges = useCallback(
    (changes, prevEdges) => {
      const hasResetChange = changes.some((c) => c.type === 'reset');
      const nextEdges = hasResetChange
        ? // Reset changes cannot be reduced.
          applyEdgeChanges(changes, prevEdges)
        : changes.reduce((nextEdges, change) => {
            switch (change.type) {
              case 'add': {
                /**
                 * Ignore `applyEdgeChanges` to avoid shifting the order of connections.
                 * @example Adding connections to Pipeline Complete.
                 */
                return addEdge(change.item, nextEdges);
              }
              case 'update': {
                return applyEdgeUpdateChange(change, nextEdges);
              }
              default:
                return applyEdgeChanges([change], nextEdges);
            }
          }, prevEdges);

      // Side effects - don't modify the next state.
      for (const change of changes) {
        switch (change.type) {
          case 'add': {
            /**
             * Defer to avoid un-registering the handle before `getHandle` is called.
             * @example Connecting a handle and calling `getHandle` inside an effect that relies on edges
             * like PipelineStart `handleConnectedOutput`.
             */
            runNextTick(() => {
              updateTargetInputValue(change.item, nextEdges);
            });
            break;
          }
          case 'remove': {
            const edge = reactFlowInstance.getEdge(change.id);
            /**
             * Defer to avoid a conflict with a node update change that ends up overriding the input value with the previous value.
             * @example Removing a child node with connections from a Batch group.
             */
            runNextTick(() => {
              clearTargetInputValue(edge);
            });
            break;
          }
          // Called when setting edges.
          case 'reset': {
            /**
             * Defer for the same reason as `add` change.
             */
            runNextTick(() => {
              updateTargetInputValue(change.item, nextEdges);
            });

            break;
          }
          case 'update': {
            /**
             * Defer for the same reason as `add` change.
             */
            runNextTick(() => {
              if (!isHandleConnection(change.newConnection)) return;

              updateTargetInputValue(change.newConnection, nextEdges);
            });

            break;
          }
        }
      }
      return nextEdges;
    },
    [clearTargetInputValue, reactFlowInstance, updateTargetInputValue],
  );

  const handleEdgesChange: ReactFlowProp['onEdgesChange'] = useCallback(
    (changes) => {
      setInternalEdges((edges) => {
        const computedEdges = edges.map((edge) => ({
          // get computed properties.
          ...reactFlowInstance.getEdge(edge.id),
          ...edge,
        }));
        return applyFlowEdgeChanges(changes, computedEdges);
      });
    },
    [applyFlowEdgeChanges, reactFlowInstance, setInternalEdges],
  );

  return {
    edges,
    nodes,
    // Outside should be only used to set initial edges.
    setInitialEdges: setInternalEdges,
    // Outside should be only used to set initial nodes.
    setInitialNodes: setInternalNodes,
    eventHandlers: {
      onConnect,
      onDragOver,
      onDrop,
      onEdgeUpdateStart,
      onEdgeUpdate,
      onEdgeUpdateEnd,
      onEdgesChange: handleEdgesChange,
      onEdgeDoubleClick,
      onMouseMove,
      onNodesChange: handleNodesChange,
      onNodeDrag,
      onNodeDragStop,
      onSelectionDrag,
      onSelectionDragStop,
    },
  };
};
