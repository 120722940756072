import { useState } from 'react';

export default function useDeletePipelineDialog() {
  const [deletePipelineDialogState, setDeletePipelineDialogState] = useState({
    open: false,
    pipelineId: '',
  });

  const openDeletePipelineDialog = (pipelineId: string) => {
    setDeletePipelineDialogState((state) => ({
      ...state,
      open: true,
      pipelineId,
    }));
  };

  const closeDeletePipelineDialog = () => {
    setDeletePipelineDialogState((state) => ({
      ...state,
      open: false,
    }));
  };

  return {
    deletePipelineDialogState,
    closeDeletePipelineDialog,
    openDeletePipelineDialog,
  };
}
