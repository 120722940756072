import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, Divider } from '@mui/material';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import ErrorAlert from 'pages/RuntimePage/components/RightSidebar/components/ErrorAlert/ErrorAlert';
import OutputTabPanelOutput from 'pages/RuntimePage/components/RightSidebar/components/OutputTabPanel/components/OutputTabPanelOutput';
import * as Styled from '../OutputTabPanel.styles';
import { FlowExecutionJob } from 'types/reactflow';
import { getExecutionStatus } from 'utils/execution';

interface OutputTabPanelErrorProps {
  executionJob?: FlowExecutionJob;
}

export default function OutputTabPanelError({ executionJob }: OutputTabPanelErrorProps) {
  const value = executionJob?.outputs;
  const status = getExecutionStatus(executionJob?.status);

  return (
    <>
      <OutputAccordion title="Error Message">
        <ErrorAlert>{executionJob?.failure}</ErrorAlert>
      </OutputAccordion>
      <Divider sx={{ marginX: 2 }} />
      <OutputAccordion title="Failure">
        <JsonEditor
          content={executionJob?.detailedFailure ?? {}}
          readOnly
          isArrayJson={Array.isArray(executionJob?.detailedFailure)}
        />
      </OutputAccordion>
      <Divider sx={{ marginX: 2 }} />
      <OutputAccordion title="View Output">
        <OutputTabPanelOutput status={status} value={value} />
      </OutputAccordion>
    </>
  );
}

interface OutputAccordionProps {
  title: string;
  children: React.ReactNode;
}

function OutputAccordion({ title, children }: OutputAccordionProps) {
  return (
    <Styled.OutputAccordion disableGutters>
      <Styled.OutputAccordionSummary expandIcon={<ExpandMore />}>
        {title}
      </Styled.OutputAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Styled.OutputAccordion>
  );
}
