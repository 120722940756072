import { useCallback, useRef } from 'react';

export function useExecuteOnce() {
  const ref = useRef(false);
  const setExecutedOnce = useCallback((value: boolean) => {
    ref.current = value;
  }, []);

  return {
    wasExecutedOnce: ref.current,
    setExecutedOnce,
  };
}
