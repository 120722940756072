import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link, Tooltip } from '@mui/material';

export default function OpenInNewTab(props: { href: string; tooltip?: string; linkText?: string }) {
  return (
    <Link
      onClick={(e) => {
        e.stopPropagation();
      }}
      rel="noopener"
      href={props.href}
      target="_blank"
      underline="none"
      variant="labelLarge"
      color="inherit"
      display="flex"
      alignItems="center"
    >
      {props.linkText}{' '}
      <Tooltip title={props.tooltip ?? 'Open in a new Tab'}>
        <OpenInNewIcon sx={{ ml: 1 }} />
      </Tooltip>
    </Link>
  );
}
