import { useCallback, useState } from 'react';
import {
  Content,
  OnChangeStatus,
  isContent,
  isJSONContent,
  toJSONContent,
} from 'vanilla-jsoneditor';

export const useJsonEditorState = <TValue>(initialValue: TValue) => {
  const [error, setError] = useState(false);
  const [content, setContent] = useState<Content>({
    json: initialValue,
  });
  const [value, setValue] = useState<TValue | ''>(initialValue);

  const onChange = useCallback(
    (updatedContent: Content, _previousContent: Content, status: OnChangeStatus) => {
      setError(Boolean(status.contentErrors));

      const nextContent = isContent(updatedContent)
        ? updatedContent
        : toJSONContent(updatedContent);

      setContent(nextContent);

      if (isJSONContent(nextContent)) {
        setValue(nextContent.json as TValue);
      } else {
        try {
          setValue(toJSONContent(nextContent).json as TValue);
        } catch (_error) {
          setValue(nextContent.text as TValue);
        }
      }
    },
    [setError],
  );

  return { content, error, value, onChange, setContent };
};
