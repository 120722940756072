import { Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import * as Styled from './ObjectExtractorModal.styles';
import { NodeOutput } from '../../../Node/Node.types';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableNodeOutput from './SortableNodeOutput';
import { useState } from 'react';
import * as StyledDialog from 'components/Dialog/Dialog.styles';

interface ObjectExtractorListProps {
  outputs: NodeOutput[];
  onCreateOutput: () => void;
  onEditOutput: (output: NodeOutput, position: number) => void;
  onRemoveOutput: (index: number) => void;
  onCloseModal: () => void;
  onConfirm: () => void;
  onReorder: (from: string, to: string) => void;
}

const ObjectExtractorList: React.FC<ObjectExtractorListProps> = ({
  outputs,
  onCreateOutput,
  onEditOutput,
  onRemoveOutput,
  onCloseModal,
  onConfirm,
  onReorder,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setIsDragging(false);
    if (event.over && event.active.id !== event.over.id) {
      onReorder(event.active.id as string, event.over.id as string);
    }
  };

  return (
    <>
      <StyledDialog.DialogTitle $hasBorder={true}>Configure Schema</StyledDialog.DialogTitle>
      <StyledDialog.DialogContent>
        <Styled.SortableBox $isDragging={isDragging}>
          {outputs.length === 0 && (
            <StyledDialog.NoItemsBox>
              <Typography variant="labelSmall" color="text.secondary">
                No outputs created yet. Use the &apos;Create Output&apos; button below to get
                started.
              </Typography>
            </StyledDialog.NoItemsBox>
          )}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            autoScroll={false}
          >
            <SortableContext
              items={outputs.map((output) => output.name)}
              strategy={verticalListSortingStrategy}
            >
              {outputs.map((output, index) => (
                <SortableNodeOutput
                  key={output.name}
                  index={index}
                  output={output}
                  isDragging={isDragging}
                  onEditOutput={onEditOutput}
                  onRemoveOutput={onRemoveOutput}
                />
              ))}
            </SortableContext>
          </DndContext>
        </Styled.SortableBox>
      </StyledDialog.DialogContent>
      <StyledDialog.DialogActions $hasBorder={true}>
        <Grid container justifyContent="space-between">
          <Button startIcon={<AddIcon />} onClick={onCreateOutput}>
            Create Output
          </Button>
          <div>
            <Button variant="text" sx={{ color: 'common.white' }} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </div>
        </Grid>
      </StyledDialog.DialogActions>
    </>
  );
};

export default ObjectExtractorList;
