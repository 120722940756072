import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

export const ProgressBar = styled(LinearProgress)`
  height: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.surface.level0};

  & > .MuiLinearProgress-bar {
    border-radius: 8px;
  }
`;
