import { Checkbox } from '@mui/material';
import { Controller, FieldPath, FieldPathValue, FieldValues } from 'react-hook-form';
import { RHFCheckboxProps } from './RHFCheckbox.types';

const RHFCheckbox = <T extends FieldValues>(props: RHFCheckboxProps<T>) => {
  const { control, name, rules, ...muiCheckboxProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={(muiCheckboxProps.checked ?? false) as FieldPathValue<T, FieldPath<T>>}
      rules={rules}
      render={({ field }) => {
        return (
          <Checkbox
            {...field}
            {...muiCheckboxProps}
            checked={field.value}
            onChange={(e) => {
              const checked = e.target.checked;
              field.onChange(checked);
            }}
          />
        );
      }}
    />
  );
};

export default RHFCheckbox;
