import { Box } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { ACTION_COLOR } from 'config/theme';
import styled, { css } from 'styled-components';

export const Container = styled(Box)(({ theme }) => {
  const textPrimaryColor = theme.palette.text.primary;
  const highlightColor = theme.palette.primary.hover;
  const textDisabledColor = theme.palette.text.disabled;
  const primaryDarkColor = theme.palette.primary.dark;
  const primaryMainColor = theme.palette.primary.main;

  return css`
    overflow: hidden;
    height: 100%;

    --jse-theme: dark;

    /* over all fonts, sizes, and colors */
    --jse-theme-color: ${primaryDarkColor};
    --jse-theme-color-highlight: ${highlightColor};
    --jse-background-color: ${({ theme }) => theme.palette.surface.level0};
    --jse-text-color: #d4d4d4;
    --jse-text-color-inverse: #4d4d4d;

    /* main, menu, modal */
    --jse-main-border: 0;
    --jse-menu-color: #fff;
    --jse-modal-background: #2f2f2f;
    --jse-modal-overlay-background: rgba(0, 0, 0, 0.5);
    --jse-modal-code-background: #2f2f2f;

    /* tooltip in text mode */
    --jse-tooltip-color: var(--jse-text-color);
    --jse-tooltip-background: #4b4b4b;
    --jse-tooltip-border: 1px solid #737373;
    --jse-tooltip-action-button-color: inherit;
    --jse-tooltip-action-button-background: #737373;

    /* panels: navigation bar, gutter, search box */
    --jse-panel-background: transparent;
    --jse-panel-background-border: 0;
    --jse-panel-color: var(--jse-text-color);
    --jse-panel-color-readonly: ${textDisabledColor};
    --jse-panel-border: 0;
    --jse-panel-button-color-highlight: #e5e5e5;
    --jse-panel-button-background-highlight: ${highlightColor};

    /* contents: json key and values */
    --jse-key-color: ${textPrimaryColor};
    --jse-value-color: ${deepPurple[100]};
    --jse-value-color-number: var(--jse-value-color);
    --jse-value-color-boolean: var(--jse-value-color);
    --jse-value-color-null: var(--jse-value-color);
    --jse-value-color-string: var(--jse-value-color);
    --jse-value-color-url: var(--jse-value-color);
    --jse-delimiter-color: ${textDisabledColor};
    --jse-edit-outline: 2px solid var(--jse-value-color);

    /* contents: selected or hovered */
    --jse-selection-background-color: #464646;
    --jse-selection-background-inactive-color: #333333;
    --jse-hover-background-color: #343434;
    --jse-active-line-background-color: rgba(255, 255, 255, 0.06);
    --jse-search-match-background-color: #343434;

    /* contents: section of collapsed items in an array */
    --jse-collapsed-items-background-color: #333333;
    --jse-collapsed-items-selected-background-color: #565656;
    --jse-collapsed-items-link-color: #b2b2b2;
    --jse-collapsed-items-link-color-highlight: #9a77ec;

    /* contents: highlighting of search results */
    --jse-search-match-color: ${primaryDarkColor};
    --jse-search-match-outline: 1px solid ${primaryMainColor};
    --jse-search-match-active-color: ${primaryDarkColor};
    --jse-search-match-active-outline: 1px solid ${primaryMainColor};

    /* contents: inline tags inside the JSON document */
    --jse-tag-background: #444444;
    --jse-tag-color: #bdbdbd;

    /* contents: table */
    --jse-table-header-background: #333333;
    --jse-table-header-background-highlight: #424242;
    --jse-table-row-odd-background: rgba(255, 255, 255, 0.1);

    /* controls in modals: inputs, buttons, and 'a' */
    --jse-input-background: #3d3d3d;
    --jse-input-border: var(--jse-main-border);
    --jse-button-background: #808080;
    --jse-button-background-highlight: #7a7a7a;
    --jse-button-color: #e0e0e0;
    --jse-button-secondary-background: #494949;
    --jse-button-secondary-background-highlight: #5d5d5d;
    --jse-button-secondary-background-disabled: #9d9d9d;
    --jse-button-secondary-color: var(--jse-text-color);
    --jse-a-color: #8855ff;
    --jse-a-color-highlight: #6b43c9;

    .cm-editor {
      --indent-marker-bg-color: hsl(258, 23%, 22%);
      --indent-marker-active-bg-color: hsl(258 21% 30% / 1);

      &.cm-focused .cm-matchingBracket {
        background-color: ${ACTION_COLOR.active(primaryDarkColor)};
      }
    }
  `;
});
