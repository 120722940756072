import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import SettingIcon from 'components/Icons/Setting';

export default function ConfigureSchemaButton(props: Omit<IconButtonProps, 'children'>) {
  return (
    <Tooltip title="Configure Schema">
      <IconButton data-testid="ConfigureSchemaButton" size="small" {...props}>
        <SettingIcon />
      </IconButton>
    </Tooltip>
  );
}
