import { Theme } from '@mui/material';
import { type PaletteColorKeys } from 'config/theme';
import { NodeType } from 'features/Flow/Flow.types';
import { ElementLayerButton } from 'features/Flow/components/ElementLayerButton/ElementLayerButton.styles';
import styled, { css } from 'styled-components';

function createStyles({
  colorKey,
  selected,
  theme,
}: {
  colorKey: PaletteColorKeys;
  selected?: boolean;
  theme: Theme;
}) {
  const paletteColor = theme.palette[colorKey];

  if (selected) {
    return css`
      color: ${paletteColor.dark};
      background-color: ${paletteColor.light};

      &:hover {
        background-color: ${paletteColor.light};
      }
    `;
  }

  return css`
    &:hover {
      background-color: ${paletteColor.hover};
    }
  `;
}

interface JobElementLayerButtonProps {
  $nodeType?: NodeType;
  selected?: boolean;
}

export const JobElementLayerButton = styled(ElementLayerButton)<JobElementLayerButtonProps>(
  ({ theme, $nodeType, selected }) => css`
    ${() => {
      switch ($nodeType) {
        case NodeType.NEURON:
          return createStyles({
            colorKey: 'primary',
            selected,
            theme,
          });
        case NodeType.FUNCTION:
        case NodeType.BATCH_START:
        case NodeType.BATCH_END:
          return createStyles({
            colorKey: 'secondary',
            selected,
            theme,
          });
        case NodeType.SUBPIPELINE:
          return createStyles({
            colorKey: 'warning',
            selected,
            theme,
          });

        default:
          return createStyles({
            colorKey: 'info',
            selected,
            theme,
          });
      }
    }}
  `,
);
