import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { NodeOutput } from '../Node/Node.types';
import { Edge } from 'reactflow';
import { useGetDataHandle } from 'features/Flow/Handles/handles';
import { isHandleConnection } from 'features/Flow/Flow.types';
import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { HandleKey } from 'features/Flow/Handles/handle.store';
import { PipelineStartData } from './PipelineStart.types';
import { useUpdateNodeOutput } from 'features/Flow/hooks/useUpdateNodeOutput';
import { unsetDataSchema } from 'utils/dataSchema';

interface UsePipelineStartOutputsProps {
  nodeId: string;
  outputs: PipelineStartData['outputs'];
}

/**
 * Custom hook for handling pipeline start outputs special rules
 * when connecting or disconnecting to other nodes.
 */
export const usePipelineStartOutputs = ({ nodeId, outputs }: UsePipelineStartOutputsProps) => {
  const updateNodeData = useUpdateNodeData();
  const updateNodeOutput = useUpdateNodeOutput();
  const getDataHandle = useGetDataHandle();

  const handleConnectedOutput = useCallback(
    (output: NodeOutput, outputEdge: Edge) => {
      if (!isHandleConnection(outputEdge)) return;

      const handleKey: HandleKey = {
        id: outputEdge.targetHandle,
        nodeId: outputEdge.target,
      };
      const targetHandle = getDataHandle(handleKey);

      if (!targetHandle) return;

      const nextDataSchema: typeof output.dataSchema = targetHandle.schema;

      const isSameConfig = isEqual(output.config, targetHandle.config);
      const isSameDataSchema = isEqual(output.dataSchema, nextDataSchema);

      if (isSameConfig && isSameDataSchema) return;

      updateNodeOutput(
        {
          nodeId,
          outputName: output.name,
        },
        () => ({
          config: targetHandle.config,
          dataSchema: nextDataSchema,
        }),
      );
    },
    [getDataHandle, nodeId, updateNodeOutput],
  );

  const handleDisconnectedOutput = useCallback(
    (output: NodeOutput) => {
      const nextConfig: typeof output.config = {
        ...output.config,
        required: false,
      };
      const nextSchema = unsetDataSchema(output.dataSchema);
      const isSameConfig = isEqual(output.config, nextConfig);
      const isSameDataSchema = isEqual(output.dataSchema, nextSchema);

      if (isSameConfig && isSameDataSchema) return;

      updateNodeOutput(
        {
          nodeId,
          outputName: output.name,
        },
        () => ({
          config: nextConfig,
          dataSchema: nextSchema,
        }),
      );
    },
    [nodeId, updateNodeOutput],
  );

  const attemptAddOutput = useCallback(
    (output: NodeOutput, nodeId: string): boolean => {
      if (outputs.length > 0) {
        const isNewKeyInUse = outputs.some((item) => item.name === output.name);

        if (isNewKeyInUse) {
          return false;
        }
      }

      updateNodeData<PipelineStartData>(nodeId, (data) => ({
        ...data,
        outputs: [...outputs, output],
      }));

      return true;
    },
    [outputs, updateNodeData],
  );

  return {
    handleConnectedOutput,
    handleDisconnectedOutput,
    attemptAddOutput,
  };
};
