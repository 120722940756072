import { NodeDataUpdater } from 'features/Flow/changes/NodeUpdateDataChange';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';
import { type CustomAddHandles, type DynamicCheckpointProps } from './DynamicCheckpoint';
import { DynamicCheckpointData } from './DynamicCheckpoint.types';
import { createAddHandles, getDataHandles } from './DynamicCheckpoint.utils';

interface UseDynamicCheckpointProps extends Pick<DynamicCheckpointProps, 'id' | 'data'> {
  isNodeConnected: boolean;
}

export function useDynamicCheckpoint(props: UseDynamicCheckpointProps) {
  const updateNodeData = useUpdateNodeData();
  const { id: nodeId, data: nodeData, isNodeConnected } = props;

  const customAddHandles = useMemo(() => createAddHandles(nodeId), [nodeId]);
  const dataHandles = useMemo(() => getDataHandles(nodeData), [nodeData]);

  const displayDataHandles = !!dataHandles;
  const displayAddHandles = !displayDataHandles;

  useEffect(() => {
    const dataHandlesUpdater = createDataHandlesUpdater({
      isNodeConnected,
      handleSchemas: nodeData.schemas,
      customAddHandles,
    });

    updateNodeData<typeof nodeData>(nodeId, dataHandlesUpdater);
  }, [customAddHandles, isNodeConnected, nodeData.schemas, nodeId, updateNodeData]);

  return {
    customAddHandles,
    dataHandles,
    displayDataHandles,
    displayAddHandles,
  };
}

export function createDataHandlesUpdater(options: {
  isNodeConnected: boolean;
  handleSchemas: DynamicCheckpointData['schemas'];
  customAddHandles: CustomAddHandles;
}) {
  const { isNodeConnected, handleSchemas, customAddHandles } = options;

  const handler: NodeDataUpdater<DynamicCheckpointData> = (data) => {
    if (isNodeConnected) return data;

    if (!handleSchemas)
      return {
        inputs: [],
        outputs: [],
      };

    const input = data.inputs.at(0);
    const isSameInputSchema = isEqual(input?.dataSchema, handleSchemas.input);
    const inputValue = isSameInputSchema ? input?.value : undefined;

    /**
     * Pre-configured data handles use the Add connection handler so when connected the source schema is set.
     * @example Asset configuration allows any media type but when connected, only that specific media type.
     */
    return {
      inputs: [
        {
          config: {
            hideConnectionStatus: true,
          },
          dataSchema: handleSchemas.input,
          id: customAddHandles.input.id,
          name: customAddHandles.input.name,
          title: customAddHandles.input.title,
          value: inputValue,
        },
        ...data.inputs.slice(1),
      ],
      outputs: [
        {
          config: {},
          dataSchema: handleSchemas.output,
          id: customAddHandles.output.id,
          name: customAddHandles.output.name,
          title: customAddHandles.output.title,
        },
        ...data.outputs.slice(1),
      ],
    };
  };

  return handler;
}
