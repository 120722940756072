import { NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { DataType } from '../../Node/DataType';
import { ArrayDataSchema } from '@pathways/pipeline-schema';

export interface NodeOutputFormData {
  path: string;
  dataType: DataType;
  arrayDataType?: ArrayDataSchema['items']['type'];
  title?: string;
}

/**
 * @param updated New list of neuron outputs for the node.
 * @param handleIdsToRemove On commit, we need to remove the connections of the handles that have been removed before updating with the new list of outputs.
 */
export type ObjectExtractorCommitFn = (
  updated: NodeOutput[],
  handleIdsToRemove: string[],
  extractions: ObjectExtractions,
) => void;

export const DEFAULT_VIEW = 'list';

export type ViewStates =
  | {
      view: 'list';
    }
  | {
      view: 'edit';
      outputToEdit: NodeOutput;
      updateIndex: number;
    }
  | {
      view: 'create';
    };

export type ObjectExtractions = Record<string, string>;
