import Splash from 'components/Splash/Splash';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { redirectToPlatform } from 'utils/redirectToPlatform';
import { useAppRoutes } from 'utils/routes';

const WORKSPACE_SLUG_KEY = 'monksFlowWorkspaceSlug';
const PROJECT_ID_KEY = 'monksFlowProjectId';

export default function PlatformLandingRoute() {
  const routes = useAppRoutes();
  const [searchParams] = useSearchParams();
  const platformWorkspaceSlug = searchParams.get(WORKSPACE_SLUG_KEY);
  const platformProjectId = searchParams.get(PROJECT_ID_KEY);
  const { workspaceSlug } = useParams();

  if (platformWorkspaceSlug && platformProjectId) {
    return <Navigate to={routes.index(platformWorkspaceSlug, platformProjectId)} replace />;
  }

  if (!workspaceSlug) {
    if (import.meta.env.USE_LOCAL_AUTH) {
      const localWorkspaceSlug = workspaceSlug ?? 'mm-gw';
      const localProjectId = import.meta.env.LOCAL_DEFAULT_PROJECT_ID;

      return <Navigate to={routes.index(localWorkspaceSlug, localProjectId)} replace />;
    }

    redirectToPlatform();

    return <Splash />;
  }

  return <Navigate to={routes.dashboard()} replace />;
}
