import { SHORTCUT_CONFIG } from 'config/shortcut';
import useToast from 'contexts/toast/useToast';
import { useEffect } from 'react';
import { getNodesBounds, useKeyPress, useStoreApi } from 'reactflow';
import { calculateNodePositionAtCursorPosition } from 'utils/neurons';
import { ClipboardData } from '../Flow.types';
import { createNodeId, isClipboardData } from '../Flow.utils';
import { isDynamicValue, isPathwayNode } from '../nodes/Node/Node.types';
import useCopyElementsToClipboard from './useCopyElementsToClipboard';
import useFlow from './useFlow';
import { getCursorPosition } from 'utils/localStorage';

export default function useCopyPasteShortcuts() {
  const toast = useToast();
  const store = useStoreApi();
  const { resetSelectedElements } = store.getState();
  const { addNodes, addEdges } = useFlow();
  const copyPressed = useKeyPress(SHORTCUT_CONFIG.copy.keyCode, {
    target: document.body,
  });
  const pastePressed = useKeyPress(SHORTCUT_CONFIG.paste.keyCode, {
    target: document.body,
  });
  const copyElementsToClipboard = useCopyElementsToClipboard();

  useEffect(() => {
    if (!copyPressed) return;

    void copyElementsToClipboard();
  }, [copyElementsToClipboard, copyPressed]);

  useEffect(() => {
    if (!pastePressed) return;

    resetSelectedElements();
    void pasteElementsFromClipboard();

    async function pasteElementsFromClipboard() {
      try {
        const clipboardText = await navigator.clipboard.readText();
        const clipboardData = JSON.parse(clipboardText) as object;

        if (!isClipboardData(clipboardData)) return;

        const { newNodes, newEdges } = createElementsFromData(clipboardData);

        addNodes(newNodes);
        addEdges(newEdges);
      } catch (untypedError) {
        const error = untypedError as Error;
        const notSupported = error.name === 'TypeError' && /not a function/i.test(error.message); // Firefox.
        const permissionDenied = error.name === 'NotAllowedError';

        if (notSupported) {
          toast.error({
            message: 'Pasting from clipboard is not supported.',
          });
        }

        if (permissionDenied) {
          toast.error({
            message: 'Clipboard permission is denied.',
          });
        }
      }
    }

    function createElementsFromData(data: ClipboardData) {
      const { nodes: newNodes, edges: newEdges } = structuredClone(data);
      const nodeIdMap = new Map(newNodes.map((node) => [node.id, createNodeId()]));
      const nodesRect = getNodesBounds(newNodes);
      const cursorPosition = getCursorPosition() ?? { x: 0, y: 0 };

      newNodes.forEach((node) => {
        const newNodeId = nodeIdMap.get(node.id) ?? '';

        node.id = newNodeId;
        node.selected = true;

        if (node.parentNode) {
          node.parentNode = nodeIdMap.get(node.parentNode);
          // Keep only selected the group node.
          node.selected = false;
        } else {
          node.position = calculateNodePositionAtCursorPosition({
            nodePosition: node.position,
            nodesRect,
            cursorPosition,
          });
        }

        if (isPathwayNode(node)) {
          node.data.inputs.forEach((input) => {
            if (isDynamicValue(input.value)) {
              input.value = undefined;
            }
          });
        }
      });

      newEdges.forEach((edge) => {
        const newSource = nodeIdMap.get(edge.source) ?? '';
        const newTarget = nodeIdMap.get(edge.target) ?? '';
        const newId = edge.id.replaceAll(edge.source, newSource).replaceAll(edge.target, newTarget);

        edge.id = newId;
        edge.source = newSource;
        edge.target = newTarget;
      });

      return { newNodes, newEdges };
    }
  }, [addEdges, addNodes, pastePressed, resetSelectedElements, store, toast]);
}
