import { ChevronLeft } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import JobDescriptionTooltip from 'features/Flow/components/JobDescriptionTooltip/JobDescriptionTooltip';
import { JobNode } from 'types/reactflow';

interface SubPanelHeaderProps {
  node: JobNode;
  title: string;
  onBackClick: () => void;
}

export default function SubPanelHeader(props: SubPanelHeaderProps) {
  return (
    <Box display="flex" gap={1} alignItems="center">
      <IconButton
        data-testid="BackButton"
        sx={{
          marginLeft: -1,
          marginY: -1,
        }}
        onClick={props.onBackClick}
      >
        <ChevronLeft />
      </IconButton>
      <Typography variant="titleMedium" width="100%">
        {props.title}
      </Typography>
      <JobDescriptionTooltip job={props.node.data.metadata} />
    </Box>
  );
}
