import ObjectBuilderCreateForm from '../ObjectBuilderForms/ObjectBuilderCreateForm';
import { ObjectBuilderQuickCreateModalProps } from './ObjectBuilderQuickCreateModal.types';
import * as Styled from 'components/Dialog/Dialog.styles';

export default function ObjectBuilderQuickCreateModal({
  open,
  inputs,
  defaultValues,
  onCloseModal,
  onCreateInput,
}: ObjectBuilderQuickCreateModalProps) {
  return (
    <Styled.Dialog
      open={open}
      onClose={onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <ObjectBuilderCreateForm
        defaultValues={defaultValues}
        nodeInputs={inputs}
        isPendingConnection
        onCancelCreate={onCloseModal}
        onConfirmCreate={onCreateInput}
      />
    </Styled.Dialog>
  );
}
