import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { formatRuntime } from 'utils/execution';
import { getStatusConfig } from 'utils/status';
import { TimerProps } from './Timer.types';

export default function Timer(props: TimerProps) {
  const { status, startedAt, completedAt } = props;
  const statusConfig = getStatusConfig(status);
  const config: typeof statusConfig.text = {
    ...statusConfig.text,
    variant: 'labelMedium',
  };

  const active = !!(startedAt && !completedAt);
  const [, renderKey] = useState(Date.now());

  useEffect(() => {
    if (!active) return;

    const intervalId = setInterval(() => {
      renderKey(Date.now());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [active, startedAt]);

  return (
    <Typography {...config}>
      {formatRuntime(startedAt ?? new Date(), completedAt ?? new Date())}
    </Typography>
  );
}
