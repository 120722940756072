import { z } from 'zod';
import { JobDataTypes, JobTypes, PipelineType } from '.';
const zodEnum = (arr) => arr;
const jobInput = z
    .array(z.object({
    id: z.string().optional(),
    name: z.string(),
    dataType: z.enum(zodEnum(Object.values(JobDataTypes))).optional(),
    dataSchema: z.custom(),
    value: z
        .union([
        z.string(),
        z.number(),
        z.boolean(),
        z.record(z.any()),
        z.array(z.string()),
        z.array(z.number()),
        z.array(z.record(z.any())),
    ])
        .optional(),
    title: z.string().optional(),
    description: z.string().default(''),
    config: z
        .object({
        enumOptions: z.array(z.string()).optional(),
        required: z.boolean().optional(),
        forceHide: z.boolean().optional(),
        minimum: z.number().optional(),
        maximum: z.number().optional(),
        hidden: z.boolean().optional(),
        default: z.union([z.string(), z.number()]).optional(),
    })
        .optional(),
}))
    .optional();
const jobOutput = z
    .array(z.object({
    name: z.string(),
    title: z.string().optional(),
    description: z.string().optional(),
    dataType: z.string().optional(),
    dataSchema: z.custom(),
    value: z.string().optional(),
    config: z
        .object({
        required: z.boolean().optional(),
        forceHide: z.boolean().optional(),
    })
        .optional(),
}))
    .optional();
const nodeSchema = z.record(z.any()).optional();
export const jobDataSchema = z
    .object({
    metadata: z.object({
        type: z.enum(zodEnum(Object.values(JobTypes))),
        jobName: z.string().optional(),
        name: z.string().optional(),
        uses: z.string().optional(),
        description: z.string().optional(),
        customTitle: z.string().optional(),
        variation: z.string().optional(),
    }),
    inputs: jobInput,
    outputs: jobOutput,
    schemas: nodeSchema,
})
    .strict();
export const jobSchema = z.object({
    id: z.string(),
    render: z
        .object({
        deletable: z.boolean().optional(),
        position: z.object({ x: z.number().optional(), y: z.number().optional() }),
    })
        .catchall(z.any()),
    data: jobDataSchema,
});
export const subpipelineJobSchema = z.object({
    id: z.string(),
    render: z
        .object({
        deletable: z.boolean().optional(),
        position: z.object({ x: z.number().optional(), y: z.number().optional() }),
    })
        .catchall(z.any()),
    data: z
        .object({
        metadata: z.object({
            type: z.enum(zodEnum(Object.values(JobTypes))),
            jobName: z.string().optional(),
            name: z.string().optional(),
            description: z.string().optional(),
            customTitle: z.string().optional(),
        }),
        pipeline: z.object({
            id: z.string(),
            version: z.number(),
            versionId: z.string(),
        }),
        inputs: jobInput,
        outputs: jobOutput,
    })
        .strict(),
});
export const batchDataSchema = z
    .object({
    metadata: z.object({
        name: z.string(),
        description: z.string().optional(),
        type: z.enum(zodEnum(Object.values(JobTypes))),
        customTitle: z.string().optional(),
    }),
    jobs: jobSchema.array().describe('Batch jobs'),
    inputs: jobInput,
    outputs: jobOutput,
})
    .strict();
export const batchJobSchema = z.object({
    id: z.string(),
    render: z
        .object({
        deletable: z.boolean().optional(),
        position: z.object({ x: z.number().optional(), y: z.number().optional() }),
    })
        .catchall(z.any()),
    data: batchDataSchema,
});
const connectionSchema = z
    .array(z
    .object({
    id: z.string().optional(),
    render: z
        .object({
        data: z
            .object({
            id: z.string().optional(),
            name: z.string().optional(),
            title: z.string().optional(),
            dataType: z.enum(zodEnum(Object.values(JobDataTypes))).optional(),
            description: z.string().optional(),
            dataSchema: z.custom(),
            config: z
                .object({
                required: z.boolean().optional(),
                forceHide: z.boolean().optional(),
            })
                .optional(),
        })
            .optional(),
        type: z.string().optional(),
        source: z.string(),
        sourceHandle: z.string(),
        target: z.string(),
        targetHandle: z.string(),
    })
        .catchall(z.any())
        .optional(),
})
    .strict())
    .describe('Neurons connections');
const groupSchema = z
    .array(z
    .object({
    id: z.string(),
    name: z.string(),
    description: z.string().optional(),
    render: z
        .object({ color: z.string().optional() })
        .catchall(z.any())
        .optional(),
})
    .strict())
    .describe('Groups of jobs')
    .optional();
const jobDiscriminatedUnion = z.record(z.any()).superRefine((val, ctx) => {
    // Cannot use z.discriminatedUnion due to nested property that marks the discrimination and zod limitations
    // Cast the type for now
    const value = val;
    try {
        switch (value.data.metadata.type) {
            case JobTypes.BATCH:
                batchJobSchema.parse(val);
                break;
            case JobTypes.SUBPIPELINE:
                subpipelineJobSchema.parse(val);
                break;
            default:
                jobSchema.parse(val);
        }
    }
    catch (err) {
        if (err instanceof Error && 'issues' in err) {
            const issues = err.issues;
            issues.forEach((issue) => {
                ctx.addIssue(issue);
            });
        }
        else {
            ctx.addIssue({
                message: err instanceof Error ? err.message : 'Unknown validation error',
                code: 'custom',
            });
        }
    }
});
export const pipelineSchema = z
    .object({
    jobs: jobDiscriminatedUnion.array().describe('Jobs to be executed'),
    connections: connectionSchema,
    groups: groupSchema,
})
    .strict();
const pipelineName = z.string().min(2).max(100);
const pipelineDescription = z.string().max(2000).optional().nullable();
const pipelineType = z.enum(zodEnum(Object.values(PipelineType))).optional();
const eitherProjectOrWorkspace = z
    .object({
    projectId: z.string().uuid(),
    workspaceId: z.string().uuid(),
})
    .partial()
    .refine((data) => !!data.projectId || !!data.workspaceId, 'Either projectId or workspaceId is required');
export const addPipelineSchema = z
    .object({
    name: pipelineName,
    type: pipelineType,
    description: pipelineDescription,
    content: z.any(), // this is already being validated by the pipelineSchema
})
    .and(eitherProjectOrWorkspace);
export const updatePipelineSchema = z.object({
    name: pipelineName,
    type: pipelineType,
});
export const importPipelineFileSchema = z.object({
    name: pipelineName,
    type: pipelineType,
    content: pipelineSchema,
    description: z.string(),
});
