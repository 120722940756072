import { useStore, useUpdateNodeInternals } from 'reactflow';
import { NodeType } from 'features/Flow/Flow.types';
import { useSelectNodeOnClick } from 'features/Flow/Flow.utils';
import { NodeInput, NodeOutput, PathwayNodeProps } from 'features/Flow/nodes/Node/Node.types';
import { NodeHeader } from 'features/Flow/nodes/Node/components/NodeHeader';
import * as Styled from 'features/Flow/nodes/Node/Node.styles';
import { useGetNodeExecution } from 'features/Flow/hooks/useGetNodeExecution';
import { FlowState } from 'types/reactflow';
import CustomHandle from 'features/Flow/components/NeuronHandle/CustomHandle';
import NodeInputHandle from 'features/Flow/nodes/Node/NodeInputHandle';
import NodeOutputHandle from 'features/Flow/nodes/Node/NodeOutputHandle';
import { useCallback, useEffect, useState } from 'react';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { IconButton } from '@mui/material';
import SettingIcon from 'components/Icons/Setting';
import DummyNeuronModal from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/DummyNeuronModal';
import useDummyNeuronCustomHandle from 'features/Flow/nodes/Neuron/DummyNeuron/useDummyNeuronCustomHandle';
import { DataHandle } from 'features/Flow/Handles/handle.store';
import { isArrayDataSchema, DataSchema } from '@pathways/pipeline-schema/web';
import DummyNeuronQuickCreateModal from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/DummyNeuronQuickCreateModal';
import useDummyNeuronQuickCreateModal from 'features/Flow/nodes/Neuron/DummyNeuron/useDummyNeuronQuickCreateModal';

const selector = ({ mode, edges }: FlowState) => ({
  mode,
  edges,
});

const canConnect = (handle: DataHandle) => {
  const validTypes: DataSchema['type'][] = [
    'integer',
    'number',
    'text',
    'boolean',
    'object',
    'asset',
  ];

  if (validTypes.includes(handle.schema.type)) {
    return true;
  }

  if (
    isArrayDataSchema(handle.schema) &&
    handle.schema.items.type &&
    validTypes.includes(handle.schema.items.type)
  ) {
    return true;
  }

  return false;
};

const DummyNeuron: React.FC<PathwayNodeProps> = (props) => {
  const {
    id: nodeId,
    data: { metadata, inputs, outputs },
  } = props;
  const { execution } = useGetNodeExecution({ id: nodeId });

  const { mode } = useStore(selector);
  const handleSelectNodeOnClick = useSelectNodeOnClick(nodeId);

  const {
    isOpen: isQuickModalOpen,
    type: quickCreateType,
    item: quickCreateItem,
    openModalForInput,
    openModalForOutput,
    closeModal: closeQuickCreateModal,
  } = useDummyNeuronQuickCreateModal();
  const {
    handle: addInputHandle,
    cancelConnection: onCloseModalForInput,
    createInput,
  } = useDummyNeuronCustomHandle({
    type: 'input',
    nodeId,
    inputs,
    canConnect,
    onOpenModalForInput: openModalForInput,
  });
  const {
    handle: addOutputHandle,
    cancelConnection: onCloseModalForOutput,
    createOutput,
  } = useDummyNeuronCustomHandle({
    type: 'output',
    nodeId,
    outputs,
    canConnect,
    onOpenModalForOutput: openModalForOutput,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfirmQuickModal = useCallback(
    (item: NodeInput | NodeOutput) => {
      if (quickCreateType === 'input') {
        createInput(item);
      } else {
        createOutput(item as NodeOutput);
      }
      closeQuickCreateModal();
    },
    [quickCreateType, createInput, createOutput, closeQuickCreateModal],
  );

  const handleCancelQuickModal = useCallback(() => {
    if (quickCreateType === 'input') {
      onCloseModalForInput();
    } else {
      onCloseModalForOutput();
    }
    closeQuickCreateModal();
  }, [quickCreateType, onCloseModalForInput, onCloseModalForOutput, closeQuickCreateModal]);

  const updateNodeInternals = useUpdateNodeInternals();
  useEffect(() => {
    updateNodeInternals(nodeId);
  }, [inputs, outputs, nodeId, updateNodeInternals]);

  return (
    <Styled.NodeContainer $isSelected={props.selected} $nodeType={NodeType.NEURON}>
      <NodeHeader
        metadata={metadata}
        execution={execution}
        icon={
          <>
            {mode === 'runtime' && (
              <StatusIcon
                status={execution?.status}
                color={({ palette }) => palette.text.primary}
              />
            )}
            {mode === 'editor' && (
              <IconButton
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <SettingIcon />
              </IconButton>
            )}
          </>
        }
      />
      <Styled.NodeDataRow className="nodrag" onClick={handleSelectNodeOnClick}>
        <Styled.NodeDataColumn>
          {inputs.map((input) => (
            <NodeInputHandle key={input.name} nodeId={nodeId} input={input} />
          ))}
          {mode === 'editor' && <CustomHandle title="Add Input" handle={addInputHandle} />}
        </Styled.NodeDataColumn>
        <Styled.NodeDataColumn>
          {outputs.map((output) => (
            <NodeOutputHandle key={output.name} nodeId={nodeId} output={output} />
          ))}
          {mode === 'editor' && <CustomHandle title="Add Output" handle={addOutputHandle} />}
        </Styled.NodeDataColumn>
      </Styled.NodeDataRow>
      {mode === 'editor' && (
        <DummyNeuronModal
          nodeId={nodeId}
          open={isModalOpen}
          inputs={inputs}
          outputs={outputs}
          onCloseModal={() => {
            setIsModalOpen(false);
          }}
        />
      )}
      {mode === 'editor' && (
        <DummyNeuronQuickCreateModal
          open={isQuickModalOpen}
          inputs={inputs}
          outputs={outputs}
          type={quickCreateType}
          defaultValues={quickCreateItem}
          onClose={handleCancelQuickModal}
          onCreateItem={handleConfirmQuickModal}
        />
      )}
    </Styled.NodeContainer>
  );
};

export default DummyNeuron;
