import { ReactNode } from 'react';

interface TabPanelProps {
  ariaLabel?: string;
  children?: ReactNode;
  index: number;
  value: number;
}

const getTabPanelAriaProps = (index: number, ariaLabel?: string) => {
  const label = ariaLabel ?? 'tabs';

  return {
    id: `${label}-tabpanel-${index}`,
    'aria-labelledby': `${label}-tab-${index}`,
  };
};

const TabPanel = (props: TabPanelProps) => {
  const { ariaLabel, children, index, value, ...rest } = props;

  return (
    <div
      {...getTabPanelAriaProps(index, ariaLabel)}
      hidden={value !== index}
      role="tabpanel"
      {...rest}
    >
      {value === index && children}
    </div>
  );
};

export default TabPanel;
