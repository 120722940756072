import { FlowNode } from 'types/reactflow';

export const DEFAULT_DIMENSIONS = {
  height: 310,
  width: 310,
};

export function calculateGroupHeightOffset(childNodes: FlowNode[], offsetHeight: number) {
  if (childNodes.length) {
    return offsetHeight;
  }

  if (offsetHeight <= DEFAULT_DIMENSIONS.height) {
    return 0;
  }

  return offsetHeight - DEFAULT_DIMENSIONS.height;
}
