import {
  isDynamicEditorValue,
  isAssetDataSchema,
  isArrayDataSchema,
} from '@pathways/pipeline-schema/web';
import { isPathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { FlowNode } from 'types/reactflow';

function stripAssetValues(node: FlowNode) {
  if (!isPathwayNode(node)) return node;

  const sanitizedInputs = node.data.inputs.map((input) => {
    if (input.value && isDynamicEditorValue(input.value)) {
      return input;
    }

    if (isAssetDataSchema(input.dataSchema) || isArrayDataSchema(input.dataSchema, 'asset')) {
      return { ...input, value: undefined };
    }

    return input;
  });

  return {
    ...node,
    data: {
      ...node.data,
      inputs: sanitizedInputs,
    },
  };
}

/**
 * Function to sanitize codes when they are being copied across the clipboard to remove content that cannot be transferred.
 * @param node
 * @returns
 */
export function sanitizeNode(node: FlowNode) {
  return stripAssetValues(node);
}
