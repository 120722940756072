import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import { ChipProps, SvgIconProps, TypographyProps } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import CheckCircleIcon from 'components/Icons/CheckCircle';
import ErrorIcon from 'components/Icons/Error';

export const ExecutionStatusReadable: Record<ExecutionStatus, string> = {
  [ExecutionStatus.CANCELED]: 'Canceled',
  [ExecutionStatus.COMPLETED]: 'Completed',
  [ExecutionStatus.FAILED]: 'Failed',
  [ExecutionStatus.PENDING]: 'Pending',
  [ExecutionStatus.REVIEW]: 'Review',
  [ExecutionStatus.SCHEDULED]: 'Scheduled',
  [ExecutionStatus.STARTED]: 'Running',
  [ExecutionStatus.TIMED_OUT]: 'Timed Out',
};

export enum AnimationClasses {
  SPIN = 'animation-spin',
}

export interface StatusConfig {
  label: string;
  chip?: ChipProps;
  icon?: SvgIconProps & {
    component: React.ElementType<SvgIconProps>;
  };
  text?: TypographyProps;
}

const BASE_ERROR_CONFIG: Omit<StatusConfig, 'label'> = {
  chip: {
    color: 'error',
  },
  icon: {
    color: 'error',
    component: ErrorIcon,
  },
  text: {
    color: 'error.main',
  },
};

export const STATUS_CONFIG: Record<ExecutionStatus, StatusConfig> = {
  [ExecutionStatus.CANCELED]: {
    ...BASE_ERROR_CONFIG,
    label: ExecutionStatusReadable[ExecutionStatus.CANCELED],
    chip: {
      ...BASE_ERROR_CONFIG.chip,
    },
  },
  [ExecutionStatus.COMPLETED]: {
    label: ExecutionStatusReadable[ExecutionStatus.COMPLETED],
    chip: {
      color: 'success',
    },
    icon: {
      component: CheckCircleIcon,
      color: 'success',
    },
    text: {
      color: 'success.main',
    },
  },
  [ExecutionStatus.FAILED]: {
    ...BASE_ERROR_CONFIG,
    label: ExecutionStatusReadable[ExecutionStatus.FAILED],
    chip: {
      ...BASE_ERROR_CONFIG.chip,
    },
  },
  [ExecutionStatus.PENDING]: {
    label: ExecutionStatusReadable[ExecutionStatus.PENDING],
    text: {
      color: 'text.disabled',
    },
  },
  [ExecutionStatus.SCHEDULED]: {
    label: ExecutionStatusReadable[ExecutionStatus.SCHEDULED],
  },
  [ExecutionStatus.STARTED]: {
    label: ExecutionStatusReadable[ExecutionStatus.STARTED],
    chip: {
      color: 'secondary',
    },
    icon: {
      component: AutorenewRoundedIcon,
      color: 'secondary',
      classes: {
        root: AnimationClasses.SPIN,
      },
    },
  },
  [ExecutionStatus.REVIEW]: {
    label: ExecutionStatusReadable[ExecutionStatus.REVIEW],
    chip: {
      color: 'warning',
      label: 'Awaiting Review',
    },
    icon: {
      component: FlagRoundedIcon,
      color: 'warning',
    },
    text: {
      color: 'warning.main',
    },
  },
  [ExecutionStatus.TIMED_OUT]: {
    ...BASE_ERROR_CONFIG,
    label: ExecutionStatusReadable[ExecutionStatus.TIMED_OUT],
    chip: {
      ...BASE_ERROR_CONFIG.chip,
    },
  },
};
