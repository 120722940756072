import { Box } from '@mui/material';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import ConfigureSchemaButton from 'features/Flow/components/ConfigureSchemaButton/ConfigureSchemaButton';
import JobDescriptionTooltip from 'features/Flow/components/JobDescriptionTooltip/JobDescriptionTooltip';
import { PathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { getJobTitle } from 'utils/neurons';
import SingleElementActionsMenu from '../SingleElementActionsMenu/SingleElementActionsMenu';

export interface PanelHeaderProps {
  node: PathwayNode;
  canConfigureSchema?: boolean;
  onConfigureSchemaClick?: () => void;
}

export default function PanelHeader(props: PanelHeaderProps) {
  const { node } = props;

  return (
    <Box display="flex" gap={1} alignItems="center">
      <TextEllipsis variant="titleMedium" width="100%">
        {getJobTitle(node.data.metadata)}
      </TextEllipsis>

      {props.canConfigureSchema && <ConfigureSchemaButton onClick={props.onConfigureSchemaClick} />}
      <JobDescriptionTooltip job={node.data.metadata} />
      <SingleElementActionsMenu variant="vertical-icon" selectedNode={node} />
    </Box>
  );
}
