import { Box, Button, Paper } from '@mui/material';
import styled from 'styled-components';

export const InputsContainer = styled(Box)`
  margin-top: 24px;
`;

export const Footer = styled(Box)`
  margin-top: 24px;
  display: flex;
  justify-content: end;
`;

export const StartPipelineButton = styled(Button)`
  border-radius: 24px;
`;

export const PipelineElements = styled(Paper)`
  background: ${({ theme }) => theme.palette.surface.level2};
  padding: 8px 16px;
  border-radius: 20px;
`;
