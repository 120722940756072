import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Stack, Tooltip, Typography } from '@mui/material';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { useState } from 'react';
import { JobNodeType } from 'types/reactflow';
import { getJobDescription, getJobName } from 'utils/neurons';
import * as Styled from './JobDescriptionTooltip.styles';

interface JobDescriptionTooltipProps {
  job: {
    type: JobNodeType;
    id?: string;
    name?: string;
    description?: string;
    customTitle?: string;
  };
}

export default function JobDescriptionTooltip(props: JobDescriptionTooltipProps) {
  const { job } = props;
  const [open, setOpen] = useState(false);

  return (
    <Tooltip
      PopperComponent={Styled.MuiPopper}
      title={
        <Stack direction="row" spacing={1}>
          <JobTypeIcon type={job.type} />

          <Stack spacing={0.5} minWidth={0}>
            <TextEllipsis variant="bodyMedium">{getJobName(job)}</TextEllipsis>

            <Typography variant="bodySmall" color={({ palette }) => palette.text.secondary}>
              {getJobDescription(job)}
            </Typography>
          </Stack>
        </Stack>
      }
      // These styles are applied to the icon not the tooltip.
      sx={({ palette }) => ({
        color: palette.action.active,
        ...(open && {
          color: palette.text.primary,
        }),
      })}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <InfoRoundedIcon color="inherit" />
    </Tooltip>
  );
}
