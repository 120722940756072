import { CompleteInput } from './nodes/PipelineComplete/PipelineComplete.types';
import { StartOutput } from './nodes/PipelineStart/PipelineStart.types';
import { NodeData, NodeInput, NodeOutput } from './nodes/Node/Node.types';
import { Connection, Edge, ReactFlowProps } from 'reactflow';
import { FlowNode, FlowPipeline } from 'types/reactflow';
import { APP_REACTFLOW_DATATRANSFER } from './Flow.consts';
import { ManifestResponse } from 'api/services/useManifests/useManifests.types';
import { SubpipelineManifestResponse } from 'api/services/useSearchSubpipelines/useSearchSubpipelines.types';

export interface FlowEditorProps {
  pipeline: FlowPipeline;
  refreshPipeline: () => Promise<void>;
}

export type FlowProps = ReactFlowProps;

export enum NodeType {
  PIPELINE_START = 'PIPELINE_RUN_START',
  PIPELINE_COMPLETE = 'PIPELINE_RUN_COMPLETE',
  NEURON = 'neuron',
  FUNCTION = 'function',
  CHECKPOINT = 'checkpoint',
  GROUP = 'group',
  BATCH_GROUP = 'batch',
  BATCH_START = 'BATCH_START',
  BATCH_END = 'BATCH_END',
  SUBPIPELINE = 'subpipeline',
}

export enum EdgeType {
  CUSTOM = 'custom',
}

export enum RightSidebarView {
  PIPELINE = 'pipeline',
  SELECTED_NODE = 'selectedNode',
  SELECTED_NODES = 'selectedNodes',
}

export type NodeHandle = CompleteInput | StartOutput | NodeInput | NodeOutput;

export interface ClipboardData {
  dataType: typeof APP_REACTFLOW_DATATRANSFER;
  nodes: FlowNode[];
  edges: Edge[];
}

export interface NodeManifest extends Omit<ManifestResponse | SubpipelineManifestResponse, 'type'> {
  type: NodeType;
}

export interface SubpipelineNodeManifest extends Omit<SubpipelineManifestResponse, 'type'> {
  type: NodeType.SUBPIPELINE;
}

export function isSubpipelineManifest(
  manifest: NodeManifest | SubpipelineManifestResponse,
): manifest is SubpipelineNodeManifest {
  return (manifest.type as NodeType) === NodeType.SUBPIPELINE;
}

export type HandleConnection = DeepNonNullable<Connection>;

export function isHandleConnection(connection?: Connection | Edge): connection is HandleConnection {
  return (
    !!connection?.source &&
    !!connection.sourceHandle &&
    !!connection.target &&
    !!connection.targetHandle
  );
}

export type FormatNodeDataFn = (manifest: ManifestResponse, customTitle: string) => NodeData;
