import type { PipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution.types';
import type {
  ExecutionJobResponse,
  PipelineJobError,
  PipelineJobsResponse,
} from 'api/services/usePipelineJobs/usePipelineJobs.types';
import { ErrorCard } from 'components/ErrorCard/ErrorCard';
import Loader from 'components/Loader/Loader';
import { PipelineOutputAccordion } from '../PipelineOutputAccordion/PipelineOutputAccordion';
import { PipelineTriggerOutput } from '../PipelineTriggerOutput/PipelineTriggerOutput';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import { NodeType } from 'features/Flow/Flow.types';
import ExecutionCheckpointOutput from 'pages/ExecutionsPage/pages/ViewExecutionPage/components/ExecutionCompleted/ExecutionCheckpointOutput';
import ExecutionOutputByType from 'pages/ExecutionsPage/pages/ViewExecutionPage/components/ExecutionCompleted/ExecutionOutputByType';

interface ExecutionAllOutputsProps {
  pipelineExecution: PipelineExecution;
  pipelineJobs: PipelineJobsResponse['jobs'];
  pipelineJobsError?: PipelineJobError;
  arePipelineJobsLoading: boolean;
  onRetry: () => void;
}

export default function ExecutionAllOutputs({
  pipelineExecution,
  pipelineJobs,
  arePipelineJobsLoading,
  pipelineJobsError,
  onRetry,
}: ExecutionAllOutputsProps) {
  if (pipelineJobsError) {
    return (
      <ErrorCard
        message="An error occurred and the execution outputs could not be loaded."
        onRetry={onRetry}
      />
    );
  }

  return (
    <>
      <PipelineTriggerOutput pipelineExecution={pipelineExecution} />
      {pipelineJobs.filter(jobFilter).map((job) => (
        <Loader key={`loader-${job.id}`} loading={arePipelineJobsLoading}>
          <ExecutionJobOutput job={job} />
        </Loader>
      ))}
    </>
  );
}

interface ExecutionJobOutputProps {
  job: ExecutionJobResponse;
}

const ExecutionJobOutput = (props: ExecutionJobOutputProps) => {
  const { job } = props;

  return (
    <PipelineOutputAccordion job={job} expandAccordionByDefault={false}>
      {job.type === NodeType.CHECKPOINT && <ExecutionCheckpointOutput job={job} />}
      {job.outputs.map((output) => (
        <ExecutionOutputByType key={output.name} output={output} />
      ))}
    </PipelineOutputAccordion>
  );
};

function jobFilter(job: ExecutionJobResponse) {
  return (
    job.status === ExecutionStatus.COMPLETED ||
    (job.status === ExecutionStatus.FAILED && job.type === NodeType.CHECKPOINT)
  );
}
