import { useContext } from 'react';
import { ExecutionCheckpointContext } from './ExecutionCheckpointContext';

export default function useExecutionCheckpointContext() {
  const context = useContext(ExecutionCheckpointContext);

  if (!context)
    throw new Error(
      `Could not find ${ExecutionCheckpointContext.displayName}, did you wrap the component in a provider?`,
    );

  return context;
}
