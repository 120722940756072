import { compatibilityCheck } from '../../DataType';
import { getDataSchema } from 'utils/neurons';
import { IOSchema } from 'api/services/useManifests/useManifests.types';
import { NodeInput, NodeOutput } from '../../Node.types';

export function keepEdgeConnection(schema: IOSchema, field: NodeInput | NodeOutput): boolean {
  return (
    !schema.properties?.[field.name] ||
    !compatibilityCheck({
      input: getDataSchema(schema.properties[field.name]),
      output: field.dataSchema,
    })
  );
}

/**
 * This takes the previous and next inputs from variation switch and maps existing values over if the data type and name are identical.
 * @param previousInputs Array of previous inputs which may have existing values via connections or static
 * @param newInputs Array of next inputs which may inherit values from previous inputs if data type and name are identical
 * @returns
 */
export function mergeVariationInputs(
  previousInputs: NodeInput[],
  newInputs: NodeInput[],
): NodeInput[] {
  return newInputs.map((input) => {
    // const values should always be updated
    if (input.dataSchema.type === 'const') {
      return input;
    }

    const previousInput = previousInputs.find((prevInput) => prevInput.name === input.name);
    if (
      previousInput &&
      previousInput.value &&
      compatibilityCheck({ input: previousInput.dataSchema, output: input.dataSchema })
    ) {
      return {
        ...input,
        value: previousInput.value,
      };
    }

    return input;
  });
}
