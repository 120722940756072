import { Tab, TabProps } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import * as Styled from './NavTabs.styles';

export type NavTabsItems = NavTabProps[];

interface NavTabProps extends TabProps<typeof Link> {
  key: NonNullable<TabProps['key']>;
}

interface NavTabsProps {
  items: NavTabsItems;
  static?: boolean;
}

export default function NavTabs(props: NavTabsProps) {
  const location = useLocation();

  return (
    <Styled.MuiTabs value={props.static ? false : location.pathname}>
      {props.items.map((tab) => (
        <Tab component={Link} value={tab.to} {...tab} key={tab.key} />
      ))}
    </Styled.MuiTabs>
  );
}
