import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { ExecutionCheckpointRejectModalProps } from './ExecutionCheckpointRejectModal.types';

const ExecutionCheckpointRejectModal = (props: ExecutionCheckpointRejectModalProps) => {
  const { isOpen = false, isLoading = false, onClose, onReject } = props;
  const [comment, setComment] = useState<string>('');

  const closeModal = () => {
    onClose();
    setComment('');
  };

  const handleReject = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await onReject({ comment });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      disableEnforceFocus
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: 800,
        },
      }}
    >
      <DialogContent>
        <Typography
          variant="headlineSmall"
          sx={{ fontWeight: 500, marginBottom: 2 }}
          component="h6"
        >
          Add a Comment
        </Typography>
        <Alert severity="warning" sx={{ marginBottom: 2.5 }}>
          <Typography variant="titleMedium">You are Rejecting a Checkpoint</Typography>
          <Typography variant="body2">
            {`Are you sure you'd like to reject this checkpoint? Any steps dependent on this
            checkpoint will not be completed in this run.`}
          </Typography>
        </Alert>
        <TextField
          sx={{
            '& label.Mui-focused': {
              color: 'text.secondary',
            },
            '& .MuiInput-root::after': {
              borderColor: 'text.secondary',
            },
          }}
          label="Comment"
          placeholder="Add a reason for rejecting this checkpoint..."
          rows={6}
          fullWidth
          multiline
          InputProps={{
            disableUnderline: true,
            disabled: isLoading,
          }}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingX: 2,
          p: 1,
        }}
      >
        <Button
          variant="text"
          sx={{ color: 'text.primary' }}
          onClick={closeModal}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button variant="text" color="error" disabled={isLoading} onClick={handleReject}>
          Add Comment & Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExecutionCheckpointRejectModal;
