import { NodeType } from 'features/Flow/Flow.types';
import useFlow from 'features/Flow/hooks/useFlow';
import {
  isBatchEndNode,
  isBatchGroupNode,
  isBatchStartNode,
} from 'features/Flow/nodes/Batch/Batch.types';
import { isPathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { isPipelineCompleteNode } from 'features/Flow/nodes/PipelineComplete/PipelineComplete.types';
import { isPipelineStartNode } from 'features/Flow/nodes/PipelineStart/PipelineStart.types';
import { ConnectingHandle } from 'reactflow';
import { FlowNode } from 'types/reactflow';

type BatchConnectionValidateFn = (
  connectionStartNode: FlowNode,
  startHandle: ConnectingHandle,
  isSameBatch: boolean,
  nonBatchedNodes?: boolean,
) => boolean | null;

const validateConnectionFromBatchStart: BatchConnectionValidateFn = (
  node,
  startHandle,
  isSameBatch,
) => {
  // Anything that is in a different batch can connect if start handle type is target
  if (isBatchStartNode(node) && startHandle.type === 'target') {
    return !isSameBatch;
  }

  // Anything that is in a different batch can connect if start handle type is target
  if (isBatchStartNode(node) && startHandle.type === 'source') {
    return isSameBatch;
  }

  return null;
};

const validateConnectionFromBatchEnd: BatchConnectionValidateFn = (
  node,
  startHandle,
  isSameBatch,
) => {
  // If batch end node connection starts from the target, must be within the same batch
  if (isBatchEndNode(node) && startHandle.type === 'target') {
    return isSameBatch;
  }

  // // Batch end connection starting from the source must be outside of the batch
  if (isBatchEndNode(node) && startHandle.type === 'source') {
    return !isSameBatch;
  }

  return null;
};

// Type is inverted for next two functions here because we're checking if connection starts from a non-batch edge node
const validateConnectionToBatchEdgeSource: BatchConnectionValidateFn = (
  node,
  startHandle,
  isSameBatch,
  nonBatchedNodes,
) => {
  if (isBatchStartNode(node) && startHandle.type === 'target') {
    return isSameBatch;
  }

  if (isBatchEndNode(node) && startHandle.type === 'target') {
    return true;
  }

  if (isPathwayNode(node)) {
    return nonBatchedNodes ? true : isSameBatch;
  }

  return null;
};

const isConnectingStartWithEnd = (a: FlowNode, b: FlowNode): boolean => {
  if (a.type === NodeType.BATCH_END) {
    return b.type === NodeType.BATCH_START;
  }
  if (a.type === NodeType.BATCH_START) {
    return b.type === NodeType.BATCH_END;
  }
  return false;
};

export const useBatchConnectionCheck = (node: FlowNode) => {
  const { getNode } = useFlow();
  const parentNode = getNode(node.parentNode ?? '');

  const validateBatchConnectionRules = (startHandle: ConnectingHandle) => {
    const startHandleNode = getNode(startHandle.nodeId);
    if (!startHandleNode) return false;
    const startHandleParentNode = getNode(startHandleNode.parentNode ?? '');

    const nonBatchedNodes =
      !isBatchGroupNode(startHandleParentNode) && !isBatchGroupNode(parentNode);

    const isSameBatch = Boolean(!nonBatchedNodes && startHandleNode.parentNode === node.parentNode);

    if (isConnectingStartWithEnd(node, startHandleNode)) {
      // Don't allow connections straight from batch start to batch end
      return false;
    }

    const validConnectionFromStart = validateConnectionFromBatchStart(
      startHandleNode,
      startHandle,
      isSameBatch,
    );
    if (validConnectionFromStart !== null) {
      return validConnectionFromStart;
    }

    const validConnectionFromEnd = validateConnectionFromBatchEnd(
      startHandleNode,
      startHandle,
      isSameBatch,
    );
    if (validConnectionFromEnd !== null) {
      return validConnectionFromEnd;
    }

    const validConnectionToEdgeSource = validateConnectionToBatchEdgeSource(
      node,
      startHandle,
      isSameBatch,
      nonBatchedNodes,
    );
    if (validConnectionToEdgeSource !== null) {
      return validConnectionToEdgeSource;
    }

    if (isPathwayNode(startHandleNode) && isBatchGroupNode(startHandleParentNode)) {
      if (isPipelineCompleteNode(node) || isPipelineStartNode(node)) {
        return false;
      }

      return isSameBatch;
    }

    return true;
  };

  return {
    validateBatchConnectionRules,
  };
};
