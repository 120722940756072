import { Download } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import useDownloadAsset from 'api/services/useDownloadAsset/useDownloadAsset';
import useToast from 'contexts/toast/useToast';
import { SignedAssetObject } from 'features/Flow/nodes/Node/Node.types';
import { useCallback } from 'react';
import styled from 'styled-components';

export const RoundedButton = styled(Button)`
  border-radius: 24px;
  text-decoration: none;
`;

interface DownloadButtonProps
  extends Omit<ButtonProps, 'children' | 'variant' | 'LinkComponent' | 'startIcon'> {
  asset?: SignedAssetObject;
  fileType?: string;
}

const getDownloadBufferUrl = async (href: string) => {
  const response = await fetch(href);
  const blob = await response.blob();
  return window.URL.createObjectURL(blob);
};

const getFileName = (url: string, fileType: string) => {
  const hrefPaths = url.split('/');
  const lastPath = hrefPaths[hrefPaths.length - 1];
  const [fileName] = lastPath.split('?');
  if (fileName.split('.').length === 2) {
    return fileName;
  }

  return `${fileName}.${fileType}`;
};

export default function DownloadButton({ asset, ...props }: DownloadButtonProps) {
  const toast = useToast();
  const { downloadAsset } = useDownloadAsset();
  const downloadFromUrl = useCallback(
    (e: React.MouseEvent) => {
      const element = e.target as HTMLAnchorElement;
      getDownloadBufferUrl(element.href)
        .then((url) => {
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', getFileName(url, props.fileType ?? ''));
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          toast.error({ message: 'Failed to download image.' });
        });
    },
    [toast, props.fileType],
  );

  const download = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!asset) {
        downloadFromUrl(e);
      } else {
        downloadAsset(asset.pathwayAssetId).catch(() => {
          toast.error({ message: 'Failed to download asset.' });
        });
      }
    },
    [asset, downloadAsset, downloadFromUrl, toast],
  );

  return (
    <RoundedButton variant="outlined" startIcon={<Download />} onClick={download} {...props}>
      Download
    </RoundedButton>
  );
}
