import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Stack, Typography } from '@mui/material';

export default function PipelineErrorCard() {
  return (
    <Typography variant="bodyLarge" color="text.secondary">
      <Stack
        sx={{
          alignItems: 'center',
          gap: 1,
          marginY: 3,
        }}
      >
        <ErrorOutlineIcon />
        An error occurred and pipelines could not be loaded. Please refresh the page and try again.
      </Stack>
    </Typography>
  );
}
