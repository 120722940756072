import { Tooltip } from '@mui/material';
import { Controller, FieldPath, FieldPathValue, FieldValues } from 'react-hook-form';
import { RHFBooleanSelectProps } from './RHFBooleanSelect.types';
import BooleanSelect from 'components/Inputs/BooleanSelect/BooleanSelect';

const RHFBooleanSelect = <T extends FieldValues>(props: RHFBooleanSelectProps<T>) => {
  const { control, defaultValue, name, rules, TooltipProps, ...muiTextFieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={(defaultValue ?? '') as FieldPathValue<T, FieldPath<T>>}
      rules={rules}
      render={({ field, fieldState }) => (
        <Tooltip title="" {...TooltipProps}>
          <span>
            <BooleanSelect
              {...muiTextFieldProps}
              {...field}
              onChange={(val) => {
                field.onChange(val);
              }}
              error={fieldState.invalid}
              helperText={
                fieldState.error?.message ? fieldState.error.message : muiTextFieldProps.helperText
              }
            />
          </span>
        </Tooltip>
      )}
    />
  );
};

export default RHFBooleanSelect;
