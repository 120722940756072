import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import { useCallback, useState } from 'react';
import { ObjectBuilderQuickCreateModalProps } from './ObjectBuilderQuickCreateModal.types';

type UseObjectBuilderQuickCreateModalProps = Pick<
  ObjectBuilderQuickCreateModalProps,
  'onCreateInput' | 'onCloseModal'
>;

type UseObjectBuilderQuickCreateModalReturn = Omit<ObjectBuilderQuickCreateModalProps, 'inputs'> & {
  openModal: (initialInput: NodeInput) => void;
};

type ModalState =
  | {
      open: true;
      defaultValue: NodeInput | null;
    }
  | {
      open: false;
    };

export const useObjectBuilderQuickCreateModal = ({
  onCreateInput,
  onCloseModal,
}: UseObjectBuilderQuickCreateModalProps): UseObjectBuilderQuickCreateModalReturn => {
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
  });

  const handleCreateInput = useCallback(
    (input: NodeInput) => {
      if (!modalState.open) {
        return;
      }

      setModalState({ open: false });
      onCreateInput(input);
    },
    [modalState.open, onCreateInput],
  );

  const handleCloseModal = () => {
    setModalState({ open: false });
    onCloseModal();
  };

  const handleOpenModal = useCallback(
    (initialInput: NodeInput) => {
      setModalState({ open: true, defaultValue: initialInput });
    },
    [setModalState],
  );

  return {
    open: modalState.open,
    defaultValues: modalState.open ? modalState.defaultValue : null,
    openModal: handleOpenModal,
    onCloseModal: handleCloseModal,
    onCreateInput: handleCreateInput,
  };
};
