import { isAssetDataSchema, isArrayDataSchema } from '@pathways/pipeline-schema/web';
import { Checkpoint } from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints.types';
import { isObjectDataSchema } from 'features/Flow/nodes/Node/DataSchemas/objectSchema';
import { isTextDataSchema } from 'features/Flow/nodes/Node/DataSchemas/textSchema';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import * as Styled from './ExecutionCheckpoint.styles';
import ExecutionCheckpointAssetListForm from './forms/ExecutionCheckpointAssetForm/ExecutionCheckpointAssetListForm';
import ExecutionCheckpointSingleAssetForm from './forms/ExecutionCheckpointAssetForm/ExecutionCheckpointSingleAssetForm';
import ExecutionCheckpointImageForm from './forms/ExecutionCheckpointImageForm/ExecutionCheckpointImageForm';
import ExecutionCheckpointObjectForm from './forms/ExecutionCheckpointObjectForm/ExecutionCheckpointObjectForm';
import ExecutionCheckpointObjectListForm from './forms/ExecutionCheckpointObjectListForm/ExecutionCheckpointObjectListForm';
import ExecutionCheckpointSingleTextForm from './forms/ExecutionCheckpointSingleTextForm/ExecutionCheckpointSingleTextForm';
import ExecutionCheckpointTextForm from './forms/ExecutionCheckpointTextForm/ExecutionCheckpointTextForm';

interface ExecutionCheckpointFormMapProps {
  input: NodeInput;
  checkpoint: Checkpoint;
  checkpointHeaderText: string;
  isWaitingExecutionRefresh: boolean;
}

const ExecutionCheckpointFormMap = ({
  input,
  checkpoint,
  checkpointHeaderText,
  isWaitingExecutionRefresh,
}: ExecutionCheckpointFormMapProps) => {
  const inputDataSchema = input.dataSchema;

  const isSingleTextCheckpoint = isTextDataSchema(inputDataSchema);

  const isTextCheckpoint = isArrayDataSchema(inputDataSchema, 'text');

  const isImageCheckpoint = isArrayDataSchema(inputDataSchema, 'image-uri');

  const isObjectCheckpoint = isObjectDataSchema(inputDataSchema);

  const isObjectListCheckpoint = isArrayDataSchema(inputDataSchema, 'object');

  const isAssetCheckpoint = isAssetDataSchema(inputDataSchema);

  const isAssetListCheckpoint = isArrayDataSchema(inputDataSchema, 'asset');

  return (
    <Styled.ExecutionCheckpointData>
      {isSingleTextCheckpoint && (
        <ExecutionCheckpointSingleTextForm
          disabled={isWaitingExecutionRefresh}
          input={input}
          pendingCheckpoint={checkpoint}
          pendingCheckpointHeader={checkpointHeaderText}
        />
      )}
      {isTextCheckpoint && (
        <ExecutionCheckpointTextForm
          disabled={isWaitingExecutionRefresh}
          input={input}
          pendingCheckpoint={checkpoint}
          pendingCheckpointHeader={checkpointHeaderText}
        />
      )}
      {isImageCheckpoint && (
        <ExecutionCheckpointImageForm
          disabled={isWaitingExecutionRefresh}
          input={input}
          pendingCheckpoint={checkpoint}
          pendingCheckpointHeader={checkpointHeaderText}
        />
      )}
      {isObjectCheckpoint && (
        <ExecutionCheckpointObjectForm
          disabled={isWaitingExecutionRefresh}
          input={input}
          pendingCheckpoint={checkpoint}
          pendingCheckpointHeader={checkpointHeaderText}
        />
      )}
      {isObjectListCheckpoint && (
        <ExecutionCheckpointObjectListForm
          disabled={isWaitingExecutionRefresh}
          input={input}
          pendingCheckpoint={checkpoint}
          pendingCheckpointHeader={checkpointHeaderText}
        />
      )}
      {isAssetCheckpoint && (
        <ExecutionCheckpointSingleAssetForm
          disabled={isWaitingExecutionRefresh}
          input={input}
          pendingCheckpoint={checkpoint}
          pendingCheckpointHeader={checkpointHeaderText}
        />
      )}
      {isAssetListCheckpoint && (
        <ExecutionCheckpointAssetListForm
          disabled={isWaitingExecutionRefresh}
          input={input}
          pendingCheckpoint={checkpoint}
          pendingCheckpointHeader={checkpointHeaderText}
        />
      )}
    </Styled.ExecutionCheckpointData>
  );
};

export default ExecutionCheckpointFormMap;
