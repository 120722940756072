/** @example
 * if (!pipelineId) throw new Error(formatRequiredMessage('pipelineId'));
 */
export function formatRequiredMessage(name: string) {
  return `"${name}" is required.`;
}

/** @example
 * if (!pipelineId) throw new Error(formatUrlMissingParameterMessage('pipelineId'));
 */
export function formatUrlMissingParameterMessage(parameterName: string) {
  return `Url is missing "${parameterName}" parameter.`;
}
