import { MenuItem, Stack, TextField } from '@mui/material';
import {
  createArrayDataTypeOptions,
  createDataTypeOptions,
} from 'features/Flow/components/DataTypeField/DataTypeField.utils';
import { useDataTypeField } from 'features/Flow/components/DataTypeField/useDataTypeField';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { DynamicCheckpointNode } from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/DynamicCheckpoint.types';
import { canConnectWithSchema } from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/DynamicCheckpoint.utils';
import { getJobTitle } from 'utils/neurons';

interface PropertiesFormProps {
  node: DynamicCheckpointNode;
  isNodeConnected: boolean;
}

export default function PropertiesForm(props: PropertiesFormProps) {
  const updateNodeData = useUpdateNodeData();
  const { node, isNodeConnected } = props;
  const { schemas: handleSchemas } = node.data;

  const dataTypeHelperText = isNodeConnected
    ? 'You cannot update the data type with a connection.'
    : undefined;
  const { dataTypeValue, arrayDataTypeValue, getDataTypeFieldOptionSchema } = useDataTypeField({
    value: handleSchemas?.input,
  });

  const handleDataTypeChange = (event: React.ChangeEvent<{ value: string }>) => {
    const optionSchema = getDataTypeFieldOptionSchema(event.target.value);
    const schemas = optionSchema
      ? {
          input: optionSchema,
          output: optionSchema,
        }
      : undefined;

    updateNodeData<typeof node.data>(node.id, () => ({
      schemas,
    }));
  };

  return (
    <Stack gap={2}>
      <TextField
        name="customTitle"
        size="small"
        fullWidth
        label="Checkpoint Name"
        value={getJobTitle(node.data.metadata)}
        onChange={(event) => {
          updateNodeData<typeof node.data>(node.id, (data) => ({
            metadata: {
              ...data.metadata,
              customTitle: event.target.value,
            },
          }));
        }}
      />

      <TextField
        select
        name="dataType"
        label="Data Type"
        disabled={isNodeConnected}
        helperText={dataTypeHelperText}
        value={dataTypeValue}
        SelectProps={{
          displayEmpty: true,
        }}
        onChange={handleDataTypeChange}
      >
        {[
          <MenuItem key="none" value="">
            Select or connect data...
          </MenuItem>,
          ...createDataTypeOptions(
            (schema) => schema.type === 'array' || canConnectWithSchema(schema),
          ),
        ]}
      </TextField>

      {dataTypeValue.startsWith('array') && (
        <TextField
          select
          name="arrayDataType"
          label="Array Data Type"
          disabled={isNodeConnected}
          helperText={dataTypeHelperText}
          value={arrayDataTypeValue}
          onChange={handleDataTypeChange}
        >
          {createArrayDataTypeOptions(canConnectWithSchema)}
        </TextField>
      )}
    </Stack>
  );
}
