import { nanoid } from 'nanoid';
import { useCallback, useContext, useEffect } from 'react';
import { HandleConnection } from '../Flow.types';
import { HandleStoreContext } from './handle.context';
import { AppHandle, CustomHandleData, DataHandle, HandleKey, HandleStore } from './handle.store';

// Keep private.
const useHandleStore = (): HandleStore => {
  const store = useContext(HandleStoreContext);
  if (!store) throw new Error('Did you forgot to wrap the component in a HandleStoreProvider?');
  return store;
};

export const useRegisterHandle = (handle: AppHandle) => {
  const store = useHandleStore();

  useEffect(() => {
    store.getState().register(handle);
    return () => {
      store.getState().unregister(handle);
    };
  }, [handle, store]);
};

export const useGetHandle = () => {
  const store = useHandleStore();
  return store.getState().getHandle;
};

export function useGetDataHandle() {
  const getHandle = useGetHandle();

  return useCallback(
    (key: HandleKey) => {
      const handle = getHandle(key);

      if (handle?.type !== 'data') return;
      return handle;
    },
    [getHandle],
  );
}

export function useGetSourceHandle() {
  const getDataHandle = useGetDataHandle();

  return useCallback(
    (connection: HandleConnection): DataHandle => {
      const handleKey: HandleKey = {
        id: connection.sourceHandle,
        nodeId: connection.source,
      };
      const sourceHandle = getDataHandle(handleKey);

      if (!sourceHandle)
        throw new Error(`Source handle not found.\n${JSON.stringify(handleKey, null, 2)}`);

      return sourceHandle;
    },
    [getDataHandle],
  );
}

export function useGetTargetHandle() {
  const getDataHandle = useGetDataHandle();

  return useCallback(
    (connection: HandleConnection): DataHandle => {
      const handleKey: HandleKey = {
        id: connection.targetHandle,
        nodeId: connection.target,
      };
      const targetHandle = getDataHandle(handleKey);

      if (!targetHandle)
        throw new Error(`Target handle not found.\n${JSON.stringify(handleKey, null, 2)}`);

      return targetHandle;
    },
    [getDataHandle],
  );
}

export const createCustomHandle = (
  nodeId: string,
  purpose: CustomHandleData['purpose'],
  canConnect: CustomHandleData['canConnect'],
): CustomHandleData => {
  return {
    type: 'custom',
    id: nanoid(),
    nodeId,
    purpose,
    canConnect,
  };
};
