export var JobTypes;
(function (JobTypes) {
    JobTypes["PIPELINE_RUN_START"] = "PIPELINE_RUN_START";
    JobTypes["PIPELINE_RUN_COMPLETE"] = "PIPELINE_RUN_COMPLETE";
    JobTypes["FUNCTION"] = "function";
    JobTypes["CHECKPOINT"] = "checkpoint";
    JobTypes["NEURON"] = "neuron";
    JobTypes["BATCH"] = "batch";
    JobTypes["SUBPIPELINE"] = "subpipeline";
    JobTypes["BATCH_START"] = "BATCH_START";
    JobTypes["BATCH_END"] = "BATCH_END";
    JobTypes["UNDEFINED"] = "";
})(JobTypes || (JobTypes = {}));
export var JobDataTypes;
(function (JobDataTypes) {
    JobDataTypes["ASSET"] = "ASSET";
    JobDataTypes["TEXT"] = "TEXT";
    JobDataTypes["NUMBER"] = "NUMBER";
    JobDataTypes["INTEGER"] = "INTEGER";
    JobDataTypes["ENUM"] = "ENUM";
    JobDataTypes["IMAGE"] = "IMAGE";
    JobDataTypes["OBJECT"] = "OBJECT";
    JobDataTypes["ARRAY_TEXT"] = "ARRAY_TEXT";
    JobDataTypes["ARRAY_NUMBER"] = "ARRAY_NUMBER";
    JobDataTypes["ARRAY_IMAGE"] = "ARRAY_IMAGE";
    JobDataTypes["ARRAY_ASSET"] = "ARRAY_ASSET";
    JobDataTypes["ARRAY_OBJECT"] = "ARRAY_OBJECT";
    JobDataTypes["PIPELINE_START_OUTPUT"] = "PIPELINE_START_OUTPUT";
})(JobDataTypes || (JobDataTypes = {}));
export var PipelineType;
(function (PipelineType) {
    PipelineType["DEFAULT"] = "default";
    PipelineType["CALLABLE"] = "callable";
})(PipelineType || (PipelineType = {}));
export var PipelineState;
(function (PipelineState) {
    PipelineState["DRAFT"] = "draft";
    PipelineState["PUBLISHED"] = "published";
})(PipelineState || (PipelineState = {}));
