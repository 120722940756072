import WarningIcon from '@mui/icons-material/Warning';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import RoundedPaper from 'components/RoundedPaper/RoundedPaper';

interface ErrorCardProps {
  message?: string;
  onRetry?: () => void;
}

export function ErrorCard(props: ErrorCardProps) {
  const theme = useTheme();
  return (
    <RoundedPaper
      sx={{
        padding: theme.spacing(3, 0),
      }}
    >
      <Stack display="grid" justifyItems="center" gap={2}>
        <WarningIcon
          color="error"
          sx={{
            fontSize: theme.spacing(6),
          }}
        />

        <Typography variant="bodyLarge" color="text.secondary">
          {props.message}
        </Typography>

        {props.onRetry && (
          <Button color="inherit" size="large" onClick={props.onRetry}>
            Retry
          </Button>
        )}
      </Stack>
    </RoundedPaper>
  );
}
