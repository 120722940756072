import { createContext, useRef } from 'react';
import { HandleStore, createHandleStore } from './handle.store';

export const HandleStoreContext = createContext<HandleStore | null>(null);

interface HandleStoreProviderProps {
  children: React.ReactNode;
}

export const HandleStoreProvider = ({ children }: HandleStoreProviderProps) => {
  const storeRef = useRef<HandleStore>();

  if (!storeRef.current) {
    storeRef.current = createHandleStore();
  }

  return (
    <HandleStoreContext.Provider value={storeRef.current}>{children}</HandleStoreContext.Provider>
  );
};
