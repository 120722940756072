import {
  isArrayDataSchema,
  isAssetDataSchema,
  isEnumDataSchema,
} from '@pathways/pipeline-schema/web';
import * as Styled from './PipelineExecutionForm.styles';
import useCreatePipelineExecution from 'api/services/useCreatePipelineExecution/useCreatePipelineExecution';
import { Control, ControllerProps, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useToast from 'contexts/toast/useToast';
import { Grid, MenuItem, Stack, Typography } from '@mui/material';
import {
  MissingInputNode,
  NewPipelineExecutionForm,
  PipelineExecutionFormProps,
} from './PipelineExecutionForm.types';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import {
  requiredValidation,
  numberValidation,
  integerValidation,
  objectValidation,
  urlValidation,
  createBooleanValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import RHFTextFieldTags from 'components/ui/rhf-components/RHFTextFieldTags/RHFTextFieldTags';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { PipelineInputResponse } from 'api/services/usePipelineInputs/usePipelineInputs.types';
import { AxiosError } from 'axios';
import { usePipeline } from 'api/services/usePipeline/usePipeline';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';
import { NodeType } from 'features/Flow/Flow.types';
import RHFJsonEditor from 'components/ui/rhf-components/RHFJsonEditor/RHFJsonEditor';
import { useAppRoutes } from 'utils/routes';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import { isIntegerDataSchema } from 'features/Flow/nodes/Node/DataSchemas/integerSchema';
import { isTextDataSchema } from 'features/Flow/nodes/Node/DataSchemas/textSchema';
import { isNumberDataSchema } from 'features/Flow/nodes/Node/DataSchemas/numberSchema';
import { isObjectDataSchema } from 'features/Flow/nodes/Node/DataSchemas/objectSchema';
import useWorkspace from 'hooks/useWorkspace';
import {
  CreatePipelineExecutionBody,
  PossibleInputTypes,
} from 'api/services/useCreatePipelineExecution/useCreatePipelineExecution.types';
import { isBooleanDataSchema } from 'features/Flow/nodes/Node/DataSchemas/booleanSchema';
import RHFBooleanSelect from 'components/ui/rhf-components/RHFBooleanSelect/RHFBooleanSelect';
import RFHAssetInput from 'components/ui/rhf-components/RHFAssetInput/RHFAssetInput';
import { ContentPaper } from 'components/Layout/Layout.styles';
import {
  filterInputsWithConnection,
  formatMissingInputMessage,
} from 'pages/ExecutionsPage/pages/NewExecutionPage/components/PipelineExecutionForm/PipelineExecutionForm.utils';

type PipelineInputs = Record<string, PossibleInputTypes>;

const PipelineExecutionForm = (props: PipelineExecutionFormProps) => {
  const { pipelineId, pipelineInputs } = props;
  const routes = useAppRoutes();

  const toast = useToast();
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { createPipelineExecution } = useCreatePipelineExecution(pipelineId);
  const { pipeline } = usePipeline(pipelineId);
  const workspace = useWorkspace();

  const form = useForm<NewPipelineExecutionForm>({
    mode: 'all',
  });
  const { control, formState, handleSubmit } = form;

  const handleNewPipelineExecutionSubmit: SubmitHandler<NewPipelineExecutionForm> = async (
    data,
  ) => {
    const filledInputValues = Object.keys(data)
      .filter((key) => data[key] !== '')
      .reduce<PipelineInputs>(
        (inputs, key) => ((inputs[key] = data[key]), inputs),
        {},
      ) as NewPipelineExecutionForm;

    const createPipelineExecutionBody: CreatePipelineExecutionBody = {
      inputs: filledInputValues,
      workspaceId: workspace.id,
      pipelineVersionId: props.versionId,
    };

    try {
      const createPipelineExecutionResponse = await createPipelineExecution(
        createPipelineExecutionBody,
      );

      await mutate(`/pipelines/${pipelineId}/executions`);
      toast.success({
        message: 'Pipeline execution started.',
      });

      navigate(routes.executions.view(pipelineId, createPipelineExecutionResponse.data.id));
    } catch (e) {
      const error = e as AxiosError;
      const { missingInputs } = error.response?.data as MissingInputNode;
      const message = missingInputs
        ? formatMissingInputMessage(missingInputs)
        : 'An error occurred while executing the pipeline.';

      toast.error({
        message,
      });
    }
  };

  const connectedInputs = filterInputsWithConnection(pipelineInputs?.inputs, pipeline?.edges);

  const canSubmit = connectedInputs.length
    ? formState.isValid && !formState.isSubmitting
    : !formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={handleSubmit(handleNewPipelineExecutionSubmit)}>
        <ContentPaper>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Stack direction="row" spacing={1} alignItems="center">
                <JobTypeIcon type={NodeType.PIPELINE_START} variant="filled" />
                <Typography variant="titleMedium" component="h2">
                  Pipeline Trigger
                </Typography>
              </Stack>
            </Grid>
            <Grid item>
              <Styled.PipelineElements>
                <Typography variant="bodySmall" color="text.primary">
                  1{' '}
                  <Typography variant="bodySmall" color="text.secondary">
                    of
                  </Typography>{' '}
                  {pipeline?.nodes.length}{' '}
                  <Typography variant="bodySmall" color="text.secondary">
                    Pipeline Elements
                  </Typography>
                </Typography>
              </Styled.PipelineElements>
            </Grid>
          </Grid>
          <Styled.InputsContainer>
            <Grid container spacing={3}>
              {connectedInputs.map((input) => {
                return (
                  <Grid key={input.name} item xs={6}>
                    <FormField control={control} input={input} />
                  </Grid>
                );
              })}
            </Grid>
          </Styled.InputsContainer>
        </ContentPaper>

        <Styled.Footer>
          <Styled.StartPipelineButton disabled={!canSubmit} variant="contained" type="submit">
            Start Pipeline
          </Styled.StartPipelineButton>
        </Styled.Footer>
      </form>
    </FormProvider>
  );
};

export default PipelineExecutionForm;

interface FormFieldProps {
  control: Control;
  input: NodeInput;
}

function FormField(props: FormFieldProps) {
  const { control, input } = props;
  const label = input.title || input.name;

  // TODO We may want to distribute these across the dataTypes

  if (isNumberDataSchema(input.dataSchema))
    return (
      <RHFTextField
        name={input.name}
        control={control}
        rules={addValidations(input)}
        valueAs="number"
        label={label}
        required={input.config.required}
        size="small"
        fullWidth
      />
    );

  if (isIntegerDataSchema(input.dataSchema))
    return (
      <RHFTextField
        name={input.name}
        control={control}
        rules={addValidations(input)}
        valueAs="integer"
        label={label}
        required={input.config.required}
        size="small"
        fullWidth
      />
    );

  if (isAssetDataSchema(input.dataSchema) || isArrayDataSchema(input.dataSchema, 'asset')) {
    return (
      <RFHAssetInput
        assetScope={{
          type: 'static',
        }}
        rules={{ required: input.config.required }}
        control={control}
        input={input}
        name={input.name}
        label={input.title || input.name}
      />
    );
  }

  if (isArrayDataSchema(input.dataSchema, 'number'))
    return (
      <RHFTextFieldTags
        name={input.name}
        control={control}
        rules={addValidations(input)}
        type="number"
        label={label}
        required={input.config.required}
        size="small"
        fullWidth
        helperText="Separate values by pressing enter"
      />
    );

  if (isArrayDataSchema(input.dataSchema, 'text'))
    return (
      <RHFTextFieldTags
        name={input.name}
        control={control}
        rules={addValidations(input)}
        label={label}
        required={input.config.required}
        size="small"
        fullWidth
        helperText="Separate values by pressing enter"
      />
    );

  if (isArrayDataSchema(input.dataSchema, 'image-uri'))
    return (
      <RHFTextFieldTags
        name={input.name}
        control={control}
        rules={addValidations(input)}
        label={label}
        required={input.config.required}
        size="small"
        fullWidth
      />
    );

  if (isTextDataSchema(input.dataSchema))
    return (
      <RHFTextField
        name={input.name}
        control={control}
        rules={addValidations(input)}
        label={label}
        required={input.config.required}
        size="small"
        fullWidth
      />
    );

  if (isEnumDataSchema(input.dataSchema))
    return (
      <RHFTextField
        select
        name={input.name}
        control={control}
        rules={addValidations(input)}
        label={label}
        required={input.config.required}
        size="small"
        fullWidth
      >
        {input.dataSchema.values.map((enumOption) => (
          <MenuItem key={enumOption} value={enumOption}>
            {enumOption}
          </MenuItem>
        ))}
      </RHFTextField>
    );

  if (isObjectDataSchema(input.dataSchema))
    return (
      <RHFJsonEditor
        label={input.title}
        name={input.name}
        control={control}
        required={input.config.required}
        rules={addValidations(input)}
      />
    );

  if (isArrayDataSchema(input.dataSchema, 'object'))
    return (
      <RHFJsonEditor
        label={input.title}
        name={input.name}
        control={control}
        required={input.config.required}
        isArrayJson
        rules={addValidations(input)}
      />
    );

  if (isBooleanDataSchema(input.dataSchema)) {
    return (
      <RHFBooleanSelect
        name={input.name}
        control={control}
        rules={addValidations(input)}
        label={label}
        required={input.config.required}
        size="small"
        defaultOptionLabel="Select data..."
        fullWidth
      />
    );
  }
}

function addValidations(input: PipelineInputResponse): ControllerProps['rules'] {
  const validations = input.config.required ? { ...requiredValidation } : {};

  // TODO It looks like we are missing some validations, we probably want to distribute these across the dataTypes
  switch (input.dataSchema.type) {
    case 'number':
      return { ...validations, ...numberValidation };

    case 'integer':
      return { ...validations, ...integerValidation };

    case 'object':
      return { ...validations, ...objectValidation };

    case 'boolean':
      // Custom validation for boolean for `required` due to the way false is handled
      return createBooleanValidation(!!input.config.required);

    case 'array':
      switch (input.dataSchema.items.type) {
        case 'number':
          return { ...validations, ...numberValidation };

        case 'image-uri':
          return { ...validations, ...urlValidation };
      }
  }

  return validations;
}
