import Toast from 'components/Toast/Toast';
import { createContext, ReactNode } from 'react';
import useToastState from './useToastState';

type ToastHandlers = ReturnType<typeof useToastState>['0'];

export const ToastContext = createContext<ToastHandlers>({
  success() {
    return;
  },
  warning() {
    return;
  },
  error() {
    return;
  },
  info() {
    return;
  },
  close() {
    return;
  },
});

interface ToastProviderProps {
  children: ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps) {
  const [handlers, state] = useToastState();

  return (
    <ToastContext.Provider value={handlers}>
      {children}
      <Toast {...state} />
    </ToastContext.Provider>
  );
}
