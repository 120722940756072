import { NodeType } from 'features/Flow/Flow.types';
import { createContext, useCallback, useState } from 'react';
import type { Connection, Edge } from 'reactflow';
import { removeByKey } from 'utils/objects';
import { computeHandleKey, createHandleKey, type HandleKey } from './Handles/handle.store';

export type ConnectionHandlerConnection = Connection & Omit<Partial<Edge>, 'source' | 'target'>;

export type ConnectionHandlerAction = (handledConnection?: ConnectionHandlerConnection) => void;

interface ConnectionHandlerParams {
  connection: Connection;
  actionCallback: ConnectionHandlerAction;
}

/**
 * @param conn The originating connection object for react flows.
 * @returns Modified connection object or null.
 * If it is null, the connection will not be made and will attempt to check the opposing handler.
 * If it is 'defer', the connection will not be made and the opposing handler will not be checked and the callConnectionHandler will return null
 */
export type ConnectionHandler = (
  conn: ConnectionHandlerParams['connection'],
  actionCallback: ConnectionHandlerParams['actionCallback'],
) => ConnectionHandlerConnection | null | 'defer';

interface ConnectionHandlers {
  /** A record of connection handlers for specific handleIds */
  connectionHandlers: Record<string, ConnectionHandler | undefined>;
  /** Calls the connection handler for the given connection */
  callConnectionHandler: (params: ConnectionHandlerParams) => ConnectionHandlerConnection | null;
  /** Runs the specified callback when the given handleId is creating a connection, useful to intercept and run custom logic */
  addConnectionHandler?: (key: HandleKey, handler: ConnectionHandler) => void;
  /** Removes the connection handler for the specific id */
  removeConnectionHandler?: (key: HandleKey) => void;
}

export interface NewNodeTypeProps {
  newNodeType: NodeType | null;
  setNewNodeType: (type: NodeType | null) => void;
}

type EditorContextValue = ConnectionHandlers & NewNodeTypeProps;

export const EditorContextProvider = ({ children }: React.PropsWithChildren) => {
  const [newNodeType, setNewNodeType] = useState<NodeType | null>(null);
  const [connectionHandlers, setConnectionHandlers] = useState<
    Record<string, ConnectionHandler | undefined>
  >({});

  const addConnectionHandler: Required<ConnectionHandlers>['addConnectionHandler'] = useCallback(
    (key, handler) => {
      setConnectionHandlers((handlers) => ({
        ...handlers,
        [computeHandleKey(key)]: handler,
      }));
    },
    [],
  );

  const removeConnectionHandler: Required<ConnectionHandlers>['removeConnectionHandler'] =
    useCallback((key) => {
      setConnectionHandlers((handlers) => removeByKey(handlers, computeHandleKey(key)));
    }, []);

  const callConnectionHandler: ConnectionHandlers['callConnectionHandler'] = useCallback(
    (params) => {
      const { connection, actionCallback } = params;

      let sourceHandler: ConnectionHandler | undefined;
      let targetHandler: ConnectionHandler | undefined;

      for (const key in connectionHandlers) {
        const { id: handleId, nodeId } = createHandleKey(key);

        if (connection.source === nodeId && connection.sourceHandle === handleId) {
          sourceHandler = connectionHandlers[key];
        }

        if (connection.target === nodeId && connection.targetHandle === handleId) {
          targetHandler = connectionHandlers[key];
        }

        if (sourceHandler && targetHandler) {
          break;
        }
      }

      if (!sourceHandler && !targetHandler) {
        return connection;
      }

      const sourceResult = sourceHandler?.(connection, actionCallback);

      if (sourceResult === 'defer') {
        return null;
      }

      const targetResult = targetHandler?.(connection, actionCallback);

      if (targetResult === 'defer') {
        return null;
      }

      return sourceResult ?? targetResult ?? connection;
    },
    [connectionHandlers],
  );

  return (
    <EditorContext.Provider
      value={{
        connectionHandlers,
        callConnectionHandler,
        addConnectionHandler,
        removeConnectionHandler,
        newNodeType,
        setNewNodeType,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export const EditorContext = createContext<EditorContextValue | null>(null);
