import { CheckpointInputValue } from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints.types';
import { PropsWithChildren, createContext, useState } from 'react';

type InputValueState = ReturnType<typeof useState<CheckpointInputValue>>;

interface ExecutionCheckpointContextValue {
  contextInputValue: InputValueState[0];
  pipelineExecutionId: string | undefined;
  setContextInputValue: InputValueState[1];
  setPipelineExecutionId: (pipelineExecutionId: string) => void;
}

export const ExecutionCheckpointContext = createContext<ExecutionCheckpointContextValue | null>(
  null,
);
ExecutionCheckpointContext.displayName = 'ExecutionCheckpointContext';

export function ExecutionCheckpointProvider({ children }: PropsWithChildren) {
  const [contextInputValue, setContextInputValue] = useState<CheckpointInputValue>();
  const [pipelineExecutionId, setPipelineExecutionId] = useState<string>();

  return (
    <ExecutionCheckpointContext.Provider
      value={{
        contextInputValue,
        pipelineExecutionId,
        setContextInputValue,
        setPipelineExecutionId,
      }}
    >
      {children}
    </ExecutionCheckpointContext.Provider>
  );
}
