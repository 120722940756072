import { ExecutionJobResponse } from 'api/services/usePipelineJobs/usePipelineJobs.types';
import { NodeType } from 'features/Flow/Flow.types';
import { Paper, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';

interface ExecutionCheckpointOutputProps {
  job: ExecutionJobResponse;
}

export default function ExecutionCheckpointOutput({ job }: ExecutionCheckpointOutputProps) {
  if (job.type !== NodeType.CHECKPOINT) return null;
  if (job.status === ExecutionStatus.COMPLETED) return null;

  const hasComment = Boolean(job.comment?.trim().length);

  return (
    <Paper elevation={2} sx={{ p: 1.5 }}>
      <Typography variant="bodySmall">
        {hasComment ? `“${job.comment}”` : 'Rejected without comment'}
      </Typography>
      <Typography component="p" variant="bodySmall" color="text.secondary">
        {/* FIXME: add real user name when available */}
        {job.completedAt && format(job.completedAt, "MM/dd/yyyy 'at' hh:mm aaa")} by User
      </Typography>
    </Paper>
  );
}
