import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Content,
  JSONEditor,
  Mode,
  OnChangeStatus,
  ValidationError,
  ValidationSeverity,
  isContent,
} from 'vanilla-jsoneditor';
import { Container } from './JsonEditor.styles';

type JSONEditorPropsOptional = Parameters<JSONEditor['updateProps']>[number];

interface JsonEditorProps {
  content: unknown;
  readOnly?: boolean;
  isArrayJson?: boolean;
  onChange?: (
    updatedContent: Content,
    previousContent: Content,
    { contentErrors, patchResult }: OnChangeStatus,
  ) => void;
}

export default function JsonEditor(props: JsonEditorProps) {
  const { readOnly = false, isArrayJson = false, onChange } = props;

  const refContainer = useRef<HTMLElement | null>(null);
  const refEditor = useRef<JSONEditor | null>(null);

  const customValidator = useCallback(
    (json: unknown) => {
      const errors: ValidationError[] = [];

      if (!isArrayJson && Array.isArray(json)) {
        errors.push({
          path: [],
          message: 'Arrays are not allowed at the top level.',
          severity: ValidationSeverity.error,
        });
      }

      if (isArrayJson && !Array.isArray(json)) {
        errors.push({
          path: [],
          message: 'Top level must be an array.',
          severity: ValidationSeverity.error,
        });
      }

      return errors;
    },
    [isArrayJson],
  );

  const editorProps: JSONEditorPropsOptional = useMemo(() => {
    const content = isContent(props.content) ? props.content : { json: props.content };

    return {
      content,
      mainMenuBar: false,
      mode: Mode.text,
      readOnly,
      statusBar: false,
      validator: customValidator,
      onChange,
    };
  }, [customValidator, onChange, props.content, readOnly]);

  useEffect(() => {
    if (!refContainer.current) return;

    refEditor.current = new JSONEditor({
      target: refContainer.current,
      props: {},
    });

    return () => {
      if (refEditor.current) {
        void refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (refEditor.current) {
      void refEditor.current.updateProps(editorProps);
    }
  }, [editorProps]);

  return (
    <Container key={String(editorProps.content)} ref={refContainer} data-testid="JsonEditor" />
  );
}
