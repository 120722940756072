import { Stack, Typography } from '@mui/material';
import CopyButton from 'components/CopyButton/CopyButton';
import { ExecutionCheckpointFormProps } from '../../../ExecutionCheckpoint/ExecutionCheckpointForm.types';
import { NodeType } from 'features/Flow/Flow.types';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import { useJsonEditorState } from 'components/JsonEditor/useJsonEditorState';
import { objectValidation } from 'components/ui/rhf-components/utils/validationRules';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { getJobName, getJobTitle } from 'utils/neurons';
import * as Styled from '../../CheckpointForm.styles';
import * as BaseStyled from '../../ExecutionCheckpoint.styles';

const ExecutionCheckpointObjectForm = ({
  disabled,
  input,
  pendingCheckpoint,
  pendingCheckpointHeader,
}: ExecutionCheckpointFormProps) => {
  const { metadata } = pendingCheckpoint;
  const initialValue = pendingCheckpoint.data.inputs[input.name];

  const { content, error, value, onChange: onJsonChange } = useJsonEditorState(initialValue);
  const { setError } = useFormContext();
  const { field } = useController({
    name: input.name,
    rules: {
      required: true,
      ...objectValidation,
    },
  });
  const { onChange: controllerOnChange } = field;

  useEffect(() => {
    controllerOnChange(value);
  }, [value, controllerOnChange]);

  useEffect(() => {
    setError(field.name, error ? { message: 'Invalid JSON object' } : {});
  }, [field.name, error, setError]);

  return (
    <Styled.FormContainer>
      <Stack direction="row" justifyContent="space-between" marginBottom={2}>
        <Stack direction="row" gap={1} alignItems="center">
          <JobTypeIcon type={NodeType.CHECKPOINT} variant="filled" />
          <Typography variant="titleMedium">{getJobTitle(metadata)}</Typography>
        </Stack>
        <BaseStyled.CheckpointApprovalHeaderPill>
          <Typography variant="bodySmall">
            {getJobName(metadata)} <span>|</span> {pendingCheckpointHeader}
          </Typography>
        </BaseStyled.CheckpointApprovalHeaderPill>
      </Stack>
      <Styled.ExecutionCheckpointApprovalHeader>
        <Typography variant="labelSmall">JSON Data</Typography>
        <CopyButton sx={{ marginRight: 1 }} value={JSON.stringify(field.value, null, 2)} />
      </Styled.ExecutionCheckpointApprovalHeader>
      <Styled.EditorContainer>
        <JsonEditor content={content} readOnly={disabled} onChange={onJsonChange} />
      </Styled.EditorContainer>
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointObjectForm;
