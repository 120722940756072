import { Typography } from '@mui/material';
import { PipelineResponse } from 'types/pipeline';
import * as Styled from './StateVersionChip.styles';

interface StateVersionChipProps {
  state: PipelineResponse['pipelineState'];
  version: number;
}

const StateVersionChip = ({ state, version }: StateVersionChipProps) => {
  return (
    <Styled.ChipContainer elevation={12}>
      <Styled.Chip $state={state} label={<Typography variant="bodySmall">{state}</Typography>} />
      <Typography variant="bodySmall">Version {version}</Typography>
    </Styled.ChipContainer>
  );
};

export default StateVersionChip;
