import { useParams } from 'react-router-dom';
import { formatUrlMissingParameterMessage } from 'utils/message';

export default function usePipelineId(): string {
  const { pipelineId } = useParams();

  if (!pipelineId) {
    throw new Error(formatUrlMissingParameterMessage('pipelineId'));
  }

  return pipelineId;
}
