import { Chip, css, styled } from '@mui/material';
import { getPaletteColor } from 'utils/styles';

export const MuiChip = styled(Chip)<{ $variant: string }>(({ theme, color, $variant }) => {
  const paletteColor = getPaletteColor(color, theme);
  const backgroundColor = paletteColor?.selected ?? 'transparent';

  if ($variant === 'elevated')
    return css`
      height: 28px;
      background-color: ${backgroundColor};
      color: ${paletteColor?.main};
    `;
});
