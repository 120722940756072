import ExecutionRunsPage from 'pages/ExecutionsPage/pages/ExecutionRunsPage/ExecutionRunsPage';
import NewExecutionPage from 'pages/ExecutionsPage/pages/NewExecutionPage/NewExecutionPage';
import ViewExecutionPage from 'pages/ExecutionsPage/pages/ViewExecutionPage/ViewExecutionPage';
import { RouteObject } from 'react-router-dom';

export function executionRoutes(): RouteObject[] {
  return [
    {
      index: true,
      Component: ExecutionRunsPage,
    },
    {
      path: 'new',
      Component: NewExecutionPage,
    },
    {
      path: ':pipelineExecutionId',
      Component: ViewExecutionPage,
    },
  ];
}

export function executionsAppRoutes(baseUrl: string) {
  return {
    base: (pipelineId: string) => `${baseUrl}/pipelines/${pipelineId}/executions`,
    runs(pipelineId: string) {
      return this.base(pipelineId);
    },
    new(pipelineId: string) {
      return `${this.base(pipelineId)}/new`;
    },
    view(pipelineId: string, pipelineExecutionId: string) {
      return `${this.base(pipelineId)}/${pipelineExecutionId}`;
    },
    versions(pipelineId: string) {
      return `${this.base(pipelineId)}/versions`;
    },
  };
}
