import * as Styled from 'components/Dialog/Dialog.styles';
import DummyNeuronModalCreate from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/DummyNeuronModalCreate';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';

interface DummyNeuronQuickCreateModalProps {
  open: boolean;
  type: 'input' | 'output';
  inputs: NodeInput[];
  outputs: NodeOutput[];
  onClose: () => void;
  onCreateItem: (item: NodeInput | NodeOutput) => void;
  defaultValues: NodeInput | NodeOutput | null;
}

const DummyNeuronQuickCreateModal = ({
  open,
  onClose,
  type,
  inputs,
  outputs,
  onCreateItem,
  defaultValues,
}: DummyNeuronQuickCreateModalProps) => {
  return (
    <Styled.Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      {open && (
        <DummyNeuronModalCreate
          items={type === 'input' ? inputs : outputs}
          type={type}
          defaultValues={defaultValues}
          onCancel={onClose}
          onConfirm={onCreateItem}
          dataTypeDisabled
        />
      )}
    </Styled.Dialog>
  );
};

export default DummyNeuronQuickCreateModal;
