import { Config, useRequest } from 'api/client';
import { PipelineExecutionCheckpointResponse } from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints.types';

const usePipelineExecutionCheckpoints = (
  pipelineExecutionId: string,
  config?: Config<PipelineExecutionCheckpointResponse>,
) => {
  const checkpointsRequest = useRequest<PipelineExecutionCheckpointResponse>(
    {
      url: `/pipeline-executions/${pipelineExecutionId}/checkpoints`,
    },
    config,
  );

  return {
    areCheckpointsLoading: checkpointsRequest.isLoading,
    checkpoints: checkpointsRequest.data?.checkpoints ?? [],
    checkpointsError: checkpointsRequest.error,
    mutateCheckpoints: checkpointsRequest.mutate,
  };
};

export default usePipelineExecutionCheckpoints;
