import { Schedule } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import ActionsMenu from 'components/ActionsMenu/ActionsMenu';
import { ActionsMenuProps } from 'components/ActionsMenu/ActionsMenu.types';
import StatusChip from 'components/StatusChip/StatusChip';
import Timer from 'components/Timer/Timer';
import { TimerProps } from 'components/Timer/Timer.types';
import { getStatusConfig } from 'utils/status';
import * as Styled from './RuntimeChip.styles';

interface RuntimeChipProps
  extends Pick<TimerProps, 'startedAt' | 'completedAt'>,
    Pick<ActionsMenuProps, 'renderActions'> {
  status: ExecutionStatus | undefined;
  fullWidth?: boolean;
}

export default function RuntimeChip(props: RuntimeChipProps) {
  const { status, fullWidth, startedAt, completedAt, renderActions } = props;
  const config = getStatusConfig(status);

  const timer = <Timer status={status} startedAt={startedAt} completedAt={completedAt} />;
  const displayTimerOnly = status === ExecutionStatus.PENDING;

  if (displayTimerOnly) {
    return timer;
  }

  return (
    <Styled.Container direction="row" spacing={1} fullWidth={fullWidth}>
      <Stack direction="row" alignItems="center">
        <Schedule fontSize="small" color={config.icon?.color} sx={{ mr: 1 }} />
        <Typography variant="labelSmall">{timer}</Typography>
      </Stack>
      <StatusChip status={status} variant="icon" />
      <ActionsMenu renderActions={renderActions} />
    </Styled.Container>
  );
}
