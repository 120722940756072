import { Alert, Button, Dialog, Stack, Typography, useTheme } from '@mui/material';
import * as Styled from 'components/Dialog/Dialog.styles';

interface ViewSwitchConfirmationModalProps {
  open: boolean;
  view: 'json' | 'table';
  onCancel: () => void;
  onConfirm: () => void;
}

const ViewSwitchConfirmationModal = ({
  open,
  view,
  onCancel,
  onConfirm,
}: ViewSwitchConfirmationModalProps) => {
  const theme = useTheme();
  return (
    <Dialog open={open} transitionDuration={theme.transitions.duration.short}>
      <Styled.DialogTitle>
        {view === 'json' ? 'Switch to Table View' : 'Switch to Edit Raw JSON View'}
      </Styled.DialogTitle>
      <Styled.DialogContent>
        {view === 'json' ? (
          <Alert severity="warning">
            <Typography variant="body2">
              You will lose your changes if you switch to Table view.
            </Typography>
            <Typography variant="body2"> Are you sure you want to continue?</Typography>
          </Alert>
        ) : (
          <Alert severity="warning">
            <Typography variant="body2">
              You will lose your changes if you switch to Edit Raw JSON view.
            </Typography>
            <Typography variant="body2">Are you sure you want to continue?</Typography>
          </Alert>
        )}
      </Styled.DialogContent>
      <Styled.DialogActions>
        <Stack direction="row">
          <Button variant="text" sx={{ color: 'common.white' }} onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Switch View</Button>
        </Stack>
      </Styled.DialogActions>
    </Dialog>
  );
};

export default ViewSwitchConfirmationModal;
