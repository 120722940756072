import { Box } from '@mui/material';
import styled from 'styled-components';

export { SectionDivider } from 'components/Sidebar/Sidebar.styles';

export const HeaderContainer = styled(Box)`
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(1)};
  max-width: 264px;
  padding: 12px;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const HeaderActionBar = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 16px;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const HeaderActionColumn = styled(Box)`
  align-items: center;
  display: flex;
  flex: 1;
  min-width: 0;

  & > :last-child {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const JSONSchemaSection = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
