import { DataSchemaCompatibilityCheck } from '../DataType';
import { DataSchema, NumberDataSchema } from '@pathways/pipeline-schema';

export const numberCompatibilityCheck: DataSchemaCompatibilityCheck<NumberDataSchema> = ({
  output,
}) => {
  return output.type === 'number';
};

export function isNumberDataSchema(schema?: DataSchema): schema is NumberDataSchema {
  return schema?.type === 'number';
}
