import { NodeInput } from '../../../Node/Node.types';
import { Button, Grid } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { NodeInputFormData } from '../ObjectBuilder.types';
import ObjectBuilderForm from './ObjectBuilderForm';
import { useCallback, useEffect } from 'react';
import * as Styled from 'components/Dialog/Dialog.styles';
import { createBuilderDataSchema } from '../ObjectBuilder.utils';

interface ObjectBuilderCreateFormProps {
  defaultValues: NodeInput | null;
  nodeInputs: NodeInput[];
  isPendingConnection?: boolean;
  onCancelCreate: () => void;
  onConfirmCreate: (input: NodeInput) => void;
}

const ObjectBuilderCreateForm: React.FC<ObjectBuilderCreateFormProps> = ({
  defaultValues,
  nodeInputs,
  isPendingConnection,
  onCancelCreate,
  onConfirmCreate,
}) => {
  const form = useForm<NodeInputFormData>({
    defaultValues: {
      name: defaultValues?.name ?? '',
      title: '',
      dataType: defaultValues?.dataSchema.type ?? 'text',
      arrayDataType: undefined,
    },
    mode: 'onBlur',
  });

  const submitForm: SubmitHandler<NodeInputFormData> = useCallback(
    (data) => {
      const dataSchema = createBuilderDataSchema(data);

      onConfirmCreate({
        name: data.name,
        dataSchema,
        title: data.title ?? '',
        config: {
          required: true,
        },
      });
    },
    [onConfirmCreate],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  // Run form validation when defaultValues are provided.
  useEffect(() => {
    if (defaultValues) {
      void form.trigger();
    }
  }, [defaultValues, form]);

  return (
    <FormProvider {...form}>
      <Styled.DialogTitle $hasBorder={true}>Create Input</Styled.DialogTitle>
      <Styled.DialogContent>
        <ObjectBuilderForm
          otherInputs={nodeInputs}
          control={form.control}
          isConnected={false}
          isPendingConnection={isPendingConnection}
        />
      </Styled.DialogContent>
      <Styled.DialogActions $hasBorder={true}>
        <Grid container justifyContent="flex-end">
          <Button variant="text" sx={{ color: 'common.white' }} onClick={onCancelCreate}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!form.formState.isValid}>
            Confirm
          </Button>
        </Grid>
      </Styled.DialogActions>
    </FormProvider>
  );
};

export default ObjectBuilderCreateForm;
