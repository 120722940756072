import styled, { css } from 'styled-components';

interface Props {
  $centerContent: boolean;
}

export const FullPageContainer = styled.main<Props>`
  height: 100vh;
  width: 100vw;

  ${(props) =>
    props.$centerContent &&
    css`
      display: grid;
      place-content: center;
    `}
`;
