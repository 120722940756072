import {
  ListPipelineVersionsResponse,
  PipelineVersionsPagination,
} from 'api/services/usePipelineVersions/usePipelineVersions.types';
import { useRequest } from 'api/client';

const defaultPagination: PipelineVersionsPagination = {
  pageSize: 2,
  pageNumber: 0,
  sortBy: 'version',
  orderBy: 'desc',
};

const usePipelineVersions = (pipelineId: string, pagination = defaultPagination) => {
  const queryParams = new URLSearchParams({
    pageSize: pagination.pageSize.toString(),
    pageNumber: pagination.pageNumber.toString(),
    sortBy: pagination.sortBy,
    orderBy: pagination.orderBy.toUpperCase(),
  });
  const {
    isLoading: isFetchingVersions,
    isValidating,
    data,
    error,
    mutate,
  } = useRequest<ListPipelineVersionsResponse>({
    url: `/pipelines/${pipelineId}/versions?${queryParams.toString()}`,
    method: 'GET',
  });

  return {
    arePipelineVersionsLoading: isFetchingVersions || isValidating,
    pipelineVersionsData: data,
    pipelineVersionsError: error,
    getPipelineVersions: mutate,
  };
};

export default usePipelineVersions;
