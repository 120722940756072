import { useCallback, useMemo, useState } from 'react';
import { Order } from 'utils/order';

export interface PaginationQueryValues<T extends string> {
  sortBy: T;
  orderBy?: Order;
  pageNumber?: number;
  pageSize?: number;
}

interface UsePaginationQueryArgs<T extends string> {
  defaultValues: PaginationQueryValues<T>;
}

export const usePaginationQuery = <T extends string>(props: UsePaginationQueryArgs<T>) => {
  const { defaultValues } = props;

  const [pagination, setPagination] = useState<Required<PaginationQueryValues<T>>>({
    sortBy: defaultValues.sortBy,
    orderBy: defaultValues.orderBy ?? 'desc',
    pageNumber: defaultValues.pageNumber ?? 0,
    pageSize: defaultValues.pageSize ?? 50,
  });

  const updateSort = useCallback(
    (columnId: T) => {
      const isAsc = pagination.sortBy === columnId && pagination.orderBy === 'asc';

      setPagination({
        sortBy: columnId,
        orderBy: isAsc ? 'desc' : 'asc',
        pageNumber: 0,
        pageSize: pagination.pageSize,
      });
    },
    [pagination.sortBy, pagination.orderBy, pagination.pageSize],
  );

  const paginationQuery = useMemo(() => {
    const queryParams = new URLSearchParams();
    queryParams.append('pageNumber', pagination.pageNumber.toString());
    queryParams.append('pageSize', pagination.pageSize.toString());
    queryParams.append('sortBy', pagination.sortBy.toString());
    queryParams.append('orderBy', pagination.orderBy.toString().toUpperCase());

    return queryParams;
  }, [pagination]);

  return {
    ...pagination,
    paginationQuery,
    updateSort,
    updatePageNumber: (page: number) => {
      setPagination({ ...pagination, pageNumber: page });
    },
  };
};
