import { Box, Button } from '@mui/material';
import styled, { css } from 'styled-components';
import PathwaysProject from 'components/Icons/PathwaysProject';

export const MuiContainer = styled(Box)`
  display: grid;
  justify-content: center;
  justify-items: center;
  padding: 48px 0;
`;

export const MuiButton = styled(Button)`
  border-radius: 50px;
  font-weight: 400;
` as typeof Button;

export const CREATE_PIPELINE_BUTTON_HEIGHT = '49px';

export const CreatePipelineButton = styled(MuiButton)`
  width: 186px;
  height: ${CREATE_PIPELINE_BUTTON_HEIGHT};
` as typeof MuiButton;

export const PipelinesListContainer = styled(Box)(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(3)} 0px;
    min-height: 300px;
    min-width: 50rem;
    text-align: center;
  `,
);

export const PathwaysProjectIcon = styled(PathwaysProject)`
  width: 48px;
  height: 48px;
`;
