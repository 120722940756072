import { useState } from 'react';
import { DataManipulatorCommitFn, DataManipulatorModalProps } from '../DataManipulator.types';
import { PathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { useUpdateNodeInternals } from 'reactflow';

interface UseDataManipulationModalProps {
  nodeId: string;
}

type UseDataManipulationModalReturn = Omit<
  DataManipulatorModalProps,
  'inputs' | 'outputs' | 'disableInputType' | 'disableOutputType'
> & {
  openModal: () => void;
};

export const useDataManipulationModal = ({
  nodeId,
}: UseDataManipulationModalProps): UseDataManipulationModalReturn => {
  const [open, setOpen] = useState<boolean>(false);
  const updateNodeInternals = useUpdateNodeInternals();
  const updateNodeData = useUpdateNodeData();

  const handleCancelChanges = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleOnConfirmChanges: DataManipulatorCommitFn = (inputs, outputs) => {
    updateNodeData<PathwayNode['data']>(nodeId, (prev) => {
      return { ...prev, inputs, outputs };
    });

    updateNodeInternals(nodeId);
    setOpen(false);
  };

  return {
    open,
    nodeId,
    openModal: handleOpenModal,
    onCloseModal: handleCancelChanges,
    onConfirm: handleOnConfirmChanges,
  };
};
