import { MenuItem, Stack, Typography } from '@mui/material';
import ElementLayers from 'features/Flow/components/ElementLayers/ElementLayers';
import Loader from 'components/Loader/Loader';
import RuntimeChip from 'components/RuntimeChip/RuntimeChip';
import { SectionDivider } from 'components/Sidebar/Sidebar.styles';
import { formatRelative } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'reactflow';
import { FlowState } from 'types/reactflow';
import { getPipelineExecutionOrDefault } from 'utils/execution';
import { useAppRoutes } from 'utils/routes';
import * as Styled from './LeftSidebar.styles';

const selector = ({ pipelineExecution, executionJobs }: FlowState) => ({
  pipelineExecution,
  executionJobs,
});

export default function LeftSidebar() {
  const routes = useAppRoutes();
  const navigate = useNavigate();
  const state = useStore(selector);
  const pipelineExecution = getPipelineExecutionOrDefault(state.pipelineExecution);
  const { pipelineId, id: pipelineExecutionId } = pipelineExecution;
  const loading = !pipelineExecutionId;
  const relativeTime = formatRelative(new Date(pipelineExecution.updatedAt), new Date());

  return (
    <Styled.Container $borderRight>
      <Stack spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <Loader loading={loading}>
            <Typography variant="labelLarge">Run {pipelineExecution.executionNumber}</Typography>
          </Loader>

          <Loader loading={loading}>
            <Typography variant="labelSmall" color="text.secondary">
              {relativeTime}
            </Typography>
          </Loader>
        </Stack>

        <Loader loading={loading} width="100%">
          <RuntimeChip
            status={pipelineExecution.status}
            startedAt={pipelineExecution.createdAt}
            completedAt={pipelineExecution.completedAt}
            renderActions={() => (
              <MenuItem
                onClick={() => {
                  navigate(routes.executions.view(pipelineId, pipelineExecutionId));
                }}
              >
                Switch to execution view
              </MenuItem>
            )}
          />
        </Loader>
      </Stack>

      <SectionDivider />

      <ElementLayers />
    </Styled.Container>
  );
}
