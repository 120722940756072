import { AxiosError } from 'axios';
import { PipelineExecutionInputsResponse } from '../usePipelineExecutionInputs/usePipelineExecutionInputs.types';
import {
  BaseJobInfoResponse,
  PipelineJobOutput,
} from '../usePipelineExecutionOutputs/usePipelineExecutionOutputs.types';
import { NodeType } from 'features/Flow/Flow.types';

export type ExecutionJobResponse = BasePipelineJobResponse | SubpipelineJobResponse;

export interface BasePipelineJobResponse extends BaseJobInfoResponse {
  inputs: PipelineExecutionInputsResponse['inputs'];
  outputs: PipelineJobOutput[];
  startedAt?: string;
}

export interface SubpipelineJobResponse extends BasePipelineJobResponse {
  executionId: string;
  pipelineId: string;
}

export function isSubpipelineJobResponse(job?: {
  type: SubpipelineJobResponse['type'];
  executionId?: SubpipelineJobResponse['executionId'];
}): job is SubpipelineJobResponse {
  return job?.type === NodeType.SUBPIPELINE && !!job.executionId;
}

export interface PipelineJobsResponse {
  jobs: ExecutionJobResponse[];
}

export type PipelineJobError = AxiosError;
