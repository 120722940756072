import { Typography, TypographyProps } from '@mui/material';

interface ShortcutCaptionProps {
  children: TypographyProps['children'];
}

export default function ShortcutCaption({ children }: ShortcutCaptionProps) {
  return (
    <Typography variant="caption" color="text.secondary" fontFamily="sans-serif" marginLeft={2}>
      {children}
    </Typography>
  );
}
