import { ExecutionCheckpointApprovalHeaderPaper } from './ExecutionCheckpoint.styles';
import { styled } from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ExecutionCheckpointApprovalHeader = styled(ExecutionCheckpointApprovalHeaderPaper)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  width: 100%;
  max-height: 40px;

  &.MuiPaper-rounded {
    border-radius: 8px;
  }
`;

export const FixedWidth = styled.div`
  text-align: center;
  width: 120px;
`;

export const CheckpointApprovalRow = styled.span`
  margin: 0;
  padding: 1rem 0.75rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--divider-main, #333);
  }
`;

export const EditorContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: ${({ theme }) => theme.spacing(1)};
  flex: 1;
`;
