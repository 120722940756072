import { ColumnConfig as CustomColumnConfig } from 'components/Table/TableHead';
import { PipelineVersionItem } from 'api/services/usePipelineVersions/usePipelineVersions.types';

export const COLUMN_IDS = {
  VERSION: 'version',
  TITLE: 'title',
  DESCRIPTION: 'description',
  PUBLISHED: 'published_at',
  STATUS: 'status',
  FAILED: 'failed',
  ACTIONS: 'actions',
} as const;

export type ColumnId = (typeof COLUMN_IDS)[keyof typeof COLUMN_IDS];

export interface ColumnConfig extends Omit<CustomColumnConfig, 'id'> {
  orderProperty?: keyof PipelineVersionItem;
}

export const COLUMN_CONFIG: Partial<Record<ColumnId, ColumnConfig>> = {
  [COLUMN_IDS.VERSION]: {
    label: 'Version',
    orderProperty: 'version',
  },
  [COLUMN_IDS.DESCRIPTION]: {
    label: 'Description',
    orderProperty: 'description',
  },
  [COLUMN_IDS.STATUS]: {
    label: 'Status',
  },
  [COLUMN_IDS.PUBLISHED]: {
    label: 'Published',
    orderProperty: 'publishedAt',
  },
  [COLUMN_IDS.ACTIONS]: {
    label: '',
  },
};
export const COLUMN_CONFIG_ARRAY = Object.entries(COLUMN_CONFIG).map(([id, config]) => ({
  id: id as ColumnId,
  ...config,
}));
