import { UnfoldMoreSharp } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { NodeObjectValue } from 'features/Flow/nodes/Node/Node.types';
import InputDialog from 'components/InputDialog/InputDialog';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import { useJsonEditorState } from 'components/JsonEditor/useJsonEditorState';
import { useState } from 'react';
import { ControllerProps, useController } from 'react-hook-form';

interface RHFJsonEditorProps {
  label: string;
  name: string;
  control?: ControllerProps['control'];
  rules?: ControllerProps['rules'];
  required?: boolean;
  isArrayJson?: boolean;
}

const RHFJsonEditor = ({
  label,
  name,
  control,
  rules,
  isArrayJson = false,
  required = false,
}: RHFJsonEditorProps) => {
  const [expand, setExpand] = useState(false);
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue: isArrayJson ? [] : {},
  });
  const {
    content,
    error,
    value,
    onChange: onJsonChange,
  } = useJsonEditorState(field.value as NodeObjectValue);

  const openDialog = () => {
    setExpand(true);
  };

  const closeDialog = () => {
    setExpand(false);
  };

  const confirmDialog = () => {
    field.onChange(value);
    closeDialog();
  };

  return (
    <>
      <TextField
        label={label}
        required={required}
        fullWidth
        value={JSON.stringify(field.value)}
        aria-readonly
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <IconButton size="small" onClick={openDialog}>
              <UnfoldMoreSharp sx={{ transform: 'rotate(45deg)' }} />
            </IconButton>
          ),
          readOnly: true,
        }}
      />
      <InputDialog
        expand={expand}
        title={label}
        onClose={closeDialog}
        onCancel={closeDialog}
        confirmDisabled={error}
        onConfirm={confirmDialog}
      >
        <JsonEditor content={content} onChange={onJsonChange} isArrayJson={isArrayJson} />
      </InputDialog>
    </>
  );
};

export default RHFJsonEditor;
