import { RightSidebarView } from 'features/Flow/Flow.types';
import { useEffect, useState } from 'react';
import { FlowNode } from 'types/reactflow';

const useRightSidebarView = (selectedNodes: FlowNode[]) => {
  const [rightSidebarView, setRightSidebarView] = useState(RightSidebarView.PIPELINE);

  useEffect(() => {
    if (selectedNodes.length === 0) {
      setRightSidebarView(RightSidebarView.PIPELINE);
      return;
    }

    if (selectedNodes.length === 1) {
      setRightSidebarView(RightSidebarView.SELECTED_NODE);
      return;
    }

    if (selectedNodes.length > 1) {
      setRightSidebarView(RightSidebarView.SELECTED_NODES);
      return;
    }
  }, [selectedNodes.length]);

  return rightSidebarView;
};

export default useRightSidebarView;
