import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Edge } from 'reactflow';
import * as Styled from './Inputs.styles';

interface VisibilityIconButtonProps {
  required?: boolean;
  hidden?: boolean;
  visible?: boolean;
  edge: Edge | undefined;
  onClick: () => void;
}

const VisibilityIconButton = ({
  required = false,
  hidden = false,
  visible = false,
  edge,
  onClick,
}: VisibilityIconButtonProps) => {
  if (required || Boolean(edge)) return null;
  if (!visible) return null;

  return (
    <Styled.MuiIconButton className="visibility-icon" size="small" onClick={onClick}>
      {hidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </Styled.MuiIconButton>
  );
};

export default VisibilityIconButton;
