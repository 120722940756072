import { Config, useRequest } from 'api/client';
import { handleLegacyData } from 'utils/mappings';
import { PipelineInputsResponse } from './usePipelineInputs.types';

interface UsePipelineInputsProps {
  pipelineId: string;
  versionId?: string;
  config?: Config<PipelineInputsResponse>;
}

const usePipelineInputs = ({ pipelineId, versionId, config }: UsePipelineInputsProps) => {
  const url = `/pipelines/${pipelineId}/inputs`;
  const pipelineInputsRequest = useRequest<PipelineInputsResponse>(
    {
      url: versionId ? `${url}?versionId=${versionId}` : url,
    },
    config,
  );

  return {
    arePipelineInputsLoading: pipelineInputsRequest.isLoading,
    pipelineInputs: handleLegacyData(pipelineInputsRequest.data),
    pipelineInputsError: pipelineInputsRequest.error,
    mutatePipelineInputs: pipelineInputsRequest.mutate,
  };
};

export default usePipelineInputs;
