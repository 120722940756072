import { FormatNodeDataFn } from 'features/Flow/Flow.types';
import { formatNodeData } from 'utils/neurons';
import { createDataManipulatorNode } from './DataManipulator/DataManipulator.utils';

/**
 * Functions may require custom rendering.
 * These mapped format node data functions are present to manage the initial state of custom function nodes when required.
 */
const FORMAT_FUNCTION_MAP: Partial<Record<string, FormatNodeDataFn>> = {
  'function/data-manipulator': createDataManipulatorNode,
};

export const formatFunctionData: FormatNodeDataFn = (manifest, customTitle) => {
  const formatter = FORMAT_FUNCTION_MAP[manifest.key];
  if (formatter) {
    return formatter(manifest, customTitle);
  }

  return formatNodeData(manifest, customTitle);
};
