import { SectionDivider } from 'components/Sidebar/Sidebar.styles';
import SubPanelHeader from 'features/Flow/components/RightSidebar/components/PanelHeader/SubPanelHeader';
import { DynamicCheckpointNode } from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/DynamicCheckpoint.types';
import { TableSchemaForm } from './TableSchemaForm';

interface TableSchemaPanelProps {
  node: DynamicCheckpointNode;
  onBackClick: () => void;
}

export function TableSchemaPanel(props: TableSchemaPanelProps) {
  const { node } = props;

  return (
    <>
      <SubPanelHeader node={node} title="Configure Schema" onBackClick={props.onBackClick} />
      <SectionDivider />
      <TableSchemaForm node={node} />
    </>
  );
}
