import { AssetObject } from '@pathways/pipeline-schema';
import axios, { AxiosResponse } from 'axios';
import { SignedAssetObject } from 'features/Flow/nodes/Node/Node.types';

interface AssetPayload {
  pipelineId: string;
  items: AssetPayloadItem[];
}

export type AssetPayloadItem = StaticAssetPayload | ExecutionAssetPayload;

interface BaseAssetPayload {
  mediaType: string;
  sequence: number;
}

export interface StaticAssetPayload extends BaseAssetPayload {
  type: 'static';
}

export interface ExecutionAssetPayload extends BaseAssetPayload {
  type: 'execution';
  executionId: string;
}

interface AssetCreated {
  sequence: number;
  asset: AssetObject;
  /**
   * The URL to upload the asset to
   */
  url: string;
  /**
   * The URL to preview the asset which as signed as a GET inline
   */
  previewUrl: string;
}

interface AssetCreatedResponse {
  items: AssetCreated[];
}

export type AssetScope =
  | Omit<ExecutionAssetPayload, keyof BaseAssetPayload>
  | Omit<StaticAssetPayload, keyof BaseAssetPayload>;

export type AssetUpload = (
  pipelineId: string,
  scope: AssetScope,
  files: File[],
  createAssets?: CreateAssets,
  upload?: UploadData,
) => Promise<SignedAssetObject[]>;

export const uploadAssets: AssetUpload = async (
  pipelineId,
  scope,
  files,
  createAssets = callCreateAssets,
  upload = uploadData,
) => {
  const payload: AssetPayload = {
    pipelineId,
    items: files.map<AssetPayloadItem>(({ type: mediaType }, index) => ({
      ...scope,
      mediaType,
      sequence: index,
    })),
  };

  const assets = await createAssets(payload);

  const uploadedAssets: SignedAssetObject[] = await Promise.all(
    assets.items.map(async ({ sequence, asset, url, previewUrl }): Promise<SignedAssetObject> => {
      const file = files[sequence];

      const buffer = await file.arrayBuffer();
      await upload({ asset, url }, buffer);

      return {
        filename: file.name,
        mediaType: asset.mediaType,
        pathwayAssetId: asset.pathwayAssetId,
        signedUrl: previewUrl,
      };
    }),
  );

  return uploadedAssets;
};

export type UploadData = (
  payload: Pick<AssetCreated, 'url' | 'asset'>,
  data: ArrayBuffer,
) => Promise<void>;

const uploadData: UploadData = ({ url, asset: { mediaType } }, data) =>
  // Disabling credentials for this one is important
  axios.put(url, data, { headers: { 'Content-Type': mediaType }, withCredentials: false });

export type CreateAssets = (payload: AssetPayload) => Promise<AssetCreatedResponse>;

const callCreateAssets: CreateAssets = async (payload) => {
  const response = await axios.post<AssetPayload, AxiosResponse<AssetCreatedResponse>>(
    `/assets`,
    payload,
  );
  return response.data;
};
