import { Typography } from '@mui/material';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';
import { JobNodeType } from 'types/reactflow';
import * as Styled from '../../RightSidebar.styles';

interface HeaderContentProps {
  type: JobNodeType;
  title?: string;
  description?: string;
}

const HeaderContent = ({ type, title, description }: HeaderContentProps) => {
  return (
    <Styled.HeaderContainer>
      <JobTypeIcon type={type} />
      <Styled.TitleContainer>
        <Typography variant="titleSmall">{title}</Typography>
        <Typography variant="bodySmall">{description}</Typography>
      </Styled.TitleContainer>
    </Styled.HeaderContainer>
  );
};

export default HeaderContent;
