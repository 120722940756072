import { SvgIcon, SvgIconProps } from '@mui/material';

const BatchStartIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
      >
        <rect x="10.0002" width="4" height="12" rx="1" fill="#80CBC4" />
        <rect x="10.0002" width="4" height="12" rx="1" fill="#00796B" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.0031 2.88833C5.21602 2.67541 5.56123 2.67541 5.77415 2.88833L8.50024 5.61442C8.71317 5.82734 8.71317 6.17255 8.50024 6.38547L5.77415 9.11156C5.56123 9.32448 5.21602 9.32448 5.0031 9.11156C4.79018 8.89864 4.79018 8.55343 5.0031 8.34051L6.79844 6.54516L0.545462 6.54517C0.244346 6.54517 0.000244141 6.30107 0.000244141 5.99995C0.000244141 5.69884 0.244346 5.45474 0.545462 5.45474L6.79844 5.45473L5.0031 3.65938C4.79018 3.44646 4.79018 3.10125 5.0031 2.88833Z"
          fill="#80CBC4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.0031 2.88833C5.21602 2.67541 5.56123 2.67541 5.77415 2.88833L8.50024 5.61442C8.71317 5.82734 8.71317 6.17255 8.50024 6.38547L5.77415 9.11156C5.56123 9.32448 5.21602 9.32448 5.0031 9.11156C4.79018 8.89864 4.79018 8.55343 5.0031 8.34051L6.79844 6.54516L0.545462 6.54517C0.244346 6.54517 0.000244141 6.30107 0.000244141 5.99995C0.000244141 5.69884 0.244346 5.45474 0.545462 5.45474L6.79844 5.45473L5.0031 3.65938C4.79018 3.44646 4.79018 3.10125 5.0031 2.88833Z"
          fill="#00796B"
        />
      </svg>
    </SvgIcon>
  );
};

export default BatchStartIcon;
