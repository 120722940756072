import { ControlButton, Controls } from 'reactflow';
import styled from 'styled-components';

export const FlowControls = styled(Controls)`
  padding: 8px;
  box-shadow: none;

  .controls-button {
    width: 40px;
    height: 40px;
    border-bottom-color: ${({ theme }) => theme.palette.surface.dividerMain};
    background: ${({ theme }) => theme.palette.surface.level1};

    &:last-child {
      border: 0;
    }
  }

  .controls-button-zoomin {
    border-radius: ${({ theme }) => theme.spacing(2, 2, 0, 0)};
  }

  .controls-interactive {
    border-radius: ${({ theme }) => theme.spacing(0, 0, 2, 2)};
  }

  .controls-button svg {
    max-width: 24px;
    max-height: 24px;
  }
`;

export const FlowControlButton = styled(ControlButton)`
  &.react-flow__controls-button.controls-button {
    height: auto;
    width: auto;
    padding: 8px;
  }
`;
