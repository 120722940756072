import { Box, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { FlowExecutionJob } from 'types/reactflow';
import { getJobTitle } from 'utils/neurons';
import * as Styled from '../Node.styles';
import { PathwayNodeMetadata } from '../Node.types';

const headerIconErrorStyles: SxProps<Theme> = (theme) => ({
  bgcolor: theme.palette.common.white,
  borderRadius: '100%',
});

interface NodeHeaderProps {
  metadata: PathwayNodeMetadata;
  error?: string;
  execution?: FlowExecutionJob;
  icon?: React.ReactNode;
}

export const NodeHeader: React.FC<NodeHeaderProps> = ({ error, execution, icon, metadata }) => {
  const status = error ? ExecutionStatus.FAILED : execution?.status;

  return (
    <Styled.NodeHeader $nodeType={metadata.type}>
      <Typography variant="labelLarge">{getJobTitle(execution ?? metadata)}</Typography>

      {icon ?? (
        <Tooltip
          title={error}
          PopperProps={{
            placement: 'top',
          }}
        >
          <Box lineHeight={0}>
            <StatusIcon
              status={status}
              sx={status === ExecutionStatus.FAILED ? headerIconErrorStyles : undefined}
            />
          </Box>
        </Tooltip>
      )}
    </Styled.NodeHeader>
  );
};
