import { ChevronRight } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { NodeType } from 'features/Flow/Flow.types';
import AddCheckpointIcon from 'components/Icons/AddCheckpoint';
import AddFunctionIcon from 'components/Icons/AddFunction';
import AddNeuronIcon from 'components/Icons/AddNeuron';
import { Sidebar } from 'components/Sidebar/Sidebar.styles';
import { useState } from 'react';
import AddElementSidebar, { AddElementSidebarProps } from '../AddElementSidebar/AddElementSidebar';
import ElementLayers from '../ElementLayers/ElementLayers';
import { ADD_ELEMENT_DATA_KEY } from './ElementSidebar.consts';
import * as Styled from './ElementSidebar.styles';
import PipelineIcon from 'components/Icons/Pipeline';

interface ElementSidebarProps {
  pipelineType?: 'default' | 'callable';
}

type AddElementSidebarState = Omit<AddElementSidebarProps, 'onClose' | 'nodes'>;

function ElementSidebar({ pipelineType = 'default' }: ElementSidebarProps) {
  const [addElementSidebarState, setAddElementSidebarState] = useState<AddElementSidebarState>();
  const dataAttributes = {
    [ADD_ELEMENT_DATA_KEY]: '',
  };

  const handleAddElementClick = (state: AddElementSidebarState) => {
    setAddElementSidebarState(state);
  };

  const handleAddElementSidebarClose = () => {
    setAddElementSidebarState(undefined);
  };

  return (
    <Sidebar $borderRight>
      <Stack spacing="8px" width="100%">
        <Styled.AddNeuronButton
          {...dataAttributes}
          fullWidth
          startIcon={<AddNeuronIcon />}
          endIcon={<ChevronRight />}
          onClick={() => {
            handleAddElementClick({
              title: 'Neurons',
              nodeType: NodeType.NEURON,
            });
          }}
        >
          <Styled.ElementLabelContainer>Add a Neuron</Styled.ElementLabelContainer>
        </Styled.AddNeuronButton>

        <Styled.AddFunctionButton
          {...dataAttributes}
          startIcon={<AddFunctionIcon />}
          endIcon={<ChevronRight />}
          onClick={() => {
            handleAddElementClick({
              title: 'Functions',
              nodeType: NodeType.FUNCTION,
            });
          }}
        >
          <Styled.ElementLabelContainer>Add a Function</Styled.ElementLabelContainer>
        </Styled.AddFunctionButton>

        <Styled.AddCheckpointButton
          {...dataAttributes}
          startIcon={<AddCheckpointIcon />}
          endIcon={<ChevronRight />}
          onClick={() => {
            handleAddElementClick({
              title: 'Checkpoints',
              nodeType: NodeType.CHECKPOINT,
            });
          }}
        >
          <Styled.ElementLabelContainer>Add a Checkpoint</Styled.ElementLabelContainer>
        </Styled.AddCheckpointButton>

        {pipelineType === 'default' && (
          <Styled.AddPipelineButton
            {...dataAttributes}
            startIcon={<PipelineIcon />}
            endIcon={<ChevronRight />}
            onClick={() => {
              handleAddElementClick({
                title: 'Subpipelines',
                nodeType: NodeType.SUBPIPELINE,
              });
            }}
          >
            <Styled.ElementLabelContainer>Add a Pipeline</Styled.ElementLabelContainer>
          </Styled.AddPipelineButton>
        )}
      </Stack>

      <AddElementSidebar
        title={addElementSidebarState?.title}
        nodeType={addElementSidebarState?.nodeType}
        onClose={handleAddElementSidebarClose}
      />

      <Styled.SectionDivider />

      <ElementLayers />
    </Sidebar>
  );
}

export default ElementSidebar;
