import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { Reducer, useCallback, useReducer } from 'react';

interface DummyNeuronQuickCreateModalState {
  open: boolean;
  item: NodeInput | NodeOutput | null;
  type: 'input' | 'output';
}

type DummyNeuronQuickCreateModalAction =
  | {
      type: 'open_input';
      payload: NodeInput;
    }
  | {
      type: 'open_output';
      payload: NodeOutput;
    }
  | {
      type: 'close';
    };

const reducer: Reducer<DummyNeuronQuickCreateModalState, DummyNeuronQuickCreateModalAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'open_input':
      return {
        open: true,
        type: 'input',
        item: action.payload,
      };
    case 'open_output':
      return {
        open: true,
        type: 'output',
        item: action.payload,
      };
    case 'close':
    default:
      return {
        ...state,
        open: false,
        item: null,
      };
  }
};

const useDummyNeuronQuickCreateModal = () => {
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    item: null,
    type: 'input',
  });

  const openModalForInput = useCallback(
    (item: NodeInput) => {
      dispatch({ type: 'open_input', payload: item });
    },
    [dispatch],
  );

  const openModalForOutput = useCallback(
    (item: NodeOutput) => {
      dispatch({ type: 'open_output', payload: item });
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    dispatch({ type: 'close' });
  }, [dispatch]);

  return {
    isOpen: state.open,
    type: state.type,
    item: state.item,
    openModalForInput,
    openModalForOutput,
    closeModal,
  };
};

export default useDummyNeuronQuickCreateModal;
