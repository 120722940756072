import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { completeNode, startNode } from 'features/Flow/Flow.consts';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';
import { Pipeline, PipelineCompleteJob, PipelineTriggerJob } from 'types/pipeline';

const CREATE_PIPELINE_KEY = 'createPipeline';

interface CreatePipeline {
  projectId?: string;
  workspaceId: string;
}

const startJob: PipelineTriggerJob = {
  id: startNode.id,
  data: {
    metadata: {
      type: startNode.data.metadata.type,
    },
    outputs: [],
  },
  render: {
    position: startNode.position,
    deletable: false,
  },
};

const completeJob: PipelineCompleteJob = {
  id: completeNode.id,
  data: {
    metadata: {
      type: completeNode.data.metadata.type,
    },
    outputs: [],
  },
  render: {
    position: completeNode.position,
    deletable: false,
  },
};

const createPipelineFetcher: MutationFetcher<
  AxiosResponse<{ id: string }>,
  typeof CREATE_PIPELINE_KEY,
  AxiosRequestConfig
> = (_, { arg: request }) => axios.request(request);

const useCreatePipeline = () => {
  const { isMutating: isSaving, trigger } = useSWRMutation(
    CREATE_PIPELINE_KEY,
    createPipelineFetcher,
  );

  const createPipeline = async ({ projectId, workspaceId }: CreatePipeline) => {
    const requestConfig = {
      url: `/pipelines`,
      method: 'POST',
      data: {
        projectId,
        workspaceId,
        name: 'Untitled Pipeline',
        content: {
          jobs: [startJob, completeJob],
          connections: [],
          groups: [],
        } satisfies Pipeline,
      },
    };

    return trigger(requestConfig);
  };

  return { createPipeline, isSaving };
};

export default useCreatePipeline;
