import { useRequest } from 'api/client';
import { useMemo } from 'react';
import { PipelineResponse } from 'types/pipeline';
import { mapPipeline } from 'utils/mappings';

export const usePipelineVersion = (id: string, versionId: string) => {
  const { isLoading, data, error, mutate, ...response } = useRequest<PipelineResponse>({
    url: `pipelines/${id}/versions/${versionId}`,
  });

  const pipeline = useMemo(() => {
    if (!data) return;

    return mapPipeline(data);
  }, [data]);

  return {
    ...response,
    isPipelineLoading: isLoading,
    pipeline,
    pipelineError: error,
  };
};
