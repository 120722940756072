import { createDataHandle, DataHandle } from 'features/Flow/Handles/handle.store';
import { useRegisterHandle } from 'features/Flow/Handles/handles';
import NeuronHandle from 'features/Flow/components/NeuronHandle/NeuronHandle';
import DotIcon from 'components/Icons/Dot';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { useMemo } from 'react';
import * as Styled from './Node.styles';
import { isStaticValue, NodeInput } from './Node.types';
import NodeDataIcon from './components/NodeDataIcon/NodeDataIcon';
import { PIPELINE_COMPLETE_NODE_ID } from '../PipelineComplete/PipelineComplete.consts';

interface NodeInputHandleProps {
  input: NodeInput;
  nodeId: string;
}

const NodeInputHandle: React.FC<NodeInputHandleProps> = ({ input, nodeId }) => {
  const dataHandle: DataHandle = useMemo(
    () =>
      createDataHandle({
        nodeId,
        id: input.id,
        data: input,
        purpose: 'input',
      }),
    [input, nodeId],
  );

  useRegisterHandle(dataHandle);

  return (
    <Styled.InputContainer>
      {nodeId !== PIPELINE_COMPLETE_NODE_ID && isStaticValue(input.value) && (
        <Styled.StaticValueIcon $dataSchema={input.dataSchema} />
      )}

      <Styled.DataContent>
        <NeuronHandle handle={dataHandle} maxConnections={1}>
          <NodeDataIcon dataSchema={input.dataSchema} />
        </NeuronHandle>

        <TextEllipsis variant="bodyMedium">{input.title || input.name}</TextEllipsis>
      </Styled.DataContent>

      {!input.config.hideConnectionStatus && (
        <Styled.ConnectionStatus
          $hasValue={input.value != null}
          $isRequired={input.config.required}
        >
          <DotIcon />
        </Styled.ConnectionStatus>
      )}
    </Styled.InputContainer>
  );
};

export default NodeInputHandle;
