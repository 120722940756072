import { handleLegacyDataType } from 'utils/mappings';
import {
  ExecutionOutputResponse,
  PipelineExecutionOutput,
} from './usePipelineExecutionOutputs.types';

type GroupedOutputsByJob = Record<string, PipelineExecutionOutput>;

export function groupOutputsByJob(outputs: ExecutionOutputResponse[]): GroupedOutputsByJob {
  return outputs.reduce<GroupedOutputsByJob>((acc, curr) => {
    const { job, ...output } = handleLegacyDataType(curr);
    const jobId = job.id;

    // ESLint rule `no-unnecessary-condition` is disabled because value will always be undefined first until it's mapped
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!acc[jobId]) {
      acc[jobId] = { job, outputs: [] };
    }

    acc[jobId].outputs.push(output);
    return acc;
  }, {});
}
