import { SvgIcon, SvgIconProps } from '@mui/material';

const EmptyArray: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="20" height="20" rx="5" fill="white" />
        <mask id="mask0_4604_16853" maskUnits="userSpaceOnUse" x="2" y="3" width="16" height="15">
          <rect x="2.5" y="3" width="15" height="15" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4604_16853)">
          <path
            d="M3.67188 16.2031C4.03646 16.5677 4.47917 16.75 5 16.75H6.25C6.42708 16.75 6.57552 16.6901 6.69531 16.5703C6.8151 16.4505 6.875 16.3021 6.875 16.125C6.875 15.9479 6.8151 15.7995 6.69531 15.6797C6.57552 15.5599 6.42708 15.5 6.25 15.5H5C4.82292 15.5 4.67448 15.4401 4.55469 15.3203C4.4349 15.2005 4.375 15.0521 4.375 14.875V6.125C4.375 5.94792 4.4349 5.79948 4.55469 5.67969C4.67448 5.5599 4.82292 5.5 5 5.5H6.25C6.42708 5.5 6.57552 5.4401 6.69531 5.32031C6.8151 5.20052 6.875 5.05208 6.875 4.875C6.875 4.69792 6.8151 4.54948 6.69531 4.42969C6.57552 4.3099 6.42708 4.25 6.25 4.25H5C4.47917 4.25 4.03646 4.43229 3.67188 4.79688C3.30729 5.16146 3.125 5.60417 3.125 6.125V14.875C3.125 15.3958 3.30729 15.8385 3.67188 16.2031Z"
            fill="black"
          />
          <path
            d="M16.3281 16.2031C15.9635 16.5677 15.5208 16.75 15 16.75H13.75C13.5729 16.75 13.4245 16.6901 13.3047 16.5703C13.1849 16.4505 13.125 16.3021 13.125 16.125C13.125 15.9479 13.1849 15.7995 13.3047 15.6797C13.4245 15.5599 13.5729 15.5 13.75 15.5H15C15.1771 15.5 15.3255 15.4401 15.4453 15.3203C15.5651 15.2005 15.625 15.0521 15.625 14.875V6.125C15.625 5.94792 15.5651 5.79948 15.4453 5.67969C15.3255 5.5599 15.1771 5.5 15 5.5H13.75C13.5729 5.5 13.4245 5.4401 13.3047 5.32031C13.1849 5.20052 13.125 5.05208 13.125 4.875C13.125 4.69792 13.1849 4.54948 13.3047 4.42969C13.4245 4.3099 13.5729 4.25 13.75 4.25H15C15.5208 4.25 15.9635 4.43229 16.3281 4.79688C16.6927 5.16146 16.875 5.60417 16.875 6.125V14.875C16.875 15.3958 16.6927 15.8385 16.3281 16.2031Z"
            fill="black"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default EmptyArray;
