import { SvgIcon, SvgIconProps } from '@mui/material';

const BooleanIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <rect width="32" height="32" rx="8" fill="#4DD0E1" />
        <mask id="mask0_10186_30171" maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
          <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_10186_30171)">
          <path
            d="M17.2221 25.7778C16.886 26.1139 16.4786 26.2819 15.9999 26.2819C15.5212 26.2819 15.1138 26.1139 14.7777 25.7778L6.2221 17.2222C5.88599 16.8861 5.71793 16.4787 5.71793 16C5.71793 15.5213 5.88599 15.1139 6.2221 14.7778L14.7777 6.22222C15.1138 5.88611 15.5212 5.71806 15.9999 5.71806C16.4786 5.71805 16.886 5.88611 17.2221 6.22222L25.7777 14.7778C26.1138 15.1139 26.2818 15.5213 26.2818 16C26.2818 16.4787 26.1138 16.8861 25.7777 17.2222L17.2221 25.7778ZM15.9999 24.5556L24.2499 16.3056C24.4186 16.1368 24.4186 15.8632 24.2499 15.6944L15.9999 7.44444V24.5556Z"
            fill="black"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default BooleanIcon;
