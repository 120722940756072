import { useCallback, useMemo } from 'react';
import { useReactFlow } from 'reactflow';
import { FlowNode, FlowNodeData } from 'types/reactflow';

export default function useFlow() {
  const base = useBaseFlow();
  const getNodeOrThrow = useGetNodeOrThrow();

  return useMemo(
    () => ({
      ...base,
      // Other common utility/related hooks.
      getNodeOrThrow,
    }),
    [base, getNodeOrThrow],
  );
}

/**
 * To avoid circular dependencies in extension hooks that require React Flow context.
 */
function useBaseFlow() {
  return useReactFlow<FlowNodeData>();
}

type TypeGuardFn<TNode> = (node?: TNode | FlowNode) => node is TNode;

export function useGetNodeOrThrow() {
  const { getNode } = useBaseFlow();

  return useCallback(
    <TNode extends FlowNode>(
      nodeId: string,
      predicate: TypeGuardFn<TNode> = (node): node is TNode => !!node,
    ): TNode => {
      const node = getNode(nodeId);
      if (!predicate(node)) throw new Error(`Node "${nodeId}" not found.`);
      return node;
    },
    [getNode],
  );
}
