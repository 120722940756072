import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RHFAssetInputProps } from './RHFAssetInput.types';
import AssetInput from 'components/Inputs/AssetUpload/AssetInput';
import { AssetUpload, uploadAssets } from 'api/services/uploadAssets';

const FILE_UPLOAD_ERROR_TYPE = 'fileUploading';

const RFHAssetInput: FC<RHFAssetInputProps> = (props) => {
  const { name, control, rules, ...assetInputProps } = props;
  const { clearErrors } = useFormContext();

  const handleAssetUpload: AssetUpload = async (...args) => {
    // invalidate form while uploading
    try {
      control?.setError(name, { type: FILE_UPLOAD_ERROR_TYPE });
      const assets = await uploadAssets(...args);

      return assets;
    } finally {
      clearErrors(name);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        const error = fieldState.invalid && fieldState.error?.type !== FILE_UPLOAD_ERROR_TYPE;
        return (
          <AssetInput
            {...assetInputProps}
            {...field}
            TextFieldProps={{
              ...assetInputProps.TextFieldProps,
              error,
            }}
            onAssetUpload={handleAssetUpload}
            onConfirmChange={(value) => {
              field.onChange(value);
            }}
          />
        );
      }}
    />
  );
};

export default RFHAssetInput;
