import Node from 'features/Flow/nodes/Node/Node';
import { PathwayNodeProps } from '../Node/Node.types';
import DynamicCheckpoint, { DynamicCheckpointProps } from './DynamicCheckpoint/DynamicCheckpoint';

export default function Checkpoint(props: PathwayNodeProps) {
  const { uses } = props.data.metadata;

  if (uses === 'dynamic@v1') {
    return <DynamicCheckpoint {...(props as DynamicCheckpointProps)} />;
  }

  return <Node {...props} />;
}
