import * as Styled from '../ExecutionCheckpoint/ExecutionCheckpointForm.styles';
import { isAssetImageType } from '../ExecutionCheckpoint/forms/ExecutionCheckpointAssetForm/ExecutionCheckpointAsset.utils';
import DynamicImageWithLoader from 'components/DynamicImageWithLoader/DynamicImageWithLoader';
import { AssetLoaderProps } from './AssetLoader.types';

const AssetLoader = ({ asset, leftAction, rightAction }: AssetLoaderProps): JSX.Element => {
  const isImageType = isAssetImageType(asset);

  return (
    <Styled.CheckpointApprovalCard>
      {isImageType && (
        <Styled.CheckpointApprovalImageContainer>
          <DynamicImageWithLoader imageUrl={asset.signedUrl} alt="Generated asset" />
        </Styled.CheckpointApprovalImageContainer>
      )}
      <Styled.CheckpointApprovalCardActions>
        {leftAction}
        {rightAction}
      </Styled.CheckpointApprovalCardActions>
    </Styled.CheckpointApprovalCard>
  );
};

export default AssetLoader;
