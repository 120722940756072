import { Chip, Stack } from '@mui/material';
import styled, { css } from 'styled-components';
import { getPaletteColor } from 'utils/styles';

export const Container = styled(Stack)<{ fullWidth?: boolean }>(
  ({ fullWidth }) => css`
    align-items: center;
    padding-left: 3px;
    justify-content: space-between;

    ${fullWidth &&
    css`
      width: 100%;
    `}
  `,
);

export const MuiChip = styled(Chip)(({ theme, color }) => {
  const paletteColor = getPaletteColor(color, theme);
  const backgroundColor = paletteColor?.selected ?? 'transparent';

  return css`
    height: 28px;
    background-color: ${backgroundColor};
  `;
});
