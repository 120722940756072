import { GetNodeOutputsProps, IOutputGroups, NodeOutputsProps } from './Outputs.types';
import { Edge, getConnectedEdges, useEdges, useNodes } from 'reactflow';
import { isPipelineStartNode } from 'features/Flow/nodes/PipelineStart/PipelineStart.types';
import { createHandleId, getHandleKeyFromId } from 'features/Flow/Flow.utils';
import { capitalize, Grid, Typography } from '@mui/material';
import { getJobTitle } from 'utils/neurons';
import { isPathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { isPipelineCompleteNode } from 'features/Flow/nodes/PipelineComplete/PipelineComplete.types';
import { FlowNode, FlowNodeData } from 'types/reactflow';
import { PIPELINE_COMPLETE_NAME } from 'features/Flow/nodes/PipelineComplete/PipelineComplete.consts';
import useStartCompleteIO from 'features/Flow/hooks/useStartCompleteIO';

const getNodeNameByTarget = (target: string, nodes: FlowNode[]) => {
  const node = nodes.find((node) => node.id === target);

  return getJobTitle({
    id: node?.id,
    ...node?.data.metadata,
  });
};

const generateOutputString = (output: IOutputGroups) => {
  if (output.targetLabel) {
    if (output.targetNode.toLowerCase() === PIPELINE_COMPLETE_NAME.toLowerCase()) {
      return `Display at ${capitalize(PIPELINE_COMPLETE_NAME)}`;
    }

    return `${capitalize(output.targetLabel)}${output.targetNode && ` (${output.targetNode})`}`;
  }

  return 'Display at execution completion';
};

const getNodeOutputs = (props: GetNodeOutputsProps) => {
  const { nodes, edges, selectedNode, pipelineStartOutputs, pipelineCompleteInputs } = props;
  let outputGroups: IOutputGroups[] | undefined = [];

  if (isPipelineStartNode(selectedNode)) {
    const nodeEdges = getConnectedEdges([selectedNode], edges);

    outputGroups = nodeEdges.map((edge) => {
      const source = pipelineStartOutputs?.find((output) => output.id === edge.sourceHandle);

      return {
        sourceLabel: source?.name ?? '',
        targetLabel: source?.title,
        targetNode: getNodeNameByTarget(edge.target, nodes),
      };
    });
  } else if (isPipelineCompleteNode(selectedNode)) {
    const nodeEdges = getConnectedEdges([selectedNode], edges);

    outputGroups = nodeEdges.map((edge) => {
      const source = pipelineCompleteInputs?.find((output) => output.id === edge.targetHandle);
      return {
        sourceLabel: source?.name ?? '',
        targetLabel: source?.title,
        targetNode: getNodeNameByTarget(edge.source, nodes),
      };
    });
  } else if (isPathwayNode(selectedNode)) {
    const getEdgesByOutput = (output: string): Edge | undefined => {
      const [edge] = edges.filter(
        (e) => e.source === selectedNode.id && e.sourceHandle === createHandleId('output', output),
      );
      return edge;
    };

    outputGroups = selectedNode.data.outputs.map((o) => {
      const edge: Edge | undefined = getEdgesByOutput(o.name);

      return {
        sourceLabel: o.name,
        targetLabel: edge?.target && getHandleKeyFromId(edge.targetHandle),
        targetNode: edge?.target ? getNodeNameByTarget(edge.target, nodes) : '',
      };
    });
  }

  return outputGroups;
};

const Outputs = (props: NodeOutputsProps) => {
  const nodes = useNodes<FlowNodeData>();
  const edges = useEdges();
  const { pipelineStartOutputs, pipelineCompleteInputs } = useStartCompleteIO();
  const { selectedNode } = props;
  const outputs = getNodeOutputs({
    nodes,
    edges,
    pipelineStartOutputs,
    pipelineCompleteInputs,
    selectedNode,
  });
  const title = isPipelineCompleteNode(selectedNode) ? 'Show at Completion' : 'Output Destinations';

  return (
    outputs.length > 0 && (
      <Grid container gap={'16px'}>
        <Typography variant="titleMedium">{title}</Typography>
        {outputs.map((output: IOutputGroups, i) => {
          return (
            <Grid
              key={`${output.targetNode}-${output.targetLabel}-${output.sourceLabel}-${i}`}
              item
            >
              <Typography variant="bodySmall" display={'block'}>
                {output.sourceLabel}
              </Typography>
              <Typography variant="bodyMedium" display={'block'}>
                {generateOutputString(output)}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    )
  );
};

export default Outputs;
