import { TextJsonSchema } from './text.schema';
import { ImageURIJsonSchema } from './image-uri.schema';
import { assetToJsonSchema } from './asset.schema';
import { constToJsonSchema } from './const.schema';
import { enumToJsonSchema } from './enum.schema';
export const toJsonSchema = (dataSchema) => {
    if (dataSchema.type === 'text') {
        return TextJsonSchema;
    }
    if (dataSchema.type === 'enum') {
        return enumToJsonSchema(dataSchema);
    }
    if (dataSchema.type === 'const') {
        return constToJsonSchema(dataSchema);
    }
    if (dataSchema.type === 'image-uri') {
        return ImageURIJsonSchema;
    }
    if (dataSchema.type === 'asset') {
        return assetToJsonSchema(dataSchema);
    }
    if (dataSchema.type === 'array') {
        if (dataSchema.items.type === null) {
            return { type: 'array' };
        }
        return { type: 'array', items: toJsonSchema(dataSchema.items) };
    }
    return dataSchema;
};
