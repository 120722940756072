import { Box, Skeleton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: #2e2e2e;
`;

export const ParagraphLoader = styled(Skeleton)`
  margin-top: 8px;
`;
