import { PipelineInputsResponse } from 'api/services/usePipelineInputs/usePipelineInputs.types';
import { isStartOutputConnection } from 'features/Flow/nodes/PipelineStart/PipelineStart.types';
import { MissingInput } from 'pages/ExecutionsPage/pages/NewExecutionPage/components/PipelineExecutionForm/PipelineExecutionForm.types';
import { FlowPipeline } from 'types/reactflow';

export const formatMissingInputMessage = (inputs: MissingInput[]) =>
  `'${inputs.map((m) => m.name).join(`', '`)}' ${
    inputs.length === 1 ? 'is' : 'are'
  } missing required inputs`;

export const filterInputsWithConnection = (
  inputs: PipelineInputsResponse['inputs'] = [],
  edges: FlowPipeline['edges'] = [],
) => {
  const connectedInputsHandleIds = edges
    .filter(isStartOutputConnection)
    .map((edge) => edge.sourceHandle);

  return inputs.filter((input) => connectedInputsHandleIds.includes(input.id));
};
