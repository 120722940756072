import { useCallback } from 'react';
import { Edge } from 'reactflow';
import useFlow from './useFlow';

const useRemoveEdge = () => {
  const { deleteElements } = useFlow();

  return useCallback(
    (edge: Edge) => {
      deleteElements({ edges: [edge] });
    },
    [deleteElements],
  );
};

export default useRemoveEdge;
