import { styled } from 'styled-components';
import { ExecutionCheckpointApprovalHeaderPaper } from '../../ExecutionCheckpoint.styles';
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ExecutionCheckpointApprovalHeader = styled(ExecutionCheckpointApprovalHeaderPaper)`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  width: 100%;
`;

export const CheckpointHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CheckpointApprovalHeaderLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const CheckpointApprovalHeaderActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  span {
    display: flex;
    gap: 12px;
  }
`;

export const CheckpointApprovalHeaderPill = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.surface.level0};
  border-radius: 8px;
  padding: 0px 12px 0px 12px;
  margin-top: 20px;
  gap: 16px;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 12px;
  }

  span {
    display: flex;
    gap: 12px;
    font-size: 12px;
  }
`;
