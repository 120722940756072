import * as Styled from './ExecutionCheckpointTextForm.styles';
import * as BaseStyled from '../../ExecutionCheckpoint.styles';
import { Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { NodeType } from 'features/Flow/Flow.types';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';
import { ChangeEvent, useState } from 'react';
import { getJobName, getJobTitle } from 'utils/neurons';
import { ExecutionCheckpointErrorMessages } from '../../ExecutionCheckpoint.type';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import { ExecutionCheckpointTextFormData } from './ExecutionCheckpointTextForm.types';

const arrayToObject = (arr?: string[]) =>
  arr?.reduce((obj, item, index) => ({ ...obj, [index]: item }), {}) ?? {};

const areInitialValuesArray = (initialValues: unknown): initialValues is string[] => {
  return Array.isArray(initialValues);
};

const ExecutionCheckpointTextForm = ({
  disabled = false,
  input,
  pendingCheckpoint,
  pendingCheckpointHeader,
}: ExecutionCheckpointFormProps) => {
  const { metadata, data } = pendingCheckpoint;
  const initialValues = data.inputs[input.name];

  if (!areInitialValuesArray(initialValues)) {
    throw new Error('Initial values must be an array');
  }

  const { control, register, setValue, watch } = useFormContext<ExecutionCheckpointTextFormData>();
  const { name } = register(input.name, {
    value: [],
    validate: (values) => values.length > 0 || ExecutionCheckpointErrorMessages.NO_INPUT_SELECTED,
  });
  const formValues = watch(name, []);
  const allSelected = formValues.length === initialValues.length;

  const [reviewText, setReviewText] = useState<Record<string, string>>(
    arrayToObject(initialValues),
  );
  const [checkedText, setCheckedText] = useState<Record<string, boolean | undefined>>({});

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const updatedValues = checked ? Object.values(reviewText) : [];
    setValue(name, updatedValues, { shouldValidate: true });

    // Mark everything as checked
    const allChecked = updatedValues.reduce((obj, _, index) => ({ ...obj, [index]: checked }), {});
    setCheckedText(allChecked);
  };

  return (
    <Styled.FormContainer>
      <Styled.CheckpointHeader>
        <Styled.CheckpointApprovalHeaderLogo>
          <JobTypeIcon type={NodeType.CHECKPOINT} variant="filled" />
          <Typography variant="titleMedium">{getJobTitle(metadata)}</Typography>
        </Styled.CheckpointApprovalHeaderLogo>
        <Styled.CheckpointApprovalHeaderActions>
          <BaseStyled.CheckpointApprovalHeaderPill>
            <Typography variant="bodySmall">
              {getJobName(metadata)} <span>|</span> {pendingCheckpointHeader}
            </Typography>
          </BaseStyled.CheckpointApprovalHeaderPill>
        </Styled.CheckpointApprovalHeaderActions>
      </Styled.CheckpointHeader>
      <Styled.CheckpointApprovalHeaderPill>
        <Typography variant="titleMedium">{input.title}</Typography>
        <FormControlLabel
          disabled={disabled}
          label="Select All"
          labelPlacement="start"
          control={
            <Checkbox
              checked={allSelected}
              indeterminate={formValues.length > 0 && !allSelected}
              onChange={handleSelectAllChange}
            />
          }
        />
      </Styled.CheckpointApprovalHeaderPill>
      <FormGroup>
        <Controller
          control={control}
          disabled={disabled}
          name={name}
          render={({ field }) => (
            <>
              {initialValues.map((text, index) => (
                <TextField
                  key={text}
                  disabled={field.disabled}
                  multiline
                  value={reviewText[index]}
                  sx={{ my: 0.5 }}
                  onChange={(event) => {
                    const newReviewText: Record<string, string> = {
                      ...reviewText,
                      [index]: event.target.value,
                    };
                    setReviewText(newReviewText);
                    const checkedValues = Object.keys(checkedText).map((key) => newReviewText[key]);
                    field.onChange(checkedValues);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <Checkbox
                        checked={checkedText[index] ?? false}
                        disabled={field.disabled}
                        edge="end"
                        sx={{ marginRight: -0.5 }}
                        onChange={(event, checked) => {
                          let checkedTextChanges;
                          if (checked) {
                            checkedTextChanges = { ...checkedText, [event.target.value]: true };
                          } else {
                            const { [event.target.value]: _removed, ...rest } = checkedText;
                            checkedTextChanges = { ...rest };
                          }

                          setCheckedText({ ...checkedTextChanges });
                          const checkedValues = Object.keys(checkedTextChanges).map(
                            (key) => reviewText[key],
                          );

                          field.onChange(checkedValues);
                        }}
                        value={index}
                      />
                    ),
                  }}
                />
              ))}
            </>
          )}
        />
      </FormGroup>
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointTextForm;
