import { CONNECTION_COLOR_DATA_KEY, DEFAULT_CONNECTION_PROPS } from 'features/Flow/Flow.consts';
import * as Styled from './NodeDataIcon.styles';
import { getAssetObjectBaseMediaType } from 'utils/assetObjects';
import { isIntegerDataSchema } from 'features/Flow/nodes/Node/DataSchemas/integerSchema';
import { isTextDataSchema } from 'features/Flow/nodes/Node/DataSchemas/textSchema';
import { isNumberDataSchema } from 'features/Flow/nodes/Node/DataSchemas/numberSchema';
import { isObjectDataSchema } from 'features/Flow/nodes/Node/DataSchemas/objectSchema';
import { isImageURIDataSchema } from 'features/Flow/nodes/Node/DataSchemas/imageURISchema';
import {
  DataSchema,
  isArrayDataSchema,
  isAssetDataSchema,
  isEnumDataSchema,
} from '@pathways/pipeline-schema/web';
import { isBooleanDataSchema } from '../../DataSchemas/booleanSchema';

export interface NodeDataIconProps {
  dataSchema: DataSchema;
  size?: number | string;
}

const ASSET_ICONS: Record<string, React.FunctionComponent | undefined> = {
  image: (attr: Record<string, string>) => <Styled.AssetObjectImageIcon {...attr} />,
  video: (attr: Record<string, string>) => <Styled.AssetObjectMediaIcon {...attr} />,
  text: (attr: Record<string, string>) => <Styled.AssetObjectFileIcon {...attr} />,
  application: (attr: Record<string, string>) => <Styled.AssetObjectFileIcon {...attr} />,
  audio: (attr: Record<string, string>) => <Styled.AssetObjectAudioIcon {...attr} />,
};

const ARRAY_ASSET_ICONS: Record<string, React.FunctionComponent | undefined> = {
  image: (attr: Record<string, string>) => <Styled.ArrayAssetObjectImageIcon {...attr} />,
  video: (attr: Record<string, string>) => <Styled.ArrayAssetObjectMediaIcon {...attr} />,
  text: (attr: Record<string, string>) => <Styled.ArrayAssetObjectFileIcon {...attr} />,
  application: (attr: Record<string, string>) => <Styled.ArrayAssetObjectFileIcon {...attr} />,
  audio: (attr: Record<string, string>) => <Styled.ArrayAssetObjectAudioIcon {...attr} />,
};

const Icon: React.FC<NodeDataIconProps> = (props) => {
  const dataAttributes = {
    [CONNECTION_COLOR_DATA_KEY]: DEFAULT_CONNECTION_PROPS.reference,
  };
  const { dataSchema } = props;

  if (isTextDataSchema(dataSchema)) {
    return <Styled.DataTextIcon {...dataAttributes} />;
  }
  if (isNumberDataSchema(dataSchema)) {
    return <Styled.DataNumberIcon {...dataAttributes} />;
  }
  if (isIntegerDataSchema(dataSchema)) {
    return <Styled.DataNumberIcon {...dataAttributes} />;
  }
  if (isImageURIDataSchema(dataSchema)) {
    return <Styled.DataImageIcon {...dataAttributes} />;
  }
  if (isBooleanDataSchema(dataSchema)) {
    return <Styled.DataBooleanIcon {...dataAttributes} />;
  }

  if (isEnumDataSchema(dataSchema)) {
    return <Styled.DataEnumIcon {...dataAttributes} />;
  }

  if (isArrayDataSchema(dataSchema)) {
    if (dataSchema.items.type === null) {
      return <Styled.EmptyArrayIcon {...dataAttributes} />;
    }
    if (isNumberDataSchema(dataSchema.items) || isIntegerDataSchema(dataSchema.items)) {
      return <Styled.DataNumberArrayIcon {...dataAttributes} />;
    }
    if (isTextDataSchema(dataSchema.items)) {
      return <Styled.DataTextArrayIcon {...dataAttributes} />;
    }
    if (isImageURIDataSchema(dataSchema.items)) {
      return <Styled.DataImageArrayIcon {...dataAttributes} />;
    }
    if (isObjectDataSchema(dataSchema.items)) {
      return <Styled.DataArrayObjectIcon {...dataAttributes} />;
    }
    if (isAssetDataSchema(dataSchema.items)) {
      const mediaType = dataSchema.items.mediaType[0] ?? '';
      const baseMediaType = getAssetObjectBaseMediaType(mediaType);
      const renderIcon = ARRAY_ASSET_ICONS[baseMediaType];
      if (baseMediaType && renderIcon) {
        return renderIcon(dataAttributes);
      }
      return <Styled.ArrayAssetObjectFileIcon {...dataAttributes} />;
    }
  }

  if (isAssetDataSchema(dataSchema)) {
    const baseMediaType = getAssetObjectBaseMediaType(dataSchema.mediaType[0] ?? '');
    const renderIcon = ASSET_ICONS[baseMediaType];
    if (baseMediaType && renderIcon) {
      return renderIcon(dataAttributes);
    }
    return <Styled.AssetObjectFileIcon {...dataAttributes} />;
  }

  return <Styled.DataObjectIcon {...dataAttributes} />;
};

const NodeDataIcon: React.FC<NodeDataIconProps> = (props) => {
  const { dataSchema, ...rest } = props;
  return (
    <Styled.IconContainer {...rest}>
      <Icon dataSchema={dataSchema} {...rest} />
    </Styled.IconContainer>
  );
};

export default NodeDataIcon;
