import { Slide } from '@mui/material';
import { DynamicCheckpointNode } from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/DynamicCheckpoint.types';
import { useState } from 'react';
import { getConnectedEdges, useEdges } from 'reactflow';
import { DynamicCheckpointPanelView } from './DynamicCheckpointPanel.consts';
import { PropertiesPanel } from './components/PropertiesPanel/PropertiesPanel';
import { TableSchemaPanel } from './components/TableSchemaPanel/TableSchemaPanel';

export interface DynamicCheckpointPanelProps {
  node: DynamicCheckpointNode;
  panelView?: DynamicCheckpointPanelView;
}

export default function DynamicCheckpointPanel(props: DynamicCheckpointPanelProps) {
  const [panelView, setPanelView] = useState(
    props.panelView ?? DynamicCheckpointPanelView.PROPERTIES,
  );
  const [slidePanelIn, setSlidePanelIn] = useState(!!props.panelView);
  const { node } = props;

  const edges = useEdges();
  const nodeConnectedEdges = getConnectedEdges([node], edges);
  const isNodeConnected = !!nodeConnectedEdges.length;

  return (
    <>
      {panelView === DynamicCheckpointPanelView.PROPERTIES && (
        <PropertiesPanel
          node={props.node}
          isNodeConnected={isNodeConnected}
          onConfigureSchemaClick={() => {
            setPanelView(DynamicCheckpointPanelView.OBJ_LIST_TABLE_SCHEMA);
            setSlidePanelIn(true);
          }}
        />
      )}

      <Slide
        in={panelView === DynamicCheckpointPanelView.OBJ_LIST_TABLE_SCHEMA && slidePanelIn}
        direction="left"
        mountOnEnter
        unmountOnExit
        onExited={() => {
          setPanelView(DynamicCheckpointPanelView.PROPERTIES);
        }}
      >
        {/* div to hold Slide ref */}
        <div>
          <TableSchemaPanel
            node={props.node}
            onBackClick={() => {
              setSlidePanelIn(false);
            }}
          />
        </div>
      </Slide>
    </>
  );
}
