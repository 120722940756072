import { Button } from '@mui/material';
import styled, { css } from 'styled-components';

const BASE_ELEMENT_LAYER_STYLES = css`
  height: 40px;
  border-radius: 8px;
`;

export const ElementLayerSkeleton = styled.div`
  ${BASE_ELEMENT_LAYER_STYLES};
`;

export const ElementLayerButton = styled(Button)(
  ({ theme }) => css`
    ${BASE_ELEMENT_LAYER_STYLES};
    background: ${({ theme }) => theme.palette.surface.level2};
    color: ${theme.palette.text.primary};
    justify-content: left;
    padding: ${theme.spacing(1, 1, 1, 1.5)};
    gap: ${theme.spacing(1)};
  `,
);
