import { DataSchema, isArrayDataSchema, isAssetDataSchema } from '@pathways/pipeline-schema/web';
import { isObjectDataSchema } from 'features/Flow/nodes/Node/DataSchemas/objectSchema';
import { isTextDataSchema } from 'features/Flow/nodes/Node/DataSchemas/textSchema';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';

export function getFooterMessage(dataSchema?: DataSchema): string {
  if (!dataSchema) return '';

  if (isTextDataSchema(dataSchema) || isAssetDataSchema(dataSchema)) return '';

  if (isObjectDataSchema(dataSchema)) return 'Approve or edit JSON values...';

  if (isArrayDataSchema(dataSchema, 'object')) return 'Approve or edit JSON list values...';

  if (isArrayDataSchema(dataSchema, 'image-uri')) return 'Select images to approve...';

  if (isArrayDataSchema(dataSchema, 'text')) return 'Select texts to approve...';

  return '';
}

export function isVisibleInput(input: NodeInput) {
  return !input.name.startsWith('__');
}
