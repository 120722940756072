import { useCallback } from 'react';
import { Edge, useStore } from 'reactflow';

interface UseIsHandleConnectedProps {
  nodeId: string;
}

export const useFindHandleEdge = ({ nodeId }: UseIsHandleConnectedProps) => {
  const edges: Edge[] = useStore((s) =>
    s.edges.filter((e) => {
      return e.target === nodeId || e.source === nodeId;
    }),
  );

  const findEdge = useCallback(
    (handleId: string) => {
      return edges.find((e) => {
        return e.targetHandle === handleId || e.sourceHandle === handleId;
      });
    },
    [edges],
  );

  return findEdge;
};
