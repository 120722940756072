import {
  isArrayDataSchema,
  isAssetDataSchema,
  isEnumDataSchema,
  isConstDataSchema,
} from '@pathways/pipeline-schema/web';
import { enumCompatibilityCheck } from './DataSchemas/enumSchema';
import { imageURICompatibilityCheck, isImageURIDataSchema } from './DataSchemas/imageURISchema';
import { integerCompatibilityCheck, isIntegerDataSchema } from './DataSchemas/integerSchema';
import { isNumberDataSchema, numberCompatibilityCheck } from './DataSchemas/numberSchema';
import { isObjectDataSchema, objectCompatibilityCheck } from './DataSchemas/objectSchema';
import { isTextDataSchema, textCompatibilityCheck } from './DataSchemas/textSchema';
import { NodeInputConfig } from './Node.types';
import { constCompatibilityCheck } from './DataSchemas/constSchema';
import { booleanCompatibilityCheck, isBooleanDataSchema } from './DataSchemas/booleanSchema';
import { DataSchema } from '@pathways/pipeline-schema';
import { assetCompatibilityCheck } from './DataSchemas/assetSchema';
import { arrayCompatibilityCheck } from './DataSchemas/arraySchema';

export type DataType = DataSchema['type'];

export interface DataConnection<T extends DataSchema> {
  input: T;
  output: DataSchema;
}

export type DataSchemaCompatibilityCheck<T extends DataSchema> = (
  conn: DataConnection<T>,
) => boolean;

export const compatibilityCheck: DataSchemaCompatibilityCheck<DataSchema> = ({ input, output }) => {
  if (isArrayDataSchema(input)) return arrayCompatibilityCheck({ input, output });
  if (isAssetDataSchema(input)) return assetCompatibilityCheck({ input, output });
  if (isImageURIDataSchema(input)) return imageURICompatibilityCheck({ input, output });
  if (isEnumDataSchema(input)) return enumCompatibilityCheck({ input, output });
  if (isIntegerDataSchema(input)) return integerCompatibilityCheck({ input, output });
  if (isNumberDataSchema(input)) return numberCompatibilityCheck({ input, output });
  if (isObjectDataSchema(input)) return objectCompatibilityCheck({ input, output });
  if (isConstDataSchema(input)) return constCompatibilityCheck({ input, output });
  if (isBooleanDataSchema(input)) return booleanCompatibilityCheck({ input, output });

  return textCompatibilityCheck({ input, output });
};

export const toLegacyType = (schema: DataSchema): JobDataTypes | undefined => {
  if (isArrayDataSchema(schema)) {
    if (!schema.items.type) return;

    if (isTextDataSchema(schema.items)) return JobDataTypes.ARRAY_TEXT;
    if (isImageURIDataSchema(schema.items)) return JobDataTypes.ARRAY_IMAGE;
    if (isNumberDataSchema(schema.items)) return JobDataTypes.ARRAY_NUMBER;
    if (isAssetDataSchema(schema.items)) return JobDataTypes.ARRAY_ASSET;
    if (isObjectDataSchema(schema.items)) return JobDataTypes.ARRAY_OBJECT;
    // Not really represented
    return JobDataTypes.ARRAY_TEXT;
  }

  if (isAssetDataSchema(schema)) return JobDataTypes.ASSET;
  if (isEnumDataSchema(schema)) return JobDataTypes.ENUM;
  if (isImageURIDataSchema(schema)) return JobDataTypes.IMAGE;
  if (isIntegerDataSchema(schema)) return JobDataTypes.INTEGER;
  if (isNumberDataSchema(schema)) return JobDataTypes.NUMBER;
  if (isObjectDataSchema(schema)) return JobDataTypes.OBJECT;
};

export interface WithDataSchema {
  dataSchema: DataSchema;
}

export interface LegacyInputConfig extends NodeInputConfig {
  enumOptions?: string[];
}

export interface LegacyDataType {
  dataType: JobDataTypes;
  assetType?: string;
  config?: LegacyInputConfig;
  // For typing union compatibility.
  dataSchema?: DataSchema;
}

export const isLegacyDataType = (data: WithDataSchema | LegacyDataType): data is LegacyDataType => {
  if (data.dataSchema) return false;
  return true;
};

export const fromLegacyDataType = ({ dataType, assetType, config }: LegacyDataType): DataSchema => {
  switch (dataType) {
    case JobDataTypes.ARRAY_ASSET:
      return { type: 'array', items: { type: 'asset', mediaType: [assetType ?? ''] } };
    case JobDataTypes.ARRAY_IMAGE:
      return { type: 'array', items: { type: 'image-uri' } };
    case JobDataTypes.ARRAY_NUMBER:
      return { type: 'array', items: { type: 'number' } };
    case JobDataTypes.ARRAY_TEXT:
      return { type: 'array', items: { type: 'text' } };
    case JobDataTypes.ARRAY_OBJECT:
      return { type: 'array', items: { type: 'object' } };
    case JobDataTypes.ASSET:
      return { type: 'asset', mediaType: [assetType ?? ''] };
    case JobDataTypes.ENUM:
      return { type: 'enum', values: config?.enumOptions ?? [] };
    case JobDataTypes.IMAGE:
      return { type: 'image-uri' };
    case JobDataTypes.INTEGER:
      return { type: 'integer' };
    case JobDataTypes.NUMBER:
      return { type: 'number' };
    case JobDataTypes.OBJECT:
      return { type: 'object' };
    case JobDataTypes.TEXT:
      return { type: 'text' };
    default:
      throw new Error('Encountered unknown data type');
  }
};

export enum JobDataTypes {
  ASSET = 'ASSET',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  INTEGER = 'INTEGER',
  ENUM = 'ENUM',
  IMAGE = 'IMAGE',
  OBJECT = 'OBJECT',
  ARRAY_TEXT = 'ARRAY_TEXT',
  ARRAY_NUMBER = 'ARRAY_NUMBER',
  ARRAY_IMAGE = 'ARRAY_IMAGE',
  ARRAY_ASSET = 'ARRAY_ASSET',
  ARRAY_OBJECT = 'ARRAY_OBJECT',
  PIPELINE_START_OUTPUT = 'PIPELINE_START_OUTPUT',
}
