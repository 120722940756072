import { SvgIcon, SvgIconProps } from '@mui/material';

const NumericIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M12.9999 20L12.1749 23.275C12.1249 23.4917 12.0166 23.6667 11.8499 23.8C11.6833 23.9333 11.4833 24 11.2499 24C10.9333 24 10.6749 23.875 10.4749 23.625C10.2749 23.375 10.2166 23.1 10.2999 22.8L10.9999 20H8.27495C7.94161 20 7.67495 19.8708 7.47495 19.6125C7.27495 19.3542 7.21661 19.0667 7.29995 18.75C7.34995 18.5167 7.46661 18.3333 7.64995 18.2C7.83328 18.0667 8.04162 18 8.27495 18H11.4999L12.4999 14H9.77495C9.44162 14 9.17495 13.8708 8.97495 13.6125C8.77495 13.3542 8.71661 13.0667 8.79995 12.75C8.84995 12.5167 8.96661 12.3333 9.14995 12.2C9.33328 12.0667 9.54162 12 9.77495 12H12.9999L13.8249 8.725C13.8749 8.50833 13.9833 8.33333 14.1499 8.2C14.3166 8.06667 14.5166 8 14.7499 8C15.0666 8 15.3249 8.125 15.5249 8.375C15.7249 8.625 15.7833 8.9 15.6999 9.2L14.9999 12H18.9999L19.8249 8.725C19.8749 8.50833 19.9833 8.33333 20.1499 8.2C20.3166 8.06667 20.5166 8 20.7499 8C21.0666 8 21.3249 8.125 21.5249 8.375C21.7249 8.625 21.7833 8.9 21.6999 9.2L20.9999 12H23.7249C24.0583 12 24.3249 12.1292 24.5249 12.3875C24.7249 12.6458 24.7833 12.9333 24.6999 13.25C24.65 13.4833 24.5333 13.6667 24.3499 13.8C24.1666 13.9333 23.9583 14 23.7249 14H20.4999L19.4999 18H22.2249C22.5583 18 22.8249 18.1292 23.0249 18.3875C23.2249 18.6458 23.2833 18.9333 23.1999 19.25C23.1499 19.4833 23.0333 19.6667 22.8499 19.8C22.6666 19.9333 22.4583 20 22.2249 20H18.9999L18.1749 23.275C18.1249 23.4917 18.0166 23.6667 17.8499 23.8C17.6833 23.9333 17.4833 24 17.2499 24C16.9333 24 16.6749 23.875 16.4749 23.625C16.2749 23.375 16.2166 23.1 16.2999 22.8L16.9999 20H12.9999ZM13.4999 18H17.4999L18.4999 14H14.4999L13.4999 18Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default NumericIcon;
