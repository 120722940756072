import { UnfoldMoreSharp } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { isArrayDataSchema } from '@pathways/pipeline-schema/web';
import VisibilityIconButton from 'features/Flow/components/RightSidebar/components/Inputs/VisibilityIconButton';
import { NodeInput, NodeObjectValue } from 'features/Flow/nodes/Node/Node.types';
import InputDialog from 'components/InputDialog/InputDialog';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import { useJsonEditorState } from 'components/JsonEditor/useJsonEditorState';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Edge } from 'reactflow';
import { VisibilityProps } from './Inputs.types';

interface ObjectInput {
  input: NodeInput;
  label: React.ReactNode | string;
  visibilityProps: VisibilityProps;
  edge: Edge | undefined;
  onConfirmChange: (value: NodeObjectValue | NodeObjectValue[] | '') => void;
  readOnly?: boolean;
}

export default function ObjectInput({
  input,
  label,
  visibilityProps,
  edge,
  onConfirmChange,
  readOnly = false,
}: ObjectInput) {
  const isArrayOfObjects = isArrayDataSchema(input.dataSchema, 'object');
  const emptyValue = isArrayOfObjects ? ([] as NodeObjectValue[]) : ({} as NodeObjectValue);
  const initialValue = (input.value as typeof emptyValue | undefined) ?? emptyValue;
  const {
    content,
    error,
    value,
    onChange: onJsonChange,
    setContent,
  } = useJsonEditorState(initialValue);
  const [expand, setExpand] = useState<boolean>(false);

  const resetDialogInput = () => {
    setExpand(false);
    setContent({
      json: initialValue,
    });
  };

  const confirmChange = () => {
    onConfirmChange(value);
    setExpand(false);

    const isEmptyValue = isEmpty(value);

    if (isEmptyValue) {
      setContent({
        json: emptyValue,
      });
    }
  };

  return (
    <React.Fragment>
      <TextField
        {...visibilityProps.hoverEvents}
        name={input.name}
        label={label}
        required={input.config.required}
        placeholder={readOnly ? '' : 'Enter or connect data...'}
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <>
              <VisibilityIconButton
                required={input.config.required}
                hidden={input.config.hidden}
                visible={visibilityProps.isHideIconVisible}
                edge={edge}
                onClick={visibilityProps.onVisibilityIconClick}
              />
              <IconButton
                size="small"
                onClick={() => {
                  setExpand(true);
                }}
              >
                <UnfoldMoreSharp sx={{ transform: 'rotate(45deg)' }} />
              </IconButton>
            </>
          ),
        }}
        value={JSON.stringify(initialValue)}
        disabled
        aria-readonly
      />
      <InputDialog
        expand={expand}
        title={input.title}
        onClose={() => {
          setExpand(false);
        }}
        onCancel={resetDialogInput}
        onConfirm={confirmChange}
        confirmDisabled={error}
      >
        <JsonEditor
          content={content}
          onChange={onJsonChange}
          isArrayJson={isArrayOfObjects}
          readOnly={readOnly}
        />
      </InputDialog>
    </React.Fragment>
  );
}
