import { PathwayNodeProps } from '../Node/Node.types';
import Node from '../Node/Node';
import DummyNeuron from './DummyNeuron/DummyNeuron';

const NEURON_COMPONENT_MAP: Partial<Record<string, React.FC<PathwayNodeProps>>> = {
  'neuron/pathwaysDummyNeuron': DummyNeuron,
};

const Neuron: React.FC<PathwayNodeProps> = (props) => {
  const CustomNeuron = NEURON_COMPONENT_MAP[props.data.metadata.uses];
  if (CustomNeuron) {
    return <CustomNeuron {...props} />;
  }

  return <Node {...props} />;
};

export default Neuron;
