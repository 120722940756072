import { SvgIcon, SvgIconProps } from '@mui/material';

const ObjectIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask
        id="mask0_2970_11643"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2970_11643)">
        <path
          d="M14.2692 19.5V18H16.7404C17.0942 18 17.3926 17.8801 17.6356 17.6404C17.8785 17.4006 18 17.1102 18 16.7692V14.7115C18 14.1166 18.1769 13.5833 18.5308 13.1115C18.8846 12.6397 19.3455 12.3115 19.9134 12.1269V11.8731C19.3455 11.6884 18.8846 11.3602 18.5308 10.8885C18.1769 10.4167 18 9.88332 18 9.28845V7.23075C18 6.88973 17.8785 6.59935 17.6356 6.3596C17.3926 6.11985 17.0942 5.99998 16.7404 5.99998H14.2692V4.5H16.7404C17.5096 4.5 18.1618 4.76603 18.6971 5.29808C19.2323 5.83013 19.5 6.47435 19.5 7.23075V9.28845C19.5 9.6423 19.6246 9.9391 19.874 10.1789C20.1233 10.4186 20.4282 10.5385 20.7884 10.5385H21.5V13.4615H20.7884C20.4282 13.4615 20.1233 13.5814 19.874 13.8211C19.6246 14.0609 19.5 14.3577 19.5 14.7115V16.7692C19.5 17.5256 19.2323 18.1698 18.6971 18.7019C18.1618 19.2339 17.5096 19.5 16.7404 19.5H14.2692ZM7.2596 19.5C6.49678 19.5 5.84615 19.2339 5.3077 18.7019C4.76923 18.1698 4.5 17.5256 4.5 16.7692V14.7115C4.5 14.3577 4.37533 14.0609 4.12598 13.8211C3.87661 13.5814 3.57179 13.4615 3.21153 13.4615H2.5V10.5385H3.21153C3.57179 10.5385 3.87661 10.4186 4.12598 10.1789C4.37533 9.9391 4.5 9.6423 4.5 9.28845V7.23075C4.5 6.47435 4.76923 5.83013 5.3077 5.29808C5.84615 4.76603 6.49678 4.5 7.2596 4.5H9.74035V5.99998H7.2596C6.91217 5.99998 6.61537 6.11985 6.3692 6.3596C6.12305 6.59935 5.99998 6.88973 5.99998 7.23075V9.28845C5.99998 9.88332 5.82466 10.4167 5.47403 10.8885C5.12338 11.3602 4.66088 11.6884 4.08653 11.8731V12.1269C4.66088 12.3115 5.12338 12.6397 5.47403 13.1115C5.82466 13.5833 5.99998 14.1166 5.99998 14.7115V16.7692C5.99998 17.1102 6.12305 17.4006 6.3692 17.6404C6.61537 17.8801 6.91217 18 7.2596 18H9.74035V19.5H7.2596Z"
          fill="black"
        />
      </g>
    </svg>
  </SvgIcon>
);

export default ObjectIcon;
