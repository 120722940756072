import { CheckboxProps, FormControlLabel, FormGroup, Radio, Typography } from '@mui/material';
import { NodeType } from 'features/Flow/Flow.types';
import {
  isSignedAssetObject,
  isSignedAssetObjectArray,
  SignedAssetObject,
} from 'features/Flow/nodes/Node/Node.types';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { getJobName, getJobTitle } from 'utils/neurons';
import * as BaseStyled from '../../ExecutionCheckpoint.styles';
import * as Styled from '../../ExecutionCheckpointForm.styles';
import AssetLoader from '../../../AssetLoader/AssetLoader';
import { ExecutionCheckpointErrorMessages } from '../../ExecutionCheckpoint.type';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import useExecutionCheckpointContext from '../../../ExecutionCheckpoint/contexts/useExecutionCheckpointContext';
import { isOpenableAsset } from 'utils/assetObjects';
import DownloadButton from 'components/DownloadButton';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';

const ExecutionCheckpointSingleAssetForm = (props: ExecutionCheckpointFormProps) => {
  const { disabled, input, pendingCheckpoint, pendingCheckpointHeader } = props;
  const { metadata: checkpointMetadata } = pendingCheckpoint;
  const { contextInputValue: assets = [], setContextInputValue } = useExecutionCheckpointContext();
  const initialValues = pendingCheckpoint.data.inputs[input.name];

  if (!isSignedAssetObject(initialValues)) {
    throw new Error('Initial values must be a single asset');
  }

  if (!isSignedAssetObjectArray(assets)) {
    throw new Error('Assets must be an array');
  }

  useEffect(() => {
    setContextInputValue([initialValues]);
  }, [initialValues, setContextInputValue]);

  const { register, setValue, watch } =
    useFormContext<Partial<Record<string, SignedAssetObject>>>();
  const { name } = register(input.name, {
    value: initialValues,
    validate: (values) =>
      (Array.isArray(values) ? values : [values]).length > 0 ||
      ExecutionCheckpointErrorMessages.NO_INPUT_SELECTED,
  });

  const selectedAsset = watch(name);

  const handleSelect =
    (asset: SignedAssetObject): CheckboxProps['onChange'] =>
    (_event) => {
      setValue(name, asset, { shouldValidate: true });
    };

  return (
    <Styled.FormContainer>
      <Styled.CheckpointApprovalHeader>
        <Styled.CheckpointApprovalHeaderLogo>
          <JobTypeIcon type={NodeType.CHECKPOINT} variant="filled" />
          <Typography variant="titleMedium">{getJobTitle(checkpointMetadata)}</Typography>
        </Styled.CheckpointApprovalHeaderLogo>
        <Styled.CheckpointApprovalHeaderActions>
          <BaseStyled.CheckpointApprovalHeaderPill>
            <Typography variant="bodySmall">
              {getJobName(checkpointMetadata)}
              <span>|</span>
              {pendingCheckpointHeader}
            </Typography>
          </BaseStyled.CheckpointApprovalHeaderPill>
        </Styled.CheckpointApprovalHeaderActions>
      </Styled.CheckpointApprovalHeader>
      <FormGroup>
        <Styled.CheckpointApprovalCardContainer>
          {assets.map((asset) => {
            const checked = selectedAsset
              ? selectedAsset.pathwayAssetId === asset.pathwayAssetId
              : false;

            return (
              <AssetLoader
                key={asset.pathwayAssetId}
                asset={asset}
                leftAction={
                  <FormControlLabel
                    disabled={disabled}
                    label="Select Asset"
                    control={
                      <Radio
                        data-testid="RadioComponent"
                        checked={checked}
                        onChange={handleSelect(asset)}
                      />
                    }
                  />
                }
                rightAction={
                  isOpenableAsset(asset) ? (
                    <OpenInNewTab href={asset.signedUrl} linkText="Open URL" />
                  ) : (
                    <DownloadButton asset={asset} />
                  )
                }
              />
            );
          })}
        </Styled.CheckpointApprovalCardContainer>
      </FormGroup>
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointSingleAssetForm;
