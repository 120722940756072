import NeuronHandle from 'features/Flow/components/NeuronHandle/NeuronHandle';
import * as Styled from './Node.styles';
import { NodeOutput } from './Node.types';
import NodeDataIcon from './components/NodeDataIcon/NodeDataIcon';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { useMemo } from 'react';
import { createDataHandle, DataHandle } from 'features/Flow/Handles/handle.store';
import { useRegisterHandle } from 'features/Flow/Handles/handles';

interface NodeOutputProps {
  output: NodeOutput;
  nodeId: string;
  fullWidth?: boolean;
}

const NodeOutputHandle: React.FC<NodeOutputProps> = (props) => {
  const { output, nodeId } = props;
  const { title, name, dataSchema } = output;

  const dataHandle: DataHandle = useMemo(
    () =>
      createDataHandle({
        nodeId,
        id: output.id,
        data: output,
        purpose: 'output',
      }),
    [nodeId, output],
  );

  useRegisterHandle(dataHandle);

  return (
    <Styled.OutputContainer>
      <Styled.DataContent $fullWidth={props.fullWidth}>
        <TextEllipsis variant="bodyMedium">{title || name}</TextEllipsis>

        <NeuronHandle handle={dataHandle}>
          <NodeDataIcon dataSchema={dataSchema} />
        </NeuronHandle>
      </Styled.DataContent>
    </Styled.OutputContainer>
  );
};

export default NodeOutputHandle;
