import { DataSchemaCompatibilityCheck } from '../DataType';
import { EnumDataSchema } from '@pathways/pipeline-schema';

export const enumCompatibilityCheck: DataSchemaCompatibilityCheck<EnumDataSchema> = ({
  input,
  output,
}) => {
  if (output.type !== 'enum') return false;

  return output.values.every((enumValue) => input.values.includes(enumValue));
};
