import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';

export interface BooleanSelectProps extends Omit<TextFieldProps, 'onChange' | 'select'> {
  required?: boolean;
  defaultOptionLabel?: string;
  onChange?: (value?: boolean) => void;
}

const BooleanSelect = forwardRef(
  (
    { value, defaultOptionLabel, onChange, ...selectFieldProps }: BooleanSelectProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <TextField
      {...selectFieldProps}
      inputRef={ref}
      select
      value={value ?? 'none'}
      onChange={(e) => {
        const value = e.target.value;
        onChange?.(value === 'none' ? undefined : value === 'true');
      }}
    >
      <MenuItem value="none">{defaultOptionLabel}</MenuItem>
      <MenuItem value="true">True</MenuItem>
      <MenuItem value="false">False</MenuItem>
    </TextField>
  ),
);

BooleanSelect.displayName = 'BooleanSelect';

export default BooleanSelect;
