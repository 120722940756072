import { usePipeline } from 'api/services/usePipeline/usePipeline';
import useToast from 'contexts/toast/useToast';
import { EditorContextProvider } from 'features/Flow/EditorContext';
import FlowEditor from 'features/Flow/FlowEditor';
import { HandleStoreProvider } from 'features/Flow/Handles/handle.context';
import FlowLoader from 'features/Flow/components/FlowLoader/FlowLoader';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { useAppRoutes } from 'utils/routes';

export default function PipelinePage() {
  const { pipelineId } = useParams();
  const navigate = useNavigate();
  const routes = useAppRoutes();
  const toast = useToast();

  if (!pipelineId) throw new Error('url is missing "id" parameter.');

  const {
    isPipelineLoading,
    pipeline,
    pipelineError,
    mutatePipeline: refreshPipeline,
  } = usePipeline(pipelineId);

  useEffect(() => {
    if (!pipelineError) return;

    toast.error({
      message: `Something went wrong. ${pipelineError.message}`,
    });
    navigate(routes.dashboard());
  }, [pipelineError, navigate, routes, toast]);

  return (
    <ReactFlowProvider>
      {pipeline && (
        <HandleStoreProvider>
          <EditorContextProvider>
            <FlowEditor
              pipeline={pipeline}
              refreshPipeline={async () => {
                await refreshPipeline();
              }}
            />
          </EditorContextProvider>
        </HandleStoreProvider>
      )}

      <FlowLoader open={isPipelineLoading} />
    </ReactFlowProvider>
  );
}
