import { Box, Breadcrumbs as MuiBreadcrumbs, Toolbar, Typography } from '@mui/material';
import { css, styled } from 'styled-components';

export const MuiToolbar = styled(Toolbar)(
  ({ theme }) => css`
    background: ${theme.palette.surface.level1};
    border: solid ${theme.palette.surface.dividerLight};
    border-width: 1px 0;
    display: flex;
    justify-content: space-between;
    min-height: 56px;
    padding: 0 ${theme.spacing(2)};
  `,
);

export const SearchContainer = styled(Box)`
  width: 100%;
`;

export const Breadcrumbs = styled(MuiBreadcrumbs)`
  flex-shrink: 1;

  & .MuiBreadcrumbs-ol {
    flex-wrap: nowrap;
  }

  & .MuiBreadcrumbs-li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .MuiBreadcrumbs-li:first-child {
    max-width: 60vw;
  }
`;

export const BreadcrumbText = styled(Typography)<{ $secondary: boolean }>(
  ({ theme, $secondary }) => css`
    color: ${$secondary ? theme.palette.text.secondary : theme.palette.text.primary};
    text-overflow: ellipsis;

    &:hover {
      color: ${theme.palette.primary.main};
    }
  `,
);

export const LastItem = styled(Box)`
  display: flex;
  font-weight: 500;
  letter-spacing: 0.25px;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  gap: 16px;
  cursor: pointer;
`;
