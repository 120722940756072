export type Order = 'asc' | 'desc';

export function getComparator<Key extends string>(
  order: Order,
  orderBy: Key,
): (a: Partial<Record<Key, unknown>>, b: Partial<Record<Key, unknown>>) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const valueA = getComparatorPropertyValue(a[orderBy]);
  const valueB = getComparatorPropertyValue(b[orderBy]);

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparatorPropertyValue(value?: unknown) {
  if (!value) return 0;

  if (typeof value === 'string') {
    return value.toLowerCase();
  }

  return value;
}
