import { createHandleId, isEdgeOfNode } from 'features/Flow/Flow.utils';
import useFlow from 'features/Flow/hooks/useFlow';
import { useRemoveNodeEdges } from 'features/Flow/hooks/useRemoveNodeEdges';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { PathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { useEffect, useState } from 'react';
import { Edge } from 'reactflow';
import { ConnectionToUpdateName, ObjectBuilderCommitFn } from '../ObjectBuilder.types';
import { ObjectBuilderModalProps } from './ObjectBuilderModal';

interface UseObjectBuilderModalProps {
  nodeId: string;
  inputs: ObjectBuilderModalProps['inputs'];
}

type UseObjectBuilderModalReturn = Omit<ObjectBuilderModalProps, 'inputs'> & {
  openModal: () => void;
};

export const useObjectBuilderModal = ({
  nodeId,
  inputs,
}: UseObjectBuilderModalProps): UseObjectBuilderModalReturn => {
  const { addEdges, deleteElements, getEdges } = useFlow();
  const updateNodeData = useUpdateNodeData();
  const removeNodeEdges = useRemoveNodeEdges({ nodeId });

  const [open, setOpen] = useState<boolean>(false);
  const [edgesToAdd, setEdgesToAdd] = useState<Edge[]>([]);

  const handleCancelChanges = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const updateHandleEdgeConnectionIdChange = (connectionsToUpdate: ConnectionToUpdateName[]) => {
    const nodeEdges = getEdges().filter((edge) => isEdgeOfNode(edge, nodeId));
    const edgesToDelete: Edge[] = [];
    const nextEdgesToAdd = connectionsToUpdate.reduce<Edge[]>((edgeChanges, updateData) => {
      const oldEdge = nodeEdges.find(
        (edge) => edge.targetHandle === createHandleId('input', updateData.oldName),
      );

      if (!oldEdge) {
        return edgeChanges;
      }

      edgesToDelete.push(oldEdge);

      return edgeChanges.concat({
        ...oldEdge,
        targetHandle: createHandleId('input', updateData.newName),
      });
    }, []);

    deleteElements({
      edges: edgesToDelete,
    });
    // Queue restoring the updated input connections.
    setEdgesToAdd(nextEdgesToAdd);
  };

  const handleOnConfirmChanges: ObjectBuilderCommitFn = (
    updated,
    handleIdsToRemove,
    connectionsToUpdate,
  ) => {
    if (handleIdsToRemove.length > 0) {
      removeNodeEdges(handleIdsToRemove);
    }

    if (connectionsToUpdate.length > 0) {
      updateHandleEdgeConnectionIdChange(connectionsToUpdate);
    }

    updateNodeData<PathwayNode['data']>(nodeId, () => ({
      inputs: updated,
    }));

    setOpen(false);
  };

  /**
   * Avoids RF edge warnings by waiting until the updated input is found
   * and then adds a connection.
   */
  useEffect(() => {
    if (!edgesToAdd.length) return;

    const canAddEdges = edgesToAdd.every((edge) =>
      inputs.some((input) => createHandleId('input', input.name) === edge.targetHandle),
    );

    if (!canAddEdges) return;

    addEdges(edgesToAdd);
    setEdgesToAdd([]);
  }, [addEdges, edgesToAdd, inputs]);

  return {
    open,
    nodeId,
    openModal: handleOpenModal,
    onCloseModal: handleCancelChanges,
    onConfirm: handleOnConfirmChanges,
  };
};
