import { useParams } from 'react-router-dom';
import { formatUrlMissingParameterMessage } from 'utils/message';

export default function usePipelineVersionId(): string {
  const { versionId } = useParams();

  if (!versionId) {
    throw new Error(formatUrlMissingParameterMessage('versionId'));
  }

  return versionId;
}
