import { Paper } from '@mui/material';
import { Chip as MuiChip } from '@mui/material';
import styled, { css } from 'styled-components';

export const ChipContainer = styled(Paper)`
  padding-left: ${({ theme }) => theme.spacing(0.75)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};

  border-radius: 100px;
  display: flex;
  align-items: center;
`;

export const Chip = styled(MuiChip)<{ $state: 'draft' | 'published' }>(({ theme, $state }) => {
  return css`
    margin-right: ${theme.spacing(1)};
    text-transform: capitalize;

    color: ${$state === 'published' ? theme.palette.success.main : theme.palette.text.primary};
    background-color: ${$state === 'published'
      ? theme.palette.success.selected
      : theme.palette.action.selected};
  `;
});
