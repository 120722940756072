import usePipelineExecutionCheckpoints from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints';
import { CheckpointStatus } from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints.types';
import Loader from 'components/Loader/Loader';
import { useEffect } from 'react';
import { ExecutionCheckpointProps } from './ExecutionCheckpoint.type';
import ExecutionCheckpointForm from './ExecutionCheckpointForm';
import useExecutionCheckpointContext from './contexts/useExecutionCheckpointContext';

const ExecutionCheckpoint = ({
  pipelineExecutionId,
  completedCheckpoints,
  totalCheckpoints,
}: ExecutionCheckpointProps) => {
  const { checkpoints, areCheckpointsLoading } =
    usePipelineExecutionCheckpoints(pipelineExecutionId);
  const pendingCheckpoint = checkpoints.find(
    (checkpoint) => checkpoint.status === CheckpointStatus.PENDING,
  );
  const { setPipelineExecutionId } = useExecutionCheckpointContext();

  useEffect(() => {
    setPipelineExecutionId(pipelineExecutionId);
  }, [pipelineExecutionId, setPipelineExecutionId]);

  return (
    <Loader loading={areCheckpointsLoading} height="48px">
      {pendingCheckpoint && (
        <ExecutionCheckpointForm
          // The key is important in having the form re-render
          key={pendingCheckpoint.id}
          checkpoint={pendingCheckpoint}
          totalCheckpoints={totalCheckpoints}
          completedCheckpoints={completedCheckpoints}
        />
      )}
    </Loader>
  );
};

export default ExecutionCheckpoint;
