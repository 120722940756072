import { useMemo } from 'react';
import { useNodes } from 'reactflow';
import { FlowNodeData } from 'types/reactflow';
import { isJobNode } from 'utils/neurons';

export default function useJobNodes() {
  const nodes = useNodes<FlowNodeData>();

  return useMemo(() => nodes.filter(isJobNode), [nodes]);
}
