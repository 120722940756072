import { Box, Chip, alpha } from '@mui/material';
import { Color } from 'components/ColorField/ColorField.consts';
import { ACTION_COLOR } from 'config/theme';
import styled, { css } from 'styled-components';
import { GROUP_NAME_CLASS_NAME } from './GroupName';
import { GROUP_DESCRIPTION_CLASS_NAME } from './GroupDescription/GroupDescription';
import { blueGrey } from '@mui/material/colors';
import { GROUP_DRAG_HOVER_CLASSNAME } from 'features/Flow/hooks/useGroupDragEvents';

export const Container = styled(Box)<{
  color: string;
  selected: boolean;
  $extraMargin: number;
}>(({ theme, selected, color, $extraMargin }) => {
  const hasPrimaryTextColor = ([Color.ORANGE] as string[]).includes(color);
  const selectedTextColor = hasPrimaryTextColor
    ? theme.palette.text.primary
    : theme.palette.getContrastText(color);

  return css`
    &.${GROUP_DRAG_HOVER_CLASSNAME} {
      border: 2px dashed ${blueGrey[400]};
      background-color: ${blueGrey[700]};
    }

    border: 2px solid var(--box-color, none);
    border-radius: ${theme.spacing(3)};
    text-align: left;
    transition:
      border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-top: -${$extraMargin}px;

    ${selected
      ? css`
          --box-color: ${color};
          background-color: ${ACTION_COLOR.selected(color)};

          .${GROUP_NAME_CLASS_NAME} {
            color: ${selectedTextColor};
            background-color: ${color};
          }

          .${GROUP_DESCRIPTION_CLASS_NAME} {
            color: ${selectedTextColor};
          }
        `
      : css`
          --box-color: ${ACTION_COLOR.selected(color)};
          background-color: ${alpha(color, 0.04)};

          .${GROUP_NAME_CLASS_NAME} {
            color: ${color};
            background-color: ${ACTION_COLOR.hover(color)};
          }

          &:hover {
            --box-color: ${alpha(color, 0.32)};
            background-color: ${ACTION_COLOR.hover(color)};

            .${GROUP_NAME_CLASS_NAME} {
              background-color: ${ACTION_COLOR.selected(color)};
            }
          }
        `};
  `;
});

export const NameChip = styled(Chip)`
  position: absolute;
  transform: translateY(calc(-100% - 4px));
  transition:
    color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
