import { Stack, Button } from '@mui/material';
import { ArrayDataSchema } from '@pathways/pipeline-schema';
import * as Styled from 'components/Dialog/Dialog.styles';
import {
  createDefaultValues,
  createDummyFieldDataSchema,
} from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuron.utils';
import DummyNeuronFormFields from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/DummyNeuronModalFormFields';
import { DataType } from 'features/Flow/nodes/Node/DataType';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

export interface DummyNeuronFormData {
  name: string;
  dataType: DataType;
  arrayDataType?: ArrayDataSchema['items']['type'];
  title?: string;
  mediaType?: string | null;
}

interface DummyNeuronModalCreateProps {
  items: NodeInput[] | NodeOutput[];
  type: 'input' | 'output';
  onCancel: () => void;
  onConfirm: (item: NodeInput | NodeOutput) => void;
  defaultValues?: NodeInput | NodeOutput | null;
  dataTypeDisabled?: boolean;
}

const DummyNeuronModalCreate = ({
  items,
  type,
  onCancel,
  onConfirm,
  defaultValues = null,
  dataTypeDisabled = false,
}: DummyNeuronModalCreateProps) => {
  const form = useForm<DummyNeuronFormData>({
    defaultValues: createDefaultValues(defaultValues),
    mode: 'onBlur',
  });
  const {
    formState: { isValid },
  } = form;

  const submitForm: SubmitHandler<DummyNeuronFormData> = useCallback(
    (data) => {
      const dataSchema = createDummyFieldDataSchema(data);

      onConfirm({
        name: data.name,
        dataSchema,
        title: data.title ?? '',
        config: {},
      });
    },
    [onConfirm],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  return (
    <FormProvider {...form}>
      <Styled.DialogTitle $hasBorder>Create {type}</Styled.DialogTitle>
      <Styled.DialogContent>
        <DummyNeuronFormFields items={items} isConnected={dataTypeDisabled} />
      </Styled.DialogContent>
      <Styled.DialogActions>
        <Stack direction="row">
          <Button variant="text" sx={{ color: 'common.white' }} onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!isValid}>
            Confirm
          </Button>
        </Stack>
      </Styled.DialogActions>
    </FormProvider>
  );
};

export default DummyNeuronModalCreate;
