import {
  isBatchChildNode,
  isBatchEndNode,
  isBatchGroupNode,
  isBatchStartNode,
} from 'features/Flow/nodes/Batch/Batch.types';
import { FlowNode } from 'types/reactflow';
import { useState, useEffect } from 'react';
import useFlow from 'features/Flow/hooks/useFlow';
import { canNodesBeAddedToBatch, canNodesBeGrouped } from 'features/Flow/Flow.utils';
import { isGroupChildNode } from 'features/Flow/nodes/Group/Group.types';

export const useSelectedNodesActions = (selectedNodes: FlowNode[]) => {
  const [canBeGrouped, setCanBeGrouped] = useState(false);
  const [canBeRemovedFromGroup, setCanBeRemovedFromGroup] = useState(false);
  const [canBeRemovedFromBatch, setCanBeRemovedFromBatch] = useState(false);
  const [canBeAddedToBatch, setCanBeAddedToBatch] = useState(false);

  const { getNodes, getEdges } = useFlow();

  useEffect(() => {
    const nodes = getNodes();
    const edges = getEdges();

    const checkCanBeGrouped = () => {
      setCanBeGrouped(canNodesBeGrouped(selectedNodes, nodes));
    };

    const checkCanBeRemovedFromGroup = () => {
      const canBeRemovedFromGroup = selectedNodes.every((node) => isGroupChildNode(node, nodes));
      setCanBeRemovedFromGroup(canBeRemovedFromGroup);
    };

    const checkCanBeRemovedFromBatch = () => {
      const canBeRemovedFromBatch = selectedNodes.every(
        (node) => isBatchChildNode(node, nodes) && !isBatchStartNode(node) && !isBatchEndNode(node),
      );
      setCanBeRemovedFromBatch(canBeRemovedFromBatch);
    };

    const checkCanBeAddedToBatch = () => {
      const selectedBatchGroups = selectedNodes.filter(isBatchGroupNode);
      const selectedBatchGroup =
        selectedBatchGroups.length === 1 ? selectedBatchGroups[0] : undefined;

      if (!selectedBatchGroup) {
        setCanBeAddedToBatch(false);
        return;
      }

      setCanBeAddedToBatch(
        canNodesBeAddedToBatch({ batchGroup: selectedBatchGroup, selectedNodes, edges }),
      );
    };

    checkCanBeGrouped();
    checkCanBeRemovedFromGroup();
    checkCanBeRemovedFromBatch();
    checkCanBeAddedToBatch();
  }, [selectedNodes, getNodes, getEdges]);

  return {
    canBeGrouped,
    canBeRemovedFromGroup,
    canBeRemovedFromBatch,
    canBeAddedToBatch,
  };
};
