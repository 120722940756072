import { DataSchemaCompatibilityCheck } from '../DataType';
import { BooleanDataSchema, DataSchema } from '@pathways/pipeline-schema';

export const booleanCompatibilityCheck: DataSchemaCompatibilityCheck<BooleanDataSchema> = ({
  output,
}) => {
  return output.type === 'boolean';
};

export function isBooleanDataSchema(schema: DataSchema): schema is BooleanDataSchema {
  return schema.type === 'boolean';
}
