import { KeyCode } from 'reactflow';
import { isMacOs } from 'utils/browser';

interface ShortcutConfig {
  keyCode: KeyCode;
  caption: string;
}

/** @see https://github.com/xyflow/xyflow/blob/v11/packages/core/src/hooks/useKeyPress.ts */
export const SHORTCUT_CONFIG = {
  copy: createShortcutConfig({
    mac: {
      keyCode: 'Meta+c',
    },
    other: {
      keyCode: 'Control+c',
    },
  }),
  paste: createShortcutConfig({
    mac: {
      keyCode: 'Meta+v',
    },
    other: {
      keyCode: 'Control+v',
    },
  }),
  delete: createShortcutConfig({
    mac: {
      keyCode: 'Backspace',
    },
    other: {
      keyCode: ['Backspace', 'Delete'],
    },
  }),
  createGroup: createShortcutConfig({
    mac: {
      keyCode: 'Meta+g',
    },
    other: {
      keyCode: 'Control+g',
    },
  }),
  ungroup: createShortcutConfig({
    mac: {
      keyCode: 'Meta+Shift+g',
    },
    other: {
      keyCode: 'Control+Shift+g',
    },
  }),
  ungroupDrag: createShortcutConfig({
    mac: {
      keyCode: 'Meta',
    },
    other: {
      keyCode: 'Control',
    },
  }),
} satisfies Record<string, ShortcutConfig>;

interface ShortcutParams {
  mac: ShortcutParam;
  other: ShortcutParam;
}

interface ShortcutParam {
  keyCode: KeyCode;
}

export function createShortcutConfig(params: ShortcutParams): ShortcutConfig {
  return {
    keyCode: getShortcutKeyCode(params),
    caption: getShortcutCaption(params),
  };
}

function getShortcutKeyCode({ mac, other }: ShortcutParams): KeyCode {
  if (isMacOs()) {
    return mac.keyCode;
  }

  return other.keyCode;
}

function getShortcutCaption({ mac, other }: ShortcutParams): string {
  const param = isMacOs() ? mac : other;
  const keyCodeArr = Array.isArray(param.keyCode) ? param.keyCode : [param.keyCode];

  return keyCodeArr.map((keyCode) => formatCaption(keyCode)).join(' / ');
}

function formatCaption(keyCode: string) {
  const caption = keyCode
    .toUpperCase()
    .replace(/Shift/i, '⇧')
    .replace(/Delete/i, 'Del');

  if (isMacOs()) {
    return formatMacCaption(caption);
  }

  return formatOtherCaption(caption);
}

function formatMacCaption(caption: string) {
  return caption
    .replaceAll('+', '')
    .replace(/Meta/i, '⌘')
    .replace(/Control/i, '⌃')
    .replace(/Backspace/i, '⌫');
}

function formatOtherCaption(caption: string) {
  return caption
    .replace(/Meta/i, '⊞')
    .replace(/Control/i, 'Ctrl')
    .replace(/Backspace/i, '←');
}
