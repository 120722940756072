import { Accordion, AccordionSummary } from '@mui/material';
import styled from 'styled-components';

export const OutputAccordion = styled(Accordion)`
  background-color: transparent;
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  transition: unset;

  &.Mui-expanded:first-of-type {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  &:before {
    background-color: unset;
    transition: unset;
    position: unset;
    content: unset;
    top: unset;
  }
`;

export const OutputAccordionSummary = styled(AccordionSummary)`
  & .Mui-expanded:first-of-type {
    margin-top: 0;
  }

  & .MuiAccordionSummary-content:first-of-type {
    margin-top: ${({ theme }) => theme.spacing(1.5)};
  }
`;
