import { SvgIcon, SvgIconProps } from '@mui/material';

const ImageArrayIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.46429 16.5C8.19911 16.5 7.9721 16.404 7.78326 16.2121C7.59442 16.0201 7.5 15.7946 7.5 15.5357V8.46429C7.5 8.20536 7.59442 7.97991 7.78326 7.78795C7.9721 7.59598 8.19911 7.5 8.46429 7.5H15.5357C15.8009 7.5 16.0279 7.59598 16.2167 7.78795C16.4056 7.97991 16.5 8.20536 16.5 8.46429V15.5357C16.5 15.7946 16.4056 16.0201 16.2167 16.2121C16.0279 16.404 15.8009 16.5 15.5357 16.5H8.46429ZM9.58929 14.5714H14.4133C14.5098 14.5714 14.5804 14.5268 14.625 14.4375C14.6696 14.3482 14.6607 14.2634 14.5982 14.183L13.1518 12.2545C13.1049 12.183 13.0424 12.1473 12.9643 12.1473C12.8862 12.1473 12.8237 12.183 12.7768 12.2545L11.5179 13.9286L10.7411 12.8973C10.6942 12.8259 10.6317 12.7902 10.5536 12.7902C10.4754 12.7902 10.4129 12.8259 10.3661 12.8973L9.40422 14.1834C9.3401 14.2635 9.33147 14.3482 9.37835 14.4375C9.42522 14.5268 9.49554 14.5714 9.58929 14.5714Z"
        fill="black"
      />
      <path
        d="M4.40625 18.8438C4.84375 19.2812 5.375 19.5 6 19.5H7.5C7.7125 19.5 7.89062 19.4281 8.03438 19.2844C8.17812 19.1406 8.25 18.9625 8.25 18.75C8.25 18.5375 8.17812 18.3594 8.03438 18.2156C7.89062 18.0719 7.7125 18 7.5 18H6C5.7875 18 5.60937 17.9281 5.46562 17.7844C5.32187 17.6406 5.25 17.4625 5.25 17.25V6.75C5.25 6.5375 5.32187 6.35938 5.46562 6.21563C5.60937 6.07188 5.7875 6 6 6H7.5C7.7125 6 7.89062 5.92813 8.03438 5.78437C8.17812 5.64062 8.25 5.4625 8.25 5.25C8.25 5.0375 8.17812 4.85938 8.03438 4.71563C7.89062 4.57188 7.7125 4.5 7.5 4.5H6C5.375 4.5 4.84375 4.71875 4.40625 5.15625C3.96875 5.59375 3.75 6.125 3.75 6.75V17.25C3.75 17.875 3.96875 18.4062 4.40625 18.8438Z"
        fill="black"
      />
      <path
        d="M19.5938 18.8438C19.1562 19.2812 18.625 19.5 18 19.5H16.5C16.2875 19.5 16.1094 19.4281 15.9656 19.2844C15.8219 19.1406 15.75 18.9625 15.75 18.75C15.75 18.5375 15.8219 18.3594 15.9656 18.2156C16.1094 18.0719 16.2875 18 16.5 18H18C18.2125 18 18.3906 17.9281 18.5344 17.7844C18.6781 17.6406 18.75 17.4625 18.75 17.25V6.75C18.75 6.5375 18.6781 6.35938 18.5344 6.21563C18.3906 6.07188 18.2125 6 18 6H16.5C16.2875 6 16.1094 5.92813 15.9656 5.78437C15.8219 5.64062 15.75 5.4625 15.75 5.25C15.75 5.0375 15.8219 4.85938 15.9656 4.71563C16.1094 4.57188 16.2875 4.5 16.5 4.5H18C18.625 4.5 19.1562 4.71875 19.5938 5.15625C20.0313 5.59375 20.25 6.125 20.25 6.75V17.25C20.25 17.875 20.0313 18.4062 19.5938 18.8438Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default ImageArrayIcon;
