import { Config, useRequest } from 'api/client';
import { useMemo } from 'react';
import { handleLegacyData } from 'utils/mappings';
import { ExecutionJobResponse, PipelineJobsResponse } from './usePipelineJobs.types';

const usePipelineJobs = (pipelineExecutionId?: string, config?: Config<PipelineJobsResponse>) => {
  const { isLoading, data, error, mutate, ...response } = useRequest<PipelineJobsResponse>(
    {
      url: pipelineExecutionId ? `/pipeline-executions/${pipelineExecutionId}/jobs` : undefined,
    },
    config,
  );

  const pipelineJobs = useMemo<ExecutionJobResponse[]>(() => {
    if (!data) {
      return [];
    }

    return data.jobs.map(handleLegacyData);
  }, [data]);

  return {
    ...response,
    arePipelineJobsLoading: isLoading,
    pipelineJobs,
    pipelineJobsError: error,
    queryPipelineJobs: mutate,
  };
};

export default usePipelineJobs;
