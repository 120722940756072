import { DataManipulatorFormData, DataManipulatorModalProps } from '../DataManipulator.types';
import * as Styled from 'components/Dialog/Dialog.styles';
import { Button, Stack } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import DataManipulatorForm from './DataManipulatorForm';
import { useCallback } from 'react';
import {
  createDynamicSchemaInput,
  createFormDefaults,
  createDataOutput,
} from '../DataManipulator.utils';
import { DATA_MENU_ITEMS, getArraySchemaWithDataType } from '../../Function.types';
import { getSchemaByDataType } from 'utils/dataSchema';

export default function DataManipulatorModal({
  inputs,
  outputs,
  open,
  onCloseModal,
  onConfirm,
  disableInputType,
  disableOutputType,
}: DataManipulatorModalProps) {
  const form = useForm<DataManipulatorFormData>({
    defaultValues: createFormDefaults({ inputs, outputs }),
  });

  const submitForm: SubmitHandler<DataManipulatorFormData> = useCallback(
    (data) => {
      const inputDataSchema =
        data.input === 'array'
          ? getArraySchemaWithDataType('object')
          : getSchemaByDataType(data.input, DATA_MENU_ITEMS);

      const outputDataSchema =
        data.output === 'array'
          ? getArraySchemaWithDataType(data.outputArrayType)
          : getSchemaByDataType(data.output, DATA_MENU_ITEMS);

      const dynamicSchemaInput = createDynamicSchemaInput(outputDataSchema);

      const updatedInputs = inputs.map((input) => {
        if (input.name === 'options') {
          return { ...input, value: { slurp: data.slurp } };
        }

        if (input.name === 'query') {
          return { ...input, value: data.query };
        }

        if (input.name === 'data') {
          return { ...input, dataSchema: inputDataSchema };
        }

        if (input.name === 'outputSchema') {
          return dynamicSchemaInput;
        }

        return input;
      });

      if (!updatedInputs.find((input) => input.name === dynamicSchemaInput.name)) {
        updatedInputs.push(dynamicSchemaInput);
      }

      const updatedOutputs = outputs.map((output) => {
        if (output.name === 'data') {
          return { ...output, dataSchema: outputDataSchema };
        }

        return output;
      });

      const input = updatedInputs.find((input) => input.name === 'data');
      const output = updatedOutputs.find((output) => output.name === 'data');

      if (!input) {
        updatedInputs.push(createDataOutput(inputDataSchema, 'Data'));
      }

      if (!output) {
        updatedOutputs.push(createDataOutput(outputDataSchema, 'Queried data'));
      }

      onConfirm(updatedInputs, updatedOutputs);
    },
    [inputs, onConfirm, outputs],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  return (
    <FormProvider {...form}>
      <Styled.Dialog
        open={open}
        onClose={onCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Styled.DialogTitle $hasBorder={true}>Configure Data Manipulator</Styled.DialogTitle>
        <Styled.DialogContent>
          <DataManipulatorForm
            disableInputType={disableInputType}
            disableOutputType={disableOutputType}
          />
        </Styled.DialogContent>
        <Styled.DialogActions $hasBorder={true}>
          <Stack direction="row">
            <Button variant="text" sx={{ color: 'common.white' }} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button onClick={handleConfirm}>Confirm</Button>
          </Stack>
        </Styled.DialogActions>
      </Styled.Dialog>
    </FormProvider>
  );
}
