import { Box, TextField } from '@mui/material';
import GroupPanel from 'features/Flow/components/RightSidebar/components/GroupPanel/GroupPanel';
import StartOrCompletePanel from 'features/Flow/components/RightSidebar/components/StartOrCompletePanel/StartOrCompletePanel';
import { isBatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';
import { isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import { isPathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { isPipelineCompleteNode } from 'features/Flow/nodes/PipelineComplete/PipelineComplete.types';
import { isPipelineStartNode } from 'features/Flow/nodes/PipelineStart/PipelineStart.types';
import { useNodes } from 'reactflow';
import { FlowNodeData } from 'types/reactflow';
import * as Styled from 'features/Flow/components/RightSidebar/RightSidebar.styles';
import PathwayNodePanelHeader from 'features/Flow/components/RightSidebar/components/PathwayNodePanelHeader/PathwayNodePanelHeader';
import { getJobTitle } from 'utils/neurons';
import { NodeType } from 'features/Flow/Flow.types';
import TabContainer from 'components/TabContainer/TabContainer';
import Inputs from 'features/Flow/components/RightSidebar/components/Inputs/Inputs';
import Outputs from 'features/Flow/components/RightSidebar/components/Outputs/Outputs';

const RightSidebar = () => {
  const nodes = useNodes<FlowNodeData>();
  const selectedNode = nodes.filter((node) => node.selected).at(0);

  if (isGroupNode(selectedNode) || isBatchGroupNode(selectedNode)) {
    return <GroupPanel groupNode={selectedNode} readOnly />;
  }

  if (isPipelineStartNode(selectedNode) || isPipelineCompleteNode(selectedNode)) {
    return <StartOrCompletePanel selectedNode={selectedNode} />;
  }

  if (!isPathwayNode(selectedNode)) return null;

  return (
    <Box width="100%">
      <Styled.HeaderActionBar>
        <Styled.HeaderActionColumn>
          <PathwayNodePanelHeader selectedNode={selectedNode} />
        </Styled.HeaderActionColumn>
      </Styled.HeaderActionBar>

      <TextField
        name="customTitle"
        size="small"
        fullWidth
        label={selectedNode.type === NodeType.CHECKPOINT ? 'Checkpoint Name' : 'Neuron Name'}
        variant="standard"
        value={getJobTitle(selectedNode.data.metadata)}
        disabled
      />

      <Styled.SectionDivider />

      <TabContainer
        tabPanelChildren={[
          <Inputs
            inputs={selectedNode.data.inputs}
            key={selectedNode.id}
            selectedNode={selectedNode}
            readOnly
          />,
          <Outputs key={selectedNode.id} selectedNode={selectedNode} />,
        ]}
        tabs={['Input', 'Output']}
      />
    </Box>
  );
};

export default RightSidebar;
