import { Grid, Typography } from '@mui/material';
import { InputProps } from './Inputs.types';
import InputByType from 'features/Flow/components/RightSidebar/components/Inputs/InputByType';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Styled from './Inputs.styles';
import { isForceHidden } from '../../RightSidebar.utils';

const Inputs = (props: InputProps) => {
  const { selectedNode, readOnly = false } = props;

  const usableInputs = props.inputs?.filter((inp) => !isForceHidden(inp)) ?? [];
  const visibleInputs = usableInputs.filter((input) => !input.config.hidden);
  const hiddenInputs = usableInputs.filter((input) => input.config.hidden);
  const allInputsRequired = props.inputs?.every((input) => input.config.required);

  return (
    <>
      <Grid container gap={2} marginBottom={4}>
        <Typography variant="titleMedium">Input Sources</Typography>
        {visibleInputs.map((input) => (
          <InputByType
            key={input.name}
            selectedNode={selectedNode}
            input={input}
            readOnly={readOnly}
          />
        ))}
      </Grid>
      <Styled.HiddenInputsAccordion key={selectedNode.id} disabled={allInputsRequired}>
        <Styled.HiddenInputsAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="bodyMedium">
            Hidden Input Properties {hiddenInputs.length > 0 && `(${hiddenInputs.length})`}
          </Typography>
        </Styled.HiddenInputsAccordionSummary>
        <Styled.HiddenInputsAccordionDetails>
          <Grid container gap={'16px'}>
            {hiddenInputs.map((input) => (
              <InputByType
                key={input.name}
                selectedNode={selectedNode}
                input={input}
                readOnly={readOnly}
              />
            ))}
            {hiddenInputs.length === 0 && (
              <Typography variant="bodySmall" color="text.secondary">
                No hidden input properties to show.
              </Typography>
            )}
          </Grid>
        </Styled.HiddenInputsAccordionDetails>
      </Styled.HiddenInputsAccordion>
    </>
  );
};

export default Inputs;
