import { Box } from '@mui/material';
import styled, { css } from 'styled-components';

export const PaginationContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacing(4)};
    padding: ${theme.spacing(1)};
    border-radius: ${theme.spacing(2)};
    background-color: ${theme.palette.surface.level1};
  `,
);
