import { NodeInput } from '../../../Node/Node.types';
import { Box, FormGroup, MenuItem, Stack, Typography } from '@mui/material';
import { Control, useFormContext } from 'react-hook-form';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import { ARRAY_TYPE_MENU_ITEMS, DATA_MENU_ITEMS } from '../../Function.types';
import { NodeInputFormData } from '../ObjectBuilder.types';
import DataSchemaIcon from 'components/DataSchemaIcon/DataSchemaIcon';

interface ObjectBuilderFormProps {
  control: Control<NodeInputFormData>;
  otherInputs: NodeInput[];
  isConnected: boolean;
  isPendingConnection?: boolean;
}

const ObjectBuilderForm = ({
  control,
  otherInputs,
  isConnected,
  isPendingConnection,
}: ObjectBuilderFormProps) => {
  const { formState, watch } = useFormContext<NodeInputFormData>();

  const validateDuplicateName = (name?: string | null) => {
    if (!otherInputs.filter((n) => n.name === name).length) {
      return true;
    }
    return 'Cannot contain duplicate names.';
  };

  return (
    <FormGroup>
      <Stack direction="column" spacing={1}>
        <RHFTextField
          name="name"
          control={control}
          fullWidth
          label="Key"
          error={!!formState.errors.name}
          helperText={formState.errors.name?.message}
          required
          rules={{
            required: 'This field is required.',
            validate: validateDuplicateName,
          }}
        />
        <RHFTextField name="title" control={control} fullWidth label="Title" />
        <RHFTextField
          select
          name="dataType"
          control={control}
          label="Data Type"
          required
          fullWidth
          helperText={
            isConnected || isPendingConnection
              ? 'You cannot update the data type of an input with a connection.'
              : formState.errors.name?.message
          }
          rules={{ required: 'This field is required.' }}
          disabled={isConnected || isPendingConnection}
        >
          {DATA_MENU_ITEMS.map(({ schema, title }) => {
            return (
              <MenuItem key={schema.type} value={schema.type}>
                <Box display="flex" alignItems="center">
                  <DataSchemaIcon size={'20px'} dataSchema={schema} />{' '}
                  <Typography variant="bodyLarge" sx={{ ml: 1 }}>
                    {title}
                  </Typography>
                </Box>
              </MenuItem>
            );
          })}
        </RHFTextField>
        {watch('dataType') === 'array' && (
          <RHFTextField
            select
            name="arrayDataType"
            control={control}
            label="Array Data Type"
            required
            fullWidth
            defaultValue="text"
            helperText={
              isConnected || isPendingConnection
                ? 'You cannot update the data type of an input with a connection.'
                : formState.errors.name?.message
            }
            rules={{ required: 'This field is required.' }}
            disabled={isConnected || isPendingConnection}
          >
            {ARRAY_TYPE_MENU_ITEMS.map(({ schema, title }) => (
              <MenuItem key={schema.type} value={schema.type}>
                <Box display="flex" alignItems="center">
                  <DataSchemaIcon size={'20px'} dataSchema={schema} />{' '}
                  <Typography variant="bodyLarge" sx={{ ml: 1 }}>
                    {title}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </RHFTextField>
        )}
      </Stack>
    </FormGroup>
  );
};

export default ObjectBuilderForm;
