import { NodeInput, NodeObjectValue, SignedAssetObject } from 'features/Flow/nodes/Node/Node.types';

export enum CheckpointStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export const CheckpointStatusReadable: Record<CheckpointStatus, string> = {
  [CheckpointStatus.APPROVED]: 'Approved',
  [CheckpointStatus.PENDING]: 'Pending',
  [CheckpointStatus.REJECTED]: 'Rejected',
};

export interface CheckpointMetadata {
  description: string;
  jobId: string;
  key: string;
  maintainers: string[];
  name: string;
  customTitle?: string;
  tags: string[];
}

export type CheckpointInputValue =
  | string
  | string[]
  | NodeObjectValue
  | NodeObjectValue[]
  | SignedAssetObject
  | SignedAssetObject[];

export type CheckpointInput = Record<string, CheckpointInputValue>;

export interface CheckpointData {
  inputs: CheckpointInput;
}

export interface Checkpoint {
  id: string;
  completedAt?: string;
  createdAt: string;
  data: CheckpointData;
  inputs: NodeInput[];
  metadata: CheckpointMetadata;
  status: CheckpointStatus;
  updatedAt: string;
  comment?: string | null;
  reviewedById?: string | null;
}

export interface PipelineExecutionCheckpointResponse {
  checkpoints: Checkpoint[];
}
