import useFlow from 'features/Flow/hooks/useFlow';
import { useCallback } from 'react';
import { getConnectedEdges, useStoreApi } from 'reactflow';
import { FlowNode } from 'types/reactflow';
import { mapNodeOutsideGroup } from 'utils/mappings';
import { createNodeUpdateChange } from '../changes/NodeUpdateChange';
import { isBatchChildNode } from '../nodes/Batch/Batch.types';

const useRemoveNodesFromGroup = () => {
  const { getNodes, getEdges, deleteElements } = useFlow();
  const storeApi = useStoreApi();

  return useCallback(
    (selectedNodes: FlowNode[]) => {
      const nodes = getNodes();
      const edges = getEdges();

      const edgesToDelete: typeof edges = [];
      const nodeChanges = selectedNodes.map((node) => {
        if (isBatchChildNode(node, nodes)) {
          const nodeEdges = getConnectedEdges([node], edges);
          edgesToDelete.push(...nodeEdges);
        }

        return createNodeUpdateChange({
          nodeId: node.id,
          updater: mapNodeOutsideGroup,
        });
      });

      deleteElements({
        edges: edgesToDelete,
      });
      storeApi.getState().onNodesChange?.(nodeChanges);
    },
    [deleteElements, getEdges, getNodes, storeApi],
  );
};

export default useRemoveNodesFromGroup;
