import { Box } from '@mui/material';
import styled, { css } from 'styled-components';
import { JobTypeIconProps } from './JobTypeIcon.types';

export const IconContainer = styled(Box)<Pick<JobTypeIconProps, 'variant'>>`
  display: inline-flex;

  ${(props) => {
    if (props.variant === 'filled') {
      return css`
        padding: 6px;
        border-radius: 50%;
      `;
    }
  }}
`;
