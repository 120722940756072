import BackButton from 'components/BackButton/BackButton';
import NavigationBar from 'components/NavigationBar/NavigationBar';

import { FlowPipeline } from 'types/reactflow';
import { useAppRoutes } from 'utils/routes';
import usePipelineExecutionId from 'hooks/usePipelineExecutionId';
import ViewExecutionNavDetails from './ViewExecutionNavDetails';
import { useViewExecutionRequests } from '../../useViewExecutionRequests';

interface ViewExecutionNavProps {
  pipeline: FlowPipeline;
}

const ViewExecutionNav = ({ pipeline }: ViewExecutionNavProps) => {
  const { name, id } = pipeline;
  const routes = useAppRoutes();
  const executionId = usePipelineExecutionId();

  const { pipelineExecutionRequest } = useViewExecutionRequests(executionId);
  const { pipelineExecution, isPipelineExecutionLoading } = pipelineExecutionRequest;
  return (
    <NavigationBar
      title={name}
      firstBreadcrumb={{
        label: <BackButton to={routes.executions.runs(id)} />,
      }}
      hideHome={true}
    >
      <ViewExecutionNavDetails
        loading={isPipelineExecutionLoading}
        pipelineExecution={pipelineExecution}
      />
    </NavigationBar>
  );
};
export default ViewExecutionNav;
