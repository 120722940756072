import { Autocomplete, Chip } from '@mui/material';
import { AutocompleteEndAdornmentBox } from 'components/Inputs/Autocomplete/Autocomplete.styles';
import VisibilityIconButton from 'features/Flow/components/RightSidebar/components/Inputs/VisibilityIconButton';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import { Edge } from 'reactflow';
import { VisibilityProps } from './Inputs.types';
import TextField, { type TextFieldProps } from 'components/Inputs/TextField/TextField';
import { getFieldValue } from 'components/Inputs/TextField/TextField.utils';

interface TextValueProps {
  valueAs?: never;
  value: string[];
}

interface NumericValueProps {
  valueAs: 'integer' | 'number';
  value: number[];
}

type RightSidebarAutocompleteProps = (TextValueProps | NumericValueProps) & {
  edge?: Edge;
  error?: boolean;
  helperText?: TextFieldProps['helperText'];
  input: NodeInput;
  label: React.ReactNode;
  readOnly?: boolean;
  visibilityProps: VisibilityProps;
  onChange(event: React.SyntheticEvent, values: string[]): void;
};

const RightSidebarAutocomplete = ({
  edge,
  error = false,
  helperText: helperTextProp,
  input,
  label,
  readOnly = false,
  valueAs,
  value,
  visibilityProps,
  onChange,
}: RightSidebarAutocompleteProps) => {
  const values = Array.isArray(value) ? value.map((v) => v.toString()) : [];
  const canBeHidden = !input.config.required;

  return (
    <Autocomplete
      multiple
      value={values}
      options={[]}
      freeSolo
      readOnly={readOnly}
      onChange={onChange}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => {
          const tagProps = getTagProps({ index });
          return (
            <Chip
              {...tagProps}
              key={tagProps.key}
              color="primary"
              label={option}
              size="small"
              variant="filled"
            />
          );
        })
      }
      renderInput={(params) => {
        // To display the `clear (X)` icon, `endAdornment` cannot be assigned on the props, not even as 'undefined'.
        const additionalInputProps: TextFieldProps['InputProps'] = canBeHidden
          ? {
              endAdornment: (
                <AutocompleteEndAdornmentBox>
                  <VisibilityIconButton
                    required={input.config.required}
                    hidden={input.config.hidden}
                    visible={visibilityProps.isHideIconVisible}
                    edge={edge}
                    onClick={visibilityProps.onVisibilityIconClick}
                  />
                </AutocompleteEndAdornmentBox>
              ),
              sx: {
                '&.MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd.MuiInput-root': {
                  // Matches the width of the icon + 3px spacing.
                  paddingRight: `${34 + 3}px`,
                },
              },
            }
          : {
              sx: {
                '&.MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd.MuiInput-root': {
                  // Matches the width of the icon + 3px spacing.
                  paddingRight: `${30 + 3}px`,

                  '.MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator': {
                    marginRight: '3px',
                  },
                },
              },
            };

        const placeholder =
          (Array.isArray(input.value) && input.value.length > 0) || readOnly
            ? ''
            : 'Enter or connect data...';

        const helperText = helperTextProp ? helperTextProp : 'Separate values by pressing Enter';

        const inputValue = params.inputProps.value ?? '';
        const fieldValue = Array.isArray(inputValue)
          ? inputValue
          : getFieldValue(inputValue.toString(), valueAs);

        return (
          <TextField
            {...params}
            {...visibilityProps.hoverEvents}
            valueAs={valueAs}
            name={input.name}
            label={label}
            required={input.config.required}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              ...additionalInputProps,
            }}
            inputProps={{
              ...params.inputProps,
              value: fieldValue,
            }}
          />
        );
      }}
    />
  );
};

export default RightSidebarAutocomplete;
