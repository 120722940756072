import { NodeInput } from '../../../Node/Node.types';
import { useState } from 'react';
import ObjectBuilderList from './ObjectBuilderList';
import ObjectBuilderEditForm from '../ObjectBuilderForms/ObjectBuilderEditForm';
import ObjectBuilderCreateForm from '../ObjectBuilderForms/ObjectBuilderCreateForm';
import { createHandleId } from 'features/Flow/Flow.utils';
import {
  ConnectionToUpdateName,
  DEFAULT_VIEW,
  ObjectBuilderCommitFn,
  ViewStates,
} from '../ObjectBuilder.types';
import { arrayMove } from '@dnd-kit/sortable';
import * as Styled from 'components/Dialog/Dialog.styles';

export interface ObjectBuilderModalProps {
  nodeId: string;
  inputs: NodeInput[];
  open: boolean;
  onCloseModal: () => void;
  onConfirm: ObjectBuilderCommitFn;
}

export default function ObjectBuilderModal({
  nodeId,
  inputs,
  open,
  onCloseModal,
  onConfirm,
}: ObjectBuilderModalProps) {
  const [nodeInputs, setNodeInputs] = useState<NodeInput[]>(inputs);
  const [removedInputs, setRemovedInputs] = useState<string[]>([]);
  const [connectionsToUpdate, setConnectionsToUpdate] = useState<ConnectionToUpdateName[]>([]);
  const [viewState, setViewState] = useState<ViewStates>({ view: DEFAULT_VIEW });

  const handleCreateInputClick = () => {
    setViewState({ view: 'create' });
  };

  const handleEditInput = (input: NodeInput, index: number) => {
    setViewState({ view: 'edit', inputToEdit: input, updateIndex: index });
  };

  const handleFormCancel = () => {
    setViewState({ view: DEFAULT_VIEW });
  };

  const handleNameChanged = (oldName: string, newName: string) => {
    setConnectionsToUpdate((prev) => {
      return [...prev, { oldName, newName }];
    });
  };

  const handleConfirmEdit = (update: NodeInput) => {
    if (viewState.view !== 'edit') return;
    setNodeInputs((prev) => {
      return prev.map((input, index) => (viewState.updateIndex === index ? update : input));
    });
    setViewState({ view: DEFAULT_VIEW });
  };

  const handleConfirmCreate = (newInput: NodeInput) => {
    setNodeInputs((prev) => {
      return [...prev, newInput];
    });
    setViewState({ view: DEFAULT_VIEW });
  };

  const handleRemoveInput = (indexToRemove: number) => {
    const inputToRemove = nodeInputs[indexToRemove];
    setRemovedInputs((prev) => {
      return [...prev, createHandleId('input', inputToRemove.name)];
    });
    setNodeInputs((prev) => {
      return prev.filter((_, index) => indexToRemove !== index);
    });
  };

  const handleCommitChanges = () => {
    const updateConnections = connectionsToUpdate.filter(({ oldName, newName }) => {
      return !removedInputs.some(
        (id) => id === createHandleId('input', oldName) || id === createHandleId('input', newName),
      );
    });
    onConfirm(nodeInputs, removedInputs, updateConnections);
  };

  const handleReorder = (from: string, to: string) => {
    setNodeInputs((nodeInputs) => {
      const oldIndex = nodeInputs.findIndex((input) => input.name === from);
      const newIndex = nodeInputs.findIndex((input) => input.name === to);
      if (oldIndex === -1 || newIndex === -1) return nodeInputs;
      return arrayMove(nodeInputs, oldIndex, newIndex);
    });
  };

  return (
    <Styled.Dialog
      open={open}
      onClose={onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      {viewState.view === DEFAULT_VIEW && (
        <ObjectBuilderList
          inputs={nodeInputs}
          onCloseModal={onCloseModal}
          onConfirm={handleCommitChanges}
          onCreateInput={handleCreateInputClick}
          onEditInput={handleEditInput}
          onRemoveInput={handleRemoveInput}
          onReorder={handleReorder}
        />
      )}
      {viewState.view === 'edit' && (
        <ObjectBuilderEditForm
          nodeId={nodeId}
          nodeInputs={nodeInputs}
          inputToEdit={viewState.inputToEdit}
          onCancelEdit={handleFormCancel}
          onConfirmEdit={handleConfirmEdit}
          onNameChange={handleNameChanged}
        />
      )}
      {viewState.view === 'create' && (
        <ObjectBuilderCreateForm
          nodeInputs={nodeInputs}
          defaultValues={null}
          onCancelCreate={handleFormCancel}
          onConfirmCreate={handleConfirmCreate}
        />
      )}
    </Styled.Dialog>
  );
}
