import { DataSchemaCompatibilityCheck } from '../DataType';
import { DataSchema, IntegerDataSchema } from '@pathways/pipeline-schema';

export const integerCompatibilityCheck: DataSchemaCompatibilityCheck<IntegerDataSchema> = ({
  output,
}) => {
  return output.type === 'integer';
};

export function isIntegerDataSchema(schema?: DataSchema): schema is IntegerDataSchema {
  return schema?.type === 'integer';
}
