import { Sidebar } from 'components/Sidebar/Sidebar.styles';
import { EditorContextProvider } from 'features/Flow/EditorContext';
import Flow from 'features/Flow/Flow';
import { fitViewOptions } from 'features/Flow/Flow.consts';
import { FlowBackground } from 'features/Flow/Flow.styles';
import { RightSidebarView } from 'features/Flow/Flow.types';
import { HandleStoreProvider } from 'features/Flow/Handles/handle.context';
import CustomControl from 'features/Flow/components/CustomControl/CustomControl';
import ElementLayers from 'features/Flow/components/ElementLayers/ElementLayers';
import PipelineSidebar from 'features/Flow/components/PipelineSidebar/PipelineSidebar';
import SelectedNodesPanel from 'features/Flow/components/RightSidebar/components/SelectedNodesPanel/SelectedNodesPanel';
import useDebouncedFitView from 'features/Flow/hooks/useDebouncedFitView';
import useRightSidebarView from 'features/Flow/hooks/useRightSidebarView';
import { isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import useProjectId from 'hooks/useProjectId';
import RightSidebar from 'pages/ReadOnlyPipelinePage/components/RightSidebar/RightSidebar';
import { useEffect } from 'react';
import { useNodesState, useEdgesState, useNodesInitialized, useStoreApi } from 'reactflow';
import { FlowNodeData, FlowPipeline } from 'types/reactflow';

interface ReadOnlyFlowProps {
  pipeline: FlowPipeline;
}

const ReadOnlyFlow = ({ pipeline }: ReadOnlyFlowProps) => {
  const storeApi = useStoreApi();
  const [nodes, setNodes, onNodesChange] = useNodesState<FlowNodeData>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const nodesInitialized = useNodesInitialized();
  const { debouncedFitView, isFitViewPending } = useDebouncedFitView();
  const selectedNodes = nodes.filter((node) => node.selected);
  const rightSidebarView = useRightSidebarView(selectedNodes);
  const projectId = useProjectId();

  useEffect(() => {
    setNodes(
      pipeline.nodes.map((node) => {
        node.draggable = false;
        node.style = {
          cursor: 'pointer',
        };

        if (isGroupNode(node)) {
          node.selectable = false;
        }

        return node;
      }),
    );
    setEdges(pipeline.edges);
  }, [pipeline, setEdges, setNodes]);

  useEffect(() => {
    if (!nodesInitialized) return;

    debouncedFitView();
  }, [debouncedFitView, nodesInitialized]);

  useEffect(() => {
    storeApi.setState({
      mode: 'readonly',
      isFlowLoading: isFitViewPending,
    });
  }, [isFitViewPending, storeApi]);

  return (
    <HandleStoreProvider>
      <EditorContextProvider>
        <Sidebar>
          <ElementLayers />
        </Sidebar>
        <Flow
          deleteKeyCode={null}
          edges={edges}
          edgesUpdatable={false}
          nodes={nodes}
          nodesConnectable={false}
          selectionOnDrag={false}
          onEdgesChange={onEdgesChange}
          onNodesChange={onNodesChange}
        >
          <CustomControl fitViewOptions={fitViewOptions} />
          <FlowBackground />
        </Flow>
        <Sidebar>
          {rightSidebarView === RightSidebarView.PIPELINE && (
            <PipelineSidebar
              pipelineName={pipeline.name}
              pipeline={pipeline}
              pipelineDescription={pipeline.description}
              projectId={projectId}
              readOnly
            />
          )}
          {rightSidebarView === RightSidebarView.SELECTED_NODE && <RightSidebar />}
          {rightSidebarView === RightSidebarView.SELECTED_NODES && (
            <SelectedNodesPanel selectedNodes={selectedNodes} disabled />
          )}
        </Sidebar>
      </EditorContextProvider>
    </HandleStoreProvider>
  );
};

export default ReadOnlyFlow;
