import React from 'react';
import * as Styled from './PaginationControls.styles';
import { SkipNext, SkipPrevious } from '@mui/icons-material';
import { Pagination, PaginationItem } from '@mui/material';

interface PaginationControlsProps {
  totalCount: number;
  pageSize: number;
  page: number;
  onPageChange: (newPage: number) => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  totalCount,
  pageSize,
  page,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <Styled.PaginationContainer>
      <Pagination
        count={totalPages}
        size="large"
        showFirstButton
        showLastButton
        page={page}
        onChange={(_, page) => {
          onPageChange(page - 1);
        }}
        renderItem={(item) => (
          <PaginationItem slots={{ first: SkipPrevious, last: SkipNext }} {...item} />
        )}
      />
    </Styled.PaginationContainer>
  );
};

export default PaginationControls;
