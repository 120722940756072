import { format } from 'date-fns';
import { PipelineResponse } from 'types/pipeline';
import * as Styles from './PipelineUserInfo.styles';
import { Divider } from '@mui/material';

export interface PipelineUserInfoProps {
  author: Partial<
    Pick<
      PipelineResponse,
      | 'createdByUsername'
      | 'createdAt'
      | 'lastUpdatedByUsername'
      | 'updatedAt'
      | 'publishedBy'
      | 'publishedAt'
    >
  >;
}

const PipelineUserInfo = ({
  author: {
    createdByUsername,
    createdAt,
    lastUpdatedByUsername,
    updatedAt,
    publishedBy,
    publishedAt,
  },
}: PipelineUserInfoProps) => {
  return (
    <Styles.PipelineUserInfoBox
      divider={<Divider orientation="horizontal" variant="inset" />}
      spacing={2}
    >
      <Styles.UserInfoSection>
        <Styles.UserInfoSectionSecondaryContent>Created by</Styles.UserInfoSectionSecondaryContent>
        <Styles.UserInfoSectionPrimaryContent>
          {createdByUsername ?? '-'}
        </Styles.UserInfoSectionPrimaryContent>
        <Styles.UserInfoSectionSecondaryContent>
          {createdAt ? format(createdAt, "MM/dd/yyyy 'at' hh:mm aaa") : '-'}
        </Styles.UserInfoSectionSecondaryContent>
      </Styles.UserInfoSection>
      <Styles.UserInfoSection>
        <Styles.UserInfoSectionSecondaryContent>
          Last edited by
        </Styles.UserInfoSectionSecondaryContent>
        <Styles.UserInfoSectionPrimaryContent>
          {lastUpdatedByUsername ?? '-'}
        </Styles.UserInfoSectionPrimaryContent>
        <Styles.UserInfoSectionSecondaryContent>
          {updatedAt ? format(updatedAt, "MM/dd/yyyy 'at' hh:mm aaa") : '-'}
        </Styles.UserInfoSectionSecondaryContent>
      </Styles.UserInfoSection>
      {publishedBy && publishedAt && (
        <Styles.UserInfoSection>
          <Styles.UserInfoSectionSecondaryContent>
            Published by
          </Styles.UserInfoSectionSecondaryContent>
          <Styles.UserInfoSectionPrimaryContent>{publishedBy}</Styles.UserInfoSectionPrimaryContent>
          <Styles.UserInfoSectionSecondaryContent>
            {publishedAt ? format(publishedAt, "MM/dd/yyyy 'at' hh:mm aaa") : '-'}
          </Styles.UserInfoSectionSecondaryContent>
        </Styles.UserInfoSection>
      )}
    </Styles.PipelineUserInfoBox>
  );
};

export default PipelineUserInfo;
