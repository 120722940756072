import { GroupNode } from 'features/Flow/nodes/Group/Group.types';
import { useCallback } from 'react';
import { FlowNode } from 'types/reactflow';
import { mapNodeInsideGroup } from 'utils/mappings';
import { useUpdateNode } from './useUpdateNode';

export const useAddNodesToGroup = () => {
  const updateNode = useUpdateNode();

  return useCallback(
    (sourceNodes: FlowNode[], targetGroup: GroupNode) => {
      sourceNodes.forEach(({ id: nodeId }) => {
        updateNode({ nodeId }, (node) => mapNodeInsideGroup(targetGroup, node));
      });
    },
    [updateNode],
  );
};
