import { CardActionArea, Paper } from '@mui/material';
import styled from 'styled-components';

export const MuiCard = styled(Paper)`
  background: ${({ theme }) => theme.palette.surface.level1};
  border-radius: 8px;
  margin: 12px 0;
  position: relative;
  box-shadow: unset;
`;

export const SelectedCardOverlay = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 8px;
  height: 100%;
  left: 0;
  opacity: 0.16;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const BasicCard = styled(Paper)`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 12px 16px 12px;
  box-shadow: unset;
`;

export const MuiCardActionArea = styled(CardActionArea)`
  background-color: transparent;
  padding: 12px;
` as typeof CardActionArea;

export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`;
