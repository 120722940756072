import { Stack, Typography } from '@mui/material';
import CopyButton from 'components/CopyButton/CopyButton';

interface PipelineIdFieldWithCopyActionProps {
  pipelineId?: string;
}

const PipelineIdFieldWithCopyAction = ({ pipelineId }: PipelineIdFieldWithCopyActionProps) => {
  return (
    <Stack gap={1}>
      <Typography color="text.secondary" variant="labelSmall">
        Pipeline ID
      </Typography>
      <CopyButton label={pipelineId} size="small" value={String(pipelineId)} />
    </Stack>
  );
};

export default PipelineIdFieldWithCopyAction;
