import { NodeType } from 'features/Flow/Flow.types';
import { createHandleId, getHandleKeyFromId } from 'features/Flow/Flow.utils';
import { BATCH_ARRAY_OUTPUT_NAME } from 'features/Flow/nodes/Batch/Batch.consts';
import {
  BatchGroupNode,
  isBatchEndNode,
  isBatchGroupJob,
} from 'features/Flow/nodes/Batch/Batch.types';
import { mapBatchJobToNode } from 'features/Flow/nodes/Batch/Batch.utils';
import { GroupNode } from 'features/Flow/nodes/Group/Group.types';
import {
  fromLegacyDataType,
  isLegacyDataType,
  LegacyDataType,
  toLegacyType,
  WithDataSchema,
} from 'features/Flow/nodes/Node/DataType';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import {
  isPipelineCompleteNode,
  PipelineCompleteNode,
} from 'features/Flow/nodes/PipelineComplete/PipelineComplete.types';
import {
  isPipelineStartNode,
  isStartOutputConnection,
  PipelineStartNode,
} from 'features/Flow/nodes/PipelineStart/PipelineStart.types';
import { Edge } from 'reactflow';
import { PipelineConnection, PipelineGroup, PipelineJob, PipelineResponse } from 'types/pipeline';
import { FlowNode, FlowPipeline } from 'types/reactflow';
import { calculateNodePositionInsideGroup, calculateNodePositionOutsideGroup } from './neurons';

export function mapPipeline(response: PipelineResponse): FlowPipeline {
  const { content, ...data } = response;
  return {
    ...data,
    nodes: mapJobsToNodes(content.jobs, content.groups),
    edges: mapConnectionsToEdges(content.connections),
  };
}

export const handleLegacyData = <
  T extends
    | {
        inputs?: WithDataSchema[];
        outputs?: WithDataSchema[];
      }
    | undefined,
>(
  data: T,
): T => {
  let newData = data;
  if (data?.inputs) {
    newData = { ...newData, inputs: data.inputs.map(handleLegacyDataType) };
  }
  if (data?.outputs) {
    newData = { ...newData, outputs: data.outputs.map(handleLegacyDataType) };
  }
  return newData;
};

export const handleLegacyDataType = <T extends WithDataSchema | LegacyDataType | undefined>(
  data: T,
): T => {
  if (data && isLegacyDataType(data)) {
    return {
      ...data,
      dataSchema: fromLegacyDataType(data),
    };
  }
  return data;
};

/**
 * TODO This adds backwards compatibility, remove when it's no longer needed.
 * Added to avoid updating the BE; dataType is a required property.
 */
export const addLegacyType = <T extends WithDataSchema>(data: T): T => ({
  ...data,
  dataType: toLegacyType(data.dataSchema),
});

/**
 * PipelineStart inputs is deprecated. This function maps inputs to outputs to provide backwards compatibility
 * @returns PipelineTriggerJob where inputs is mapped to outputs
 * @param startNode
 */
function mapLegacyPipelineStart(startNode: PipelineStartNode): PipelineStartNode {
  const { inputs, outputs } = startNode.data;
  // This is for backwards compatibility. If outputs was undefined or null, then it should get mapped
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (outputs == null) {
    return {
      ...startNode,
      data: {
        ...startNode.data,
        inputs: undefined,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        outputs: (inputs ?? []).map((input: NodeInput) => {
          return {
            ...input,
            id: input.id
              ? createHandleId('output', input.id)
              : createHandleId('output', input.name),
            value: undefined,
          };
        }),
      },
    };
  }

  return startNode;
}

function mapLegacyPipelineComplete(job: PipelineJob): PipelineCompleteNode {
  const data = handleLegacyData(job.data as PipelineCompleteNode['data']);
  return {
    id: job.id,
    type: NodeType.PIPELINE_COMPLETE,
    ...job.render,
    data: {
      ...data,
      inputs: (data.outputs ?? []).map((input) => {
        return {
          ...input,
          // Some legacy data types are not compatible with the new data types
          // eslint-disable-next-line
          config: input.config ?? {},
        };
      }),
      outputs: undefined,
    },
  };
}

function mapJobToNode(job: PipelineJob): FlowNode[] {
  if (isBatchGroupJob(job)) {
    return mapBatchJobToNode(job);
  }

  const node = {
    id: job.id,
    type: job.data.metadata.type,
    data: handleLegacyData(job.data),
    ...job.render,
  };
  if (isPipelineStartNode(node)) {
    return [mapLegacyPipelineStart(node)];
  } else if (isPipelineCompleteNode(node)) {
    return [mapLegacyPipelineComplete(job)];
  }

  return [node];
}

export function mapJobsToNodes(
  jobs: PipelineJob[],
  groups: PipelineGroup[] | undefined,
): FlowNode[] {
  // Sort nodes for executions.
  const pipelineCompleteIndex = jobs.findIndex((job) => isPipelineCompleteNode(job));
  const isPipelineCompleteLast = pipelineCompleteIndex === jobs.length - 1;

  if (!isPipelineCompleteLast) {
    const [pipelineCompleteNode] = jobs.splice(pipelineCompleteIndex, 1);
    jobs.push(pipelineCompleteNode);
  }

  const mappedNodes = jobs.flatMap<FlowNode>(mapJobToNode);
  const groupNodes = groups?.map(mapGroupToNode) ?? [];

  return mappedNodes.concat(groupNodes);
}

export function mapGroupToNode({ id, render, ...data }: PipelineGroup): GroupNode {
  return {
    id,
    type: NodeType.GROUP,
    data: {
      metadata: {
        type: NodeType.GROUP,
      },
      color: render.color,
      description: data.description,
      name: data.name,
    },
    position: render.position,
  };
}

export function mapConnectionsToEdges(connections: PipelineConnection[]): Edge[] {
  return connections.map<Edge>(({ id, render }) => ({
    ...render,
    id,
    data: handleLegacyDataType(render.data),
  }));
}

export function mapJobInputValue({
  edges,
  nodes,
  targetNodeId,
  input,
  createSourceValue = createInputSourceValue,
}: {
  edges: Edge[];
  nodes: FlowNode[];
  targetNodeId: string;
  input: NodeInput;
  createSourceValue?: (edge: Edge, nodes: FlowNode[]) => NodeInput['value'];
}): NodeInput['value'] {
  const nodeEdges = edges.filter((edge) => edge.target === targetNodeId);
  const inputEdge = nodeEdges.find((edge) => getHandleKeyFromId(edge.targetHandle) === input.name);
  return inputEdge ? createSourceValue(inputEdge, nodes) : input.value;
}

export function createInputSourceValue(edge: Edge, nodes: FlowNode[]) {
  const handleKey = getHandleKeyFromId(edge.sourceHandle) ?? 'key';
  const sourceNode = nodes.find((node) => node.id === edge.source);

  if (isStartOutputConnection(edge)) {
    return {
      source: `pipeline.inputs.${handleKey}`,
    };
  }
  if (isBatchEndNode(sourceNode)) {
    return {
      source: `jobs.${sourceNode.parentNode}.outputs.${BATCH_ARRAY_OUTPUT_NAME}`,
    };
  }

  return {
    source: `jobs.${edge.source}.outputs.${handleKey}`,
  };
}

export function mapNodeInsideGroup(
  groupNode: GroupNode | BatchGroupNode,
  node: FlowNode,
): FlowNode {
  return {
    ...node,
    parentNode: groupNode.id,
    position: calculateNodePositionInsideGroup(groupNode.position, node.position),
    selected: false,
  };
}

export function mapNodeOutsideGroup(node: FlowNode): FlowNode {
  return {
    ...node,
    parentNode: undefined,
    position: calculateNodePositionOutsideGroup(node),
    selected: true,
  };
}
