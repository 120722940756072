import { DataSchemaCompatibilityCheck } from '../DataType';
import { DataSchema, TextDataSchema } from '@pathways/pipeline-schema';

export const textCompatibilityCheck: DataSchemaCompatibilityCheck<TextDataSchema> = ({
  output,
}) => {
  return output.type === 'text';
};

export function isTextDataSchema(schema: DataSchema): schema is TextDataSchema {
  return schema.type === 'text';
}
