import 'reactflow/dist/style.css';
import * as Styled from './Flow.styles';
import NodeConnectionLine from 'features/Flow/nodes/Node/components/NodeConnectionLine';
import { useCallback } from 'react';
import {
  DEFAULT_CONNECTION_PROPS,
  defaultEdgeOptions,
  edgeTypes,
  fitViewOptions,
  nodeTypes,
  panOnDrag,
  proOptions,
} from './Flow.consts';
import { SelectionMode } from 'reactflow';
import { FlowProps } from './Flow.types';
import { ReactFlowProp } from 'types/reactflow';
import { SHORTCUT_CONFIG } from 'config/shortcut';
import useIsFlowLoading from './hooks/useIsFlowLoading';

export default function Flow({
  edges,
  nodes,
  children,
  ...reactFlowProps
}: React.PropsWithChildren<FlowProps>) {
  const isLoading = useIsFlowLoading();
  const isValidConnection: ReactFlowProp['isValidConnection'] = useCallback((connection) => {
    return connection.source !== connection.target;
  }, []);

  return (
    <Styled.ReactFlow
      $isLoading={isLoading}
      connectionLineComponent={NodeConnectionLine}
      connectionRadius={DEFAULT_CONNECTION_PROPS.connectionRadius}
      defaultEdgeOptions={defaultEdgeOptions}
      deleteKeyCode={SHORTCUT_CONFIG.delete.keyCode}
      edges={edges}
      edgeTypes={edgeTypes}
      isValidConnection={isValidConnection}
      minZoom={fitViewOptions.minZoom}
      nodes={nodes}
      nodeTypes={nodeTypes}
      panOnDrag={panOnDrag}
      panOnScroll
      proOptions={proOptions}
      selectionMode={SelectionMode.Partial}
      selectionOnDrag
      {...reactFlowProps}
    >
      {children}
    </Styled.ReactFlow>
  );
}
