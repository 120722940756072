import { BatchGroupData, BatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import BaseGroupForm from './BaseGroupForm';

interface BatchGroupFormProps {
  groupNode: BatchGroupNode;
  readOnly?: boolean;
}

export default function BatchGroupForm(props: BatchGroupFormProps) {
  const { groupNode, readOnly } = props;

  const updateNodeData = useUpdateNodeData();

  const updateGroupData = ({ name, description }: Partial<BatchGroupData['metadata']>) => {
    updateNodeData<BatchGroupData>(groupNode.id, (data) => ({
      ...data,
      metadata: {
        ...data.metadata,
        name: name ?? data.metadata.name,
        description: description ?? data.metadata.description,
      },
    }));
  };

  const { name, description } = groupNode.data.metadata;

  return (
    <BaseGroupForm
      label="Batch"
      name={name}
      description={description}
      onChangeData={updateGroupData}
      readOnly={readOnly}
    />
  );
}
