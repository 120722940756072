import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ListItemText, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import ActionsMenu from 'components/ActionsMenu/ActionsMenu';
import useAddGroupNode from 'features/Flow/hooks/useAddGroupNode';
import useCopyElementsToClipboard from 'features/Flow/hooks/useCopyElementsToClipboard';
import useFlow from 'features/Flow/hooks/useFlow';
import ShortcutCaption from 'components/ShortcutCaption/ShortcutCaption';
import { SectionDivider } from 'components/Sidebar/Sidebar.styles';
import { SHORTCUT_CONFIG } from 'config/shortcut';
import { useExecuteOnce } from 'hooks/useExecuteOnce';
import { useEffect } from 'react';
import { useKeyPress } from 'reactflow';
import { FlowNode } from 'types/reactflow';
import { isBatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';
import { useAddNodeToBatchGroup } from 'features/Flow/nodes/Batch/Batch.utils';
import useRemoveNodesFromGroup from 'features/Flow/hooks/useRemoveNodeFromGroup';
import { useSelectedNodesActions } from './SelectedNodesPanel.utils';

interface SelectedNodesPanelProps {
  selectedNodes: FlowNode[];
  disabled?: boolean;
}
// TODO: Refactor SelectedNodesPanel, GroupPanel/Header and SingleElementActionsMenu components breaking into smaller ones and reusing duplicated logic
export default function SelectedNodesPanel(props: SelectedNodesPanelProps) {
  const { selectedNodes, disabled = false } = props;
  const { wasExecutedOnce: wasShortcutTriggered, setExecutedOnce: setShortcutTriggered } =
    useExecuteOnce();

  const createGroupPressed = useKeyPress(SHORTCUT_CONFIG.createGroup.keyCode, {
    target: document.body,
  });

  const ungroupPressed = useKeyPress(SHORTCUT_CONFIG.ungroup.keyCode, {
    target: document.body,
  });

  const addGroupNode = useAddGroupNode();
  const copyElementsToClipboard = useCopyElementsToClipboard();

  const addNodeToBatchGroup = useAddNodeToBatchGroup();
  const removeNodesFromGroup = useRemoveNodesFromGroup();

  const { deleteElements } = useFlow();

  const { canBeGrouped, canBeRemovedFromGroup, canBeRemovedFromBatch, canBeAddedToBatch } =
    useSelectedNodesActions(selectedNodes);

  const hasBatchGroupSelected = selectedNodes.some(isBatchGroupNode);

  useEffect(() => {
    if (wasShortcutTriggered || !createGroupPressed) return;
    // It's only set once per instance, it gets reset when the component mounts.
    setShortcutTriggered(true);

    if (canBeGrouped) {
      addGroupNode(selectedNodes);
    }

    if (canBeAddedToBatch) {
      addNodeToBatchGroup(selectedNodes);
    }
  }, [
    addGroupNode,
    addNodeToBatchGroup,
    canBeAddedToBatch,
    canBeGrouped,
    createGroupPressed,
    selectedNodes,
    setShortcutTriggered,
    wasShortcutTriggered,
  ]);

  useEffect(() => {
    if (wasShortcutTriggered || !ungroupPressed || !canBeRemovedFromGroup || !canBeRemovedFromBatch)
      return;
    setShortcutTriggered(true);

    removeNodesFromGroup(selectedNodes);
  }, [
    canBeRemovedFromBatch,
    canBeRemovedFromGroup,
    removeNodesFromGroup,
    selectedNodes,
    setShortcutTriggered,
    ungroupPressed,
    wasShortcutTriggered,
  ]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="bodySmall">{selectedNodes.length} elements selected</Typography>

        <ActionsMenu
          renderButton={(props) => (
            <Button
              disabled={disabled}
              {...props}
              endIcon={<ArrowDropDownIcon />}
              variant="outlined"
            >
              Actions
            </Button>
          )}
          renderActions={(closeMenu) => {
            const actions = [
              <MenuItem
                key="copy"
                onClick={() => {
                  closeMenu();
                  void copyElementsToClipboard();
                }}
              >
                <ListItemText>Copy</ListItemText>
                <ShortcutCaption>{SHORTCUT_CONFIG.copy.caption}</ShortcutCaption>
              </MenuItem>,
            ];

            if (canBeGrouped) {
              actions.push(
                <MenuItem
                  key="createGroup"
                  onClick={() => {
                    closeMenu();
                    addGroupNode(selectedNodes);
                  }}
                >
                  <ListItemText>Create Group</ListItemText>
                  <ShortcutCaption>{SHORTCUT_CONFIG.createGroup.caption}</ShortcutCaption>
                </MenuItem>,
              );
            } else if (hasBatchGroupSelected) {
              actions.push(
                <Tooltip
                  key="addToBatch"
                  title={!canBeAddedToBatch && 'Element Types Not Compatible With Batch Function'}
                  placement="bottom-end"
                >
                  <div>
                    <MenuItem
                      onClick={() => {
                        closeMenu();
                        addNodeToBatchGroup(selectedNodes);
                      }}
                      disabled={!canBeAddedToBatch}
                    >
                      <ListItemText>Add To Batch</ListItemText>
                      <ShortcutCaption>{SHORTCUT_CONFIG.createGroup.caption}</ShortcutCaption>
                    </MenuItem>
                  </div>
                </Tooltip>,
              );
            } else if (canBeRemovedFromGroup) {
              actions.push(
                <MenuItem
                  key="removeFromGroup"
                  onClick={() => {
                    closeMenu();
                    removeNodesFromGroup(selectedNodes);
                  }}
                >
                  <ListItemText>Remove From Group</ListItemText>
                  <ShortcutCaption>{SHORTCUT_CONFIG.ungroup.caption}</ShortcutCaption>
                </MenuItem>,
              );
            } else if (canBeRemovedFromBatch) {
              actions.push(
                <MenuItem
                  key="removeFromBatch"
                  onClick={() => {
                    closeMenu();
                    removeNodesFromGroup(selectedNodes);
                  }}
                >
                  <ListItemText>Remove From Batch</ListItemText>
                  <ShortcutCaption>{SHORTCUT_CONFIG.ungroup.caption}</ShortcutCaption>
                </MenuItem>,
              );
            }

            actions.push(
              <MenuItem
                key="delete"
                onClick={() => {
                  closeMenu();
                  deleteElements({
                    nodes: selectedNodes,
                  });
                }}
              >
                <ListItemText
                  sx={{
                    color: 'error.main',
                  }}
                >
                  Delete
                </ListItemText>
                <ShortcutCaption>{SHORTCUT_CONFIG.delete.caption}</ShortcutCaption>
              </MenuItem>,
            );

            return actions;
          }}
          MenuListProps={{
            sx: {
              minWidth: 220,
            },
          }}
        />
      </Stack>

      <SectionDivider />
    </>
  );
}
