import styled from 'styled-components';
import { Handle } from 'reactflow';

export const BaseHandle = styled(Handle)`
  position: static;
  width: auto;
  height: auto;
  transform: none;
  background: none;
  border: none;
  border-radius: 0;
  flex-shrink: 0;
  opacity: ${(props) => ((props.isConnectableStart ?? true) || props.isConnectableEnd ? 1 : 0.3)};
`;
