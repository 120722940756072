import {
  ImageCard,
  ImageCardActionsContainer,
  ImageContainer,
} from 'components/ImageCard/ImageCard.styles';
import styled from 'styled-components';

export const CheckpointApprovalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CheckpointApprovalHeaderLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const CheckpointApprovalHeaderActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  label {
    margin: 0;
  }

  span {
    display: flex;
    gap: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const CheckpointApprovalCardContainer = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing(3)};
  width: 100%;
`;

export const CheckpointApprovalCard = ImageCard;

export const CheckpointApprovalImageContainer = ImageContainer;

export const CheckpointApprovalCardActions = ImageCardActionsContainer;
