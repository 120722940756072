import { removeByKey } from 'utils/objects';
import { NodeInput } from '../../Node/Node.types';
import { NodeOutputFormData, ObjectExtractions } from './ObjectExtractor.types';
import { DataType } from '../../Node/DataType';
import { ArrayDataSchema, DataSchema, isArrayDataSchema } from '@pathways/pipeline-schema/web';
import { DataHandle } from 'features/Flow/Handles/handle.store';
import { EXTRACT_INPUT_NAME } from './ObjectExtractor';
import { DATA_MENU_ITEMS } from 'features/Flow/nodes/Function/Function.types';
import { getSchemaByDataType } from 'utils/dataSchema';

export const getExtractionInputValue = (inputs: NodeInput[]): ObjectExtractions => {
  return (getExtractionInput(inputs)?.value ?? {}) as ObjectExtractions;
};

export const getExtractionInput = (inputs: NodeInput[]) => {
  return inputs.find((input) => input.name === EXTRACT_INPUT_NAME);
};

export const getExtraction = (extractions: ObjectExtractions, name: string): string => {
  return extractions[name];
};

export const saveExtraction = (
  extractions: ObjectExtractions,
  name: string,
  path: string,
): ObjectExtractions => {
  return { ...extractions, [name]: path };
};

export const removeExtraction = (
  extractions: ObjectExtractions,
  name: string,
): ObjectExtractions => {
  return removeByKey(extractions, name);
};

const isValidConnectableType = (type: DataType | null, isArray?: boolean) => {
  if (!type) return false;

  const validTypes = ['text', 'integer', 'number', 'object'];
  if (!isArray) {
    validTypes.push('boolean');
  }

  return validTypes.includes(type);
};

export const canExtractorCustomHandleConnect = ({ schema }: DataHandle) => {
  if (isArrayDataSchema(schema)) {
    const itemType = schema.items.type;
    return isValidConnectableType(itemType, true);
  }

  return isValidConnectableType(schema.type, false);
};

export const createExtractorDataSchema = (data: NodeOutputFormData): DataSchema => {
  const dataSchema = getSchemaByDataType(data.dataType, DATA_MENU_ITEMS);
  if (isArrayDataSchema(dataSchema)) {
    return {
      ...dataSchema,
      items: {
        type: data.arrayDataType ?? null,
      },
    } as ArrayDataSchema;
  }

  return dataSchema;
};
