import * as Styled from './GroupContainer.styles';
import { ChipProps } from '@mui/material';

export const GROUP_NAME_CLASS_NAME = 'group-name';

type GroupNameProps = Pick<ChipProps, 'label'>;

export default function GroupName(props: GroupNameProps) {
  const { label } = props;

  return <Styled.NameChip className={GROUP_NAME_CLASS_NAME} label={label} size="small" />;
}
