import { ToastProps } from 'components/Toast/Toast';
import { useCallback, useMemo, useState } from 'react';

type ToastState = ToastProps;

type OpenToastOptions = Omit<ToastState, 'onClose'> & {
  onClose?: ToastProps['onClose'];
};

type ToastHandlerOptions = Omit<OpenToastOptions, 'severity'>;

function useToastState() {
  const [state, setState] = useState<ToastState>({
    message: '',
    onClose: () => undefined,
  });

  const openToast = useCallback((options: OpenToastOptions) => {
    setState({
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      autoHideDuration: 6000,
      onClose: closeToast,
      ...options,
      open: true,
    });
  }, []);

  const success = useCallback(
    (options: ToastHandlerOptions) => {
      openToast({
        ...options,
        severity: 'success',
      });
    },
    [openToast],
  );

  const info = useCallback(
    (options: ToastHandlerOptions) => {
      openToast({
        ...options,
        severity: 'info',
      });
    },
    [openToast],
  );

  const warning = useCallback(
    (options: ToastHandlerOptions) => {
      openToast({
        ...options,
        severity: 'warning',
      });
    },
    [openToast],
  );

  const error = useCallback(
    (options: ToastHandlerOptions) => {
      openToast({
        ...options,
        severity: 'error',
      });
    },
    [openToast],
  );

  const closeToast = () => {
    setState((state) => ({
      ...state,
      open: false,
    }));
  };

  const handlers = useMemo(
    () => ({
      success,
      info,
      warning,
      error,
      close: closeToast,
    }),
    [error, info, success, warning],
  );

  return [handlers, state satisfies ToastProps] as const;
}

export default useToastState;
