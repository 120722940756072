import { Button } from '@mui/material';
import styled, { css } from 'styled-components';

export const AddElementButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    padding: 12px 16px;
    height: 40px;

    & svg.MuiSvgIcon-root {
      font-size: 24px;
    }
  `,
);
