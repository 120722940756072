import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { NodeType } from 'features/Flow/Flow.types';
import DynamicImageWithLoader from 'components/DynamicImageWithLoader/DynamicImageWithLoader';
import { ExecutionCheckpointErrorMessages } from '../../ExecutionCheckpoint.type';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';
import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as BaseStyled from '../../ExecutionCheckpoint.styles';
import * as Styled from '../../ExecutionCheckpointForm.styles';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import { ExecutionCheckpointImageFormData } from './ExecutionCheckpointImageForm.types';
import { getJobName, getJobTitle } from 'utils/neurons';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';

const areInitialValuesArray = (initialValues: unknown): initialValues is string[] => {
  return Array.isArray(initialValues);
};

const ExecutionCheckpointImageForm = ({
  disabled,
  input,
  pendingCheckpoint,
  pendingCheckpointHeader,
}: ExecutionCheckpointFormProps) => {
  const { metadata, data } = pendingCheckpoint;
  const initialValues = data.inputs[input.name];

  if (!areInitialValuesArray(initialValues)) {
    throw new Error('Initial values must be an array');
  }

  const { control, register, setValue, watch } = useFormContext<ExecutionCheckpointImageFormData>();
  const { name } = register(input.name, {
    value: [],
    validate: (values) => values.length > 0 || ExecutionCheckpointErrorMessages.NO_INPUT_SELECTED,
  });
  const formValues = watch(name, []);
  const areAllSelected = formValues.length === initialValues.length;

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const updatedValues = checked ? initialValues : [];
    setValue(name, updatedValues, { shouldValidate: true });
  };

  return (
    <Styled.FormContainer>
      <Styled.CheckpointApprovalHeader>
        <Styled.CheckpointApprovalHeaderLogo>
          <JobTypeIcon type={NodeType.CHECKPOINT} variant="filled" />
          <Typography variant="titleMedium">{getJobTitle(metadata)}</Typography>
        </Styled.CheckpointApprovalHeaderLogo>
        <Styled.CheckpointApprovalHeaderActions>
          <FormControlLabel
            disabled={disabled}
            label="Select All"
            control={
              <Checkbox
                checked={areAllSelected}
                indeterminate={formValues.length > 0 && !areAllSelected}
                onChange={handleSelectAllChange}
              />
            }
          />
          <BaseStyled.CheckpointApprovalHeaderPill>
            <Typography variant="bodySmall">
              {getJobName(metadata)} <span>|</span> {pendingCheckpointHeader}
            </Typography>
          </BaseStyled.CheckpointApprovalHeaderPill>
        </Styled.CheckpointApprovalHeaderActions>
      </Styled.CheckpointApprovalHeader>
      <FormGroup>
        <Controller
          name={name}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <Styled.CheckpointApprovalCardContainer>
              {initialValues.map((imageUrl, index) => (
                <Styled.CheckpointApprovalCard key={imageUrl}>
                  <Styled.CheckpointApprovalImageContainer>
                    <DynamicImageWithLoader
                      imageUrl={imageUrl}
                      alt={`Generated resource ${index + 1}`}
                    />
                  </Styled.CheckpointApprovalImageContainer>
                  <Styled.CheckpointApprovalCardActions>
                    <FormControlLabel
                      disabled={field.disabled}
                      label="Select Image"
                      control={
                        <Checkbox
                          checked={field.value.includes(imageUrl)}
                          value={imageUrl}
                          onChange={(event, checked) => {
                            const updatedValues = checked
                              ? [...field.value, event.target.value]
                              : field.value.filter((value) => value !== event.target.value);

                            field.onChange(updatedValues);
                          }}
                        />
                      }
                    />
                    <OpenInNewTab href={imageUrl} linkText="Open URL" />
                  </Styled.CheckpointApprovalCardActions>
                </Styled.CheckpointApprovalCard>
              ))}
            </Styled.CheckpointApprovalCardContainer>
          )}
        />
      </FormGroup>
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointImageForm;
