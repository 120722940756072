import styled from 'styled-components';
import { Typography } from '@mui/material';

export const GroupDescription = styled(Typography)`
  padding: ${({ theme }) => `${theme.spacing(2.75, 2, 0, 2)}`};
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  white-space: pre-wrap;

  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
