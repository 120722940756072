export enum ExecutionStatus {
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  FAILED = 'failed',
  PENDING = 'pending',
  REVIEW = 'review',
  SCHEDULED = 'scheduled',
  STARTED = 'started',
  TIMED_OUT = 'timed-out',
}

export interface ExecutionResponse {
  id: string;
  completedAt: string | null;
  completedJobs: number;
  completedCheckpoints: number;
  createdAt: string;
  executedById?: string;
  executionNumber: number;
  hasPendingCheckpoints: boolean;
  pipelineId: string;
  status: ExecutionStatus;
  totalJobs: number;
  totalCheckpoints: number;
  updatedAt: string;
  version: string;
  revision: string;
  pipelineVersionState: string;
}

export interface PipelineExecutionsResponse {
  items: ExecutionResponse[];
}
