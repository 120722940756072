import { Box, Button } from '@mui/material';
import { usePipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution';
import { usePipelineFromExecution } from 'api/services/usePipelineFromExecution/usePipelineFromExecution';
import { Layout, LayoutContainer } from 'components/Layout/Layout.styles';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import useToast from 'contexts/toast/useToast';
import FlowLoader from 'features/Flow/components/FlowLoader/FlowLoader';
import usePipelineExecutionId from 'hooks/usePipelineExecutionId';
import usePipelineId from 'hooks/usePipelineId';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { isPipelineExecutionActive } from 'utils/execution';
import { useAppRoutes } from 'utils/routes';
import LeftSidebar from './components/LeftSidebar/LeftSidebar';
import RightSidebar from './components/RightSidebar/RightSidebar';
import RuntimeFlow from './components/RuntimeFlow/RuntimeFlow';

export default function RuntimePage() {
  const navigate = useNavigate();
  const pipelineId = usePipelineId();
  const pipelineExecutionId = usePipelineExecutionId();
  const routes = useAppRoutes();
  const toast = useToast();

  const { isPipelineLoading, pipeline, pipelineError } = usePipelineFromExecution(
    pipelineId,
    pipelineExecutionId,
  );
  const { isPipelineExecutionLoading, pipelineExecution, pipelineExecutionError } =
    usePipelineExecution(pipelineExecutionId, {
      refreshInterval(latestData) {
        if (isPipelineExecutionActive(latestData?.data.status)) {
          return 5000;
        }

        return 0;
      },
    });
  const loading = isPipelineLoading || isPipelineExecutionLoading;

  useEffect(() => {
    if (!pipelineError && !pipelineExecutionError) return;

    const message: string = pipelineError?.message ?? pipelineExecutionError?.message ?? '';

    toast.error({
      message: `Something went wrong. ${message}`,
    });
    navigate(routes.executions.runs(pipelineId));
  }, [navigate, pipelineError, pipelineExecutionError, pipelineId, routes, toast]);

  return (
    <ReactFlowProvider>
      <Layout>
        <NavigationBar
          title={pipeline?.name ?? 'Executions'}
          firstBreadcrumb={{
            label: pipeline?.name ?? 'Executions',
            route: routes.executions.runs(pipelineId),
          }}
          lastBreadcrumb="Pipeline Run"
        >
          <Button
            component={Link}
            color="primary"
            variant="contained"
            to={routes.pipelines(pipelineId)}
          >
            Edit Pipeline
          </Button>
        </NavigationBar>
        <LayoutContainer>
          <LeftSidebar />

          {pipeline && pipelineExecution.id ? (
            <RuntimeFlow pipeline={pipeline} pipelineExecution={pipelineExecution} />
          ) : (
            <Box display="flex" width="100%" />
          )}

          <RightSidebar />
        </LayoutContainer>
      </Layout>

      <FlowLoader open={loading} />
    </ReactFlowProvider>
  );
}
