import DynamicImageWithLoader from 'components/DynamicImageWithLoader/DynamicImageWithLoader';
import * as Styled from './ImageCard.styles';

interface ImageCardProps {
  imageUrl: string;
  alt: string;
  actions?: React.ReactNode;
}

export default function ImageCard({ actions, imageUrl, alt }: ImageCardProps) {
  return (
    <Styled.ImageCard>
      <Styled.ImageContainer>
        <DynamicImageWithLoader imageUrl={imageUrl} alt={alt} />
      </Styled.ImageContainer>
      {actions && <Styled.ImageCardActionsContainer>{actions}</Styled.ImageCardActionsContainer>}
    </Styled.ImageCard>
  );
}
