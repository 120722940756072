import { compatibilityCheck, DataSchemaCompatibilityCheck } from '../DataType';
import { ArrayDataSchema } from '@pathways/pipeline-schema';

export const arrayCompatibilityCheck: DataSchemaCompatibilityCheck<ArrayDataSchema> = ({
  input,
  output,
}) => {
  if (output.type !== 'array') return false;
  if (input.items.type === null) return false;
  if (output.items.type === null) return false;

  return compatibilityCheck({ input: input.items, output: output.items });
};

export function isNullArrayDataSchema(schema: ArrayDataSchema['items']): schema is { type: null } {
  return schema.type === null;
}
