import { Typography } from '@mui/material';
import { NodeType, isHandleConnection } from 'features/Flow/Flow.types';
import { createHandleId, getHandleKeyFromId, useSelectNodeOnClick } from 'features/Flow/Flow.utils';
import { CustomHandleData, HandleKey } from 'features/Flow/Handles/handle.store';
import { useGetSourceHandle } from 'features/Flow/Handles/handles';
import CustomHandle from 'features/Flow/components/NeuronHandle/CustomHandle';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import useFlow from 'features/Flow/hooks/useFlow';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import { NodeProps, getConnectedEdges, useStore, useUpdateNodeInternals } from 'reactflow';
import { FlowState } from 'types/reactflow';
import { shallow } from 'zustand/shallow';
import NodeInputHandle from '../Node/NodeInputHandle';
import NodeOutputHandle from '../Node/NodeOutputHandle';
import { BATCH_ARRAY_OUTPUT_NAME } from './Batch.consts';
import * as Styled from './Batch.styles';
import {
  BatchEndData,
  BatchInput,
  BatchInputEdge,
  BatchOutput,
  isBatchEndNode,
} from './Batch.types';
import { createBatchInputEdge, getBatchInputConnectedEdges } from './Batch.utils';
import { isBooleanDataSchema } from '../Node/DataSchemas/booleanSchema';
import { isArrayDataSchema } from '@pathways/pipeline-schema/web';

// TODO implement runtime view: runtimeMode, executionJobs.
const selector = ({ edges }: FlowState) => ({
  edges,
});

interface BatchEndAddHandle extends Omit<BatchInput, 'config' | 'dataSchema'> {
  id: string;
  handle: CustomHandleData;
}

interface BatchEndDataHandle {
  input: BatchInput;
  output: BatchOutput;
}

type BatchEndProps = NodeProps<BatchEndData>;

const BatchEnd = (props: BatchEndProps) => {
  const { id: nodeId, selected } = props;
  const { getNodeOrThrow, deleteElements } = useFlow();
  const node = useMemo(() => getNodeOrThrow(nodeId, isBatchEndNode), [getNodeOrThrow, nodeId]);

  const updateNodeInternals = useUpdateNodeInternals();
  const selectNodeOnClick = useSelectNodeOnClick(nodeId);
  const { addConnectionHandler, removeConnectionHandler } = useEditorContext();
  const getSourceHandle = useGetSourceHandle();

  const { edges } = useStore(selector, shallow);
  const customAddHandle = useMemo(() => createAddHandle(props.id), [props.id]);
  const [connectedDataHandle, setConnectedDataHandle] = useState(() =>
    getBatchInputConnectedEdges(node, edges)
      .map((edge) => mapHandleData(edge.data))
      .at(0),
  );

  const displayDataHandle = !!connectedDataHandle;
  const displayAddHandle = !displayDataHandle;

  useEffect(() => {
    const connectionHandlerKey: HandleKey = {
      id: customAddHandle.id,
      nodeId,
    };
    addConnectionHandler?.(connectionHandlerKey, (connection) => {
      if (!isHandleConnection(connection)) return connection;

      const sourceHandle = getSourceHandle(connection);
      const handle: typeof sourceHandle = {
        ...sourceHandle,
        // Reuse the `id` to keep calling the current connection handler.
        id: connectedDataHandle?.input.id ?? customAddHandle.id,
        name: BATCH_ARRAY_OUTPUT_NAME,
      };

      return createBatchInputEdge(connection, handle);
    });

    return () => {
      removeConnectionHandler?.(connectionHandlerKey);
    };
  }, [
    addConnectionHandler,
    connectedDataHandle?.input.id,
    customAddHandle.id,
    getSourceHandle,
    nodeId,
    removeConnectionHandler,
  ]);

  useEffect(() => {
    const nodeConnectedEdges = getBatchInputConnectedEdges(node, edges);
    const nextHandle = nodeConnectedEdges
      .map<BatchEndDataHandle>((edge) => mapHandleData(edge.data))
      .at(0);

    setConnectedDataHandle(nextHandle);
  }, [edges, node]);

  useEffect(() => {
    updateNodeInternals(nodeId);
    // 'connectedDataHandle' is required as a dependency to allow creating connections.
  }, [connectedDataHandle, nodeId, updateNodeInternals]);

  // This effect removes all connections when the required input handle is disconnected.
  useEffect(() => {
    const inputConnectedEdges = getBatchInputConnectedEdges(node, edges);
    const hasConnectedInput = inputConnectedEdges.length > 0;

    if (hasConnectedInput) return;

    const nodeConnectedHandles = getConnectedEdges([node], edges);

    deleteElements({
      edges: nodeConnectedHandles,
    });
  }, [deleteElements, edges, node]);

  return (
    <Styled.NodeContainer
      $nodeType={NodeType.FUNCTION}
      $isSelected={selected}
      onClick={selectNodeOnClick}
    >
      <Styled.NodeHeader $nodeType={NodeType.FUNCTION}>
        <Typography variant="labelLarge">Batch End</Typography>
      </Styled.NodeHeader>

      {displayAddHandle && (
        <Styled.NodeDataRow className="nodrag">
          <Styled.NodeDataColumn>
            <CustomHandle handle={customAddHandle.handle} title={customAddHandle.title} />
          </Styled.NodeDataColumn>
        </Styled.NodeDataRow>
      )}

      {displayDataHandle && (
        <Styled.NodeDataRow className="nodrag">
          <Styled.NodeDataColumn>
            <NodeInputHandle
              key={connectedDataHandle.input.id}
              nodeId={nodeId}
              input={connectedDataHandle.input}
            />
          </Styled.NodeDataColumn>

          <Styled.NodeDataColumn>
            <NodeOutputHandle
              key={connectedDataHandle.output.id}
              nodeId={nodeId}
              output={connectedDataHandle.output}
            />
          </Styled.NodeDataColumn>
        </Styled.NodeDataRow>
      )}
    </Styled.NodeContainer>
  );
};

export default BatchEnd;

function createAddHandle(nodeId: string): BatchEndAddHandle {
  const handleId = createHandleId('input', nanoid(4));

  return {
    id: handleId,
    name: 'connect_data',
    title: 'Connect Data to Collect',
    handle: {
      type: 'custom',
      purpose: 'input',
      id: handleId,
      nodeId,
      canConnect: ({ schema }) => !isArrayDataSchema(schema) && !isBooleanDataSchema(schema),
    },
  };
}

function mapHandleData(input: BatchInputEdge['data']): BatchEndDataHandle {
  const handleKey = getHandleKeyFromId(input.id) ?? '';

  return {
    input,
    // Outputs are mirrored from the inputs.
    output: {
      config: input.config,
      dataSchema: {
        items: input.dataSchema,
        type: 'array',
      },
      description: input.description,
      id: createHandleId('output', handleKey),
      name: input.name,
      title: input.title,
    },
  } satisfies BatchEndDataHandle;
}
