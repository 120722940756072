import { AxiosError, AxiosResponse } from 'axios';
import { SWRMutationConfiguration } from 'swr/mutation';

export const DOWNLOAD_ASSET_KEY = 'downloadAsset';
export type DownloadAssetResponse = AxiosResponse<{ url: string }>;
export type DownloadAssetError = AxiosError;
export type DownloadAssetConfig = SWRMutationConfiguration<
  DownloadAssetResponse,
  DownloadAssetError,
  typeof DOWNLOAD_ASSET_KEY
>;
