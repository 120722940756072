import { FormatNodeDataFn } from 'features/Flow/Flow.types';
import { cloneDeep } from 'lodash';
import { formatNodeInputs, formatNodeOutputs } from 'utils/neurons';
import { NodeInput, NodeOutput } from '../../Node/Node.types';
import { isObjectDataSchema } from '../../Node/DataSchemas/objectSchema';
import { DataManipulatorFormData } from './DataManipulator.types';
import { DataSchema, isArrayDataSchema, toJsonSchema } from '@pathways/pipeline-schema/web';
import { DataType } from '../../Node/DataType';
import { DataHandle } from 'features/Flow/Handles/handle.store';

// This input is controlled so that visibility of this can be manually controlled by the node
export const createDataInput = (schema: DataSchema): NodeInput => {
  return {
    name: 'data',
    config: {},
    dataSchema: schema,
    description: 'Data to query',
    title: 'Data',
  };
};

export const createDataOutput = (schema: DataSchema, title: string): NodeOutput => {
  return {
    name: 'data',
    config: {
      required: true,
    },
    dataSchema: schema,
    title,
  };
};

export const canDataManipulatorAddInputConnect = ({ schema }: DataHandle) => {
  return isArrayDataSchema(schema, 'object') || isObjectDataSchema(schema);
};

export const canDataManipulatorAddOutputConnect = ({ schema }: DataHandle) =>
  isArrayDataSchema(schema)
    ? isValidConnectableType(schema.items.type)
    : isValidConnectableType(schema.type);

const isValidConnectableType = (type: DataType | null) => {
  if (!type) return false;
  const validTypes: DataType[] = ['text', 'integer', 'number', 'object'];

  return validTypes.includes(type);
};

export const createDataManipulatorNode: FormatNodeDataFn = (manifest, customTitle) => {
  if (!manifest.schemas) {
    throw new Error('Schema is empty for Data Manipulator');
  }

  const clonedSchemas = cloneDeep(manifest.schemas);

  // Remove these on initial mount as they'll be set manually by the node
  delete clonedSchemas.input.properties?.data;
  delete clonedSchemas.output.properties?.data;

  return {
    metadata: {
      type: manifest.type,
      name: manifest.name,
      description: manifest.description,
      uses: manifest.key,
      customTitle,
    },
    inputs: formatNodeInputs(clonedSchemas.input).map((inp) => {
      if (inp.name !== 'json') {
        inp.config.forceHide = true;
      }

      return inp;
    }),
    outputs: formatNodeOutputs(clonedSchemas.output),
  };
};

export const createFormDefaults = ({
  inputs,
  outputs,
}: {
  inputs: NodeInput[];
  outputs: NodeOutput[];
}): Partial<DataManipulatorFormData> => {
  const queryInput = inputs.find((input) => input.name === 'query');
  const optionsInput = inputs.find((input) => input.name === 'options');
  const options = optionsInput?.value as { slurp: boolean } | undefined;
  const dataInput = inputs.find((input) => input.name === 'data');
  const dataOutput = outputs.find((output) => output.name === 'data');

  return {
    query: queryInput?.value as string,
    slurp: options?.slurp,
    input: dataInput?.dataSchema.type,
    output: dataOutput?.dataSchema.type,
    outputArrayType:
      dataOutput?.dataSchema.type === 'array' && dataOutput.dataSchema.items.type
        ? dataOutput.dataSchema.items.type
        : 'text',
  };
};

export const createDynamicSchemaInput = (outputDataSchema: DataSchema): NodeInput => {
  return {
    title: 'Output Schema',
    name: 'outputSchema',
    value: {
      __type: 'DynamicSchema',
      value: {
        type: 'object',
        properties: {
          data: toJsonSchema(outputDataSchema),
        },
      },
    } satisfies Record<string, unknown>,
    config: { forceHide: true },
    dataSchema: {
      type: 'object',
    } satisfies DataSchema,
  };
};
