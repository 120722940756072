import React, { createContext, useState } from 'react';
import { getFeatureFlagsFromParam } from './FeatureFlag.utils';

const ADMIN_FLAG = 'admin';

export type FeatureFlags = Record<string, boolean>;

/**
 * @param flags Flags that are set in the context by default for testing purposes.
 * @param isEnabled Function to check if a feature is enabled.
 */
interface FeatureFlagContext {
  flags: FeatureFlags;
  isEnabled: (flag: string) => boolean;
}

export const FeatureFlagContext = createContext<FeatureFlagContext>({
  flags: {},
  isEnabled: (_: string) => false,
});

// Temporary solution to provide feature flags to the app until we have a proper solution using a server based and user permission based feature flagging tool
const FeatureFlagProvider = ({
  testFlags,
  children,
}: React.PropsWithChildren<{ testFlags?: FeatureFlags }>) => {
  const [featureFlags] = useState<FeatureFlags>(testFlags ?? getFeatureFlagsFromParam());
  const isEnabled = (feature: string) => {
    if (featureFlags[ADMIN_FLAG]) {
      return true;
    }

    return !!featureFlags[feature];
  };

  return (
    <FeatureFlagContext.Provider
      value={{
        flags: featureFlags,
        isEnabled,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
