import styled, { css } from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from 'components/Icons/Image';
import ImageArrayIcon from 'components/Icons/ImageArray';
import ListIcon from 'components/Icons/List';
import NumericIcon from 'components/Icons/Numeric';
import NumberArrayIcon from 'components/Icons/NumericArray';
import TextIcon from 'components/Icons/Text';
import TextArrayIcon from 'components/Icons/TextArray';
import { DataTypeColor } from './NodeDataIcon.consts';
import {
  ArrayAssetObjectFile,
  ArrayAssetObjectImage,
  AssetObjectFile,
  AssetObjectImage,
  AssetAudio,
  ArrayAssetAudio,
} from 'components/Icons/AssetObject';
import ObjectIcon from 'components/Icons/Object';
import EmptyArray from 'components/Icons/EmptyArray';
import { NodeDataIconProps } from './NodeDataIcon';
import ArrayObjectIcon from 'components/Icons/ArrayObject';
import BooleanIcon from 'components/Icons/Boolean';

export const IconContainer = styled.div<Pick<NodeDataIconProps, 'size'>>`
  pointer-events: none;
  display: grid;
  font-size: ${(props) => props.size ?? '32px'};
`;

const BASE_ICON_STYLES = css`
  font-size: inherit;
  border-radius: 8px;
  color: #000000;
`;

export const DataTextIcon = styled(TextIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.TEXT};
`;

export const DataEnumIcon = styled(ListIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.ENUM};
`;

export const DataNumberIcon = styled(NumericIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.NUMERICAL};
`;

export const DataBooleanIcon = styled(BooleanIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.BOOLEAN};
`;

export const DataImageIcon = styled(ImageIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.IMAGE_URL};
  padding: 4px;
`;

export const AssetObjectImageIcon = styled(AssetObjectImage)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.ASSET};
  padding: 4px;
`;

export const AssetObjectAudioIcon = styled(AssetAudio)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.ASSET};
  padding: 4px;
`;

export const ArrayAssetObjectAudioIcon = styled(ArrayAssetAudio)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.ASSET};
  padding: 4px;
`;

export const AssetObjectMediaIcon = styled(AssetObjectFile)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.ASSET};
`;

export const ArrayAssetObjectImageIcon = styled(ArrayAssetObjectImage)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.ASSET};
`;

export const ArrayAssetObjectMediaIcon = styled(ArrayAssetObjectFile)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.ASSET};
`;

export const AssetObjectFileIcon = styled(AssetObjectFile)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.ASSET};
`;

export const ArrayAssetObjectFileIcon = styled(ArrayAssetObjectFile)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.ASSET};
`;

export const EmptyArrayIcon = styled(EmptyArray)`
  ${BASE_ICON_STYLES};
`;

export const DataTextArrayIcon = styled(TextArrayIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.TEXT};
`;

export const DataNumberArrayIcon = styled(NumberArrayIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.NUMERICAL};
`;

export const DataImageArrayIcon = styled(ImageArrayIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.IMAGE_URL};
`;

export const DataPipelineStartOutputIcon = styled(AddIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.PIPELINE_START_OUTPUT};
  padding: 4px;
`;

export const DataObjectIcon = styled(ObjectIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.OBJECT};
`;

export const DataArrayObjectIcon = styled(ArrayObjectIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.OBJECT};
`;

export const AddDataIcon = styled(AddIcon)`
  ${BASE_ICON_STYLES};
  background-color: ${DataTypeColor.PIPELINE_START_OUTPUT};
`;
