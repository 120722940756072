import { useSelectNodeOnClick } from 'features/Flow/Flow.utils';
import * as Styled from '../Node.styles';
import { NodeInput, NodeOutput } from '../Node.types';
import NodeInputHandle from '../NodeInputHandle';
import NodeOutputHandle from '../NodeOutputHandle';
import { isConstDataSchema } from '@pathways/pipeline-schema/web';

interface NodeDataSectionProps {
  nodeId: string;
  inputs: NodeInput[];
  outputs: NodeOutput[];
}

export const NodeDataSection: React.FC<NodeDataSectionProps> = ({ nodeId, inputs, outputs }) => {
  const handleSelectNodeOnClick = useSelectNodeOnClick(nodeId);

  return (
    <Styled.NodeDataRow className="nodrag" onClick={handleSelectNodeOnClick}>
      <Styled.NodeDataColumn>
        {inputs.map((input, inputIndex) => {
          if (
            input.config.hidden ??
            input.config.forceHide ??
            isConstDataSchema(input.dataSchema)
          ) {
            return null;
          }

          return <NodeInputHandle key={inputIndex} input={input} nodeId={nodeId} />;
        })}
      </Styled.NodeDataColumn>
      <Styled.NodeDataColumn>
        {outputs.map((output, outputIndex) => {
          if (output.config.forceHide) {
            return null;
          }

          return <NodeOutputHandle key={outputIndex} output={output} nodeId={nodeId} />;
        })}
      </Styled.NodeDataColumn>
    </Styled.NodeDataRow>
  );
};
