import { useMemo } from 'react';
import { ColumnConfig } from 'components/Table/TableHead';
import { Row } from './useDynamicTable';
import DynamicTableCell from 'components/DynamicTable/DynamicTableCell';
import * as Styled from 'components/Table/Table.styles';

interface ReadOnlyTableRowProps {
  row: Row;
  columns: ColumnConfig[];
}

const ReadOnlyTableRow = ({ row, columns, ...tableRowProps }: ReadOnlyTableRowProps) => {
  const rowValues = useMemo(() => mapRowToRowValues(row), [row]);

  return (
    <Styled.TableBodyRow {...tableRowProps}>
      {columns.map((column, index) => (
        <DynamicTableCell
          key={column.id}
          value={rowValues[column.id]}
          column={column}
          columnIndex={index}
          isEditMode={false}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={false}
        />
      ))}
    </Styled.TableBodyRow>
  );
};

// TODO: refactor and move to separate file
const mapRowToRowValues = (row: Row): Record<string, string> => {
  return Object.keys(row).reduce((acc, cell) => {
    return {
      ...acc,
      [cell]: renderValue(row[cell]),
    };
  }, {});
};

// To be improved later once we understand how to render objects and arrays
const renderValue = (value: unknown) => {
  if ((typeof value === 'object' && value !== null) || Array.isArray(value)) {
    return JSON.stringify(value);
  }

  return value;
};

export default ReadOnlyTableRow;
