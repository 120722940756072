import { NodeManifest, NodeType } from 'features/Flow/Flow.types';

export const batchNode: NodeManifest = {
  type: NodeType.BATCH_GROUP,
  key: 'function/batch',
  name: 'Batch',
  description: 'Batch function',
};

export const dummyNode: NodeManifest = {
  type: NodeType.NEURON,
  key: 'neuron/pathwaysDummyNeuron',
  description: 'Dummy neuron to draft pipelines',
  name: 'Dummy Neuron',
  schemas: {
    input: { type: 'object', properties: {} },
    output: { type: 'object', properties: {} },
  },
};
