import { Config, useRequest } from 'api/client';
import { useMemo } from 'react';
import {
  PipelineExecutionOutput,
  PipelineExecutionOutputsResponse,
} from './usePipelineExecutionOutputs.types';
import { groupOutputsByJob } from './usePipelineExecutionOutputs.utils';

const usePipelineExecutionOutputs = (
  pipelineExecutionId?: string,
  config?: Config<PipelineExecutionOutputsResponse>,
) => {
  const { isLoading, data, error, mutate, ...response } =
    useRequest<PipelineExecutionOutputsResponse>(
      {
        url: pipelineExecutionId
          ? `/pipeline-executions/${pipelineExecutionId}/outputs`
          : undefined,
      },
      config,
    );

  const pipelineExecutionOutputs = useMemo<PipelineExecutionOutput[]>(() => {
    if (!data) {
      return [];
    }

    const groupedByJob = groupOutputsByJob(data.outputs);

    return Object.values(groupedByJob).sort((a, b) => {
      const timeA = +(a.job.completedAt ?? 0);
      const timeB = +(b.job.completedAt ?? 0);

      return timeA - timeB;
    });
  }, [data]);

  return {
    ...response,
    loadingPipelineExecutionOutputs: isLoading,
    pipelineExecutionOutputs,
    pipelineExecutionOutputsError: error,
    mutatePipelineExecutionOutputs: mutate,
  };
};

export default usePipelineExecutionOutputs;
