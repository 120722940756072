import { SvgIcon, SvgIconProps } from '@mui/material';

const TextIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M16.5 24C16.0833 24 15.7292 23.8542 15.4375 23.5625C15.1458 23.2708 15 22.9167 15 22.5V11H11.5C11.0833 11 10.7292 10.8542 10.4375 10.5625C10.1458 10.2708 10 9.91667 10 9.5C10 9.08333 10.1458 8.72917 10.4375 8.4375C10.7292 8.14583 11.0833 8 11.5 8H21.5C21.9167 8 22.2708 8.14583 22.5625 8.4375C22.8542 8.72917 23 9.08333 23 9.5C23 9.91667 22.8542 10.2708 22.5625 10.5625C22.2708 10.8542 21.9167 11 21.5 11H18V22.5C18 22.9167 17.8542 23.2708 17.5625 23.5625C17.2708 23.8542 16.9167 24 16.5 24Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default TextIcon;
