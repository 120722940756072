import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Stack, useTheme } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import { JobInfoResponse } from 'api/services/usePipelineExecutionOutputs/usePipelineExecutionOutputs.types';
import HeaderChip from 'components/HeaderChip/HeaderChip';
import JobInfoTitle from '../JobInfoTitle/JobInfoTitle';
import { formatStatusDateTime } from 'utils/execution';
import * as Styled from './PipelineOutputAccordion.styles';
import { useAppRoutes } from 'utils/routes';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';
import { isSubpipelineJobResponse } from 'api/services/usePipelineJobs/usePipelineJobs.types';

interface PipelineOutputAccordionProps {
  children: React.ReactNode;
  job: JobInfoResponse;
  expandAccordionByDefault?: boolean;
}

export function PipelineOutputAccordion(props: PipelineOutputAccordionProps) {
  const { job } = props;
  const theme = useTheme();
  const routes = useAppRoutes();
  const jobCompletedDate = job.completedAt ? new Date(job.completedAt) : undefined;
  const completedDateTimeLabel = jobCompletedDate
    ? formatStatusDateTime(ExecutionStatus.COMPLETED, jobCompletedDate)
    : undefined;
  return (
    <Styled.MuiAccordion
      data-testid={`pipeline-output-accordion-${job.name}`}
      disableGutters
      defaultExpanded={props.expandAccordionByDefault ?? true}
      slotProps={{
        transition: {
          timeout: theme.transitions.duration.standard,
        },
      }}
    >
      <Styled.MuiAccordionSummary
        expandIcon={
          <IconButton size="small">
            <ExpandMoreIcon />
          </IconButton>
        }
        tabIndex={-1}
        aria-controls={`${job.id}-content`}
        id={`${job.id}-header`}
      >
        <JobInfoTitle {...job} />
        <Stack direction="row" gap={1.5}>
          <HeaderChip>
            <Stack direction="row" gap={1.5}>
              {job.name} <span>|</span>{' '}
              <span title={jobCompletedDate?.toJSON()}>{completedDateTimeLabel}</span>
            </Stack>
          </HeaderChip>
          {isSubpipelineJobResponse(job) && (
            <OpenInNewTab href={routes.executions.view(job.pipelineId, job.executionId)} />
          )}
        </Stack>
      </Styled.MuiAccordionSummary>
      <Styled.MuiAccordionDetails>{props.children}</Styled.MuiAccordionDetails>
    </Styled.MuiAccordion>
  );
}
