export const assetToJsonSchema = (dataSchema) => {
    const mediaTypeJsonSchema = dataSchema.mediaType.length === 1
        ? { const: dataSchema.mediaType[0] }
        : { oneOf: dataSchema.mediaType };
    return {
        type: 'object',
        assetObject: true,
        properties: { pathwayAssetId: { type: 'string' }, mediaType: mediaTypeJsonSchema },
    };
};
export const getAssetMediaTypes = (dataSchema) => {
    if (dataSchema.type === 'asset') {
        return dataSchema.mediaType;
    }
    if (dataSchema.type === 'array' && dataSchema.items.type === 'asset') {
        return getAssetMediaTypes(dataSchema.items);
    }
    return [];
};
export function isAssetDataSchema(schema) {
    return schema.type === 'asset';
}
