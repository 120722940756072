import { styled } from '@mui/material';
import RoundedPaper from 'components/RoundedPaper/RoundedPaper';

export const StatusPaper = styled(RoundedPaper)`
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const ProgressPaper = styled(RoundedPaper)`
  padding: ${({ theme }) => theme.spacing(5, 3)};
`;

export const SubpipelinePaper = styled(RoundedPaper)`
  padding: ${({ theme }) => theme.spacing(2, 3)};
`;
