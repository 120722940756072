import { DEFAULT_CONNECTION_PROPS } from 'features/Flow/Flow.consts';
import { BaseEdge, EdgeProps, getBezierPath } from 'reactflow';
import { getColorDataFromHandle } from 'utils/styles';

export default function NodeEdge(props: EdgeProps) {
  const { id, target, targetHandleId, targetX, source, sourceX, sourceHandleId } = props;

  const [edgePath] = getBezierPath({
    ...props,
    sourceX: sourceX + 10,
    targetX: targetX - 19,
  });
  const markerEndId = `marker-end:${id}`;

  const sourceColorData = getColorDataFromHandle({
    nodeId: source,
    type: 'source',
    handleId: sourceHandleId,
  });
  const targetColorData = getColorDataFromHandle({
    nodeId: target,
    type: 'target',
    handleId: targetHandleId,
  });
  const colorReference =
    sourceColorData?.reference ?? targetColorData?.reference ?? DEFAULT_CONNECTION_PROPS.reference;
  const colorMap = {
    source: sourceColorData,
    target: targetColorData,
    default: sourceColorData ?? targetColorData,
  };
  const colorData = colorMap[colorReference];
  const color = colorData?.color ?? DEFAULT_CONNECTION_PROPS.color;

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={`url(#${markerEndId})`}
        style={{
          stroke: color,
          strokeWidth: DEFAULT_CONNECTION_PROPS.strokeWidth,
        }}
      />
      <defs>
        <marker
          id={markerEndId}
          viewBox="0 0 10 10"
          refX="1"
          refY="5"
          markerWidth="5"
          markerHeight="5"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d={DEFAULT_CONNECTION_PROPS.arrowPath} fill={color} />
        </marker>
      </defs>
    </>
  );
}
