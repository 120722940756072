import { Typography } from '@mui/material';
import styled from 'styled-components';

export const ContainedLabel = styled(Typography)`
  background: ${({ theme }) => theme.palette.surface.level2};
  display: block;
  padding: 8px 12px;
  border-radius: 8px;
`;

export const ImageLayout = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing(3)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
