import { Box, Grid } from '@mui/material';
import { green, yellow } from '@mui/material/colors';
import { NodeType } from 'features/Flow/Flow.types';
import ArrowIcon from 'components/Icons/Arrow';
import styled, { css } from 'styled-components';
import { DataTypeColor } from './components/NodeDataIcon/NodeDataIcon.consts';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import { isIntegerDataSchema } from './DataSchemas/integerSchema';
import { isTextDataSchema } from './DataSchemas/textSchema';
import { isNumberDataSchema } from './DataSchemas/numberSchema';
import { isObjectDataSchema } from './DataSchemas/objectSchema';
import { isImageURIDataSchema } from './DataSchemas/imageURISchema';
import {
  DataSchema,
  isArrayDataSchema,
  isAssetDataSchema,
  isEnumDataSchema,
  isConstDataSchema,
} from '@pathways/pipeline-schema/web';
import { NODE_ADD_GROUP_SHADOW_CLASSNAME } from 'features/Flow/hooks/useGroupDragEvents';
import {
  FUNCTION_NODE_SELECTED_COLOR,
  FUNCTION_NODE_BACKGROUND_COLOR,
} from 'features/Flow/nodes/Function/Function.consts';
import { isBooleanDataSchema } from './DataSchemas/booleanSchema';
import {
  SUBPIPELINE_NODE_COLOR_LIGHT,
  SUBPIPELINE_NODE_COLOR_MAIN,
} from 'features/Flow/components/ElementSidebar/ElementSidebar.styles';

interface IStyledNodeContainer {
  $error?: string;
  $isSelected: boolean;
  $nodeType?: NodeType;
  $status?: ExecutionStatus;
}

export const NodeContainer = styled(Box)<IStyledNodeContainer>(({
  theme,
  $error,
  $isSelected,
  $nodeType,
  $status,
}) => {
  const status = $error ? ExecutionStatus.FAILED : $status;

  return css`
    background: ${({ theme }) => theme.palette.surface.level0};
    border-radius: 18px;
    width: 320px;
    border: 2px solid;
    border-color: ${theme.palette.divider};

    &.${NODE_ADD_GROUP_SHADOW_CLASSNAME} {
      box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.12);
    }

    ${() => {
      if (!$isSelected) return;

      switch ($nodeType) {
        case NodeType.NEURON:
          return css`
            border-color: ${status === ExecutionStatus.FAILED
              ? theme.palette.error.main
              : theme.palette.primary.main};
          `;
        case NodeType.FUNCTION:
          return css`
            border-color: ${FUNCTION_NODE_SELECTED_COLOR};
          `;

        case NodeType.SUBPIPELINE:
          return css`
            border-color: ${SUBPIPELINE_NODE_COLOR_LIGHT};
          `;

        default:
          return css`
            border-color: ${status === ExecutionStatus.FAILED
              ? theme.palette.error.main
              : theme.palette.info.main};
          `;
      }
    }}
  `;
});

export const NodeHeader = styled(Box)<{
  $nodeType?: NodeType;
}>(
  ({ theme, $nodeType }) => css`
    display: flex;
    height: 44px;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px 16px 0 0;
    padding: 10px;

    ${() => {
      switch ($nodeType) {
        case NodeType.NEURON:
          return css`
            background-color: ${theme.palette.primary.dark};
          `;
        case NodeType.FUNCTION:
          return css`
            background-color: ${FUNCTION_NODE_BACKGROUND_COLOR};
          `;
        case NodeType.SUBPIPELINE:
          return css`
            background-color: ${SUBPIPELINE_NODE_COLOR_MAIN};
          `;

        default:
          return css`
            background-color: ${theme.palette.info.dark};
          `;
      }
    }};
  `,
);

export const NodeDataRow = styled(Box)(
  ({ theme }) => css`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    padding: ${theme.spacing(1)};
    gap: ${theme.spacing(1)};
    cursor: default;

    & + & {
      padding-top: 0;
    }
  `,
);

export const NodeDataColumn = styled(Grid)`
  display: grid;
  align-content: start;
  gap: 8px;
`;

interface DataContainerProps {
  hidden?: boolean;
}

export const DataContainer = styled.div<DataContainerProps>`
  background: ${({ theme }) => theme.palette.surface.level1};
  display: flex;
  align-items: center;
  border-radius: 8px;
  min-width: 0;

  ${({ hidden }) =>
    hidden &&
    css`
      /* Not using 'display' to avoid console warnings on load. */
      opacity: 0;
      position: fixed;
    `}
`;

export const InputContainer = styled(DataContainer)`
  padding-right: 8px;
  justify-content: start;
`;

export const OutputContainer = styled(DataContainer)`
  padding-left: 8px;
  justify-content: end;
`;

interface DataContentProps {
  $fullWidth?: boolean;
}

export const DataContent = styled(Box)<DataContentProps>(
  ({ theme, $fullWidth }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    min-width: 0;

    ${$fullWidth &&
    css`
      justify-content: space-between;
      width: 100%;
    `}
  `,
);

interface ConnectionStatusProps {
  $hasValue?: boolean;
  $isRequired?: boolean;
}

export const ConnectionStatus = styled.div<ConnectionStatusProps>(
  ({ theme, ...props }) => css`
    display: flex;
    align-items: center;
    padding-left: ${theme.spacing(0.5)};
    margin-left: auto;

    svg {
      width: 6px;
      height: 6px;
    }

    circle {
      fill: ${props.$hasValue ? green[400] : props.$isRequired ? yellow[400] : 'primary'};
    }
  `,
);

interface StaticValueIconProps {
  $dataSchema: DataSchema;
}

export const StaticValueIcon = styled(ArrowIcon)<StaticValueIconProps>(
  ({ theme, $dataSchema }) => css`
    width: 8px;
    height: 10px;
    margin-left: ${theme.spacing(-1)};
    fill: ${() => {
      // TODO  We most likely want to decentralize this across the dataTypes
      if (isEnumDataSchema($dataSchema)) {
        return DataTypeColor.ENUM;
      }
      if (isNumberDataSchema($dataSchema) || isIntegerDataSchema($dataSchema)) {
        return DataTypeColor.NUMERICAL;
      }
      if (isImageURIDataSchema($dataSchema)) {
        return DataTypeColor.IMAGE_URL;
      }
      if (isObjectDataSchema($dataSchema)) {
        return DataTypeColor.OBJECT;
      }
      if (isAssetDataSchema($dataSchema)) {
        return DataTypeColor.ASSET;
      }
      if (isTextDataSchema($dataSchema)) {
        return DataTypeColor.TEXT;
      }
      if (isBooleanDataSchema($dataSchema)) {
        return DataTypeColor.BOOLEAN;
      }
      if (isConstDataSchema($dataSchema)) {
        return undefined;
      }
      if (isArrayDataSchema($dataSchema) && $dataSchema.items.type !== null) {
        if (isAssetDataSchema($dataSchema.items)) return DataTypeColor.ASSET;
        if (isTextDataSchema($dataSchema.items)) return DataTypeColor.TEXT;
        if (isObjectDataSchema($dataSchema.items)) return DataTypeColor.OBJECT;
        if (isImageURIDataSchema($dataSchema.items)) return DataTypeColor.IMAGE_URL;
        if (isEnumDataSchema($dataSchema.items)) return DataTypeColor.ENUM;
        if (isNumberDataSchema($dataSchema.items) || isIntegerDataSchema($dataSchema.items))
          return DataTypeColor.NUMERICAL;
      }
    }};
  `,
);
