import { FC } from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { RHFTextFieldTagsProps } from './RHFTextFieldTags.types';

const RHFTextFieldTags: FC<RHFTextFieldTagsProps> = (props) => {
  const { control, rules, type, ...muiTextFieldProps } = props;

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={[]}
      rules={rules}
      render={({ field, fieldState }) => (
        <Autocomplete
          multiple
          options={[]}
          freeSolo
          {...field}
          onChange={(_event, values) => {
            const isNumber = type === 'number';
            if (isNumber) {
              !values.some(isNaN) && field.onChange(values.map(Number));
              return;
            }

            field.onChange(values);
          }}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const props = getTagProps({ index });
              return (
                <Chip
                  {...props}
                  key={props.key}
                  label={option}
                  variant="filled"
                  color="primary"
                  size="small"
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              {...muiTextFieldProps}
              error={fieldState.invalid}
              helperText={
                fieldState.error?.message ? fieldState.error.message : muiTextFieldProps.helperText
              }
              InputLabelProps={{
                ...params.InputLabelProps,
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
        />
      )}
    />
  );
};

export default RHFTextFieldTags;
