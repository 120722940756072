import ColorField from 'components/ColorField/ColorField';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { GroupData, GroupNode } from 'features/Flow/nodes/Group/Group.types';
import BaseGroupForm from './BaseGroupForm';

interface GroupFormProps {
  groupNode: GroupNode;
  readOnly?: boolean;
}

export default function GroupForm(props: GroupFormProps) {
  const { groupNode, readOnly = false } = props;

  const updateNodeData = useUpdateNodeData();

  const updateGroupData = ({ name, description, color }: Partial<GroupData>) => {
    updateNodeData<GroupData>(groupNode.id, (data) => ({
      ...data,
      name: name ?? data.name,
      description: description ?? data.description,
      color: color ?? data.color,
    }));
  };

  const { name, description, color } = groupNode.data;

  return (
    <BaseGroupForm
      label="Group"
      name={name}
      description={description ?? ''}
      onChangeData={updateGroupData}
      readOnly={readOnly}
    >
      <ColorField
        fullWidth
        name="groupColor"
        label="Group Color"
        value={color}
        size="small"
        onColorClick={(color) => {
          updateGroupData({ color });
        }}
        InputProps={{
          readOnly,
        }}
      />
    </BaseGroupForm>
  );
}
