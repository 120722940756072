import { isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import { FlowNode } from 'types/reactflow';
import { isJobNode } from 'utils/neurons';
import { GroupElementLayerButton } from './components/GroupElementLayerButton/GroupElementLayerButton';
import { JobElementLayerButton } from './components/JobElementLayerButton/JobElementLayerButton';
import { isBatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';

interface ElementLayerButtonProps {
  node: FlowNode;
  nested?: boolean;
}

export default function ElementLayerButton(props: ElementLayerButtonProps) {
  const { node, nested } = props;

  if (isGroupNode(node) || isBatchGroupNode(node)) {
    return <GroupElementLayerButton {...props} node={node} />;
  }

  if (isJobNode(node)) {
    if (!nested && node.parentNode) return null;

    return <JobElementLayerButton {...props} node={node} />;
  }

  return null;
}
