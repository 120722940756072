class CustomError extends Error {}

export const isCustomError = (error: unknown): error is CustomError => {
  return error instanceof CustomError;
};

export class NotFoundError extends CustomError {}

export class MissingWorkspaceError extends CustomError {}

export class UnauthorizedError extends CustomError {}
