import { SvgIcon, SvgIconProps } from '@mui/material';

const AddNeuronIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M15 14H13.75C13.5375 14 13.3594 13.9285 13.2156 13.7856C13.0719 13.6427 13 13.4656 13 13.2544C13 13.0431 13.0719 12.8646 13.2156 12.7188C13.3594 12.5729 13.5375 12.5 13.75 12.5H15V11.25C15 11.0375 15.0715 10.8594 15.2144 10.7156C15.3573 10.5719 15.5344 10.5 15.7456 10.5C15.9569 10.5 16.1354 10.5719 16.2812 10.7156C16.4271 10.8594 16.5 11.0375 16.5 11.25V12.5H17.75C17.9625 12.5 18.1406 12.5715 18.2844 12.7144C18.4281 12.8573 18.5 13.0344 18.5 13.2456C18.5 13.4569 18.4281 13.6354 18.2844 13.7812C18.1406 13.9271 17.9625 14 17.75 14H16.5V15.25C16.5 15.4625 16.4285 15.6406 16.2856 15.7844C16.1427 15.9281 15.9656 16 15.7544 16C15.5431 16 15.3646 15.9281 15.2188 15.7844C15.0729 15.6406 15 15.4625 15 15.25V14ZM3.5 17C3.09722 17 2.74653 16.8507 2.44792 16.5521C2.14931 16.2535 2 15.9028 2 15.5V4.5C2 4.0875 2.14931 3.73438 2.44792 3.44063C2.74653 3.14688 3.09722 3 3.5 3H14.5C14.9125 3 15.2656 3.14688 15.5594 3.44063C15.8531 3.73438 16 4.0875 16 4.5V8.25C16 8.4625 15.9285 8.64063 15.7856 8.78438C15.6427 8.92813 15.4656 9 15.2544 9C15.0431 9 14.8646 8.92813 14.7188 8.78438C14.5729 8.64063 14.5 8.4625 14.5 8.25V7H3.5V15.5H12.875C13.0875 15.5 13.2656 15.5715 13.4094 15.7144C13.5531 15.8573 13.625 16.0344 13.625 16.2456C13.625 16.4569 13.5531 16.6354 13.4094 16.7812C13.2656 16.9271 13.0875 17 12.875 17H3.5Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default AddNeuronIcon;
