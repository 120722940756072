import axios from 'axios';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';
import {
  DOWNLOAD_ASSET_KEY,
  DownloadAssetConfig,
  DownloadAssetResponse,
} from './useDownloadAsset.types';

const downloadAssetFetcher: MutationFetcher<
  DownloadAssetResponse,
  typeof DOWNLOAD_ASSET_KEY,
  string
> = (_key, { arg: assetId }) => axios.post('/assets/download', { assetId });

const useDownloadAsset = (config?: DownloadAssetConfig) => {
  const {
    data: response,
    error,
    isMutating,
    trigger,
    reset,
  } = useSWRMutation(DOWNLOAD_ASSET_KEY, downloadAssetFetcher, config);

  const downloadAsset = async (assetId: string, config?: DownloadAssetConfig) => {
    const response = await trigger(assetId, config);
    const link = document.createElement('a');
    link.href = response.data.url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return {
    response,
    downloadAssetError: error,
    isDownloading: isMutating,
    downloadAsset,
    reset,
  };
};

export default useDownloadAsset;
