import { useCallback } from 'react';
import { useStoreApi } from 'reactflow';
import { FlowNode } from 'types/reactflow';
import { NodeUpdateChange, NodeUpdater, createNodeUpdateChange } from '../changes/NodeUpdateChange';

type UseUpdateNodeParams = Omit<NodeUpdateChange, 'type' | 'updater'>;

export function useUpdateNode() {
  const storeApi = useStoreApi();

  return useCallback(
    <TNode extends FlowNode>(params: UseUpdateNodeParams, updater: NodeUpdater<TNode>) => {
      storeApi.getState().onNodesChange?.([
        createNodeUpdateChange<TNode>({
          nodeId: params.nodeId,
          updater,
        }),
      ]);
    },
    [storeApi],
  );
}
