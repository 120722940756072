import { concat, isNil, uniq } from 'lodash';
function mergeSchema(defaults, variation) {
    return {
        ...defaults,
        ...variation,
        properties: {
            ...defaults.properties,
            ...variation.properties,
        },
        required: uniq(concat(defaults.required, variation.required)).filter((val) => {
            return !isNil(val);
        }),
    };
}
export function flattenSchemaVariations(schema, variation) {
    const variationSchema = schema.variations?.options.find((v) => v.title === variation);
    if (!variationSchema) {
        return {
            input: schema.input,
            output: schema.output,
        };
    }
    const input = mergeSchema(schema.input, variationSchema.input);
    const output = mergeSchema(schema.output, variationSchema.output);
    return {
        input,
        output,
    };
}
