import { Box, Typography } from '@mui/material';
import { NodeObjectValue } from 'features/Flow/nodes/Node/Node.types';
import { useJsonEditorState } from 'components/JsonEditor/useJsonEditorState';
import * as Styled from '../../CheckpointForm.styles';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import { CopyButton } from 'components/CopyButton/CopyButton.styles';
import { useEffect } from 'react';
import { UseControllerReturn, useFormContext } from 'react-hook-form';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';

type BaseProps = Pick<ExecutionCheckpointFormProps, 'disabled'>;

interface ExecutionCheckpointObjectListJsonEditorProps extends BaseProps {
  controller: UseControllerReturn<Record<string, NodeObjectValue[]>>;
  initialValue: NodeObjectValue[];
}

const ExecutionCheckpointObjectListJsonEditor = ({
  disabled,
  controller,
  initialValue,
}: ExecutionCheckpointObjectListJsonEditorProps) => {
  const { content, error, value, onChange: onJsonChange } = useJsonEditorState(initialValue);
  const { setError } = useFormContext();
  const {
    field: { onChange: controllerOnChange },
  } = controller;

  useEffect(() => {
    setError(controller.field.name, error ? { message: 'Invalid JSON list' } : {});
  }, [controller.field.name, error, setError]);

  useEffect(() => {
    controllerOnChange(value);
  }, [controllerOnChange, value]);

  return (
    <Box>
      <Styled.ExecutionCheckpointApprovalHeader>
        <Typography variant="labelSmall">JSON Data</Typography>
        <CopyButton
          sx={{ marginRight: 1 }}
          value={JSON.stringify(controller.field.value, null, 2)}
        />
      </Styled.ExecutionCheckpointApprovalHeader>
      <Styled.EditorContainer>
        <JsonEditor content={content} readOnly={disabled} onChange={onJsonChange} isArrayJson />
      </Styled.EditorContainer>
    </Box>
  );
};

export default ExecutionCheckpointObjectListJsonEditor;
