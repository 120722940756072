import Loader from 'components/Loader/Loader';
import { PipelineOutputAccordion } from '../PipelineOutputAccordion/PipelineOutputAccordion';
import usePipelineExecutionOutputs from 'api/services/usePipelineExecutionOutputs/usePipelineExecutionOutputs';
import { ErrorCard } from 'components/ErrorCard/ErrorCard';
import ExecutionOutputByType from './ExecutionOutputByType';
import { Fragment } from 'react';

type BaseProps = ReturnType<typeof usePipelineExecutionOutputs>;

interface ExecutionPipelineOutputsProps extends BaseProps {
  onRetry: () => void;
}

export default function ExecutionPipelineOutputs({
  pipelineExecutionOutputs,
  loadingPipelineExecutionOutputs,
  pipelineExecutionOutputsError,
  onRetry,
}: ExecutionPipelineOutputsProps) {
  if (pipelineExecutionOutputsError) {
    return (
      <ErrorCard
        message="An error occurred and the execution outputs could not be loaded."
        onRetry={onRetry}
      />
    );
  }

  return (
    <>
      {pipelineExecutionOutputs.map((pipelineOutput) => (
        <Loader key={`loader-${pipelineOutput.job.id}`} loading={loadingPipelineExecutionOutputs}>
          <PipelineOutputAccordion job={pipelineOutput.job}>
            {pipelineOutput.outputs.map((output) => {
              const outputProp = {
                ...output,
                title: output.title ?? 'Output',
              };

              return (
                <Fragment key={output.name}>
                  <ExecutionOutputByType key={pipelineOutput.job.id} output={outputProp} />
                </Fragment>
              );
            })}
          </PipelineOutputAccordion>
        </Loader>
      ))}
    </>
  );
}
