import { FlowNode, FlowNodeData } from 'types/reactflow';
import { NodeOutput } from '../nodes/Node/Node.types';
import { applyNodeUpdateDataChange, createNodeUpdateDataChange } from './NodeUpdateDataChange';

export type NodeOutputUpdater<TOutput extends NodeOutput = NodeOutput> = (
  prev: TOutput,
) => Partial<TOutput>;

export interface NodeUpdateOutputChange<TOutput extends NodeOutput = NodeOutput> {
  nodeId: string;
  outputName: string;
  type: 'updateOutput';
  updater: NodeOutputUpdater<TOutput>;
}

export function createNodeUpdateOutputChange<TOutput extends NodeOutput>(
  params: Omit<NodeUpdateOutputChange<TOutput>, 'type'>,
): NodeUpdateOutputChange {
  return {
    type: 'updateOutput',
    nodeId: params.nodeId,
    outputName: params.outputName,
    updater: params.updater as unknown as NodeOutputUpdater,
  };
}

export function applyNodeUpdateOutputChange(change: NodeUpdateOutputChange, nodes: FlowNode[]) {
  const { nodeId, outputName, updater } = change;
  const dataChange = createNodeUpdateDataChange<WithOutputs<NodeOutput>>({
    nodeId,
    updater: (data) => {
      if (!hasOutputs<NodeOutput>(data)) {
        return data;
      }

      const nextOutputs = data.outputs.map((output) => {
        if (output.name !== outputName) {
          return output;
        }

        const updates = updater(output);
        const nextOutput: typeof output = {
          ...output,
          ...updates,
        };

        return nextOutput;
      });

      return {
        outputs: nextOutputs,
      };
    },
  });
  return applyNodeUpdateDataChange(dataChange, nodes);
}

interface WithOutputs<TOutput> extends FlowNodeData {
  outputs: TOutput[];
}

function hasOutputs<TOutput>(data: Partial<WithOutputs<TOutput>>): data is WithOutputs<TOutput> {
  return !!data.outputs;
}
