import { Box } from '@mui/material';
import { blue, deepPurple, teal } from '@mui/material/colors';
import styled, { css } from 'styled-components';
import { AddElementButton } from './AddElementButton.styles';

export { SectionDivider } from 'components/Sidebar/Sidebar.styles';

export const AddNeuronButton = styled(AddElementButton)`
  ${() => css`
    background: linear-gradient(92deg, ${deepPurple[700]} 0%, ${deepPurple[400]} 100%);

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.08) 0%,
          rgba(255, 255, 255, 0.08) 100%
        ),
        linear-gradient(92deg, ${deepPurple[700]} 0%, ${deepPurple[400]} 100%);
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
        linear-gradient(92deg, ${deepPurple[700]} 0%, ${deepPurple[400]} 100%);
    }
  `}
`;

export const AddFunctionButton = styled(AddElementButton)`
  background: linear-gradient(92deg, ${teal[700]} 0%, ${teal[400]} 100%);

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%),
      linear-gradient(92deg, ${teal[700]} 0%, ${teal[400]} 100%);
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
      linear-gradient(92deg, ${teal[700]} 0%, ${teal[400]} 100%);
  }
`;

export const AddCheckpointButton = styled(AddElementButton)`
  ${() => css`
    background: linear-gradient(92deg, ${blue[700]} 0%, ${blue[500]} 100%);

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.08) 0%,
          rgba(255, 255, 255, 0.08) 100%
        ),
        linear-gradient(92deg, ${blue[700]} 0%, ${blue[500]} 100%);
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
        linear-gradient(92deg, ${blue[700]} 0%, ${blue[500]} 100%);
    }
  `}
`;

export const SUBPIPELINE_NODE_COLOR_MAIN = '#9C7000';
export const SUBPIPELINE_NODE_COLOR_LIGHT = '#F6CE41';
export const AddPipelineButton = styled(AddElementButton)`
  background: linear-gradient(
    92deg,
    ${SUBPIPELINE_NODE_COLOR_MAIN} 0%,
    ${SUBPIPELINE_NODE_COLOR_LIGHT} 100%
  );

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%),
      linear-gradient(
        92deg,
        ${SUBPIPELINE_NODE_COLOR_MAIN} 0%,
        ${SUBPIPELINE_NODE_COLOR_LIGHT} 100%
      );
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
      linear-gradient(
        92deg,
        ${SUBPIPELINE_NODE_COLOR_MAIN} 0%,
        ${SUBPIPELINE_NODE_COLOR_LIGHT} 100%
      );
  }
`;

export const ElementLabelContainer = styled(Box)`
  display: flex;
  width: 100%;
`;
