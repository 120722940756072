import { useContext } from 'react';
import { EditorContext } from '../EditorContext';

export const useEditorContext = () => {
  const context = useContext(EditorContext);
  if (!context)
    throw new Error('Could not find EditorContext, did you wrap the component in a provider?');

  return context;
};
