import { Connection, Edge, NodeProps } from 'reactflow';
import { NodeData, NodeInput } from 'features/Flow/nodes/Node/Node.types';
import { PIPELINE_COMPLETE_NODE_ID } from 'features/Flow/nodes/PipelineComplete/PipelineComplete.consts';
import { HandleConnection, NodeType } from 'features/Flow/Flow.types';
import { FlowNode, FlowNodeBase, FlowNodeData } from 'types/reactflow';
import { CustomHandleData } from 'features/Flow/Handles/handle.store';

export type PipelineCompleteProps = NodeProps<PipelineCompleteData>;

export interface PipelineCompleteData extends FlowNodeData {
  metadata: {
    type: NodeType.PIPELINE_COMPLETE;
  };
  outputs?: NodeData['outputs']; // TODO Remove this when we activate PipelineComplete V2
  inputs: NodeData['inputs'];
}

export interface PipelineCompleteNode extends FlowNodeBase<PipelineCompleteData> {
  type: NodeType.PIPELINE_COMPLETE;
}

export function isPipelineCompleteNode(
  node?: Pick<FlowNode, 'id' | 'type'>,
): node is PipelineCompleteNode {
  return node?.id === PIPELINE_COMPLETE_NODE_ID && node.type === NodeType.PIPELINE_COMPLETE;
}

export interface CompleteAddHandle extends Omit<NodeInput, 'config' | 'dataSchema'> {
  id: string;
  handle: CustomHandleData;
}

export interface CompleteInput extends NodeInput {
  id: string;
}

export type CompleteInputConnection = HandleConnection;

export type CompleteInputEdge = Edge<CompleteInput> &
  CompleteInputConnection & {
    data: CompleteInput;
  };

export function isCompleteInputConnection(
  connection?: Connection | Edge,
): connection is CompleteInputConnection {
  return connection?.target === PIPELINE_COMPLETE_NODE_ID;
}

export function isCompleteNodeInputEdge(edge?: Edge<CompleteInput>): edge is CompleteInputEdge {
  return isCompleteInputConnection(edge) && !!edge.data;
}
