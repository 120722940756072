import { SvgIcon, SvgIconProps } from '@mui/material';

const AddCheckpointIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M3.5 16.25V11H8.5L8.85417 12.4375C8.89156 12.6016 8.97881 12.7363 9.11592 12.8418C9.25303 12.9473 9.40883 13 9.58333 13H13.25C13.4625 13 13.6406 12.9281 13.7844 12.7844C13.9281 12.6406 14 12.4625 14 12.25V5.75C14 5.5375 13.9281 5.35937 13.7844 5.21562C13.6406 5.07187 13.4625 5 13.25 5H9.5L9.14583 3.5625C9.10917 3.39844 9.02361 3.26368 8.88917 3.15821C8.75472 3.05274 8.59544 3 8.41133 3H2.75C2.5375 3 2.35938 3.07187 2.21563 3.21562C2.07188 3.35937 2 3.5375 2 3.75V16.25C2 16.4625 2.07292 16.6406 2.21875 16.7844C2.36458 16.9281 2.54313 17 2.7544 17C2.96565 17 3.14273 16.9281 3.28565 16.7844C3.42855 16.6406 3.5 16.4625 3.5 16.25ZM15.25 15.75H13.7292C13.5347 15.75 13.3646 15.6781 13.2188 15.5344C13.0729 15.3906 13 15.2125 13 15C13 14.8056 13.0719 14.6319 13.2156 14.4792C13.3594 14.3264 13.5375 14.25 13.75 14.25H15.25V12.75C15.25 12.5375 15.3215 12.3594 15.4644 12.2156C15.6073 12.0719 15.7844 12 15.9956 12C16.2069 12 16.3845 12.0719 16.5286 12.2156C16.6727 12.3594 16.7448 12.5375 16.7448 12.75V14.25H18.2483C18.4613 14.25 18.6398 14.3219 18.7839 14.4656C18.928 14.6094 19 14.7875 19 15C19 15.2125 18.928 15.3906 18.7839 15.5344C18.6398 15.6781 18.4613 15.75 18.2483 15.75H16.7448V17.25C16.7448 17.4625 16.6727 17.6406 16.5286 17.7844C16.3845 17.9281 16.2069 18 15.9956 18C15.7844 18 15.6073 17.9281 15.4644 17.7844C15.3215 17.6406 15.25 17.4625 15.25 17.25V15.75Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default AddCheckpointIcon;
