import { Box, Paper, Stack, Typography } from '@mui/material';
import { PipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution.types';
import { SectionDivider } from 'components/Sidebar/Sidebar.styles';

export default function ExecutionSummary(props: { execution: PipelineExecution }) {
  const { execution } = props;

  return (
    <Paper
      sx={{
        height: '100%',
        padding: 2,
      }}
    >
      <Paper
        elevation={2}
        sx={{
          padding: 1.5,
        }}
      >
        <Stack spacing={1}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="labelSmall" color={({ palette }) => palette.text.secondary}>
              Elements Completed
            </Typography>
            <Typography variant="labelSmall">
              {execution.completedJobs} / {execution.totalJobs}
            </Typography>
          </Box>

          <SectionDivider $addMargin={false} />

          <Box display="flex" justifyContent="space-between">
            <Typography variant="labelSmall" color={({ palette }) => palette.text.secondary}>
              Percent Completion
            </Typography>
            <Typography variant="labelSmall">{execution.progressPercentage}%</Typography>
          </Box>
        </Stack>
      </Paper>
    </Paper>
  );
}
