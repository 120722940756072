import { useMemo } from 'react';
import { useNodes } from 'reactflow';
import { FlowNodeData } from 'types/reactflow';
import { filterGroupChildNodes } from 'utils/group';

export default function useGroupChildNodes(groupId: string) {
  const nodes = useNodes<FlowNodeData>();

  return useMemo(() => filterGroupChildNodes(nodes, groupId), [groupId, nodes]);
}
