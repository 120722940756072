import { NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import PipelineStartCreateForm from '../PipelineStartForms/PipelineStartCreateForm';
import * as Styled from 'components/Dialog/Dialog.styles';

export type PipelineStartQuickCreateFn = (newOutput: NodeOutput) => void;

export interface PipelineStartQuickCreateModalProps {
  outputs: NodeOutput[];
  defaultValues: NodeOutput | null;
  open: boolean;
  onCloseModal: () => void;
  onCreateOutput: PipelineStartQuickCreateFn;
}

export default function PipelineStartQuickCreateModal({
  outputs,
  defaultValues,
  open,
  onCloseModal,
  onCreateOutput,
}: PipelineStartQuickCreateModalProps) {
  return (
    <Styled.Dialog
      open={open}
      onClose={onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <PipelineStartCreateForm
        isPendingConnection
        nodeOutputs={outputs}
        defaultValues={defaultValues}
        onConfirmCreate={onCreateOutput}
        onCancelCreate={onCloseModal}
      />
    </Styled.Dialog>
  );
}
