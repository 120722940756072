import { NodeOutput } from '../../../Node/Node.types';
import { Button, Grid } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { NodeOutputFormData } from '../ObjectExtractor.types';
import ObjectExtractorForm from './ObjectExtractorForm';
import { useCallback, useEffect } from 'react';
import * as Styled from 'components/Dialog/Dialog.styles';
import { nanoid } from 'nanoid';
import { createExtractorDataSchema } from '../ObjectExtractor.utils';

interface ObjectExtractorCreateFormProps {
  defaultValues: NodeOutput | null;
  isPendingConnection?: boolean;
  onCancelCreate: () => void;
  onConfirmCreate: (output: NodeOutput, path: string) => void;
}

const ObjectExtractorCreateForm: React.FC<ObjectExtractorCreateFormProps> = ({
  defaultValues,
  isPendingConnection,
  onCancelCreate,
  onConfirmCreate,
}) => {
  const form = useForm<NodeOutputFormData>({
    defaultValues: {
      path: defaultValues?.name ?? '',
      title: defaultValues?.title ?? '',
      dataType: defaultValues?.dataSchema.type ?? 'text',
    },
    mode: 'onBlur',
  });

  const submitForm: SubmitHandler<NodeOutputFormData> = useCallback(
    (data) => {
      onConfirmCreate(
        {
          name: `output_${nanoid(4)}`,
          dataSchema: createExtractorDataSchema(data),
          title: data.title ?? '',
          config: {
            required: true,
          },
        },
        data.path,
      );
    },
    [onConfirmCreate],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  // Run form validation when defaultValues are provided.
  useEffect(() => {
    if (defaultValues) {
      void form.trigger();
    }
  }, [defaultValues, form]);

  return (
    <FormProvider {...form}>
      <Styled.DialogTitle $hasBorder={true}>Create Output</Styled.DialogTitle>
      <Styled.DialogContent>
        <ObjectExtractorForm
          control={form.control}
          isConnected={false}
          isPendingConnection={isPendingConnection}
        />
      </Styled.DialogContent>
      <Styled.DialogActions $hasBorder={true}>
        <Grid container justifyContent="flex-end">
          <Button variant="text" sx={{ color: 'common.white' }} onClick={onCancelCreate}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!form.formState.isValid}>
            Confirm
          </Button>
        </Grid>
      </Styled.DialogActions>
    </FormProvider>
  );
};

export default ObjectExtractorCreateForm;
