import { BackupOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { isArrayDataSchema, isAssetDataSchema } from '@pathways/pipeline-schema/web';
import AssetInput from 'components/Inputs/AssetUpload/AssetInput';
import { NodeInput, isSignedAssetObjectArray } from 'features/Flow/nodes/Node/Node.types';
import { useRef } from 'react';
import { formatRequiredMessage } from 'utils/message';
import { getFooterMessage } from './ExecutionCheckpoint.utils';
import useExecutionCheckpointContext from './contexts/useExecutionCheckpointContext';

interface Props {
  input: NodeInput;
}

export default function ExecutionCheckpointFooterContent(props: Props) {
  const { input } = props;

  if (isArrayDataSchema(input.dataSchema, 'asset') || isAssetDataSchema(input.dataSchema)) {
    return <AssetListContent input={input} />;
  }

  return <span>{getFooterMessage(input.dataSchema)}</span>;
}

function AssetListContent(props: Props) {
  const { input } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { pipelineExecutionId, contextInputValue, setContextInputValue } =
    useExecutionCheckpointContext();

  if (!pipelineExecutionId) {
    throw new Error(formatRequiredMessage('pipelineExecutionId'));
  }

  return (
    <>
      <AssetInput
        assetScope={{
          type: 'execution',
          executionId: pipelineExecutionId,
        }}
        fileInputOnly
        fileInputRef={fileInputRef}
        input={input}
        label=""
        onConfirmChange={(uploadedAssets) => {
          if (!isSignedAssetObjectArray(contextInputValue)) {
            throw new Error('Context value must be an array');
          }

          setContextInputValue(contextInputValue.concat(uploadedAssets));
        }}
      />
      <Button startIcon={<BackupOutlined />} onClick={() => fileInputRef.current?.click()}>
        Upload New Asset
      </Button>
    </>
  );
}
