import { Edge, updateEdge } from 'reactflow';

type UpdateEdgeParameters = Parameters<typeof updateEdge>;

export interface EdgeUpdateChange {
  newConnection: UpdateEdgeParameters[1];
  oldEdge: UpdateEdgeParameters[0];
  options?: UpdateEdgeParameters[3];
  type: 'update';
}

export function createEdgeUpdateChange(params: Omit<EdgeUpdateChange, 'type'>): EdgeUpdateChange {
  return {
    type: 'update',
    oldEdge: params.oldEdge,
    newConnection: params.newConnection,
  };
}

export function applyEdgeUpdateChange(change: EdgeUpdateChange, edges: Edge[]) {
  const { oldEdge, newConnection, options } = change;
  const nextEdges = updateEdge(oldEdge, newConnection, edges, options);
  return nextEdges;
}
