import PlatformApiSdk from '@monksflow/platform-api-sdk';
import { AxiosError } from 'axios';
import { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';

type Response = Awaited<ReturnType<typeof PlatformApiSdk.getWorkspaceProject>>;
type Error = AxiosError;

export default function useWorkspaceProject(
  workspaceId: string | null | undefined,
  projectId?: string,
  config?: SWRConfiguration,
) {
  const {
    isLoading,
    data: response,
    error,
  } = useSWRImmutable<Response, Error>(
    projectId ? projectId : null,
    projectId ? () => PlatformApiSdk.getWorkspaceProject(workspaceId ?? '', projectId) : null,
    {
      shouldRetryOnError: false,
      ...config,
    },
  );

  return {
    isWorkspaceProjectLoading: isLoading,
    workspaceProject: response?.data,
    workspaceProjectError: error,
  };
}
