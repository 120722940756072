import NodeDataIcon from 'features/Flow/nodes/Node/components/NodeDataIcon/NodeDataIcon';
import styled from 'styled-components';

const DataSchemaIcon = styled(NodeDataIcon)`
  svg {
    border-radius: 5px;
  }
`;

export default DataSchemaIcon;
