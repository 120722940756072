import { Skeleton, Stack } from '@mui/material';
import React from 'react';
import { ElementsLoaderProps } from './ElementsLoader.types';
import * as Styled from './ElementsLoader.styles';

const ElementsLoader: React.FC<ElementsLoaderProps> = (props) => {
  return [...Array(props.quantity).keys()].map((ele) => (
    <Styled.Container data-testid="elements-loader" key={ele}>
      <Skeleton variant="rounded" width={140} height={24} />
      <Styled.ParagraphLoader variant="rounded" width={224} height={12} />
      <Styled.ParagraphLoader variant="rounded" width={200} height={12} />
      <Stack direction="row" gap={1} marginTop={1}>
        <Skeleton variant="rounded" width={40} height={20} />
        <Skeleton variant="rounded" width={40} height={20} />
        <Skeleton variant="rounded" width={40} height={20} />
        <Skeleton variant="rounded" width={40} height={20} />
      </Stack>
    </Styled.Container>
  ));
};

export default ElementsLoader;
