import { useParams } from 'react-router-dom';
import { formatUrlMissingParameterMessage } from 'utils/message';

export default function useWorkspaceSlug(): string {
  const { workspaceSlug } = useParams();

  if (!workspaceSlug) {
    throw new Error(formatUrlMissingParameterMessage('workspaceSlug'));
  }

  return workspaceSlug;
}
