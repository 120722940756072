import { DataSchemaCompatibilityCheck } from '../DataType';
import { DataSchema, ObjectDataSchema } from '@pathways/pipeline-schema';

export const objectCompatibilityCheck: DataSchemaCompatibilityCheck<ObjectDataSchema> = ({
  output,
}) => {
  return output.type === 'object';
};

export function isObjectDataSchema(schema: DataSchema): schema is ObjectDataSchema {
  return schema.type === 'object';
}
