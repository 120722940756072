import { Box, Chip, MenuItem, Paper, Table, TableBody, TableCell } from '@mui/material';
import CustomTableHead from 'components/Table/TableHead';
import * as Styled from 'components/Table/Table.styles';
import ActionsMenu from 'components/ActionsMenu/ActionsMenu';
import PipelineErrorCard from 'pages/DashboardPage/components/PipelineErrorCard/PipelineErrorCard';
import { PipelineVersionItem } from 'api/services/usePipelineVersions/usePipelineVersions.types';
import { MouseEvent, useMemo } from 'react';
import Loader from 'components/Loader/Loader';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { formatDate } from 'utils/string';
import {
  COLUMN_CONFIG_ARRAY,
  ColumnId,
} from 'pages/PipelineVersionsPage/components/PipelineVersionsTable/PipelineVersionsTable.constants';
import { PipelineState } from '@pathways/pipeline-schema/web';
import { Order } from 'utils/order';
import PaginationControls from 'components/PaginationControls/PaginationControls';

const SKELETON_WIDTH = 120;

export interface PipelineVersionsTableProps {
  loading: boolean;
  loadingFailed: boolean;
  pipelineVersions: PipelineVersionItem[];
  totalCount: number;
  pageSize: number;
  pageNumber: number;
  sortBy: ColumnId;
  orderBy: Order;
  onRowClick: (pipelineId: PipelineVersionItem['id']) => void;
  onEditDraft: () => void;
  onViewVersion: (versionId: string) => void;
  onPageNumberChange: (pageNumber: number) => void;
  onSort: (columnId: ColumnId) => void;
}

function menuAction(
  onEditDraft: PipelineVersionsTableProps['onEditDraft'],
  onViewVersion: PipelineVersionsTableProps['onViewVersion'],
  pipelineVersion: PipelineVersionItem,
) {
  return pipelineVersion.state === PipelineState.DRAFT ? (
    <MenuItem
      key={`edit-pipeline-versions-${pipelineVersion.id}`}
      data-testid={`edit-pipeline-versions-${pipelineVersion.id}`}
      onClick={() => {
        onEditDraft();
      }}
    >
      Edit Draft
    </MenuItem>
  ) : (
    <MenuItem
      key={`view-pipeline-versions-${pipelineVersion.id}`}
      data-testid={`view-pipeline-versions-${pipelineVersion.id}`}
      onClick={() => {
        onViewVersion(pipelineVersion.id);
      }}
    >
      View Pipeline
    </MenuItem>
  );
}

export default function PipelineVersionsTable(props: PipelineVersionsTableProps) {
  const {
    loading,
    loadingFailed,
    pipelineVersions,
    sortBy,
    orderBy,
    totalCount,
    pageSize,
    pageNumber,
    onSort,
    onPageNumberChange,
  } = props;
  const canClickRow = !loading;

  const displayError = loadingFailed;
  const disableHeader = loading || displayError;
  const displayRows = !displayError;
  const displayActionsMenu = !loading;

  const rows = useMemo(() => {
    if (loading) {
      return Array<PipelineVersionItem>(5).fill({} as PipelineVersionItem);
    }

    return pipelineVersions;
  }, [loading, pipelineVersions]);

  const createRowClickHandler = (pipelineId: PipelineVersionItem['id']) => (event: MouseEvent) => {
    if (!canClickRow) return;

    const target = event.target as HTMLElement;
    const isButtonTarget = !!target.closest('button');
    const isMenuTarget = !!target.closest('.MuiMenu-root');
    const ignoreEvent = isButtonTarget || isMenuTarget;

    if (ignoreEvent) return;

    props.onRowClick(pipelineId);
  };

  return (
    <Box>
      <Styled.TableContainer component={Paper} elevation={4} sx={{ maxWidth: '95vw' }}>
        <Table aria-label="pipeline-versions" sx={{ minWidth: 880 }}>
          <CustomTableHead
            disabled={disableHeader}
            columns={COLUMN_CONFIG_ARRAY}
            order={orderBy}
            orderBy={sortBy}
            onSort={(columnId) => {
              onSort(columnId as ColumnId);
            }}
          />

          <TableBody>
            {displayRows &&
              rows.map((pipelineVersion, index) => {
                return (
                  <Styled.TableBodyRow
                    hover={!loading}
                    key={pipelineVersion.id || index}
                    data-testid={`table-row-${pipelineVersion.id}`}
                    onClick={createRowClickHandler(pipelineVersion.id)}
                  >
                    <TableCell>
                      <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                        <TextEllipsis color="text.primary" variant="bodyMedium" maxWidth="20vw">
                          {pipelineVersion.version}
                        </TextEllipsis>
                      </Loader>
                    </TableCell>

                    <TableCell>
                      <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                        <TextEllipsis color="text.secondary" variant="bodyMedium" maxWidth="20vw">
                          {pipelineVersion.description}
                        </TextEllipsis>
                      </Loader>
                    </TableCell>
                    <TableCell>
                      <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                        <TextEllipsis color="text.secondary" variant="bodyMedium" maxWidth="20vw">
                          <Chip
                            label={pipelineVersion.state}
                            size="small"
                            variant="filled"
                            color={
                              pipelineVersion.state === PipelineState.DRAFT ? 'default' : 'success'
                            }
                          />
                        </TextEllipsis>
                      </Loader>
                    </TableCell>
                    <TableCell>
                      <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                        <TextEllipsis color="text.secondary" variant="bodyMedium" maxWidth="20vw">
                          {formatDate(pipelineVersion.publishedAt)}
                        </TextEllipsis>
                      </Loader>
                    </TableCell>
                    <TableCell
                      data-testid={`action-table-cell-${pipelineVersion.id}`}
                      align="center"
                    >
                      {displayActionsMenu && (
                        <ActionsMenu
                          renderActions={() => [
                            menuAction(props.onEditDraft, props.onViewVersion, pipelineVersion),
                          ]}
                        />
                      )}
                    </TableCell>
                  </Styled.TableBodyRow>
                );
              })}

            {displayError && (
              <Styled.TableBodyRow>
                <TableCell colSpan={10}>
                  <PipelineErrorCard />
                </TableCell>
              </Styled.TableBodyRow>
            )}
          </TableBody>
        </Table>
      </Styled.TableContainer>
      <PaginationControls
        page={pageNumber + 1}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={onPageNumberChange}
      />
    </Box>
  );
}
