import { PipelineInfo } from '@pathways/pipeline-schema/web';
import { NodeType } from 'features/Flow/Flow.types';
import { Node } from 'reactflow';
import { FlowNode } from 'types/reactflow';
import { NodeData } from '../Node/Node.types';

export interface SubpipelineData extends NodeData {
  pipeline: PipelineInfo;
}

export interface SubpipelineNode extends Node<SubpipelineData> {
  type: NodeType.SUBPIPELINE;
}

export function isSubpipelineNode(node?: Pick<FlowNode, 'type'>): node is SubpipelineNode {
  return node?.type === NodeType.SUBPIPELINE;
}
