import { Backdrop, CircularProgress } from '@mui/material';
import useIsFlowLoading from 'features/Flow/hooks/useIsFlowLoading';

interface Props {
  open: boolean;
}

export default function FlowLoader(props: Props) {
  const isFlowLoading = useIsFlowLoading();
  const open = isFlowLoading || props.open;

  return (
    <Backdrop open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
