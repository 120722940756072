import { Divider, Paper, css, styled } from '@mui/material';
import { CSSProperties } from 'react';
import { forwardTransientProps } from 'utils/styles';

export const Sidebar = styled(Paper)<{
  $borderLeft?: boolean;
  $borderRight?: boolean;
  $width?: CSSProperties['width'];
}>(
  ({ theme, $borderLeft, $borderRight, $width }) => css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: ${theme.spacing(2)};
    overflow-x: hidden;
    overflow-y: auto;

    ${{
      /* https://v5.mui.com/system/sizing */
      width: $width ?? 264,
    }}
    ${$borderLeft &&
    css`
      border-left: 1px solid ${theme.palette.divider};
    `}
    ${$borderRight &&
    css`
      border-right: 1px solid ${theme.palette.divider};
    `}
  `,
);

export const SidebarSection = styled('div')(
  ({ theme }) => css`
    margin: ${theme.spacing(-2)};
  `,
);

export const SectionDivider = styled(Divider, {
  shouldForwardProp: forwardTransientProps,
})<{
  $addMargin?: boolean;
}>(
  ({ theme, $addMargin = true }) => css`
    ${$addMargin &&
    css`
      margin: ${theme.spacing(2, -2)};
    `};
    border-color: ${theme.palette.divider};

    /* Hide the hr element if it's directly below another hr element */
    & + hr {
      display: none;
    }
  `,
);
