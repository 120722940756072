import { Stack, Typography } from '@mui/material';

export default function NoPipelinesCreatedCard() {
  return (
    <Stack
      sx={{
        display: 'grid',
        gap: 2,
        textAlign: 'center',
        maxWidth: 480,
        marginTop: 2,
      }}
    >
      <Typography variant="titleLarge">No pipelines created</Typography>
      <Typography variant="bodyLarge" color="text.secondary">
        No pipelines have been created for this project yet. Create your first pipeline by selecting
        the “Create Pipeline” button above.
      </Typography>
    </Stack>
  );
}
