import styled from 'styled-components';

export const ImageCard = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.palette.surface.level2};
  border-radius: ${({ theme }) => theme.spacing(1)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.75)};
  padding: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 550px;
  width: 100%;

  > img {
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }
`;

export const ImageCardActionsContainer = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
`;
