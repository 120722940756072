import { NeuronSchemaProperty } from 'api/services/useManifests/useManifests.types';
import { SignedAssetObject } from '../features/Flow/nodes/Node/Node.types';

// TODO These functions could live alongside the Asset Schema
export const getAssetObjectBaseMediaType = (mediaType: string): string => {
  return mediaType.split('/')[0];
};

const getAssetObjectMediaType = (
  property: NeuronSchemaProperty,
): NeuronSchemaProperty | undefined => {
  return property.type === 'array'
    ? property.items?.properties?.mediaType
    : property.properties?.mediaType;
};

// Responsible for ensuring the schema `const` and `enum` are all matching the same base type for assets.
export const getAssetMediaTypes = (property: NeuronSchemaProperty): string[] => {
  const mediaTypeSchema = getAssetObjectMediaType(property);

  if (!mediaTypeSchema) throw new Error(`Called getAssetObjectMediaType for a non-asset object`);
  if (mediaTypeSchema.const) {
    return [mediaTypeSchema.const];
  }

  if (mediaTypeSchema.oneOf) return mediaTypeSchema.oneOf;
  if (mediaTypeSchema.enum) return mediaTypeSchema.enum;

  throw new Error('Encountered an asset without a proper media type');
};

const openableAssetTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf'];

export function isOpenableAsset(asset: SignedAssetObject) {
  return openableAssetTypes.includes(asset.mediaType);
}
