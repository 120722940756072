import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';
import { Edge } from 'reactflow';
import { FlowNode, FlowPipeline } from 'types/reactflow';
import { useCallback } from 'react';
import { formatPipelineContent } from 'utils/pipelineContent';

const PUBLISH_PIPELINE_VERSION_KEY = 'publishPipelineVersion';

interface AddPipelineRevisionProps {
  edges: Edge[];
  nodes: FlowNode[];
  pipeline: FlowPipeline;
  pipelineDescription?: string;
}

const publishPipelineVersionFetcher: MutationFetcher<
  AxiosResponse<{ id: string }>,
  typeof PUBLISH_PIPELINE_VERSION_KEY,
  AxiosRequestConfig
> = (_, { arg: request }) => axios.request(request);

const useAddPipelineRevision = () => {
  const { isMutating: isAddingRevision, trigger } = useSWRMutation(
    PUBLISH_PIPELINE_VERSION_KEY,
    publishPipelineVersionFetcher,
  );

  const addRevision = useCallback(
    ({ edges, nodes, pipeline, pipelineDescription }: AddPipelineRevisionProps) => {
      const pipelineContent = formatPipelineContent(nodes, edges);

      const requestConfig: AxiosRequestConfig = {
        url: `/pipelines/${pipeline.id}/revision`,
        method: 'POST',
        data: {
          description: pipelineDescription,
          content: pipelineContent,
        },
      };

      return trigger(requestConfig);
    },
    [trigger],
  );

  return { addRevision, isAddingRevision };
};

export default useAddPipelineRevision;
