import axios, { AxiosResponse } from 'axios';
import {
  CreatePipelineExecutionBody,
  CreatePipelineExecutionResponse,
} from './useCreatePipelineExecution.types';
import useSWRMutation from 'swr/mutation';

const useCreatePipelineExecution = (pipelineId?: string) => {
  const createPipelineExecutionRequest = (
    requestUrl: string,
    { arg }: { arg: CreatePipelineExecutionBody },
  ) =>
    axios.post<
      CreatePipelineExecutionResponse,
      AxiosResponse<CreatePipelineExecutionResponse>,
      CreatePipelineExecutionBody
    >(requestUrl, arg);

  const createPipelineExecutionMutation = useSWRMutation(
    `/pipelines/${pipelineId}/executions`,
    createPipelineExecutionRequest,
  );

  return {
    isCreatePipelineExecutionLoading: createPipelineExecutionMutation.isMutating,
    createPipelineExecution: createPipelineExecutionMutation.trigger,
    ...createPipelineExecutionMutation,
  };
};

export default useCreatePipelineExecution;
