import { Paper, Tab, Tabs } from '@mui/material';
import * as Styled from 'components/Sidebar/Sidebar.styles';
import TabPanel from 'components/TabContainer/TabPanel';
import { ReactNode, SyntheticEvent, useState } from 'react';

interface TabContainerProps {
  ariaLabel?: string;
  tabPanelChildren: ReactNode[];
  tabs: string[];
}

const getTabContainerAriaProps = (index: number, ariaLabel?: string) => {
  const label = ariaLabel ?? 'tabs';

  return {
    id: `${label}-tab-${index}`,
    'aria-controls': `${label}-tabpanel-${index}`,
  };
};

const TabContainer = ({ ariaLabel, tabPanelChildren, tabs }: TabContainerProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_event: SyntheticEvent, index: number) => {
    setActiveTab(index);
  };

  return (
    <Paper elevation={1}>
      <Tabs aria-label={ariaLabel} onChange={handleTabChange} value={activeTab}>
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab} {...getTabContainerAriaProps(index, ariaLabel)} />
        ))}
      </Tabs>

      <Styled.SectionDivider />

      {tabPanelChildren.map((child, index) => (
        <TabPanel key={index} index={index} value={activeTab}>
          {child}
        </TabPanel>
      ))}
    </Paper>
  );
};

export default TabContainer;
