import { Stack } from '@mui/material';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { PathwayNode } from 'features/Flow/nodes/Node/Node.types';
import JobDescriptionTooltip from 'features/Flow/components/JobDescriptionTooltip/JobDescriptionTooltip';
import { getJobTitle } from 'utils/neurons';

interface PathwayNodePanelHeaderProps {
  selectedNode: PathwayNode;
}

const PathwayNodePanelHeader = ({ selectedNode }: PathwayNodePanelHeaderProps) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <TextEllipsis variant="titleSmall">{getJobTitle(selectedNode.data.metadata)}</TextEllipsis>
      <JobDescriptionTooltip job={selectedNode.data.metadata} />
    </Stack>
  );
};

export default PathwayNodePanelHeader;
