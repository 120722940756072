import { SvgIcon, SvgIconProps } from '@mui/material';

const DotIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg width="4" height="4" viewBox="0 0 4 4" fill="white" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" />
      </svg>
    </SvgIcon>
  );
};

export default DotIcon;
