import { Typography } from '@mui/material';
import { ConnectionHandler } from 'features/Flow/EditorContext';
import { isHandleConnection, NodeType } from 'features/Flow/Flow.types';
import { createHandleId, getHandleKeyFromId, useSelectNodeOnClick } from 'features/Flow/Flow.utils';
import { CustomHandleData, HandleKey } from 'features/Flow/Handles/handle.store';
import { useGetSourceHandle } from 'features/Flow/Handles/handles';
import CustomHandle from 'features/Flow/components/NeuronHandle/CustomHandle';
import { useCleanInvalidEdges } from 'features/Flow/hooks/useCleanInvalidEdges';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import useFlow from 'features/Flow/hooks/useFlow';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Connection,
  getConnectedEdges,
  NodeProps,
  useStore,
  useUpdateNodeInternals,
} from 'reactflow';
import { FlowState } from 'types/reactflow';
import { shallow } from 'zustand/shallow';
import NodeInputHandle from '../Node/NodeInputHandle';
import NodeOutputHandle from '../Node/NodeOutputHandle';
import { BATCH_ARRAY_INPUT_NAME } from './Batch.consts';
import * as Styled from './Batch.styles';
import {
  BatchInput,
  BatchInputEdge,
  BatchOutput,
  BatchStartData,
  isBatchStartNode,
} from './Batch.types';
import { createBatchInputEdge, getBatchInputConnectedEdges } from './Batch.utils';
import { isArrayDataSchema } from '@pathways/pipeline-schema/web';

// TODO implement runtime view: runtimeMode, executionJobs.
const selector = ({ edges }: FlowState) => ({
  edges,
});

interface BatchStartAddHandle extends Omit<BatchInput, 'config' | 'dataSchema'> {
  id: string;
  handle: CustomHandleData;
}

interface BatchStartDataHandle {
  input: BatchInput;
  output: BatchOutput;
}

type BatchStartProps = NodeProps<BatchStartData>;

const BatchStart = (props: BatchStartProps) => {
  const { id: nodeId, selected } = props;
  const { getNodeOrThrow, deleteElements } = useFlow();
  const node = useMemo(() => getNodeOrThrow(nodeId, isBatchStartNode), [nodeId, getNodeOrThrow]);

  const updateNodeInternals = useUpdateNodeInternals();
  const selectNodeOnClick = useSelectNodeOnClick(nodeId);
  const { addConnectionHandler, removeConnectionHandler } = useEditorContext();
  const getSourceHandle = useGetSourceHandle();
  const createStaticEdge = useCreateStaticEdge();

  const { edges } = useStore(selector, shallow);
  const customAddArrayHandle = useMemo(() => createAddArrayHandle(nodeId), [nodeId]);
  const [customAddStaticHandle, setCustomAddStaticHandle] = useState(createAddStaticHandle(nodeId));
  const [dataHandles, setDataHandles] = useState<BatchStartDataHandle[]>(() =>
    getBatchInputConnectedEdges(node, edges).map((edge) => mapHandleData(edge.data)),
  );

  const connectedArrayHandle = dataHandles.find((handle) =>
    isArrayDataSchema(handle.input.dataSchema),
  );
  const displayAddArrayHandle = !connectedArrayHandle;
  const displayDataHandles = !!dataHandles.length;

  const handleArrayConnection = useCallback<ConnectionHandler>(
    (connection) => {
      if (!isHandleConnection(connection)) return connection;

      const sourceHandle = getSourceHandle(connection);
      const handle: typeof sourceHandle = {
        ...sourceHandle,
        // Reuse the `id` to keep calling the current connection handler.
        id: customAddArrayHandle.id,
        // Expected name for execution.
        name: BATCH_ARRAY_INPUT_NAME,
      };

      if (!isArrayDataSchema(handle.schema) || handle.schema.items.type == null)
        throw new Error('only typed arrays can be connected');

      return createBatchInputEdge(connection, handle);
    },
    [customAddArrayHandle.id, getSourceHandle],
  );

  useEffect(() => {
    const connectionHandlerKey: HandleKey = {
      id: customAddArrayHandle.id,
      nodeId,
    };
    addConnectionHandler?.(connectionHandlerKey, handleArrayConnection);

    return () => {
      removeConnectionHandler?.(connectionHandlerKey);
    };
  }, [
    addConnectionHandler,
    customAddArrayHandle.id,
    handleArrayConnection,
    nodeId,
    removeConnectionHandler,
  ]);

  useEffect(() => {
    const connectionHandlerKey: HandleKey = {
      id: customAddStaticHandle.id,
      nodeId,
    };
    // Handles edge creation only.
    addConnectionHandler?.(connectionHandlerKey, (connection) => {
      setCustomAddStaticHandle(createAddStaticHandle(nodeId));

      return createStaticEdge(connection, customAddStaticHandle.id);
    });

    return () => {
      removeConnectionHandler?.(connectionHandlerKey);
    };
  }, [
    addConnectionHandler,
    createStaticEdge,
    customAddStaticHandle.id,
    nodeId,
    removeConnectionHandler,
  ]);

  useEffect(() => {
    const inputConnectedEdges = getBatchInputConnectedEdges(node, edges);
    const nextHandles = inputConnectedEdges
      .map<BatchStartDataHandle>((edge) => mapHandleData(edge.data))
      // Sort the array handle to be always first.
      // When updating an edge, it gets pushed.
      .sort((a) => {
        if (isArrayDataSchema(a.input.dataSchema)) return -1;
        return 0;
      });

    setDataHandles(nextHandles);

    const staticHandles = nextHandles.slice(1);

    staticHandles.forEach((handle) => {
      const connectionHandlerKey: HandleKey = {
        id: handle.input.id,
        nodeId,
      };
      // Handles edges for static handles only.
      addConnectionHandler?.(connectionHandlerKey, (connection) =>
        createStaticEdge(connection, handle.input.id),
      );
    });

    return () => {
      staticHandles.forEach((handle) => {
        const connectionHandlerKey = {
          id: handle.input.id,
          nodeId,
        };
        removeConnectionHandler?.(connectionHandlerKey);
      });
    };
  }, [addConnectionHandler, createStaticEdge, edges, node, nodeId, removeConnectionHandler]);

  useEffect(() => {
    updateNodeInternals(nodeId);
    // 'dataHandles' is required as a dependency to allow creating connections.
  }, [dataHandles, nodeId, updateNodeInternals]);

  // This effect removes all connections when the required array handle is disconnected.
  useEffect(() => {
    const inputConnectedEdges = getBatchInputConnectedEdges(node, edges);
    const isArrayInputConnected = inputConnectedEdges.some((edge) =>
      isArrayDataSchema(edge.data.dataSchema),
    );

    if (isArrayInputConnected) return;

    const nodeConnectedEdges = getConnectedEdges([node], edges);

    deleteElements({
      edges: nodeConnectedEdges,
    });
  }, [deleteElements, displayAddArrayHandle, displayDataHandles, edges, node]);

  const cleanInvalidEdges = useCleanInvalidEdges();

  // This effect removes invalid connections.
  // When a child node in the group is connected to a static output,
  // and then the static input is disconnected,
  // removes the output but leaves an invalid edge.
  useEffect(() => {
    const handleIds = dataHandles.flatMap((handleData) => [
      handleData.input.id,
      handleData.output.id,
    ]);

    cleanInvalidEdges(node, handleIds);
  }, [cleanInvalidEdges, dataHandles, node]);

  return (
    <Styled.NodeContainer
      $nodeType={NodeType.FUNCTION}
      $isSelected={selected}
      onClick={selectNodeOnClick}
    >
      <Styled.NodeHeader $nodeType={NodeType.FUNCTION}>
        <Typography variant="labelLarge">Batch Start</Typography>
      </Styled.NodeHeader>

      {displayAddArrayHandle && (
        <Styled.NodeDataRow className="nodrag">
          <Styled.NodeDataColumn>
            <CustomHandle handle={customAddArrayHandle.handle} title={customAddArrayHandle.title} />
          </Styled.NodeDataColumn>
        </Styled.NodeDataRow>
      )}

      {displayDataHandles && (
        <>
          <Styled.NodeDataRow className="nodrag">
            <Styled.NodeDataColumn>
              {dataHandles.map(({ input }) => (
                <NodeInputHandle key={input.id} nodeId={nodeId} input={input} />
              ))}
            </Styled.NodeDataColumn>

            <Styled.NodeDataColumn>
              {dataHandles.map(({ output }) => (
                <NodeOutputHandle key={output.id} nodeId={nodeId} output={output} />
              ))}
            </Styled.NodeDataColumn>
          </Styled.NodeDataRow>

          <Styled.NodeDataRow className="nodrag">
            <Styled.NodeDataColumn>
              <CustomHandle
                handle={customAddStaticHandle.handle}
                title={customAddStaticHandle.title}
              />
            </Styled.NodeDataColumn>
          </Styled.NodeDataRow>
        </>
      )}
    </Styled.NodeContainer>
  );
};

export default BatchStart;

function createAddArrayHandle(nodeId: string): BatchStartAddHandle {
  const handleId = createHandleId('input', BATCH_ARRAY_INPUT_NAME);

  return {
    id: handleId,
    name: 'connect_array',
    title: 'Connect Array',
    handle: {
      type: 'custom',
      purpose: 'input',
      id: handleId,
      nodeId,
      canConnect: ({ schema }) => isArrayDataSchema(schema),
    },
  };
}

function createAddStaticHandle(nodeId: string): BatchStartAddHandle {
  const handleId = createHandleId('input', nanoid(4));

  return {
    id: handleId,
    name: 'connect_static_values',
    title: 'Connect Static Values',
    handle: {
      type: 'custom',
      purpose: 'input',
      id: handleId,
      nodeId,
      canConnect: ({ schema }) => !isArrayDataSchema(schema),
    },
  };
}

function mapHandleData(input: BatchInputEdge['data']): BatchStartDataHandle {
  const handleKey = getHandleKeyFromId(input.id) ?? '';

  return {
    input,
    // Outputs are mirrored from the inputs.
    output: {
      config: input.config,
      dataSchema:
        isArrayDataSchema(input.dataSchema) && input.dataSchema.items.type != null
          ? input.dataSchema.items
          : input.dataSchema,
      description: input.description,
      id: createHandleId('output', handleKey),
      name: input.name,
      title: input.title,
    },
  } satisfies BatchStartDataHandle;
}

// Hooks

function useCreateStaticEdge() {
  const getSourceHandle = useGetSourceHandle();

  return useCallback(
    (connection: Connection, handleId: string) => {
      if (!isHandleConnection(connection)) return connection;

      const sourceHandle = getSourceHandle(connection);
      const handle: typeof sourceHandle = {
        ...sourceHandle,
        id: handleId,
        name: getHandleKeyFromId(handleId) ?? '',
      };

      return createBatchInputEdge(connection, handle);
    },
    [getSourceHandle],
  );
}
