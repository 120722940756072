import PlatformApiSdk from '@monksflow/platform-api-sdk';
import { FC, ReactNode } from 'react';
import useSWRImmutable from 'swr/immutable';
import AuthContext from './AuthContext';
import { AuthContextValue } from './AuthContext.types';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const response = useSWRImmutable(
    'authenticated-user',
    () => PlatformApiSdk.getAuthenticatedUser(),
    {
      errorRetryCount: 3,
      errorRetryInterval: 1000,
    },
  );
  const user = response.data?.data;

  const authContextProviderValue: AuthContextValue = {
    isUserAuthenticated: !!user,
    user,
  };

  return <AuthContext.Provider value={authContextProviderValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
