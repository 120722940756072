import { Typography } from '@mui/material';
import { Layout, LayoutContainer } from 'components/Layout/Layout.styles';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import FlowLoader from 'features/Flow/components/FlowLoader/FlowLoader';
import usePipelineId from 'hooks/usePipelineId';
import ReadOnlyFlow from 'pages/ReadOnlyPipelinePage/components/ReadOnlyFlow/ReadOnlyFlow';
import StateVersionChip from 'pages/ReadOnlyPipelinePage/components/StateVersionChip/StateVersionChip';
import { ReactFlowProvider } from 'reactflow';
import { useAppRoutes } from 'utils/routes';
import { usePipelineVersion } from 'api/services/usePipelineVersion/usePipelineVersion';
import usePipelineVersionId from 'hooks/usePipelineVersionId';
import BackButton from 'components/BackButton/BackButton';

const ReadOnlyPipelinePage = () => {
  const pipelineId = usePipelineId();
  const versionId = usePipelineVersionId();
  const { pipeline, isPipelineLoading } = usePipelineVersion(pipelineId, versionId);
  const routes = useAppRoutes();

  return (
    <ReactFlowProvider>
      <Layout>
        <NavigationBar
          hideHome
          title={pipeline?.name ?? ''}
          firstBreadcrumb={{
            label: (
              <BackButton to={routes.versions(pipelineId)}>
                <Typography variant="titleMedium">View Pipeline Version</Typography>
              </BackButton>
            ),
          }}
        >
          {pipeline && (
            <StateVersionChip state={pipeline.pipelineState} version={pipeline.version} />
          )}
        </NavigationBar>
        <LayoutContainer>{pipeline && <ReadOnlyFlow pipeline={pipeline} />}</LayoutContainer>
      </Layout>
      <FlowLoader open={isPipelineLoading} />
    </ReactFlowProvider>
  );
};

export default ReadOnlyPipelinePage;
