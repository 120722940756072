import { Stack, Typography } from '@mui/material';
import * as Styled from './ProgressCard.styles';

interface ProgressCardProps {
  label: string;
  completedCount: number;
  totalCount: number;
}

export default function ProgressCard(props: ProgressCardProps) {
  const { completedCount, totalCount, label } = props;

  return (
    <Styled.Container elevation={2}>
      <Stack gap={2} alignItems="center">
        <Stack direction="row" alignItems="baseline" gap={1}>
          <Typography variant="headlineLarge">{completedCount}</Typography>
          <Typography variant="headlineSmall" color="text.disabled">
            of
          </Typography>
          <Typography variant="headlineLarge">{totalCount}</Typography>
        </Stack>
        <Typography variant="bodyLarge" color="text.secondary">
          {label}
        </Typography>
      </Stack>
    </Styled.Container>
  );
}
