import { useMemo } from 'react';
import { getConnectedEdges, HandleProps, useNodeId, useStore } from 'reactflow';
import { FlowState } from 'types/reactflow';
import * as Styled from './NeuronHandle.styles';

import { useGetHandle } from 'features/Flow/Handles/handles';
import { shallow } from 'zustand/shallow';
import {
  AppHandle,
  computeHandleKey,
  toFlowHandlePosition,
  toFlowHandleType,
} from 'features/Flow/Handles/handle.store';
import { compatibilityCheck, DataConnection } from 'features/Flow/nodes/Node/DataType';
import { useBatchConnectionCheck } from './useBatchConnectionCheck';
import { DataSchema } from '@pathways/pipeline-schema';

interface Props
  extends Omit<
    HandleProps,
    'isConnectable' | 'type' | 'position' | 'isConnectableEnd' | 'isConnectableStart' | 'id'
  > {
  children: React.ReactNode;
  handle: AppHandle;
  maxConnections?: number;
}

const selector = ({ edges, updatingEdge, getNodes, connectionStartHandle }: FlowState) => ({
  edges,
  updatingEdge,
  getNodes,
  connectionStartHandle,
});

// TODO rename to NodeHandle? ConnectionHandle? IOHandle?
export default function NeuronHandle(props: Props) {
  const { maxConnections, handle, ...handleProps } = props;
  const { edges, updatingEdge, getNodes, connectionStartHandle } = useStore(selector, shallow);
  const nodeId = useNodeId();
  const nodes = getNodes();
  const node = useMemo(() => nodes.find((node) => node.id === nodeId), [nodes, nodeId]);
  const { purpose } = handle;

  if (!node) throw new Error(`Node "${nodeId}" not found.`);
  const { validateBatchConnectionRules } = useBatchConnectionCheck(node);
  const getHandle = useGetHandle();

  const isConnectable = useMemo(() => {
    const nodeConnectedEdges = getConnectedEdges([node], edges);
    const handleConnectedEdges = nodeConnectedEdges.filter((edge) => {
      // Exclude the already connected edge.
      if (edge.id === updatingEdge?.id) return false;

      const flowHandleType = toFlowHandleType(purpose);

      return edge[flowHandleType] === node.id && edge[`${flowHandleType}Handle`] === handle.id;
    });

    if (maxConnections) {
      return handleConnectedEdges.length < maxConnections;
    }

    return true;
  }, [edges, handle.id, maxConnections, node, purpose, updatingEdge?.id]);

  const isConnectableStart = useMemo(
    () =>
      connectionStartHandle
        ? connectionStartHandle.nodeId === node.id && connectionStartHandle.handleId === handle.id
        : true,
    [connectionStartHandle, node.id, handle.id],
  );

  const isConnectableEnd = useMemo(() => {
    if (connectionStartHandle === null) return true;
    if (!isConnectable) return false;

    const isSameNode = connectionStartHandle.nodeId === handle.nodeId;
    if (isSameNode) return false;
    if (!connectionStartHandle.handleId) throw new Error('Found a handle without an ID');

    const connectingHandle = getHandle({
      id: connectionStartHandle.handleId,
      nodeId: connectionStartHandle.nodeId,
    });

    if (!connectingHandle) return false;

    if (handle.purpose === connectingHandle.purpose) return false;

    const validBatchRules = validateBatchConnectionRules(connectionStartHandle);
    if (handle.type === 'custom') {
      if (connectingHandle.type === 'custom') {
        return false;
      }

      return handle.canConnect(connectingHandle) && validBatchRules;
    }

    if (connectingHandle.type === 'custom') {
      return connectingHandle.canConnect(handle) && validBatchRules;
    }

    const { schema: sourceSchema } = connectingHandle;

    const dataConnection: DataConnection<DataSchema> =
      handle.purpose === 'input'
        ? { input: handle.schema, output: sourceSchema }
        : { input: sourceSchema, output: handle.schema };

    return compatibilityCheck(dataConnection) && validBatchRules;
  }, [connectionStartHandle, getHandle, handle, isConnectable, validateBatchConnectionRules]);

  return (
    <Styled.BaseHandle
      data-testid={computeHandleKey(handle)}
      data-type={handle.type}
      data-purpose={purpose}
      isConnectable={isConnectable}
      isConnectableStart={isConnectableStart}
      isConnectableEnd={isConnectableEnd}
      id={handle.id}
      type={toFlowHandleType(purpose)}
      position={toFlowHandlePosition(purpose)}
      {...handleProps}
    />
  );
}
