import { Config, useRequest } from 'api/client';
import { PipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution.types';
import { useMemo } from 'react';
import { createPipelineExecution, getPipelineExecutionOrDefault } from 'utils/execution';
import { ExecutionResponse } from '../usePipelineExecutions/usePipelineExecutions.types';

export function usePipelineExecution(
  pipelineExecutionId?: string,
  config?: Config<ExecutionResponse>,
) {
  if (!pipelineExecutionId) throw new Error('"pipelineExecutionId" is required.');
  const { isLoading, data, error, mutate, ...response } = useRequest<ExecutionResponse>(
    {
      url: `pipeline-executions/${pipelineExecutionId}`,
    },
    config,
  );

  const pipelineExecution = useMemo<PipelineExecution>(() => {
    if (!data) {
      return getPipelineExecutionOrDefault();
    }

    return createPipelineExecution(data);
  }, [data]);

  return {
    ...response,
    isPipelineExecutionLoading: isLoading,
    pipelineExecution,
    pipelineExecutionError: error,
    mutatePipelineExecution: mutate,
  };
}
