import axios from 'axios';
import useSWRMutation from 'swr/mutation';

interface RejectCheckpointBody {
  comment?: string;
}

const useRejectCheckpoint = (checkpointId?: string) => {
  const rejectCheckpointRequest = (requestUrl: string, { arg }: { arg: RejectCheckpointBody }) =>
    axios.post(requestUrl, arg);

  const rejectCheckpointMutation = useSWRMutation(
    `/checkpoints/${checkpointId}/actions/reject`,
    rejectCheckpointRequest,
  );

  return {
    isRejectCheckpointPending: rejectCheckpointMutation.isMutating,
    rejectCheckpoint: rejectCheckpointMutation.trigger,
    ...rejectCheckpointMutation,
  };
};

export default useRejectCheckpoint;
