import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrayObjectIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <rect width="32" height="32" rx="8" fill="#FF7AED" />
      <mask id="mask0_5323_4367" maskUnits="userSpaceOnUse" x="3" y="4" width="26" height="24">
        <rect x="3" y="4" width="26" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5323_4367)">
        <path
          d="M4.2 23.3C4.66667 23.7667 5.23333 24 5.9 24H7.5C7.72667 24 7.91667 23.9233 8.07 23.77C8.22333 23.6167 8.3 23.4267 8.3 23.2C8.3 22.9733 8.22333 22.7833 8.07 22.63C7.91667 22.4767 7.72667 22.4 7.5 22.4H5.9C5.67333 22.4 5.48333 22.3233 5.33 22.17C5.17667 22.0167 5.1 21.8267 5.1 21.6V10.4C5.1 10.1733 5.17667 9.98333 5.33 9.83C5.48333 9.67667 5.67333 9.6 5.9 9.6H7.5C7.72667 9.6 7.91667 9.52333 8.07 9.37C8.22333 9.21667 8.3 9.02667 8.3 8.8C8.3 8.57333 8.22333 8.38333 8.07 8.23C7.91667 8.07667 7.72667 8 7.5 8H5.9C5.23333 8 4.66667 8.23333 4.2 8.7C3.73333 9.16667 3.5 9.73333 3.5 10.4V21.6C3.5 22.2667 3.73333 22.8333 4.2 23.3Z"
          fill="black"
        />
        <path
          d="M27.8 23.3C27.3333 23.7667 26.7667 24 26.1 24H24.5C24.2733 24 24.0833 23.9233 23.93 23.77C23.7767 23.6167 23.7 23.4267 23.7 23.2C23.7 22.9733 23.7767 22.7833 23.93 22.63C24.0833 22.4767 24.2733 22.4 24.5 22.4H26.1C26.3267 22.4 26.5167 22.3233 26.67 22.17C26.8233 22.0167 26.9 21.8267 26.9 21.6V10.4C26.9 10.1733 26.8233 9.98333 26.67 9.83C26.5167 9.67667 26.3267 9.6 26.1 9.6H24.5C24.2733 9.6 24.0833 9.52333 23.93 9.37C23.7767 9.21667 23.7 9.02667 23.7 8.8C23.7 8.57333 23.7767 8.38333 23.93 8.23C24.0833 8.07667 24.2733 8 24.5 8H26.1C26.7667 8 27.3333 8.23333 27.8 8.7C28.2667 9.16667 28.5 9.73333 28.5 10.4V21.6C28.5 22.2667 28.2667 22.8333 27.8 23.3Z"
          fill="black"
        />
        <path
          d="M17.0192 23.5V22H19.4904C19.8442 22 20.1426 21.8801 20.3856 21.6404C20.6285 21.4006 20.75 21.1102 20.75 20.7692V18.7115C20.75 18.1166 20.9269 17.5833 21.2808 17.1115C21.6346 16.6397 22.0955 16.3115 22.6634 16.1269V15.8731C22.0955 15.6884 21.6346 15.3602 21.2808 14.8885C20.9269 14.4167 20.75 13.8833 20.75 13.2885V11.2308C20.75 10.8897 20.6285 10.5994 20.3856 10.3596C20.1426 10.1199 19.8442 9.99998 19.4904 9.99998H17.0192V8.5H19.4904C20.2596 8.5 20.9118 8.76603 21.4471 9.29808C21.9823 9.83013 22.25 10.4744 22.25 11.2308V13.2885C22.25 13.6423 22.3746 13.9391 22.624 14.1789C22.8733 14.4186 23.1782 14.5385 23.5384 14.5385H24.25V17.4615H23.5384C23.1782 17.4615 22.8733 17.5814 22.624 17.8211C22.3746 18.0609 22.25 18.3577 22.25 18.7115V20.7692C22.25 21.5256 21.9823 22.1698 21.4471 22.7019C20.9118 23.2339 20.2596 23.5 19.4904 23.5H17.0192ZM12.5096 23.5C11.7468 23.5 11.0962 23.2339 10.5577 22.7019C10.0192 22.1698 9.75 21.5256 9.75 20.7692V18.7115C9.75 18.3577 9.62533 18.0609 9.37598 17.8211C9.12661 17.5814 8.82179 17.4615 8.46153 17.4615H7.75V14.5385H8.46153C8.82179 14.5385 9.12661 14.4186 9.37598 14.1789C9.62533 13.9391 9.75 13.6423 9.75 13.2885V11.2308C9.75 10.4744 10.0192 9.83013 10.5577 9.29808C11.0962 8.76603 11.7468 8.5 12.5096 8.5H14.9904V9.99998H12.5096C12.1622 9.99998 11.8654 10.1199 11.6192 10.3596C11.3731 10.5994 11.25 10.8897 11.25 11.2308V13.2885C11.25 13.8833 11.0747 14.4167 10.724 14.8885C10.3734 15.3602 9.91088 15.6884 9.33653 15.8731V16.1269C9.91088 16.3115 10.3734 16.6397 10.724 17.1115C11.0747 17.5833 11.25 18.1166 11.25 18.7115V20.7692C11.25 21.1102 11.3731 21.4006 11.6192 21.6404C11.8654 21.8801 12.1622 22 12.5096 22H14.9904V23.5H12.5096Z"
          fill="black"
        />
      </g>
    </svg>
  </SvgIcon>
);

export default ArrayObjectIcon;
