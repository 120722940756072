import styled from 'styled-components';
import { Box, Container } from '@mui/material';

export const Layout = styled.div`
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-rows: auto 1fr;
  overflow: auto;
  background: ${({ theme }) => theme.palette.surface.level0};
`;

export const LayoutContainer = styled.main`
  display: flex;
  overflow: auto;
`;

export const ScrollContainer = styled.main`
  overflow: auto;
`;

export const MuiContainer = styled(Container)`
  padding: ${({ theme }) => theme.spacing(4, 0)};
`;

export const ContentPaper = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2, 3)};
  background-color: ${({ theme }) => theme.palette.surface.level1};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;
`;
