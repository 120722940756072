import { usePipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import usePipelineJobs from 'api/services/usePipelineJobs/usePipelineJobs';
import usePipelineExecutionOutputs from 'api/services/usePipelineExecutionOutputs/usePipelineExecutionOutputs';
import { isPipelineExecutionFinished } from 'utils/execution';

export function useViewExecutionRequests(pipelineExecutionId?: string) {
  const pipelineExecutionRequest = usePipelineExecution(pipelineExecutionId, {
    refreshInterval(latestData) {
      if (!isPipelineExecutionFinished(latestData?.data.status)) {
        return 5000;
      }

      return 0;
    },
  });
  const {
    pipelineExecution: { status },
  } = pipelineExecutionRequest;
  const isCompleted = ExecutionStatus.COMPLETED === status;

  // Conditional fetching of outputs and jobs only if execution is completed
  const pipelineOutputRequest = usePipelineExecutionOutputs(pipelineExecutionId, {
    shouldFetch: isCompleted,
  });
  const pipelineJobRequest = usePipelineJobs(pipelineExecutionId);

  return {
    pipelineExecutionRequest,
    pipelineOutputRequest,
    pipelineJobRequest,
  };
}
