import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';
import { PipelineResponse } from 'types/pipeline';
import { SavePipelineProps } from './useSavePipeline.types';
import useAddPipelineRevision from 'api/services/useAddPipelineRevision/useAddPipelineRevision';

const savePipelineFetcher: MutationFetcher<
  AxiosResponse<PipelineResponse>,
  string,
  AxiosRequestConfig
> = (_, { arg: request }) => axios.request(request);

const useSavePipeline = () => {
  const { isMutating: isSaving, trigger } = useSWRMutation('savePipeline', savePipelineFetcher);
  const { isAddingRevision, addRevision } = useAddPipelineRevision();

  const savePipeline = useCallback(
    async ({ edges, nodes, pipeline, pipelineDescription, pipelineName }: SavePipelineProps) => {
      const name = pipelineName ? pipelineName : nanoid();

      const requestConfig = {
        url: `pipelines/${pipeline.id}`,
        method: 'PUT',
        data: {
          name,
        },
      };

      return trigger(requestConfig).then(() =>
        addRevision({ edges, nodes, pipeline, pipelineDescription }),
      );
    },
    [trigger, addRevision],
  );

  return { savePipeline, isSaving: isSaving || isAddingRevision };
};

export default useSavePipeline;
