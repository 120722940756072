import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TableContainer as MuiTableContainer, TableRow } from '@mui/material';
import styled, { css } from 'styled-components';

export const TableContainer = styled(MuiTableContainer)(
  ({ theme }) => css`
    border-radius: ${theme.spacing(1)};
    background-color: ${theme.palette.surface.level1};
  `,
) as typeof MuiTableContainer;

export const TableHeadRow = styled(TableRow)(
  ({ theme }) => css`
    .MuiTableCell-head {
      border: 0;
      background: ${theme.palette.surface.level2};

      &:first-child {
        border-top-left-radius: ${theme.spacing(1)};
      }

      &:last-child {
        border-top-right-radius: ${theme.spacing(1)};
      }

      &:hover {
        .MuiTableSortLabel-root:not(.Mui-active) {
          & .MuiTableSortLabel-icon {
            opacity: 0.5;
          }
        }
      }
    }
  `,
);

export const TableSortLabelIcon = styled(ArrowDropDownIcon)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(-1)};
  translate: 100%;
`;

export const TableBodyRow = styled(TableRow)(
  ({ theme }) => css`
    &.MuiTableRow-hover {
      cursor: pointer;
    }

    &:last-child td,
    &:last-child th {
      border: 0;
    }

    .MuiTableCell-body {
      border-color: ${theme.palette.divider};
      color: ${theme.palette.text.secondary};
    }
  `,
);

export const TableFooterRow = styled(TableRow)(
  ({ theme }) => css`
    .MuiTableCell-footer {
      border-top: 1px solid ${theme.palette.divider};
      border-bottom: 0;
    }
  `,
);
