import { isArrayDataSchema } from '@pathways/pipeline-schema/web';
import { useNodeError } from 'features/Flow/hooks/useNodeError';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { useUpdateNodeInput } from 'features/Flow/hooks/useUpdateNodeInput';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import { useCallback, useEffect, useMemo } from 'react';
import { DynamicCheckpointNode, TableSchemaColumnConfig } from '../../DynamicCheckpoint.types';

// This input is hidden.
export const TABLE_SCHEMA_INPUT_NAME = '__tableSchema__';

interface TableSchemaInput extends Omit<NodeInput, 'value'> {
  value: TableSchemaColumnConfig[];
}

function findTableSchemaInput(inputs: NodeInput[]) {
  const input = inputs.find((input) => input.name === TABLE_SCHEMA_INPUT_NAME);

  return input as unknown as TableSchemaInput | undefined;
}

interface UseTableSchemaInputProps {
  node: DynamicCheckpointNode;
}

export function useTableSchemaInput(props: UseTableSchemaInputProps) {
  const updateNodeData = useUpdateNodeData();
  const updateNodeInput = useUpdateNodeInput();
  const { node } = props;
  const { nodeError, setNodeError } = useNodeError(node.id);
  const tableSchemaInput = useMemo(
    () => findTableSchemaInput(node.data.inputs),
    [node.data.inputs],
  );

  const addTableSchemaInput = useCallback(() => {
    updateNodeData<typeof node.data>(node.id, (data) => {
      const nextInputs: typeof data.inputs = [
        ...data.inputs,
        {
          config: {
            forceHide: true,
          },
          dataSchema: {
            type: 'array',
            items: {
              type: 'object',
            },
          },
          name: TABLE_SCHEMA_INPUT_NAME,
          title: 'Table Schema',
          value: [],
        },
      ];

      return {
        inputs: nextInputs,
      };
    });
  }, [node, updateNodeData]);

  const removeTableSchemaInput = useCallback(() => {
    setNodeError(undefined);
    updateNodeData<typeof node.data>(node.id, (data) => {
      const nextInputs = data.inputs
        .filter((input) => input.name !== TABLE_SCHEMA_INPUT_NAME)
        .slice();

      return {
        inputs: nextInputs,
      };
    });
  }, [node, setNodeError, updateNodeData]);

  const updateTableSchemaInput = useCallback(
    (value?: TableSchemaColumnConfig[]) => {
      updateNodeInput(
        {
          nodeId: node.id,
          inputName: TABLE_SCHEMA_INPUT_NAME,
        },
        (input) => {
          return {
            ...input,
            value: value as unknown as NodeInput['value'],
          };
        },
      );
    },
    [node.id, updateNodeInput],
  );

  useEffect(() => {
    const dataInput = node.data.inputs.at(0);

    if (!dataInput) {
      if (nodeError) setNodeError(undefined);
      return;
    }

    const isObjectListSchema = isArrayDataSchema(dataInput.dataSchema, 'object');

    if (isObjectListSchema) {
      if (!tableSchemaInput) {
        addTableSchemaInput();
      }
    } else if (tableSchemaInput) {
      removeTableSchemaInput();
    }
  }, [
    addTableSchemaInput,
    node.data.inputs,
    nodeError,
    removeTableSchemaInput,
    setNodeError,
    tableSchemaInput,
  ]);

  return {
    tableSchemaInput,
    updateTableSchemaInput,
  };
}
