import { Skeleton } from '@mui/material';
import { LoaderProps } from './Loader.types';

export default function Loader({ loading, children, ...skeletonProps }: LoaderProps) {
  const { height, width } = skeletonProps;
  const fixedSize = !!height || !!width;

  if (loading)
    return (
      <Skeleton variant="rounded" {...skeletonProps}>
        {fixedSize ? null : children}
      </Skeleton>
    );

  return children;
}
