import { Tab, Button, Stack } from '@mui/material';
import * as StyledDialog from 'components/Dialog/Dialog.styles';
import * as Styled from './DummyNeuronModalView.styles';
import SortableNodeDataList from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/SortableNodeDataList';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { SyntheticEvent } from 'react';

interface DummyNeuronModalViewProps {
  inputs: NodeInput[];
  outputs: NodeOutput[];
  onTabChange: (_event: SyntheticEvent, tab: number) => void;
  activeTabIndex: number;
  activeTab: 'input' | 'output';
  onCreate: () => void;
  onConfirm: () => void;
  onClose: () => void;
  onRemoveItem: (indexToRemove: number) => void;
  onReorderItem: (from: string, to: string) => void;
  onEditItem: (item: NodeInput | NodeOutput, index: number) => void;
}

const DummyNeuronModalView = ({
  inputs,
  outputs,
  onTabChange,
  activeTabIndex,
  activeTab,
  onCreate,
  onConfirm,
  onClose,
  onRemoveItem,
  onReorderItem,
  onEditItem,
}: DummyNeuronModalViewProps) => {
  return (
    <>
      <StyledDialog.DialogTitle $hasBorder>Configure Schema</StyledDialog.DialogTitle>
      <StyledDialog.DialogContent>
        <Styled.Tabs onChange={onTabChange} value={activeTabIndex}>
          <Tab label="Inputs" />
          <Tab label="Outputs" />
        </Styled.Tabs>
        <Styled.Divider />
        <SortableNodeDataList
          dataList={activeTab === 'input' ? inputs : outputs}
          dataType={activeTab}
          onReorder={onReorderItem}
          onEditItem={onEditItem}
          onRemoveItem={onRemoveItem}
        />
      </StyledDialog.DialogContent>
      <StyledDialog.DialogActions>
        <Stack flex={1} direction="row" justifyContent="space-between">
          <Button onClick={onCreate}>Create {activeTab}</Button>
          <Stack direction="row">
            <Button variant="text" sx={{ color: 'common.white' }} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </Stack>
        </Stack>
      </StyledDialog.DialogActions>
    </>
  );
};

export default DummyNeuronModalView;
