import { PipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution.types';
import usePipelineExecutionOutputs from 'api/services/usePipelineExecutionOutputs/usePipelineExecutionOutputs';
import ExecutionPipelineOutputs from './ExecutionPipelineOutputs';
import ExecutionAllOutputs from './ExecutionAllOutputs';
import usePipelineJobs from 'api/services/usePipelineJobs/usePipelineJobs';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import ExecutionHeaderSubsection from './ExecutionHeaderSubsection';

interface ExecutionCompletedProps {
  view: 'all' | 'output';
  pipelineExecution: PipelineExecution;
  pipelineJobs: ReturnType<typeof usePipelineJobs>;
  pipelineExecutionOutputs: ReturnType<typeof usePipelineExecutionOutputs>;
  onViewChange: (view: 'all' | 'output') => void;
}

export default function ExecutionCompleted({
  view,
  pipelineExecution,
  pipelineJobs,
  pipelineExecutionOutputs,
  onViewChange,
}: ExecutionCompletedProps) {
  const isCompleted = pipelineExecution.status === ExecutionStatus.COMPLETED;

  return (
    <>
      {isCompleted && <ExecutionHeaderSubsection view={view} onViewChange={onViewChange} />}
      {isCompleted && view === 'output' && (
        <ExecutionPipelineOutputs
          {...pipelineExecutionOutputs}
          onRetry={pipelineExecutionOutputs.mutatePipelineExecutionOutputs}
        />
      )}
      {view === 'all' && (
        <ExecutionAllOutputs
          pipelineExecution={pipelineExecution}
          {...pipelineJobs}
          onRetry={pipelineJobs.queryPipelineJobs}
        />
      )}
    </>
  );
}
