import { SvgIcon, SvgIconProps } from '@mui/material';

const UnlockedIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 17C12.55 17 13.0208 16.8042 13.4125 16.4125C13.8042 16.0208 14 15.55 14 15C14 14.45 13.8042 13.9792 13.4125 13.5875C13.0208 13.1958 12.55 13 12 13C11.45 13 10.9792 13.1958 10.5875 13.5875C10.1958 13.9792 10 14.45 10 15C10 15.55 10.1958 16.0208 10.5875 16.4125C10.9792 16.8042 11.45 17 12 17ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V10C4 9.45 4.19583 8.97917 4.5875 8.5875C4.97917 8.19583 5.45 8 6 8H13V6C13 4.61667 13.4875 3.4375 14.4625 2.4625C15.4375 1.4875 16.6167 1 18 1C19.25 1 20.3375 1.40417 21.2625 2.2125C22.1875 3.02083 22.7417 4.01667 22.925 5.2C22.9583 5.41667 22.8833 5.60417 22.7 5.7625C22.5167 5.92083 22.2833 6 22 6C21.7167 6 21.4833 5.94167 21.3 5.825C21.1167 5.70833 20.9833 5.51667 20.9 5.25C20.7167 4.61667 20.3625 4.08333 19.8375 3.65C19.3125 3.21667 18.7 3 18 3C17.1667 3 16.4583 3.29167 15.875 3.875C15.2917 4.45833 15 5.16667 15 6V8H18C18.55 8 19.0208 8.19583 19.4125 8.5875C19.8042 8.97917 20 9.45 20 10V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6Z"
          fill="white"
          fillOpacity="0.56"
        />
      </svg>
    </SvgIcon>
  );
};

export default UnlockedIcon;
