import { PropsWithChildren, ReactNode, createContext, useState } from 'react';

interface ExecutionContextValue {
  pageFooter: ReactNode;
  setPageFooter: (footer: ReactNode) => void;
}

export const ExecutionContext = createContext<ExecutionContextValue | null>(null);

export function ExecutionContextProvider({ children }: PropsWithChildren) {
  const [pageFooter, setPageFooter] = useState<ExecutionContextValue['pageFooter']>();

  return (
    <ExecutionContext.Provider
      value={{
        pageFooter,
        setPageFooter,
      }}
    >
      {children}
    </ExecutionContext.Provider>
  );
}
