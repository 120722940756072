import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useSWRConfig } from 'swr';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';

const updatePipelineFetcher: MutationFetcher<
  AxiosResponse<{ id: string }>,
  string,
  AxiosRequestConfig
> = (_, { arg: request }) => axios.request(request);

interface UpdatePipelineMetadataProps {
  pipelineId: string;
}

interface UpdatePipelineMetadataRequest {
  name: string;
  type: 'default' | 'callable';
}

export const useUpdatePipelineMetadata = ({ pipelineId }: UpdatePipelineMetadataProps) => {
  const swrConfig = useSWRConfig();
  const {
    isMutating: isSaving,
    trigger,
    ...rest
  } = useSWRMutation('updatePipelineMetadata', updatePipelineFetcher);

  const updatePipeline = useCallback(
    async (data: Partial<UpdatePipelineMetadataRequest>) => {
      const requestConfig = {
        url: `pipelines/${pipelineId}`,
        method: 'PUT',
        data,
      };

      const response = await trigger(requestConfig);

      await swrConfig.mutate(`pipelines/${pipelineId}`);

      return response;
    },
    [trigger, pipelineId, swrConfig],
  );

  return { updatePipeline, isSaving, ...rest };
};
