import { NodeOutput } from '../../../Node/Node.types';
import { useState } from 'react';
import ObjectExtractorList from './ObjectExtractorList';
import ObjectExtractorEditForm from '../ObjectExtractorForms/ObjectExtractorEditForm';
import ObjectExtractorCreateForm from '../ObjectExtractorForms/ObjectExtractorCreateForm';
import { createHandleId } from 'features/Flow/Flow.utils';
import {
  DEFAULT_VIEW,
  ObjectExtractions,
  ObjectExtractorCommitFn,
  ViewStates,
} from '../ObjectExtractor.types';
import { arrayMove } from '@dnd-kit/sortable';
import * as Styled from 'components/Dialog/Dialog.styles';
import { getExtraction, removeExtraction, saveExtraction } from '../ObjectExtractor.utils';

export interface ObjectExtractorModalProps {
  nodeId: string;
  outputs: NodeOutput[];
  extractions: ObjectExtractions;
  open: boolean;
  onCloseModal: () => void;
  onConfirm: ObjectExtractorCommitFn;
}

export default function ObjectExtractorModal({
  nodeId,
  outputs,
  extractions,
  open,
  onCloseModal,
  onConfirm,
}: ObjectExtractorModalProps) {
  const [nodeOutputs, setNodeOutputs] = useState<NodeOutput[]>(outputs);
  const [nodeExtractions, setNodeExtractions] = useState<ObjectExtractions>(extractions);
  const [removedOutputs, setRemovedOutputs] = useState<string[]>([]);
  const [viewState, setViewState] = useState<ViewStates>({ view: DEFAULT_VIEW });

  const handleCreateOutputClick = () => {
    setViewState({ view: 'create' });
  };

  const handleEditOutput = (output: NodeOutput, index: number) => {
    setViewState({ view: 'edit', outputToEdit: output, updateIndex: index });
  };

  const handleFormCancel = () => {
    setViewState({ view: DEFAULT_VIEW });
  };

  const handleConfirmEdit = (update: NodeOutput, path: string) => {
    if (viewState.view !== 'edit') return;
    setNodeOutputs((prev) => {
      return prev.map((output, index) => (viewState.updateIndex === index ? update : output));
    });
    setNodeExtractions((prev) => {
      return saveExtraction(prev, update.name, path);
    });
    setViewState({ view: DEFAULT_VIEW });
  };

  const handleConfirmCreate = (newOutput: NodeOutput, path: string) => {
    setNodeOutputs((prev) => {
      return [...prev, newOutput];
    });
    setNodeExtractions((prev) => {
      return saveExtraction(prev, newOutput.name, path);
    });
    setViewState({ view: DEFAULT_VIEW });
  };

  const handleRemoveOutput = (indexToRemove: number) => {
    const outputToRemove = nodeOutputs[indexToRemove];
    setRemovedOutputs((prev) => {
      return [...prev, createHandleId('output', outputToRemove.name)];
    });
    setNodeOutputs((prev) => {
      return prev.filter((_, index) => indexToRemove !== index);
    });
    setNodeExtractions((prev) => {
      return removeExtraction(prev, outputToRemove.name);
    });
  };

  const handleCommitChanges = () => {
    onConfirm(nodeOutputs, removedOutputs, nodeExtractions);
  };

  const handleReorder = (from: string, to: string) => {
    setNodeOutputs((nodeOutputs) => {
      const oldIndex = nodeOutputs.findIndex((output) => output.name === from);
      const newIndex = nodeOutputs.findIndex((output) => output.name === to);
      if (oldIndex === -1 || newIndex === -1) return nodeOutputs;
      return arrayMove(nodeOutputs, oldIndex, newIndex);
    });
  };

  return (
    <Styled.Dialog
      open={open}
      onClose={onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      {viewState.view === DEFAULT_VIEW && (
        <ObjectExtractorList
          outputs={nodeOutputs}
          onCloseModal={onCloseModal}
          onConfirm={handleCommitChanges}
          onCreateOutput={handleCreateOutputClick}
          onEditOutput={handleEditOutput}
          onRemoveOutput={handleRemoveOutput}
          onReorder={handleReorder}
        />
      )}
      {viewState.view === 'edit' && (
        <ObjectExtractorEditForm
          nodeId={nodeId}
          outputToEdit={viewState.outputToEdit}
          pathToEdit={getExtraction(nodeExtractions, viewState.outputToEdit.name)}
          onCancelEdit={handleFormCancel}
          onConfirmEdit={handleConfirmEdit}
        />
      )}
      {viewState.view === 'create' && (
        <ObjectExtractorCreateForm
          defaultValues={null}
          onCancelCreate={handleFormCancel}
          onConfirmCreate={handleConfirmCreate}
        />
      )}
    </Styled.Dialog>
  );
}
