import { Box, Grid, Stack, Typography } from '@mui/material';
import { isSubpipelineJobResponse } from 'api/services/usePipelineJobs/usePipelineJobs.types';
import RoundedPaper from 'components/RoundedPaper/RoundedPaper';
import HeaderChip from 'components/HeaderChip/HeaderChip';
import Loader from 'components/Loader/Loader';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import StatusChip from 'components/StatusChip/StatusChip';
import JobInfoTitle from '../JobInfoTitle/JobInfoTitle';
import { useViewExecutionRequests } from '../../useViewExecutionRequests';
import { useAppRoutes } from 'utils/routes';
import ProgressCard from '../ProgressCard/ProgressCard';
import * as Styled from './ExecutionProgress.styles';

interface ExecutionProgressProps {
  pipelineExecutionId: string;
}

export default function ExecutionProgress(props: ExecutionProgressProps) {
  const routes = useAppRoutes();

  const { pipelineExecutionId } = props;
  const { pipelineExecutionRequest, pipelineJobRequest } =
    useViewExecutionRequests(pipelineExecutionId);

  const { pipelineExecution, isPipelineExecutionLoading } = pipelineExecutionRequest;
  const { progressPercentage, completedCheckpoints, totalCheckpoints } = pipelineExecution;
  const { pipelineJobs } = pipelineJobRequest;
  const subpipelineJobs = pipelineJobs.filter(isSubpipelineJobResponse);

  return (
    <Grid container gap={2} padding={3} component={RoundedPaper}>
      <Grid item xs={6} component={Styled.StatusPaper} elevation={2}>
        <Stack gap={2} alignItems="center">
          <Loader loading={isPipelineExecutionLoading}>
            <Typography variant="headlineLarge">Pipeline is running...</Typography>
          </Loader>
          <Loader loading={isPipelineExecutionLoading}>
            <Typography variant="bodyLarge" color="text.secondary">
              {progressPercentage}% Completed
            </Typography>
          </Loader>
          <Loader loading={isPipelineExecutionLoading}>
            <ProgressBar
              value={progressPercentage}
              sx={{
                width: 400,
              }}
            />
          </Loader>
        </Stack>
      </Grid>

      {!isPipelineExecutionLoading && (
        <>
          <Grid item xs>
            <ProgressCard
              label="Elements Completed"
              completedCount={pipelineExecution.completedJobs}
              totalCount={pipelineExecution.totalJobs}
            />
          </Grid>

          {totalCheckpoints > 0 && (
            <Grid item xs>
              <ProgressCard
                label="Checkpoints Completed"
                completedCount={completedCheckpoints}
                totalCount={totalCheckpoints}
              />
            </Grid>
          )}
        </>
      )}

      {subpipelineJobs.map((job) => (
        <Grid key={job.id} item xs={12} component={Styled.SubpipelinePaper} elevation={2}>
          <Stack direction="row" justifyContent="space-between">
            <JobInfoTitle {...job} />

            <Stack direction="row" alignItems="center" color="text.secondary">
              <HeaderChip $noSpacingRight elevation={4}>
                Callable Pipeline
                <Box
                  component={StatusChip}
                  status={job.status}
                  variant="icon"
                  sx={{
                    marginLeft: 1.5,
                  }}
                />
              </HeaderChip>

              <OpenInNewTab href={routes.executions.view(job.pipelineId, job.executionId)} />
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
