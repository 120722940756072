import { NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { useCallback, useState } from 'react';
import { PipelineStartQuickCreateModalProps } from './PipelineStartQuickCreateModal';

type UsePipelineStartQuickCreateModalProps = Pick<
  PipelineStartQuickCreateModalProps,
  'onCreateOutput' | 'onCloseModal'
>;

type UsePipelineStartQuickCreateModalReturn = Omit<
  PipelineStartQuickCreateModalProps,
  'outputs'
> & {
  openModal: (initialInput: NodeOutput) => void;
};

type ModalState =
  | {
      open: true;
      defaultValue: NodeOutput | null;
    }
  | {
      open: false;
    };

export const usePipelineStartQuickCreateModal = ({
  onCreateOutput,
  onCloseModal,
}: UsePipelineStartQuickCreateModalProps): UsePipelineStartQuickCreateModalReturn => {
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
  });

  const handleCreateOutput = useCallback(
    (output: NodeOutput) => {
      if (!modalState.open) {
        return;
      }

      setModalState({ open: false });
      onCreateOutput(output);
    },
    [modalState.open, onCreateOutput],
  );

  const handleOpenModal = useCallback((initialOutput: NodeOutput) => {
    setModalState({
      open: true,
      defaultValue: initialOutput,
    });
  }, []);

  const handleCloseModal = () => {
    setModalState({ open: false });
    onCloseModal();
  };

  return {
    open: modalState.open,
    defaultValues: modalState.open ? modalState.defaultValue : null,
    openModal: handleOpenModal,
    onCloseModal: handleCloseModal,
    onCreateOutput: handleCreateOutput,
  };
};
