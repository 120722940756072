import { SvgIcon, SvgIconProps } from '@mui/material';

const PathwaysProjectIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="13" fill="url(#paint0_linear_3513_16294)" />
      <path
        d="M38.1442 22.734L39.6562 18H43.7422L39.2422 30.816H34.5982L33.1222 25.632C32.7262 24.21 32.3122 22.788 31.9342 21.366C31.5382 22.824 31.1242 24.3 30.7102 25.794L29.3062 30.816H24.6442L20.1802 18H24.2842L25.7602 22.716C26.2102 24.174 26.6062 25.704 26.9842 27.18C27.3622 25.686 27.7762 24.192 28.2262 22.716L29.6482 18H34.2382L35.6782 22.716C36.1282 24.21 36.5242 25.65 36.9022 27.144C37.2802 25.668 37.6582 24.21 38.1442 22.734Z"
        fill="white"
      />
      <path
        d="M9.87025 30.816H5.80225V18H14.4062C17.5922 18 19.6082 19.854 19.6082 22.464C19.6082 25.092 17.5382 26.946 14.3342 26.946H9.87025V30.816ZM9.87025 20.97V24.084H13.7582C14.8922 24.084 15.5582 23.562 15.5582 22.5C15.5582 21.474 14.9102 20.97 13.7042 20.97H9.87025Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3513_16294"
          x1="48"
          y1="48"
          x2="0"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB3E8" />
          <stop offset="1" stopColor="#4E25EE" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

export default PathwaysProjectIcon;
