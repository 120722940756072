import { DataSchemaCompatibilityCheck } from '../DataType';
import { AssetDataSchema } from '@pathways/pipeline-schema';

export const assetCompatibilityCheck: DataSchemaCompatibilityCheck<AssetDataSchema> = ({
  input,
  output,
}) => {
  if (output.type !== 'asset') return false;

  // If the input mediaType is empty, treat it as if it can accept any media type
  if (input.mediaType.length === 0) return true;

  return output.mediaType.every((mediaType) => input.mediaType.includes(mediaType));
};
