import { useCallback } from 'react';
import { useStoreApi } from 'reactflow';
import {
  NodeUpdateInputChange,
  createNodeUpdateInputChange,
} from '../changes/NodeUpdateInputChange';
import { NodeInput } from '../nodes/Node/Node.types';

type UseUpdateNodeInputParams = Omit<NodeUpdateInputChange, 'type' | 'updater'>;

export function useUpdateNodeInput() {
  const storeApi = useStoreApi();

  return useCallback(
    <TInput extends NodeInput>(
      params: UseUpdateNodeInputParams,
      updater: NodeUpdateInputChange<TInput>['updater'],
    ) => {
      storeApi.getState().onNodesChange?.([
        createNodeUpdateInputChange({
          ...params,
          updater: updater as unknown as NodeUpdateInputChange['updater'],
        }),
      ]);
    },
    [storeApi],
  );
}
