import {
  DynamicValue,
  NodeData,
  NodeInput,
  PathwayNode,
} from 'features/Flow/nodes/Node/Node.types';
import { addLegacyType, createInputSourceValue } from 'utils/mappings';
import { SavePipelineCompleteParams } from './useSavePipeline.types';

// This value is Dynamic and may contain a source property, we need to check for that.
// This property is too open and may contain anything inside.
function isInputSourceValid(inputsValue: DynamicValue) {
  return (
    typeof inputsValue === 'object' &&
    'source' in inputsValue &&
    typeof inputsValue.source === 'string'
  );
}

const getPipelineStartInputConnection = (
  input: NodeInput,
  pipelineStartInputs: NodeInput[],
): NodeInput | null => {
  const inputsValue = input.value as DynamicValue;

  if (isInputSourceValid(inputsValue)) {
    const inputId = inputsValue.source.split('.')[2];
    const pipelineStartInput = pipelineStartInputs.find((startInput) => startInput.id === inputId);
    return pipelineStartInput ? pipelineStartInput : null;
  }

  return null;
};

export const formatNodeData = (node: PathwayNode, pipelineStartInputs: NodeInput[]): NodeData => {
  const newInputs = node.data.inputs
    .map((input) => {
      const pipelineStartInput = getPipelineStartInputConnection(input, pipelineStartInputs);

      return pipelineStartInput
        ? // Maps the value taking into account the friendly name,
          // when having two or more inputs connected with the same name like:
          // `pipeline.inputs.keywords1`, `pipeline.inputs.keywords2`, etc.
          { ...input, value: { source: `pipeline.inputs.${pipelineStartInput.name}` } }
        : input;
    })
    .map(addLegacyType);

  const newOutputs = node.data.outputs.map(addLegacyType);

  return { ...node.data, inputs: newInputs, outputs: newOutputs };
};

export const formatPipelineCompleteInputsAsOutputs = ({
  edges,
  nodes,
  pipelineCompleteNode,
}: SavePipelineCompleteParams): NodeInput[] => {
  return pipelineCompleteNode.data.inputs.map((input) => {
    const edge = edges.find(
      (edge) => edge.target === pipelineCompleteNode.id && edge.targetHandle === input.id,
    );
    if (!edge) return { ...input, value: undefined };

    const { source: value } = createInputSourceValue(edge, nodes);
    return { ...input, value };
  });
};
