import { NodeInput, NodeOutput, PathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { SyntheticEvent, useEffect, useState } from 'react';
import * as Styled from 'components/Dialog/Dialog.styles';
import { arrayMove } from '@dnd-kit/sortable';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import DummyNeuronModalCreate from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/DummyNeuronModalCreate';
import DummyNeuronModalEdit from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/DummyNeuronModalEdit';
import DummyNeuronModalView from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/DummyNeuronModalView';
import { useUpdateConnectionOnNameChange } from 'features/Flow/hooks/useUpdateConnectionsOnNameChange';
import { useUpdateNodeInternals } from 'reactflow';
import { createHandleId } from 'features/Flow/Flow.utils';

export interface DummyNeuronModalProps {
  nodeId: string;
  inputs: NodeInput[];
  outputs: NodeOutput[];
  open: boolean;
  onCloseModal: () => void;
}

export default function DummyNeuronModal({
  nodeId,
  inputs = [],
  outputs = [],
  open,
  onCloseModal,
}: DummyNeuronModalProps) {
  const [nodeInputs, setNodeInputs] = useState<NodeInput[]>(inputs);
  const [nodeOutputs, setNodeOutputs] = useState<NodeOutput[]>(outputs);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [viewMode, setViewMode] = useState<'view' | 'edit' | 'create'>('view');
  const [itemToEdit, setItemToEdit] = useState<NodeInput | NodeOutput | null>(null);
  const { addConnectionToUpdate: addInputConnectionToUpdate, updateEdges: updateInputEdges } =
    useUpdateConnectionOnNameChange({ nodeId, type: 'input', inputs });
  const { addConnectionToUpdate: addOutputConnectionToUpdate, updateEdges: updateOutputEdges } =
    useUpdateConnectionOnNameChange({ nodeId, type: 'output', outputs });
  const [removedInputs, setRemovedInputs] = useState<string[]>([]);
  const [removedOutputs, setRemovedOutputs] = useState<string[]>([]);
  const updateNodeData = useUpdateNodeData();

  const activeTab = activeTabIndex === 0 ? 'input' : 'output';

  const handleNameChanged = (oldName: string, newName: string) => {
    if (activeTab === 'input') {
      addInputConnectionToUpdate({ oldName, newName });
    } else {
      addOutputConnectionToUpdate({ oldName, newName });
    }
  };

  const handleTabChange = (_event: SyntheticEvent, tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };

  const handleCreate = () => {
    setViewMode('create');
  };

  const updateNodeInternals = useUpdateNodeInternals();
  const handleCommitChanges = () => {
    updateNodeData<PathwayNode['data']>(nodeId, (prev) => {
      return {
        ...prev,
        inputs: nodeInputs,
        outputs: nodeOutputs,
      };
    });
    updateNodeInternals(nodeId);

    updateInputEdges(removedInputs);
    updateOutputEdges(removedOutputs);

    handleClose();
  };

  const handleClose = () => {
    setActiveTabIndex(0);
    setViewMode('view');
    setRemovedInputs([]);
    setRemovedOutputs([]);
    onCloseModal();
  };

  const handleCancel = () => {
    setItemToEdit(null);
    setViewMode('view');
  };

  const handleCreateConfirm = (item: NodeInput | NodeOutput) => {
    setViewMode('view');

    if (activeTab === 'input') {
      setNodeInputs((prev) => [...prev, item]);
    } else {
      setNodeOutputs((prev) => [...prev, item as NodeOutput]);
    }
  };

  const handleRemoveItem = (indexToRemove: number) => {
    if (activeTab === 'input') {
      const inputToRemove = nodeInputs[indexToRemove];
      setRemovedInputs((prev) => [...prev, createHandleId('input', inputToRemove.name)]);
      setNodeInputs((prev) => prev.filter((_, index) => indexToRemove !== index));
    } else {
      const outputToRemove = nodeOutputs[indexToRemove];
      setRemovedOutputs((prev) => [...prev, createHandleId('output', outputToRemove.name)]);
      setNodeOutputs((prev) => prev.filter((_, index) => indexToRemove !== index));
    }
  };

  const handleReorderItem = (from: string, to: string) => {
    const reorderFn = <T extends { name: string }>(items: T[]) => {
      const oldIndex = items.findIndex((item) => item.name === from);
      const newIndex = items.findIndex((item) => item.name === to);
      if (oldIndex === -1 || newIndex === -1) return items;
      return arrayMove(items, oldIndex, newIndex);
    };

    if (activeTab === 'input') {
      setNodeInputs(reorderFn);
    } else {
      setNodeOutputs(reorderFn);
    }
  };

  const handleEditItem = (item: NodeInput | NodeOutput) => {
    setItemToEdit(item);
    setViewMode('edit');
  };

  const handleEditConfirm = (updatedItem: NodeInput | NodeOutput) => {
    if (itemToEdit === null) return;

    const editFn = <T extends { name: string }>(items: T[]) => {
      const indexToEdit = items.findIndex((item) => item.name === itemToEdit.name);
      return items.map((item, index) => (indexToEdit === index ? updatedItem : item)) as T[];
    };

    if (activeTab === 'input') {
      setNodeInputs(editFn);
    } else {
      setNodeOutputs(editFn);
    }

    setViewMode('view');
    setItemToEdit(null);
  };

  useEffect(() => {
    if (open) {
      setNodeInputs(inputs);
      setNodeOutputs(outputs);
    }
  }, [open, inputs, outputs]);

  return (
    <Styled.Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      {viewMode === 'view' && (
        <DummyNeuronModalView
          inputs={nodeInputs}
          outputs={nodeOutputs}
          onTabChange={handleTabChange}
          activeTabIndex={activeTabIndex}
          activeTab={activeTab}
          onCreate={handleCreate}
          onConfirm={handleCommitChanges}
          onClose={handleClose}
          onRemoveItem={handleRemoveItem}
          onReorderItem={handleReorderItem}
          onEditItem={handleEditItem}
        />
      )}
      {viewMode === 'create' && (
        <DummyNeuronModalCreate
          items={activeTab === 'input' ? nodeInputs : nodeOutputs}
          type={activeTab}
          onCancel={handleCancel}
          onConfirm={handleCreateConfirm}
        />
      )}
      {viewMode === 'edit' && itemToEdit && (
        <DummyNeuronModalEdit
          nodeId={nodeId}
          itemToEdit={itemToEdit}
          items={activeTab === 'input' ? nodeInputs : nodeOutputs}
          type={activeTab}
          onCancel={handleCancel}
          onConfirm={handleEditConfirm}
          onNameChange={handleNameChanged}
        />
      )}
    </Styled.Dialog>
  );
}
