import { IconButton, Stack, Typography } from '@mui/material';
import { NodeManifest, isSubpipelineManifest } from 'features/Flow/Flow.types';
import * as Styled from './SidebarElementCard.styles';
import { InfoOutlined } from '@mui/icons-material';
import { getValueOrDefault } from 'utils/string';

interface SidebarElementCardTooltipProps {
  manifest: NodeManifest;
}

const SidebarElementCardTooltip = ({ manifest }: SidebarElementCardTooltipProps) => {
  const version = isSubpipelineManifest(manifest)
    ? manifest.pipeline.version
    : manifest.key?.split('@v')[1] ?? '';
  const maintainers = manifest.maintainers ?? [];
  const description = getValueOrDefault(manifest.description.trim(), 'No description');

  return (
    <Styled.ElementTooltip
      title={
        <Stack gap={0.5}>
          {Boolean(version) && (
            <Typography variant="labelSmall" color="text.secondary">
              Version {version}
            </Typography>
          )}
          <Typography variant="bodySmall">{description}</Typography>
          {maintainers.length > 0 && (
            <>
              <Typography variant="labelSmall" color="text.secondary">
                Maintainers:
              </Typography>
              <Styled.MaintainerList>
                {maintainers.map((m) => (
                  <Styled.MaintainerListItem key={m}>
                    <Typography variant="bodySmall">{m}</Typography>
                  </Styled.MaintainerListItem>
                ))}
              </Styled.MaintainerList>
            </>
          )}
        </Stack>
      }
      placement="bottom"
    >
      <IconButton aria-label="More Info" component="span">
        <InfoOutlined />
      </IconButton>
    </Styled.ElementTooltip>
  );
};

export default SidebarElementCardTooltip;
