export const JOB_DEPENDENCY_REGEX = /^jobs\.(.*)\.outputs\.(.*)$/;
export const PIPELINE_DEPENDENCY_REGEX = /^pipeline\.inputs\.(.*)$/;
export const BATCH_DEPENDENCY_REGEX = /^batch\.inputs\.(.*)$/;
const BATCH_CURRENT_ITEM_REGEX = /^batch\.currentItem$/;
export const isValidDependencyString = (from) => {
    return (JOB_DEPENDENCY_REGEX.test(from) ||
        PIPELINE_DEPENDENCY_REGEX.test(from) ||
        BATCH_DEPENDENCY_REGEX.test(from) ||
        BATCH_CURRENT_ITEM_REGEX.test(from));
};
export const isFromJobDependency = (from) => from.match(JOB_DEPENDENCY_REGEX);
export const isFromPipelineInput = (from) => from.match(PIPELINE_DEPENDENCY_REGEX);
export const isFromBatchInput = (from) => from.match(BATCH_DEPENDENCY_REGEX);
export const isFromBatchCurrentItem = (from) => from.match(BATCH_CURRENT_ITEM_REGEX);
export const isDynamicValue = (value) => typeof value === 'object' &&
    'from' in value &&
    /^(jobs[.]|pipeline[.]|batch[.])/.test(value.from);
export const isDynamicEditorValue = (value) => !Array.isArray(value) &&
    typeof value === 'object' &&
    'source' in value &&
    /^(jobs[.]|pipeline[.])|batch[.]/.test(value.source);
