import styled from 'styled-components';
import { Select } from '@mui/material';
import { DataContainer, NodeDataRow } from 'features/Flow/nodes/Node/Node.styles';

export const SelectVariationField = styled(Select)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 1rem;
  min-width: 0;
  font-size: 0.875rem;
  min-height: ${({ theme }) => theme.spacing(4)};
  height: ${({ theme }) => theme.spacing(4)};
  overflow: hidden;
  .MuiSelect-select && {
    padding: ${({ theme }) => theme.spacing(1, 6, 1, 1.5)};
  }
`;

export const NodeVariationData = styled(NodeDataRow)`
  grid-template-columns: 1fr 1fr;
  flex-grow: 1;
  align-items: center;
  padding: 0 4px;
`;

export const VariationDataContainer = styled(DataContainer)`
  flex-direction: row;
  margin: 8px 8px 0;
  padding: 4px;
`;
