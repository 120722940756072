import { useEffect, useState, createRef } from 'react';

export const useGroupDescription = () => {
  const [descriptionHeight, setDescriptionHeight] = useState(0);

  const descriptionRef = createRef<HTMLParagraphElement>();

  useEffect(() => {
    if (descriptionRef.current) {
      const totalHeight = descriptionRef.current.offsetHeight;

      if (descriptionHeight !== totalHeight) {
        setDescriptionHeight(totalHeight);
      }
    } else {
      setDescriptionHeight(0);
    }
  }, [descriptionRef, descriptionHeight]);

  return {
    descriptionHeight,
    descriptionRef,
  };
};
