import axios, { AxiosResponse } from 'axios';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';
import { PipelineResponse } from 'types/pipeline';

const COPY_PIPELINE_KEY = 'copyPipeline';

interface CopyPipeline {
  pipelineId: string;
}

const copyPipelineFetcher: MutationFetcher<
  AxiosResponse<PipelineResponse>,
  typeof COPY_PIPELINE_KEY,
  CopyPipeline
> = (__key, opts: { arg: CopyPipeline }) => {
  const { pipelineId } = opts.arg;
  return axios.post(`/pipelines/${pipelineId}/copy`);
};

const useCopyPipeline = () => {
  const { isMutating: isCopying, trigger } = useSWRMutation(COPY_PIPELINE_KEY, copyPipelineFetcher);

  const copyPipeline = (copy: CopyPipeline) => {
    return trigger(copy);
  };

  return { copyPipeline, isCopying };
};

export default useCopyPipeline;
