import { usePipeline } from 'api/services/usePipeline/usePipeline';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import usePipelineId from 'hooks/usePipelineId';
import { useLocation, matchPath } from 'react-router-dom';
import { useAppRoutes } from 'utils/routes';
import ExecutionsNav from './components/ExecutionsNav/ExecutionsNav';
import NewExecutionNav from './pages/NewExecutionPage/components/NewExecutionNav/NewExecutionNav';
import ViewExecutionNav from './pages/ViewExecutionPage/components/ViewExecutionNav/ViewExecutionNav';

const PageNavigation = () => {
  const routes = useAppRoutes();
  const pipelineId = usePipelineId();
  const { pipeline } = usePipeline(pipelineId);
  const { pathname } = useLocation();

  if (!pipeline) {
    return <NavigationBar title="Pathways" />;
  }

  if (
    matchPath({ path: routes.executions.runs(pipelineId) }, pathname) ??
    matchPath({ path: routes.versions(pipelineId) }, pathname)
  ) {
    return <ExecutionsNav pipeline={pipeline} />;
  }

  if (matchPath({ path: routes.executions.new(pipelineId) }, pathname)) {
    return <NewExecutionNav pipeline={pipeline} />;
  }

  if (matchPath({ path: routes.executions.view(pipelineId, ':pipelineExecutionId') }, pathname)) {
    return <ViewExecutionNav pipeline={pipeline} />;
  }

  return <NavigationBar title="Pathways" />;
};

export default PageNavigation;
