import * as Styled from '../../CheckpointForm.styles';
import * as BaseStyled from '../../ExecutionCheckpoint.styles';
import { FormGroup, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import { getJobName, getJobTitle } from 'utils/neurons';

const ExecutionCheckpointSingleTextForm = ({
  disabled,
  input,
  pendingCheckpoint,
  pendingCheckpointHeader,
}: ExecutionCheckpointFormProps) => {
  const { metadata } = pendingCheckpoint;
  const defaultValue = pendingCheckpoint.data.inputs[input.name];
  const { control } = useFormContext();

  return (
    <Styled.FormContainer>
      <Styled.ExecutionCheckpointApprovalHeader>
        <Typography>{getJobTitle(metadata)}</Typography>
        <BaseStyled.CheckpointApprovalHeaderPill>
          <Typography variant="bodySmall">
            {getJobName(metadata)} <span>|</span> {pendingCheckpointHeader}
          </Typography>
        </BaseStyled.CheckpointApprovalHeaderPill>
      </Styled.ExecutionCheckpointApprovalHeader>
      <FormGroup>
        <Styled.CheckpointApprovalRow>
          <RHFTextField
            control={control}
            defaultValue={defaultValue}
            disabled={disabled}
            fullWidth
            multiline
            name={input.name}
          />
        </Styled.CheckpointApprovalRow>
      </FormGroup>
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointSingleTextForm;
