import { Typography } from '@mui/material';
import { JobInfoResponse } from 'api/services/usePipelineExecutionOutputs/usePipelineExecutionOutputs.types';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';

type JobInfoTitleProps = Pick<JobInfoResponse, 'name' | 'type' | 'customTitle'>;

export default function JobInfoTitle(props: JobInfoTitleProps) {
  return (
    <Typography variant="titleMedium" display="flex" alignItems="center" gap={1.5}>
      <JobTypeIcon type={props.type} variant="filled" />
      {props.customTitle ?? props.name}
    </Typography>
  );
}
