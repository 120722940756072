import { Stack, Chip, Typography } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import * as Styled from './SidebarElementCard.styles';

interface SidebarElementCardTaglistProps {
  tags?: string[];
}

const SidebarElementCardTaglist = ({ tags = [] }: SidebarElementCardTaglistProps) => {
  const [visibleTags, setVisibleTags] = useState(tags);
  const [overflowTags, setOverflowTags] = useState<string[]>([]);
  const stackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (stackRef.current) {
      let totalWidth = 0;
      const overflow: string[] = [];
      const visible: string[] = [];

      tags.forEach((tag) => {
        const containerWidth = stackRef.current?.offsetWidth ?? 0;
        const chipWidth = Math.ceil(tag.length * 8 + 32);
        totalWidth += chipWidth;

        if (totalWidth < containerWidth) {
          visible.push(tag);
        } else {
          overflow.push(tag);
        }

        setVisibleTags(visible);
        setOverflowTags(overflow);
      });
    }
  }, [tags, stackRef]);

  return (
    <Stack ref={stackRef} direction="row" gap={1} marginTop={1}>
      {visibleTags.map((tag, i) => (
        <Chip key={tag + i} label={tag} />
      ))}
      {overflowTags.length > 0 && (
        <Styled.TaglistTooltip
          title={
            <Stack>
              {overflowTags.map((tag) => (
                <Typography key={tag} variant="bodySmall">
                  {tag}
                </Typography>
              ))}
            </Stack>
          }
          placement="bottom"
        >
          <Chip label={`+${overflowTags.length}`} />
        </Styled.TaglistTooltip>
      )}
    </Stack>
  );
};

export default SidebarElementCardTaglist;
