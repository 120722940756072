import { useEffect, useMemo, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { numberValidation } from 'components/ui/rhf-components/utils/validationRules';

export interface DecimalInputProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (value?: number) => void;
}

const DecimalInput = ({ onChange, ...textFieldProps }: DecimalInputProps) => {
  const { error, helperText } = textFieldProps;
  const [decimalValue, setDecimalValue] = useState<string>(
    textFieldProps.value ? String(textFieldProps.value) : '',
  );

  const errorMessage = useMemo(() => {
    if (error) {
      return helperText;
    }

    if (decimalValue && !numberValidation.pattern.value.test(decimalValue)) {
      return numberValidation.pattern.message;
    }

    return undefined;
  }, [error, helperText, decimalValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setDecimalValue('');
      onChange(undefined);
    } else {
      if (!isNaN(+event.target.value)) {
        setDecimalValue(event.target.value);
        onChange(parseFloat(event.target.value));
      }
    }
  };

  useEffect(() => {
    setDecimalValue(textFieldProps.value ? String(textFieldProps.value) : '');
  }, [textFieldProps.value]);

  return (
    <TextField
      {...textFieldProps}
      value={decimalValue}
      error={!!errorMessage}
      helperText={errorMessage ?? helperText}
      onChange={handleChange}
    />
  );
};

export default DecimalInput;
