import { BoxProps, SvgIconProps } from '@mui/material';
import { NodeType } from 'features/Flow/Flow.types';
import CheckpointIcon from 'components/Icons/Checkpoint';
import FunctionIcon from 'components/Icons/Function';
import NeuronIcon from 'components/Icons/Neuron';
import BatchIcon from 'components/Icons/Batch';
import BatchStartIcon from 'components/Icons/BatchStartIcon';
import React from 'react';
import { JobNodeType } from 'types/reactflow';
import { JobTypeIconVariant } from './JobTypeIcon.types';
import BatchEndIcon from 'components/Icons/BatchEndIcon';
import PipelineIcon from 'components/Icons/Pipeline';

interface IconConfig {
  icon: React.ElementType<SvgIconProps>;
  variantProps: Record<
    JobTypeIconVariant,
    {
      container?: BoxProps;
      icon?: SvgIconProps;
    }
  >;
}

const NEURON_CONFIG: IconConfig = {
  icon: NeuronIcon,
  variantProps: {
    default: {
      container: {
        color: 'primary.main',
      },
    },
    filled: {
      container: {
        bgcolor: 'primary.dark',
      },
    },
  },
};
const FUNCTION_CONFIG: IconConfig = {
  icon: FunctionIcon,
  variantProps: {
    default: {
      container: {
        color: 'secondary.main',
      },
    },
    filled: {
      container: {
        bgcolor: 'secondary.dark',
      },
    },
  },
};
const CHECKPOINT_CONFIG: IconConfig = {
  icon: CheckpointIcon,
  variantProps: {
    default: {
      container: {
        color: 'info.main',
      },
    },
    filled: {
      container: {
        bgcolor: 'info.dark',
      },
    },
  },
};
const BATCH_CONFIG: IconConfig = {
  ...FUNCTION_CONFIG,
  icon: BatchIcon,
};
const BATCH_START_CONFIG: IconConfig = {
  ...FUNCTION_CONFIG,
  icon: BatchStartIcon,
};
const BATCH_END_CONFIG: IconConfig = {
  ...FUNCTION_CONFIG,
  icon: BatchEndIcon,
};
const SUBPIPELINE_CONFIG: IconConfig = {
  icon: PipelineIcon,
  variantProps: {
    default: {
      container: {
        color: '#E4A80B',
      },
    },
    filled: {
      container: {
        bgcolor: '#E4A80B',
      },
    },
  },
};

export const JOB_TYPE_ICON_CONFIG: Record<JobNodeType, IconConfig | undefined> = {
  [NodeType.PIPELINE_START]: CHECKPOINT_CONFIG,
  [NodeType.PIPELINE_COMPLETE]: CHECKPOINT_CONFIG,
  [NodeType.NEURON]: NEURON_CONFIG,
  [NodeType.FUNCTION]: FUNCTION_CONFIG,
  [NodeType.CHECKPOINT]: CHECKPOINT_CONFIG,
  [NodeType.BATCH_GROUP]: BATCH_CONFIG,
  [NodeType.BATCH_START]: BATCH_START_CONFIG,
  [NodeType.BATCH_END]: BATCH_END_CONFIG,
  [NodeType.SUBPIPELINE]: SUBPIPELINE_CONFIG,
};
