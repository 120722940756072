import { Box, Card, Stack, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { NodeType } from 'features/Flow/Flow.types';
import { SUBPIPELINE_NODE_COLOR_MAIN } from 'features/Flow/components/ElementSidebar/ElementSidebar.styles';
import { FUNCTION_NODE_BACKGROUND_COLOR } from 'features/Flow/nodes/Function/Function.consts';
import styled, { css } from 'styled-components';

export const ElementCard = styled(Card)`
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.surface.level2};
`;

export const ElementHeader = styled(Stack)<{
  $jobType: NodeType | undefined;
}>(
  ({ theme, $jobType }) => css`
    border-top-left-radius: ${theme.spacing(1)};
    border-top-right-radius: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(1)};
    padding-top: ${theme.spacing(0.5)};
    padding-bottom: ${theme.spacing(0.5)};
    padding-left: ${theme.spacing(1.5)};
    padding-right: ${theme.spacing(1)};
    ${() => {
      switch ($jobType) {
        case NodeType.NEURON:
          return css`
            background-color: ${theme.palette.primary.dark};
          `;
        case NodeType.FUNCTION:
        case NodeType.BATCH_GROUP:
          return css`
            background-color: ${FUNCTION_NODE_BACKGROUND_COLOR};
          `;
        case NodeType.SUBPIPELINE:
          return css`
            background-color: ${SUBPIPELINE_NODE_COLOR_MAIN};
          `;
        default:
          return css`
            background-color: ${theme.palette.info.dark};
          `;
      }
    }};
  `,
);

export const ElementContent = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1.5)};
  background-color: transparent;
`;

export const ElementChips = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const ElementTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.surface.level2,
    padding: theme.spacing(2),
  },
}));

export const TaglistTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.surface.level1,
    padding: theme.spacing(1),
  },
}));

export const MaintainerList = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const MaintainerListItem = styled('li')`
  display: 'block';
`;
