import { ClickAwayListener, Fade, Stack, Typography } from '@mui/material';
import { APP_REACTFLOW_DATATRANSFER } from 'features/Flow/Flow.consts';
import SearchField from 'components/SearchField/SearchField';
import React, { useEffect, useState } from 'react';
import { JobNodeType } from 'types/reactflow';
import { ADD_ELEMENT_DATA_KEY } from '../ElementSidebar/ElementSidebar.consts';
import * as Styled from './AddElementSidebar.styles';
import { NodeManifest, NodeType } from 'features/Flow/Flow.types';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import AddElementNodeList from './components/AddElementNodeList';
import AddCallablePipelineNodeList from 'features/Flow/components/AddElementSidebar/components/AddCallablePipelineNodeList';

export interface AddElementSidebarProps {
  title?: string;
  nodeType?: NodeType;
  onClose: () => void;
}

function AddElementSidebar({ title, nodeType, onClose }: AddElementSidebarProps) {
  const [canClose, setCanClose] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const open = !!title && !canClose;
  const { setNewNodeType } = useEditorContext();

  useEffect(() => {
    setCanClose(false);
  }, [nodeType, title]);

  useEffect(() => {
    setSearchVal('');
  }, [nodeType]);

  if (!title) {
    return null;
  }

  const onDragStart = (e: React.DragEvent, manifest: NodeManifest) => {
    e.dataTransfer.setData(APP_REACTFLOW_DATATRANSFER, JSON.stringify(manifest));
    e.dataTransfer.effectAllowed = 'move';
    // Position 0,0 matches the drop position.
    e.dataTransfer.setDragImage(e.currentTarget, 0, 0);

    setNewNodeType(manifest.type);
  };

  return (
    <ClickAwayListener
      onClickAway={(event: MouseEvent | TouchEvent) => {
        if (event.target) {
          const targetElement = event.target as HTMLElement;
          const ignoreClickAway = targetElement.closest(`[${ADD_ELEMENT_DATA_KEY}]`);

          if (ignoreClickAway) return;
        }
        setCanClose(true);
      }}
    >
      <Fade
        in={open}
        onExited={() => {
          onClose();
          setSearchVal('');
        }}
      >
        <Styled.Container role="presentation">
          <Stack spacing="16px">
            <Typography variant="titleMedium">Browse {title}</Typography>

            <SearchField
              value={searchVal}
              placeholder="Search..."
              onChange={(event) => {
                setSearchVal(event.target.value);
              }}
            />

            {nodeType === NodeType.SUBPIPELINE ? (
              <AddCallablePipelineNodeList searchVal={searchVal} onDragStart={onDragStart} />
            ) : (
              <AddElementNodeList
                searchVal={searchVal}
                onDragStart={onDragStart}
                nodeType={nodeType as JobNodeType}
              />
            )}
          </Stack>
        </Styled.Container>
      </Fade>
    </ClickAwayListener>
  );
}

export default AddElementSidebar;
