import { PipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution.types';
import {
  ExecutionResponse,
  ExecutionStatus,
} from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import { ExecutionStatusReadable } from 'config/status';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { getPercentage } from './number';

const ACTIVE_EXECUTION_STATUSES = [
  ExecutionStatus.PENDING,
  ExecutionStatus.SCHEDULED,
  ExecutionStatus.STARTED,
  ExecutionStatus.REVIEW,
];

const NOT_SUCCESS_STATUSES = [
  ExecutionStatus.CANCELED,
  ExecutionStatus.FAILED,
  ExecutionStatus.TIMED_OUT,
];

export function getExecutionStatus(status: ExecutionStatus | undefined) {
  return status ?? ExecutionStatus.PENDING;
}

export function isPipelineExecutionStarted(status: ExecutionStatus | undefined) {
  return status === ExecutionStatus.STARTED;
}

export function isPipelineExecutionScheduled(status: ExecutionStatus | undefined) {
  return status === ExecutionStatus.SCHEDULED || status === ExecutionStatus.PENDING;
}

export function isPipelineExecutionReview(status: ExecutionStatus | undefined) {
  return status === ExecutionStatus.REVIEW;
}

export function isPipelineExecutionActive(status: ExecutionStatus | undefined) {
  return ACTIVE_EXECUTION_STATUSES.includes(getExecutionStatus(status));
}

export function isPipelineExecutionFinished(status: ExecutionStatus | undefined) {
  return !ACTIVE_EXECUTION_STATUSES.includes(getExecutionStatus(status));
}

export function isPipelineExecutionFinishedWithoutSuccess(status: ExecutionStatus | undefined) {
  return NOT_SUCCESS_STATUSES.includes(getExecutionStatus(status));
}

export function isExecutionQueued(status: ExecutionStatus | undefined) {
  return [ExecutionStatus.PENDING, ExecutionStatus.SCHEDULED].includes(getExecutionStatus(status));
}

function formatStatusTime(date?: Date) {
  return date?.toLocaleString('en-us', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}

function formatStatusDate(date?: Date) {
  return date?.toLocaleString('en-us', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

export function formatStatusDateTime(status: ExecutionStatus, date?: Date) {
  if (!date) return undefined;

  const label = status === ExecutionStatus.STARTED ? 'Started' : ExecutionStatusReadable[status];

  return `${label} at ${formatStatusTime(date)} on ${formatStatusDate(date)}`;
}

export function formatRuntime(startAt: Date, completeAt: Date) {
  const date = new Date(completeAt.getTime() - startAt.getTime());
  const hours = differenceInHours(completeAt, startAt);
  const minutes = differenceInMinutes(completeAt, startAt);
  const seconds = date.getSeconds();

  const hoursText = `${hours}`.padStart(2, '0');
  const minutesText = `${minutes - hours * 60}`.padStart(2, '0');
  const secondsText = `${seconds}`.padStart(2, '0');

  const minutesFormat = `${minutesText}:${secondsText}`;

  if (hours > 0) {
    return `${hoursText}:${minutesFormat}`;
  }

  return minutesFormat;
}

function getPipelineExecutionCompletedAt(
  execution: Pick<ExecutionResponse, 'completedAt' | 'status' | 'updatedAt'>,
) {
  if (isPipelineExecutionFinishedWithoutSuccess(execution.status) && !execution.completedAt) {
    return execution.updatedAt;
  }

  return execution.completedAt;
}

export function createPipelineExecution(execution: ExecutionResponse): PipelineExecution {
  const completedAt = getPipelineExecutionCompletedAt(execution);

  return {
    ...execution,
    createdAt: new Date(execution.createdAt),
    updatedAt: new Date(execution.updatedAt),
    completedAt: completedAt ? new Date(completedAt) : undefined,
    progressPercentage: getPercentage(execution.completedJobs, execution.totalJobs),
  };
}

export function getPipelineExecutionOrDefault(execution?: PipelineExecution): PipelineExecution {
  if (execution) return execution;

  return {
    id: '',
    completedAt: undefined,
    completedJobs: 0,
    completedCheckpoints: 0,
    createdAt: new Date(),
    executionNumber: 0,
    hasPendingCheckpoints: false,
    pipelineId: '',
    progressPercentage: 0,
    status: ExecutionStatus.PENDING,
    totalJobs: 0,
    totalCheckpoints: 0,
    updatedAt: new Date(),
    version: '',
    revision: '',
    pipelineVersionState: 'draft',
  };
}
