import { createStore, StoreApi } from 'zustand';
import { removeByKey } from 'utils/objects';
import { NodeInput, NodeOutput } from '../nodes/Node/Node.types';
import { createHandleId } from '../Flow.utils';
import { HandleType as FlowHandleType, Position } from 'reactflow';
import { DataSchema } from '@pathways/pipeline-schema';

export interface HandleStoreState {
  handles: Record<string, AppHandle>;
  register: (handle: AppHandle) => void;
  unregister: (key: HandleKey) => void;
  getHandle: (key: HandleKey) => AppHandle | undefined;
}

export type HandleStore = StoreApi<HandleStoreState>;

export const createHandleStore = () =>
  createStore<HandleStoreState>()((set, get) => ({
    handles: {},
    register: (handle) => {
      set({
        handles: { ...get().handles, [computeHandleKey(handle)]: handle },
      });
    },
    unregister: (key) => {
      set({
        handles: removeByKey(get().handles, computeHandleKey(key)),
      });
    },
    getHandle: (key) => get().handles[computeHandleKey(key)],
  }));

export interface CustomHandleData extends HandleKey {
  type: 'custom';
  purpose: HandlePurpose;
  /**
   * The callback to verify if a handle can connection can be made.
   * @param handle The full data handle information including the schema for the handle that is selected.
   * @returns
   */
  canConnect: (handle: DataHandle) => boolean;
}

export type AppHandle = CustomHandleData | DataHandle;

export interface HandleKey {
  id: string;
  nodeId: string;
}

export type HandlePurpose = 'input' | 'output';

export const toFlowHandleType = (purpose: HandlePurpose): FlowHandleType => {
  const typeMapping: Record<HandlePurpose, FlowHandleType> = {
    input: 'target',
    output: 'source',
  };
  return typeMapping[purpose];
};

export const toFlowHandlePosition = (purpose: HandlePurpose): Position => {
  const positionMapping: Record<HandlePurpose, Position> = {
    input: Position.Left,
    output: Position.Right,
  };
  return positionMapping[purpose];
};

interface CreateInputDataHandle {
  data: NodeInput;
  purpose: 'input';
  nodeId: string;
  id?: string;
}

interface CreateOutputDataHandle {
  data: NodeOutput;
  purpose: 'output';
  nodeId: string;
  id?: string;
}

export const createDataHandle = ({
  purpose,
  data,
  nodeId,
  id,
}: CreateInputDataHandle | CreateOutputDataHandle): DataHandle => {
  const { dataSchema: schema, ...handleData } = data;

  return {
    ...handleData,
    type: 'data',
    purpose,
    schema,
    nodeId,
    id: id ?? createHandleId(purpose, data.name),
  };
};

export type DataHandle = HandleKey &
  (
    | Omit<CreateInputDataHandle['data'], 'dataSchema'>
    | Omit<CreateOutputDataHandle['data'], 'dataSchema'>
  ) & {
    type: 'data';
    purpose: HandlePurpose;
    schema: DataSchema;
  };

const HANDLE_KEY_SEPARATOR = '@';

export const computeHandleKey = ({ id, nodeId }: HandleKey): string =>
  `${id}${HANDLE_KEY_SEPARATOR}${nodeId}`;

export const createHandleKey = (key: string): HandleKey => {
  const [id, nodeId] = key.split(HANDLE_KEY_SEPARATOR);
  return { id, nodeId };
};
