import { forwardRef, useEffect, useState } from 'react';
import * as Styled from './CopyButton.styles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, IconButtonProps } from '@mui/material';

interface CopyButtonProps extends Pick<IconButtonProps, 'sx' | 'size' | 'color'> {
  label?: string;
  value: string;
}

const CopyButton = forwardRef<HTMLButtonElement, CopyButtonProps>(function CopyButton(props, ref) {
  const { color, label, size, sx, value } = props;
  const [copied, setCopied] = useState(false);
  const [hovered, setHovered] = useState(false);
  // We are intentionally ignoring the color prop here
  const { color: _ignored, ...labeledButtonProps } = props;

  useEffect(() => {
    if (!copied) return;

    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  const handleClick = () => {
    void navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
    });
  };

  return label ? (
    <Styled.CopyButton
      {...labeledButtonProps}
      ref={ref}
      disableRipple
      endIcon={
        copied ? (
          <CheckRoundedIcon color="primary" fontSize={size} />
        ) : (
          hovered && <ContentCopyIcon color="primary" fontSize={size} />
        )
      }
      onClick={handleClick}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      {label}
    </Styled.CopyButton>
  ) : (
    <IconButton
      color={color ?? 'primary'}
      {...props}
      ref={ref}
      aria-label="copy"
      edge="end"
      sx={sx}
      size={size}
      onClick={handleClick}
    >
      {copied ? <CheckRoundedIcon fontSize="inherit" /> : <ContentCopyIcon fontSize="inherit" />}
    </IconButton>
  );
});

export default CopyButton;
