import { Config, useRequest } from 'api/client';
import { PipelineExecutionsResponse } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';

const usePipelineExecutions = (id: string, config?: Config<PipelineExecutionsResponse>) => {
  const executionsRequest = useRequest<PipelineExecutionsResponse>(
    {
      url: `/pipelines/${id}/executions`,
    },
    config,
  );

  return {
    areExecutionsLoading: executionsRequest.isLoading,
    areExecutionsValidating: executionsRequest.isValidating,
    executions: executionsRequest.data?.items ?? [],
    executionsError: executionsRequest.error,
    mutateExecutions: executionsRequest.mutate,
  };
};

export default usePipelineExecutions;
