import { SvgIcon, SvgIconProps } from '@mui/material';

const TextArrayIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M5.875 25.125C6.45833 25.7083 7.16667 26 8 26H10C10.2833 26 10.5208 25.9042 10.7125 25.7125C10.9042 25.5208 11 25.2833 11 25C11 24.7167 10.9042 24.4792 10.7125 24.2875C10.5208 24.0958 10.2833 24 10 24H8C7.71667 24 7.47917 23.9042 7.2875 23.7125C7.09583 23.5208 7 23.2833 7 23V9C7 8.71667 7.09583 8.47917 7.2875 8.2875C7.47917 8.09583 7.71667 8 8 8H10C10.2833 8 10.5208 7.90417 10.7125 7.7125C10.9042 7.52083 11 7.28333 11 7C11 6.71667 10.9042 6.47917 10.7125 6.2875C10.5208 6.09583 10.2833 6 10 6H8C7.16667 6 6.45833 6.29167 5.875 6.875C5.29167 7.45833 5 8.16667 5 9V23C5 23.8333 5.29167 24.5417 5.875 25.125Z"
          fill="black"
        />
        <path
          d="M26.125 25.125C25.5417 25.7083 24.8333 26 24 26H22C21.7167 26 21.4792 25.9042 21.2875 25.7125C21.0958 25.5208 21 25.2833 21 25C21 24.7167 21.0958 24.4792 21.2875 24.2875C21.4792 24.0958 21.7167 24 22 24H24C24.2833 24 24.5208 23.9042 24.7125 23.7125C24.9042 23.5208 25 23.2833 25 23V9C25 8.71667 24.9042 8.47917 24.7125 8.2875C24.5208 8.09583 24.2833 8 24 8H22C21.7167 8 21.4792 7.90417 21.2875 7.7125C21.0958 7.52083 21 7.28333 21 7C21 6.71667 21.0958 6.47917 21.2875 6.2875C21.4792 6.09583 21.7167 6 22 6H24C24.8333 6 25.5417 6.29167 26.125 6.875C26.7083 7.45833 27 8.16667 27 9V23C27 23.8333 26.7083 24.5417 26.125 25.125Z"
          fill="black"
        />
        <path
          d="M14.9375 21.5625C15.2292 21.8542 15.5833 22 16 22C16.4167 22 16.7708 21.8542 17.0625 21.5625C17.3542 21.2708 17.5 20.9167 17.5 20.5V14H19.5C19.9167 14 20.2708 13.8542 20.5625 13.5625C20.8542 13.2708 21 12.9167 21 12.5C21 12.0833 20.8542 11.7292 20.5625 11.4375C20.2708 11.1458 19.9167 11 19.5 11H12.5C12.0833 11 11.7292 11.1458 11.4375 11.4375C11.1458 11.7292 11 12.0833 11 12.5C11 12.9167 11.1458 13.2708 11.4375 13.5625C11.7292 13.8542 12.0833 14 12.5 14H14.5V20.5C14.5 20.9167 14.6458 21.2708 14.9375 21.5625Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default TextArrayIcon;
