import {
  TextFieldProps as MuiTextFieldProps,
  TextField as MuiTextField,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import DecimalInput from 'components/Inputs/DecimalInput/DecimalInput';
import { getFieldValue } from 'components/Inputs/TextField/TextField.utils';
import { integerValidation } from 'components/ui/rhf-components/utils/validationRules';
import { forwardRef, useMemo } from 'react';
import type { ValueAs, ValueAsMap } from './TextField.types';

export type TextFieldProps = Omit<MuiTextFieldProps, 'onChange'> & {
  TooltipProps?: Omit<TooltipProps, 'children'>;
  valueAs?: ValueAs;
  onChange?: (value?: ValueAsMap[ValueAs] | string) => void;
};

export default forwardRef<HTMLDivElement, TextFieldProps>(function TextField(props, ref) {
  const { TooltipProps, valueAs, value, ...muiTextFieldProps } = props;
  const { error, helperText } = muiTextFieldProps;
  const supportDecimal = valueAs === 'number';

  const errorMessage = useMemo(() => {
    if (error) {
      return helperText;
    }

    if (valueAs === 'integer' && value && !integerValidation.pattern.value.test(String(value))) {
      return integerValidation.pattern.message;
    }

    return undefined;
  }, [error, helperText, value, valueAs]);

  return (
    <Tooltip title="" {...TooltipProps}>
      {/* span: holds the ref for the Tooltip target */}
      <span>
        {supportDecimal ? (
          <DecimalInput
            {...muiTextFieldProps}
            ref={ref}
            onChange={(value) => {
              props.onChange?.(value);
            }}
          />
        ) : (
          <MuiTextField
            {...muiTextFieldProps}
            ref={ref}
            error={!!errorMessage}
            helperText={errorMessage ?? helperText}
            value={value ?? ''}
            onChange={(e) => {
              const { value } = e.target;
              const fieldValue = getFieldValue(value, valueAs);

              props.onChange?.(fieldValue);
            }}
          />
        )}
      </span>
    </Tooltip>
  );
});
