import useAuthContext from 'contexts/auth/useAuthContext';
import { formatRequiredMessage } from 'utils/message';
import useWorkspaceSlug from './useWorkspaceSlug';
import { MissingWorkspaceError, UnauthorizedError } from 'utils/customErrors';

export default function useWorkspace() {
  const { user } = useAuthContext();
  const workspaceSlug = useWorkspaceSlug();

  if (!user) {
    throw new UnauthorizedError(formatRequiredMessage('user'));
  }

  const workspace = user.workspaces.find((workspace) => workspace.slug === workspaceSlug);

  if (!workspace) {
    throw new MissingWorkspaceError(formatRequiredMessage('workspace'));
  }

  return workspace;
}
