import { Paper } from '@mui/material';
import { getExecutionStatus, isPipelineExecutionFinishedWithoutSuccess } from 'utils/execution';
import { FlowExecutionJob } from 'types/reactflow';
import OutputTabPanelOutput from 'pages/RuntimePage/components/RightSidebar/components/OutputTabPanel/components/OutputTabPanelOutput';
import OutputTabPanelError from 'pages/RuntimePage/components/RightSidebar/components/OutputTabPanel/components/OutputTabPanelError';

interface OutputTabPanelProps {
  executionJob?: FlowExecutionJob;
}

export default function OutputTabPanel(props: OutputTabPanelProps) {
  const { executionJob } = props;
  const value = executionJob?.outputs;
  const status = getExecutionStatus(executionJob?.status);

  const executionJobNotSuccessful = isPipelineExecutionFinishedWithoutSuccess(status);

  return (
    <Paper
      elevation={2}
      sx={{
        height: '100%',
        marginTop: 0,
      }}
    >
      {executionJobNotSuccessful ? (
        <OutputTabPanelError executionJob={executionJob} />
      ) : (
        <OutputTabPanelOutput status={status} value={value} />
      )}
    </Paper>
  );
}
