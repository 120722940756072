import { isHandleConnection } from 'features/Flow/Flow.types';
import { getNodeInput, getNodeOutput } from 'features/Flow/Flow.utils';
import { CustomHandleData, HandleKey } from 'features/Flow/Handles/handle.store';
import {
  createCustomHandle,
  useGetSourceHandle,
  useGetTargetHandle,
} from 'features/Flow/Handles/handles';
import { useDeferredConnectionState } from 'features/Flow/hooks/useDeferredConnectionState';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { NodeInput, NodeOutput, PathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { useCallback, useEffect, useMemo } from 'react';

interface UseDummyNeuronCustomHandleOptions {
  type: 'input' | 'output';
  nodeId: string;
  inputs?: NodeInput[];
  outputs?: NodeOutput[];
  canConnect?: CustomHandleData['canConnect'];
  onOpenModalForInput?: (input: NodeInput) => void;
  onOpenModalForOutput?: (output: NodeOutput) => void;
}

const defaultCanConnect = () => true;
const defaultInputs: NodeInput[] = [];
const defaultOutputs: NodeOutput[] = [];

const useDummyNeuronCustomHandle = ({
  type,
  nodeId,
  inputs = defaultInputs,
  outputs = defaultOutputs,
  canConnect = defaultCanConnect,
  onOpenModalForInput,
  onOpenModalForOutput,
}: UseDummyNeuronCustomHandleOptions) => {
  const { addConnectionHandler, removeConnectionHandler } = useEditorContext();
  const getSourceHandle = useGetSourceHandle();
  const getTargetHandle = useGetTargetHandle();
  const updateNodeData = useUpdateNodeData();
  const { deferredConnection, setDeferredConnectionState, cancelDeferredConnectionAction } =
    useDeferredConnectionState({ inputs, outputs });
  const handle = useMemo(() => {
    return createCustomHandle(nodeId, type, canConnect);
  }, [nodeId, type, canConnect]);

  const attemptAddOutput = useCallback(
    (output: NodeOutput) => {
      if (isKeyInUse(output.name, outputs)) {
        return false;
      }

      updateNodeData<PathwayNode['data']>(nodeId, (data) => ({
        ...data,
        outputs: [...outputs, output],
      }));
      return true;
    },
    [nodeId, outputs, updateNodeData],
  );

  const createOutput = useCallback(
    (output: NodeOutput) => {
      if (!deferredConnection) {
        throw new Error('No deferred connection found.');
      }

      const newOutput = getNodeOutput(output);
      const isAdded = attemptAddOutput(newOutput);

      if (!isAdded) {
        throw new Error('Input was not added.');
      }

      setDeferredConnectionState({
        connection: {
          ...deferredConnection,
          sourceHandle: newOutput.id,
        },
        outputName: newOutput.name,
      });
    },
    [attemptAddOutput, deferredConnection, setDeferredConnectionState],
  );

  const attemptAddInput = useCallback(
    (input: NodeInput) => {
      if (isKeyInUse(input.name, inputs)) {
        return false;
      }
      updateNodeData<PathwayNode['data']>(nodeId, (data) => ({
        ...data,
        inputs: [...inputs, input],
      }));
      return true;
    },
    [nodeId, inputs, updateNodeData],
  );

  const createInput = useCallback(
    (input: NodeInput) => {
      if (!deferredConnection) {
        throw new Error('No deferred connection found.');
      }

      const newInput = getNodeInput(input);
      const isAdded = attemptAddInput(newInput);

      if (!isAdded) {
        throw new Error('Input was not added.');
      }

      setDeferredConnectionState({
        connection: {
          ...deferredConnection,
          targetHandle: newInput.id,
        },
        inputName: newInput.name,
      });
    },
    [attemptAddInput, deferredConnection, setDeferredConnectionState],
  );

  useEffect(() => {
    const connectionHandlerKey: HandleKey = {
      id: handle.id,
      nodeId,
    };

    addConnectionHandler?.(connectionHandlerKey, (connection, action) => {
      if (!isHandleConnection(connection)) return null;

      if (type === 'input') {
        const sourceHandle = getSourceHandle(connection);

        const newInput = getNodeInput({
          config: sourceHandle.config,
          dataSchema: sourceHandle.schema,
          name: sourceHandle.name,
          title: sourceHandle.title,
          description: sourceHandle.description,
        });

        const isAdded = attemptAddInput(newInput);
        setDeferredConnectionState({
          action,
          connection: {
            ...connection,
            targetHandle: newInput.id,
          },
        });

        if (isAdded) {
          setDeferredConnectionState({
            inputName: newInput.name,
          });
        } else {
          onOpenModalForInput?.(newInput);
        }

        return 'defer';
      }

      const targetHandle = getTargetHandle(connection);

      const newOutput = getNodeOutput({
        config: targetHandle.config,
        dataSchema: targetHandle.schema,
        name: targetHandle.name,
        title: targetHandle.title,
        description: targetHandle.description,
      });

      const isAdded = attemptAddOutput(newOutput);
      setDeferredConnectionState({
        action,
        connection: {
          ...connection,
          sourceHandle: newOutput.id,
        },
      });

      if (isAdded) {
        setDeferredConnectionState({
          outputName: newOutput.name,
        });
      } else {
        onOpenModalForOutput?.(newOutput);
      }

      return 'defer';
    });

    return () => {
      removeConnectionHandler?.(connectionHandlerKey);
    };
  }, [
    addConnectionHandler,
    handle,
    getSourceHandle,
    getTargetHandle,
    removeConnectionHandler,
    nodeId,
    type,
    inputs,
    outputs,
    updateNodeData,
    setDeferredConnectionState,
    onOpenModalForInput,
    onOpenModalForOutput,
    attemptAddInput,
    attemptAddOutput,
  ]);

  return {
    handle,
    cancelConnection: cancelDeferredConnectionAction,
    createInput,
    createOutput,
  };
};

const isKeyInUse = <T extends { name: string }>(key: string, items: T[]) => {
  return items.some((item) => item.name === key);
};

export default useDummyNeuronCustomHandle;
