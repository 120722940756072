import { useState } from 'react';
import { getNodeInput } from 'features/Flow/Flow.utils';
import { DEFAULT_VIEW, InputConfigCommitFn, ViewStates } from './PipelineCompleteModal.types';
import { arrayMove } from '@dnd-kit/sortable';
import * as Styled from 'components/Dialog/Dialog.styles';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import PipelineCompleteList from './PipelineCompleteList';
import { PipelineCompleteEditForm } from '../PipelineCompleteForms/PipelineCompleteEditForm';
import PipelineCompleteCreateForm from '../PipelineCompleteForms/PipelineCompleteCreateForm';

export interface PipelineCompleteConfigModalProps {
  nodeId: string;
  inputs: NodeInput[];
  open: boolean;
  onCloseModal: () => void;
  onConfirm: InputConfigCommitFn;
}

export default function PipelineCompleteConfigModal({
  inputs,
  open,
  onCloseModal,
  onConfirm,
  nodeId,
}: PipelineCompleteConfigModalProps) {
  const [nodeInputs, setNodeInputs] = useState<NodeInput[]>(inputs);
  const [removedInputs, setRemovedInputs] = useState<string[]>([]);
  const [viewState, setViewState] = useState<ViewStates>({ view: DEFAULT_VIEW });

  const handleCreateOutputClick = () => {
    setViewState({ view: 'create' });
  };

  const handleEditInput = (input: NodeInput, index: number) => {
    setViewState({ view: 'edit', inputToEdit: input, updateIndex: index });
  };

  const handleFormCancel = () => {
    setViewState({ view: DEFAULT_VIEW });
  };

  const handleConfirmCreate = (newInput: NodeInput) => {
    setNodeInputs((prev) => {
      return [...prev, newInput];
    });
    setViewState({ view: DEFAULT_VIEW });
  };

  const handleRemoveInput = (indexToRemove: number) => {
    const inputToRemove = getNodeInput(nodeInputs[indexToRemove]);

    setRemovedInputs((prev) => {
      return [...prev, inputToRemove.id];
    });
    setNodeInputs((prev) => {
      return prev.filter((_, index) => indexToRemove !== index);
    });
  };

  const handleCommitChanges = () => {
    onConfirm(nodeInputs, removedInputs);
  };

  const handleReorder = (from: string, to: string) => {
    setNodeInputs((nodeInputs) => {
      const oldIndex = nodeInputs.findIndex((input) => input.name === from);
      const newIndex = nodeInputs.findIndex((input) => input.name === to);
      if (oldIndex === -1 || newIndex === -1) return nodeInputs;
      return arrayMove(nodeInputs, oldIndex, newIndex);
    });
  };

  const handleConfirmEdit = (update: NodeInput) => {
    if (viewState.view !== 'edit') return;

    setNodeInputs((prev) => {
      return prev.map((input, index) =>
        viewState.updateIndex === index ? { ...input, ...update } : input,
      );
    });
    setViewState({ view: DEFAULT_VIEW });
  };

  return (
    <Styled.Dialog
      open={open}
      onClose={onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      {viewState.view === DEFAULT_VIEW && (
        <PipelineCompleteList
          inputs={nodeInputs}
          onCloseModal={onCloseModal}
          onConfirm={handleCommitChanges}
          onCreateInput={handleCreateOutputClick}
          onEditInput={handleEditInput}
          onRemoveInput={handleRemoveInput}
          onReorder={handleReorder}
        />
      )}

      {viewState.view === 'edit' && (
        <PipelineCompleteEditForm
          nodeId={nodeId}
          inputs={nodeInputs}
          inputToEdit={viewState.inputToEdit}
          onCancelEdit={handleFormCancel}
          onConfirmEdit={handleConfirmEdit}
        />
      )}

      {viewState.view === 'create' && (
        <PipelineCompleteCreateForm
          nodeInputs={nodeInputs}
          defaultValues={null}
          onCancelCreate={handleFormCancel}
          onConfirmCreate={handleConfirmCreate}
        />
      )}
    </Styled.Dialog>
  );
}
