import type { ValueAs } from './TextField.types';

export function getFieldValue<TValueAs extends ValueAs | undefined = undefined>(
  value: string,
  valueAs?: TValueAs,
) {
  // TODO: check if 'boolean' is used/needed.
  // Select with MenuItem does not support actual `boolean` values so it needs to be mapped
  const isBoolean = valueAs === 'boolean';
  if (isBoolean) {
    return value === 'true';
  }

  const isInteger = valueAs === 'integer';
  if (isInteger) {
    return toInteger(value);
  }

  return value;
}

function toInteger(value: string) {
  const output = parseInt(value);
  return Number.isNaN(output) ? '' : output;
}
