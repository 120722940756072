import { Box } from '@mui/material';
import styled from 'styled-components';

export const AutocompleteEndAdornmentBox = styled(Box).attrs({
  className: `AutocompleteEndAdornmentBox`,
})`
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
`;
