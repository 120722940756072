import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';

const importPipelineFetcher: MutationFetcher<
  AxiosResponse<{ id: string }>,
  string,
  AxiosRequestConfig
> = (_, { arg: request }) => axios.request(request);

interface ImportPipelineProps {
  projectId?: string;
  workspaceId?: string;
  file: File;
}

const useImportPipeline = () => {
  const { trigger, isMutating } = useSWRMutation('importPipeline', importPipelineFetcher);

  const importPipeline = useCallback(
    async ({ projectId, workspaceId, file }: ImportPipelineProps) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', file.name);
      let url = 'pipelines/import';
      if (workspaceId) {
        url += `?workspaceId=${workspaceId}`;
      } else if (projectId) {
        url += `?projectId=${projectId}`;
      }

      const requestConfig: AxiosRequestConfig = {
        url: url,
        method: 'POST',
        data: formData,
        headers: { 'content-type': 'multipart/form-data' },
      };

      return trigger(requestConfig);
    },
    [trigger],
  );

  return { isLoading: isMutating, importPipeline };
};

export default useImportPipeline;
