import { MenuItem } from '@mui/material';
import { DataSchema, isArrayDataSchema } from '@pathways/pipeline-schema/web';
import { ARRAY_DATA_TYPE_OPTIONS, DATA_TYPE_OPTIONS, DataTypeOption } from './DataTypeField.consts';
import { DataTypeOptionContent } from './DataTypeOptionContent';

export function getDataTypeFieldValue(schema?: DataSchema) {
  if (!schema) return '';
  return getDataTypeOptionKey(schema);
}

export function getSingleDataTypeFieldValue(schema?: DataSchema) {
  if (!schema) return '';
  const key = getDataTypeOptionKey(schema);
  return key.startsWith('array') ? 'array' : key;
}

export function getArrayDataTypeFieldValue(schema?: DataSchema) {
  if (!schema) return '';
  return getDataTypeOptionKey(schema);
}

export function getDataTypeOptionKey(schema: DataSchema) {
  if (isArrayDataSchema(schema)) {
    return schema.items.type === null ? 'array' : `array-${schema.items.type}`;
  }
  return schema.type;
}

const optionsData = DATA_TYPE_OPTIONS.concat(ARRAY_DATA_TYPE_OPTIONS);

export function getDataTypeOptionSchema(key: string) {
  return optionsData.find((option) => option.value === key)?.schema;
}

export function createDataTypeOptions(
  schemaFilter: (schema: DataTypeOption['schema']) => boolean = Boolean,
) {
  return DATA_TYPE_OPTIONS.filter(({ schema }) => schemaFilter(schema)).map(mapDataTypeOption);
}

export function createArrayDataTypeOptions(
  schemaFilter: (schema: DataTypeOption['schema']) => boolean = Boolean,
) {
  return ARRAY_DATA_TYPE_OPTIONS.filter(({ schema }) => schemaFilter(schema)).map(
    mapDataTypeOption,
  );
}

export function mapDataTypeOption(option: DataTypeOption) {
  return (
    <MenuItem key={option.value} value={option.value}>
      <DataTypeOptionContent option={option} />
    </MenuItem>
  );
}
