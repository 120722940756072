import React from 'react';
import { useFeatureFlag } from './useFeatureFlag';
import { AppFeatures } from './FeatureFlag.const';

interface FeatureFlagProps {
  feature: AppFeatures;
  fallback?: React.ReactNode;
}

const FeatureFlag = ({
  feature,
  fallback,
  children,
}: React.PropsWithChildren<FeatureFlagProps>) => {
  const isFeatureEnabled = useFeatureFlag(feature);

  return isFeatureEnabled ? children : fallback ?? null;
};

export default FeatureFlag;
