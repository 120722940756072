import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import { useCallback, useState } from 'react';
import {
  ModalState,
  UsePipelineCompleteQuickCreateModalProps,
  UsePipelineCompleteQuickCreateModalReturn,
} from './PipelineCompleteQuickCreateModal.types';

export const usePipelineCompleteQuickCreateModal = ({
  onCreateInput,
  onCloseModal,
}: UsePipelineCompleteQuickCreateModalProps): UsePipelineCompleteQuickCreateModalReturn => {
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
  });

  const handleCreateInput = useCallback(
    (input: NodeInput) => {
      if (!modalState.open) {
        return;
      }

      setModalState({ open: false });
      onCreateInput(input);
    },
    [modalState.open, onCreateInput],
  );

  const handleOpenModal = useCallback((initialInput: NodeInput) => {
    setModalState({
      open: true,
      defaultValue: initialInput,
    });
  }, []);

  const handleCloseModal = () => {
    setModalState({ open: false });
    onCloseModal();
  };

  return {
    open: modalState.open,
    defaultValues: modalState.open ? modalState.defaultValue : null,
    openModal: handleOpenModal,
    onCloseModal: handleCloseModal,
    onCreateInput: handleCreateInput,
  };
};
