import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { styled } from '@mui/material';
import { forwardTransientProps } from 'utils/styles';

export default styled(DragIndicatorIcon, {
  shouldForwardProp: forwardTransientProps,
})<{ $isDragging: boolean }>`
  color: ${({ theme }) => theme.palette.action.active};
  cursor: ${({ $isDragging }) => ($isDragging ? 'grabbing' : 'grab')};
`;
