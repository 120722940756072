import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ReactNode } from 'react';
import * as Styled from './InputDialog.styles';

interface InputDialogProps {
  expand: boolean;
  onClose(): void;
  onCancel(): void;
  onConfirm(): void;
  confirmDisabled?: boolean;
  title: string;
  children: ReactNode;
}

const InputDialog = ({
  expand,
  title,
  children,
  onClose,
  onCancel,
  onConfirm,
  confirmDisabled = false,
}: InputDialogProps) => {
  if (!expand) return null;

  return (
    <Styled.InputDialog
      open={expand}
      fullWidth
      aria-labelledby="input-dialog-expanded"
      aria-describedby="input-dialog-expanded"
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title">Input a Value</DialogTitle>
      <DialogContent>
        <Typography variant="labelSmall">{title}</Typography>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="text" disabled={confirmDisabled} onClick={onConfirm}>
          Confirm Input
        </Button>
      </DialogActions>
    </Styled.InputDialog>
  );
};

export default InputDialog;
