import { GroupNode } from 'features/Flow/nodes/Group/Group.types';
import { SectionDivider } from 'components/Sidebar/Sidebar.styles';
import Header from './components/Header/Header';
import GroupForm from './components/GroupForm/GroupForm';
import { BatchGroupNode, isBatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';
import BatchGroupForm from './components/GroupForm/BatchGroupForm';

interface GroupPanelProps {
  groupNode: GroupNode | BatchGroupNode;
  readOnly?: boolean;
}

export default function GroupPanel(props: GroupPanelProps) {
  const { groupNode, readOnly = false } = props;

  const isBatchGroup = isBatchGroupNode(groupNode);

  return (
    <>
      <Header groupNode={groupNode} readOnly={readOnly} />
      <SectionDivider />
      {isBatchGroup ? (
        <BatchGroupForm groupNode={groupNode} readOnly={readOnly} />
      ) : (
        <GroupForm groupNode={groupNode} readOnly={readOnly} />
      )}
    </>
  );
}
