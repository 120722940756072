import { Stack, TextField } from '@mui/material';
import { SectionDivider } from 'components/Sidebar/Sidebar.styles';
import CallableToggle from 'features/Flow/components/CallableToggle/CallableToggle';
import PipelineIdFieldWithCopyAction from 'features/Flow/components/PipelineIdFieldWithCopyAction/PipelineIdFieldWithCopyAction';
import PipelineUserInfo from 'features/Flow/components/PipelineUserInfo/PipelineUserInfo';
import { FlowPipeline } from 'types/reactflow';

interface PipelineSidebarProps {
  pipelineNameRef?: React.RefObject<HTMLInputElement>;
  pipelineName: string;
  setPipelineName?: (value: React.SetStateAction<string>) => void;
  pipelineNameError?: boolean;
  pipeline: FlowPipeline;
  pipelineDescription: string | undefined;
  setPipelineDescription?: (value: React.SetStateAction<string | undefined>) => void;
  projectId?: string;
  readOnly?: boolean;
}

const PipelineSidebar = ({
  pipelineNameRef,
  pipelineName,
  setPipelineName,
  pipelineNameError = false,
  pipeline,
  pipelineDescription,
  setPipelineDescription,
  projectId,
  readOnly = false,
}: PipelineSidebarProps) => {
  return (
    <>
      <Stack gap={2}>
        <TextField
          inputRef={pipelineNameRef}
          name="pipelineName"
          size="small"
          fullWidth
          variant="standard"
          value={pipelineName}
          onChange={(event) => {
            setPipelineName?.(event.target.value);
          }}
          label="Pipeline Name"
          required
          inputProps={{
            maxLength: 100,
          }}
          error={pipelineNameError}
          helperText={pipelineNameError && 'This field is required'}
          InputProps={{ readOnly }}
        />
        <PipelineUserInfo
          author={{
            ...pipeline,
          }}
        />
        <TextField
          name="pipelineDescription"
          size="small"
          fullWidth
          multiline
          value={pipelineDescription ?? ''}
          onChange={(event) => {
            setPipelineDescription?.(event.target.value);
          }}
          label="Pipeline Description"
          inputProps={{
            maxLength: 2000,
          }}
          InputProps={{ readOnly }}
        />
      </Stack>
      <SectionDivider />
      <CallableToggle pipelineId={pipeline.id} projectId={projectId} readOnly={readOnly} />
      <SectionDivider />
      <PipelineIdFieldWithCopyAction pipelineId={pipeline.id} />
    </>
  );
};

export default PipelineSidebar;
