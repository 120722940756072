import { FC, useCallback, useEffect } from 'react';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import { Button, Grid } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from 'components/Dialog/Dialog.styles';
import PipelineCompleteForm from './PipelineCompleteForm';
import { createHandleId } from 'features/Flow/Flow.utils';
import { createInputDataSchema, createInputDefaultValues } from '../PipelineComplete.utils';
import { NodeInputFormData } from '../PipelineCompleteModal/PipelineCompleteModal.types';

interface PipelineCompleteCreateFormProps {
  defaultValues: NodeInput | null;
  nodeInputs: NodeInput[];
  isPendingConnection?: boolean;
  onCancelCreate: () => void;
  onConfirmCreate: (input: NodeInput) => void;
}

const PipelineCompleteCreateForm: FC<PipelineCompleteCreateFormProps> = ({
  defaultValues,
  nodeInputs,
  isPendingConnection,
  onCancelCreate,
  onConfirmCreate,
}) => {
  const form = useForm<NodeInputFormData>({
    defaultValues: createInputDefaultValues(defaultValues),
    mode: 'onBlur',
  });

  const submitForm: SubmitHandler<NodeInputFormData> = useCallback(
    (data: NodeInputFormData) => {
      onConfirmCreate({
        name: data.name,
        dataSchema: createInputDataSchema(data),
        title: data.title ?? '',
        config: {
          required: !!isPendingConnection,
        },
        id: createHandleId('input', data.name),
      });
    },
    [onConfirmCreate, isPendingConnection],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  useEffect(() => {
    if (defaultValues) {
      void form.trigger();
    }
  }, [defaultValues, form]);

  return (
    <FormProvider {...form}>
      <Styled.DialogTitle $hasBorder>Create Output</Styled.DialogTitle>
      <Styled.DialogContent>
        <PipelineCompleteForm
          otherInputs={nodeInputs}
          control={form.control}
          isConnected={false}
          isPendingConnection={isPendingConnection}
        />
      </Styled.DialogContent>
      <Styled.DialogActions $hasBorder>
        <Grid container justifyContent="flex-end">
          <Button variant="text" color="inherit" onClick={onCancelCreate}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!form.formState.isValid}>
            Confirm
          </Button>
        </Grid>
      </Styled.DialogActions>
    </FormProvider>
  );
};

export default PipelineCompleteCreateForm;
