import { useUpdateNodeInternals } from 'reactflow';
import { useCallback, useEffect, useMemo } from 'react';
import { createHandleId } from 'features/Flow/Flow.utils';
import {
  createCustomHandle,
  useGetSourceHandle,
  useGetTargetHandle,
} from 'features/Flow/Handles/handles';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { PathwayNode, PathwayNodeProps } from '../../Node/Node.types';
import {
  canDataManipulatorAddInputConnect,
  canDataManipulatorAddOutputConnect,
  createDataInput,
  createDynamicSchemaInput,
  createDataOutput,
} from './DataManipulator.utils';
import { DataHandle, HandleKey } from 'features/Flow/Handles/handle.store';
import { useDeferredConnectionState } from 'features/Flow/hooks/useDeferredConnectionState';
import { isHandleConnection } from 'features/Flow/Flow.types';

export const useDataManipulator = (props: PathwayNodeProps) => {
  const {
    id: nodeId,
    data: { inputs, outputs },
  } = props;
  const { addConnectionHandler, removeConnectionHandler } = useEditorContext();
  const { setDeferredConnectionState } = useDeferredConnectionState({ inputs, outputs });
  const updateNodeInternals = useUpdateNodeInternals();
  const getSourceHandle = useGetSourceHandle();
  const getTargetHandle = useGetTargetHandle();
  const updateNodeData = useUpdateNodeData();

  const addOutputHandle = useMemo(
    () => createCustomHandle(nodeId, 'output', canDataManipulatorAddOutputConnect),
    [nodeId],
  );
  const addInputHandle = useMemo(
    () => createCustomHandle(nodeId, 'input', canDataManipulatorAddInputConnect),
    [nodeId],
  );

  const updateNodeOnInputConnect = useCallback(
    (handle: DataHandle) => {
      updateNodeData<PathwayNode['data']>(nodeId, (prev) => {
        const inputs = [...prev.inputs];
        const dataInput = inputs.find((inp) => inp.name === 'data');
        if (dataInput) {
          dataInput.dataSchema = handle.schema;
        } else {
          inputs.push(createDataInput(handle.schema));
        }

        return {
          ...prev,
          inputs,
        };
      });

      updateNodeInternals(nodeId);
    },
    [nodeId, updateNodeData, updateNodeInternals],
  );

  const updateNodeOnOutputConnect = useCallback(
    (handle: DataHandle) => {
      updateNodeData<PathwayNode['data']>(nodeId, (prev) => {
        const inputs = prev.inputs.filter((input) => input.name !== 'outputSchema');
        const outputs = [...prev.outputs];
        const dataOutput = outputs.find((output) => output.name === 'data');

        if (dataOutput) {
          dataOutput.dataSchema = handle.schema;

          const dynamicSchemaInput = createDynamicSchemaInput(dataOutput.dataSchema);
          inputs.push(dynamicSchemaInput);
        } else {
          const newDataOutput = createDataOutput(handle.schema, 'Queried Data');
          const dynamicSchemaInput = createDynamicSchemaInput(newDataOutput.dataSchema);

          outputs.push(newDataOutput);
          inputs.push(dynamicSchemaInput);
        }

        return {
          ...prev,
          outputs,
          inputs,
        };
      });

      updateNodeInternals(nodeId);
    },
    [nodeId, updateNodeData, updateNodeInternals],
  );

  useEffect(() => {
    const connectionHandlerKey: HandleKey = {
      id: addInputHandle.id,
      nodeId,
    };
    addConnectionHandler?.(connectionHandlerKey, (conn, actionCallback) => {
      if (!isHandleConnection(conn)) return null;

      const sourceHandle = getSourceHandle(conn);
      const inputName = 'data';
      const inputId = createHandleId('input', inputName);

      updateNodeOnInputConnect(sourceHandle);

      setDeferredConnectionState({
        action: actionCallback,
        connection: {
          ...conn,
          targetHandle: inputId,
        },
        inputName,
      });

      return 'defer';
    });
    return () => {
      removeConnectionHandler?.(connectionHandlerKey);
    };
  }, [
    addConnectionHandler,
    addInputHandle.id,
    getSourceHandle,
    nodeId,
    removeConnectionHandler,
    setDeferredConnectionState,
    updateNodeOnInputConnect,
  ]);

  useEffect(() => {
    const connectionHandlerKey: HandleKey = {
      id: addOutputHandle.id,
      nodeId,
    };
    addConnectionHandler?.(connectionHandlerKey, (conn, actionCallback) => {
      if (!isHandleConnection(conn)) return null;

      const targetHandle = getTargetHandle(conn);
      const outputName = 'data';
      const outputId = createHandleId('output', outputName);

      updateNodeOnOutputConnect(targetHandle);

      setDeferredConnectionState({
        action: actionCallback,
        connection: {
          ...conn,
          sourceHandle: outputId,
        },
        outputName,
      });

      return 'defer';
    });

    return () => {
      removeConnectionHandler?.(connectionHandlerKey);
    };
  }, [
    addConnectionHandler,
    addOutputHandle.id,
    getTargetHandle,
    nodeId,
    removeConnectionHandler,
    setDeferredConnectionState,
    updateNodeOnOutputConnect,
  ]);

  return { addOutputHandle, addInputHandle };
};
