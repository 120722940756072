import { Node, XYPosition } from 'reactflow';
import { FlowNode, FlowNodeData } from 'types/reactflow';
import { NodeManifest, NodeType, isSubpipelineManifest } from './Flow.types';
import { formatNodeData, formatSubpipelineNodeData } from 'utils/neurons';
import { createNodeId } from './Flow.utils';
import { createNewBatchNodes } from './nodes/Batch/Batch.utils';
import { isPathwayNode, PathwayNode } from './nodes/Node/Node.types';
import { formatFunctionData } from './nodes/Function/Function.utils';

const createBatchNodes = (position: XYPosition) => {
  const batchId = createNodeId();
  const batchNodes = createNewBatchNodes({ batchId, position });
  return batchNodes;
};

// Neuron nodes
const getNodeTitle = (existingNodes: Node<FlowNodeData>[], manifest: NodeManifest) => {
  const nodes = existingNodes.filter(isPathwayNode);
  const counter = nodes.filter((n) => n.data.metadata.name === manifest.name).length;

  return counter < 1 ? manifest.name : `${manifest.name} ${counter + 1}`;
};

const createNewNode = (
  existingNodes: Node<FlowNodeData>[],
  manifest: PathwayNode & NodeManifest,
  position: XYPosition,
): PathwayNode => {
  const customTitle = getNodeTitle(existingNodes, manifest);

  return {
    id: createNodeId(),
    type: manifest.type,
    position,
    data: formatNodeData({ ...manifest, key: manifest.key ?? '' }, customTitle),
    selected: true,
  };
};

const createFunctionNode = (
  existingNodes: Node<FlowNodeData>[],
  manifest: PathwayNode & NodeManifest,
  position: XYPosition,
): PathwayNode => {
  const customTitle = getNodeTitle(existingNodes, manifest);

  return {
    id: createNodeId(),
    type: manifest.type,
    position,
    data: formatFunctionData({ ...manifest, key: manifest.key ?? '' }, customTitle),
    selected: true,
  };
};

const createSubpipelineNode = (
  existingNodes: Node<FlowNodeData>[],
  manifest: PathwayNode & NodeManifest,
  position: XYPosition,
) => {
  if (!isSubpipelineManifest(manifest)) {
    throw new Error('Not a subpipeline manifest');
  }
  const customTitle = getNodeTitle(existingNodes, manifest);

  return {
    id: createNodeId(),
    type: manifest.type,
    position,
    data: formatSubpipelineNodeData(manifest, customTitle),
    selected: true,
  };
};

export const mapNodeData = (
  existingNodes: Node<FlowNodeData>[],
  manifest: NodeManifest,
  position: XYPosition,
) => {
  const newNodes: FlowNode[] = [];

  if (manifest.type === NodeType.BATCH_GROUP) {
    newNodes.push(...createBatchNodes(position));
  }

  if (isPathwayNode(manifest)) {
    switch (manifest.type) {
      case NodeType.NEURON:
      case NodeType.CHECKPOINT:
        newNodes.push(createNewNode(existingNodes, manifest, position));
        break;
      case NodeType.FUNCTION:
        newNodes.push(createFunctionNode(existingNodes, manifest, position));
        break;
      case NodeType.SUBPIPELINE:
        newNodes.push(createSubpipelineNode(existingNodes, manifest, position));
        break;
      default:
        throw new Error('Unknown manifest type');
    }
  }

  return newNodes;
};
