import { DataMenuItem, getSchemaByDataType } from 'utils/dataSchema';
import { DataType } from '../Node/DataType';
import { ArrayDataSchema } from '@pathways/pipeline-schema';

export const DATA_MENU_ITEMS: DataMenuItem[] = [
  { schema: { type: 'integer' }, title: 'Integer' },
  { schema: { type: 'number' }, title: 'Number' },
  { schema: { type: 'text' }, title: 'Text' },
  { schema: { type: 'boolean' }, title: 'Boolean' },
  { schema: { type: 'object' }, title: 'Object' },
  {
    schema: {
      type: 'array',
      items: {
        type: null,
      },
    },
    title: 'Array',
  },
];

export const ARRAY_TYPE_MENU_ITEMS: DataMenuItem[] = [
  { schema: { type: 'integer' }, title: 'Integer' },
  { schema: { type: 'number' }, title: 'Number' },
  { schema: { type: 'text' }, title: 'Text' },
  { schema: { type: 'object' }, title: 'Object' },
];

export const OBJECT_DATA_MENU_ITEMS: DataMenuItem[] = [
  { schema: { type: 'object' }, title: 'Object' },
  {
    schema: {
      type: 'array',
      items: {
        type: 'object',
      },
    },
    title: 'Array of objects',
  },
];

export const VALID_DATA_TYPES: DataType[] = DATA_MENU_ITEMS.map(({ schema }) => schema.type);

export const getArraySchemaWithDataType = (itemsType: DataType) => {
  const schema = getSchemaByDataType('array', DATA_MENU_ITEMS) as ArrayDataSchema;
  schema.items = getSchemaByDataType(itemsType, DATA_MENU_ITEMS);

  return schema;
};
