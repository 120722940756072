import { DataSchema } from '@pathways/pipeline-schema/web';
import { getDataTypeOptionKey } from './DataTypeField.utils';

export interface DataTypeOption {
  schema: DataSchema;
  title: string;
  value: string;
}

export const DATA_TYPE_OPTIONS: DataTypeOption[] = [
  {
    schema: { type: 'integer' },
    title: 'Integer',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: { type: 'number' },
    title: 'Number',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: { type: 'text' },
    title: 'Text',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: { type: 'boolean' },
    title: 'Boolean',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: { type: 'object' },
    title: 'Object',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: { type: 'asset', mediaType: [] },
    title: 'Asset',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: { type: 'enum', values: [] },
    title: 'Enum',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: {
      type: 'array',
      items: {
        type: null,
      },
    },
    title: 'Array',
    value: 'array',
  },
];

export const ARRAY_DATA_TYPE_OPTIONS: DataTypeOption[] = [
  {
    schema: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    title: 'Integer',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'number',
      },
    },
    title: 'Number',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'text',
      },
    },
    title: 'Text',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'object',
      },
    },
    title: 'Object',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'image-uri',
      },
    },
    title: 'Image URI',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'asset',
        mediaType: [],
      },
    },
    title: 'Asset',
    get value() {
      return getDataTypeOptionKey(this.schema);
    },
  },
];
