import { NodeType } from 'features/Flow/Flow.types';
import { canNodesBeAddedToBatch, canNodesBeGrouped } from 'features/Flow/Flow.utils';
import { useAddNodesToGroup } from 'features/Flow/hooks/useAddNodesToGroup';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import useFlow from 'features/Flow/hooks/useFlow';
import useRemoveNodesFromGroup from 'features/Flow/hooks/useRemoveNodeFromGroup';
import { isBatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';
import { useAddNodeToBatchGroup } from 'features/Flow/nodes/Batch/Batch.utils';
import { isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import { SHORTCUT_CONFIG } from 'config/shortcut';
import { useCallback, useEffect, useState } from 'react';
import { NodeDragHandler, SelectionDragHandler, useKeyPress } from 'reactflow';
import { FlowNode } from 'types/reactflow';

export const GROUP_DRAG_HOVER_CLASSNAME = 'group-add-hover';
export const NODE_ADD_GROUP_SHADOW_CLASSNAME = 'node-add-group-shadow';

const NON_DRAGGABLE_TYPES = [
  NodeType.GROUP,
  NodeType.BATCH_GROUP,
  NodeType.BATCH_END,
  NodeType.BATCH_START,
];

const DROPPABLE_TYPES = [NodeType.GROUP, NodeType.BATCH_GROUP];
const DEFAULT_CARD_WIDTH = 250;
const DEFAULT_CARD_HEIGHT = 150;

const useGroupDragEvents = () => {
  const { addNodes, getIntersectingNodes, getNode, getNodes, getEdges, screenToFlowPosition } =
    useFlow();
  const addNodesToBatchGroup = useAddNodeToBatchGroup();
  const addNodesToGroup = useAddNodesToGroup();
  const removeNodeFromGroup = useRemoveNodesFromGroup();
  const { newNodeType } = useEditorContext();
  const ungroupPressed = useKeyPress(SHORTCUT_CONFIG.ungroupDrag.keyCode, {
    target: document.body,
  });

  const [sourceNodeIds, setSourceNodeIds] = useState<string[]>([]);
  const [targetNodeId, setTargetNodeId] = useState<string | null>(null);

  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      const position = screenToFlowPosition({ x: e.clientX, y: e.clientY });

      const cardElement: HTMLDivElement | null = document.querySelector(
        `[data-element-card-type="${newNodeType}"]`,
      );

      const intersectingGroup = getIntersectingNodes({
        ...position,
        width: cardElement?.offsetWidth ?? DEFAULT_CARD_WIDTH,
        height: cardElement?.offsetHeight ?? DEFAULT_CARD_HEIGHT,
      }).find((node) => DROPPABLE_TYPES.includes(node.type as NodeType));

      setTargetNodeId(null);

      if (intersectingGroup && newNodeType) {
        const allNodes = getNodes();
        const edges = getEdges();
        const targetNode = getNode(intersectingGroup.id);
        // Dummy node to check for type restrictions when dropping a new node from sidebar directly into a batch/group
        const nodesToAdd = [{ id: '', type: newNodeType }];

        const canBeGrouped = isGroupNode(targetNode) && canNodesBeGrouped(nodesToAdd, allNodes);
        const canBeAddedToBatch =
          isBatchGroupNode(targetNode) &&
          canNodesBeAddedToBatch({ batchGroup: targetNode, selectedNodes: nodesToAdd, edges });

        if (canBeGrouped || canBeAddedToBatch) {
          setTargetNodeId(intersectingGroup.id);
        }
      }
    },
    [getIntersectingNodes, screenToFlowPosition, getEdges, getNode, getNodes, newNodeType],
  );

  const onDrop = useCallback(
    (newNodes: FlowNode[]) => {
      const allNodes = getNodes();
      const nextNodes = [...allNodes.slice(0, -1), ...newNodes, ...allNodes.slice(-1)];

      setTargetNodeId(null);
      if (targetNodeId) {
        const targetGroup = getNode(targetNodeId);
        const canAddToGroup = isGroupNode(targetGroup) && canNodesBeGrouped(newNodes, allNodes);
        const canAddToBatch =
          isBatchGroupNode(targetGroup) &&
          canNodesBeAddedToBatch({
            batchGroup: targetGroup,
            selectedNodes: newNodes,
            edges: getEdges(),
          });

        if (canAddToBatch) {
          addNodesToBatchGroup([...newNodes, targetGroup], nextNodes);

          return true;
        }

        if (canAddToGroup) {
          addNodes(newNodes);
          addNodesToGroup(newNodes, targetGroup);

          return true;
        }
      }

      return false;
    },
    [getNodes, targetNodeId, getNode, getEdges, addNodesToBatchGroup, addNodes, addNodesToGroup],
  );

  const onDragStop = useCallback(() => {
    if (!sourceNodeIds.length) {
      return;
    }
    setSourceNodeIds([]);

    const sourceNodes = sourceNodeIds.map(getNode) as FlowNode[];
    const nodesBelongToGroup = sourceNodes.every((node) => !!node.parentNode);

    if (ungroupPressed && nodesBelongToGroup) {
      removeNodeFromGroup(sourceNodes);
    }

    if (!targetNodeId) {
      return;
    }
    setTargetNodeId(null);

    const targetGroup = getNode(targetNodeId);

    if (
      sourceNodes.length &&
      (isGroupNode(targetGroup) || isBatchGroupNode(targetGroup)) &&
      sourceNodes.every((node) => node.parentNode !== targetGroup.id)
    ) {
      const targetNodeIsBatch = isBatchGroupNode(targetGroup);

      if (targetNodeIsBatch) {
        addNodesToBatchGroup([...sourceNodes, targetGroup]);
      } else {
        addNodesToGroup(sourceNodes, targetGroup);
      }
    }
  }, [
    getNode,
    addNodesToBatchGroup,
    sourceNodeIds,
    targetNodeId,
    addNodesToGroup,
    removeNodeFromGroup,
    ungroupPressed,
  ]);

  const onDrag = useCallback(
    (draggedNodes: FlowNode[]) => {
      const nodesAreValid = draggedNodes.every(
        (node) => !NON_DRAGGABLE_TYPES.includes(node.type as NodeType),
      );
      const nodesBelongToGroup = draggedNodes.every((node) => !!node.parentNode);

      if (!nodesAreValid) {
        return;
      }

      if (nodesBelongToGroup) {
        setSourceNodeIds(draggedNodes.map((node) => node.id));
        return;
      }

      const intersectingGroup = draggedNodes
        .flatMap((node) => getIntersectingNodes(node))
        .find((node) => DROPPABLE_TYPES.includes(node.type as NodeType));

      if (
        intersectingGroup?.id &&
        draggedNodes.some((node) => node.parentNode === intersectingGroup.id)
      ) {
        return;
      }

      if (intersectingGroup?.id) {
        const groupNode = getNode(intersectingGroup.id);
        const allNodes = getNodes();
        const edges = getEdges();
        const canGroup = isGroupNode(groupNode) && canNodesBeGrouped(draggedNodes, allNodes);
        const canAddToBatch =
          isBatchGroupNode(groupNode) &&
          canNodesBeAddedToBatch({ batchGroup: groupNode, selectedNodes: draggedNodes, edges });

        if (canGroup || canAddToBatch) {
          setSourceNodeIds(draggedNodes.map((node) => node.id));
          setTargetNodeId(intersectingGroup.id);
        }
      } else {
        setTargetNodeId(null);
      }
    },
    [getEdges, getIntersectingNodes, getNode, getNodes],
  );

  const onSelectionDrag: SelectionDragHandler = useCallback(
    (_, draggedNodes: FlowNode[]) => {
      onDrag(draggedNodes);
    },
    [onDrag],
  );

  const onNodeDrag: NodeDragHandler = useCallback(
    (_, _draggedNode: FlowNode, draggedNodes: FlowNode[]) => {
      onDrag(draggedNodes);
    },
    [onDrag],
  );

  useEffect(() => {
    removeDragHoverClassnames();

    if (targetNodeId) {
      document
        .querySelector(`[data-group-id="${targetNodeId}"]`)
        ?.classList.add(GROUP_DRAG_HOVER_CLASSNAME);

      if (sourceNodeIds.length) {
        sourceNodeIds.forEach((nodeId) => {
          document
            .querySelector(`[data-node-id="${nodeId}"]`)
            ?.classList.add(NODE_ADD_GROUP_SHADOW_CLASSNAME);
        });
      }
    }
  }, [sourceNodeIds, targetNodeId, ungroupPressed]);

  return {
    onNodeDrag,
    onNodeDragStop: onDragStop,
    onDragOver,
    onDrop,
    onSelectionDrag,
    onSelectionDragStop: onDragStop,
  };
};

export function removeDragHoverClassnames() {
  document.querySelectorAll(`.${GROUP_DRAG_HOVER_CLASSNAME}`).forEach((groupElement) => {
    groupElement.classList.remove(GROUP_DRAG_HOVER_CLASSNAME);
  });

  document.querySelectorAll(`.${NODE_ADD_GROUP_SHADOW_CLASSNAME}`).forEach((nodeElement) => {
    nodeElement.classList.remove(NODE_ADD_GROUP_SHADOW_CLASSNAME);
  });
}

export default useGroupDragEvents;
