import {
  ConnectionLineComponentProps,
  Position,
  ReactFlowState,
  getBezierPath,
  useStore,
} from 'reactflow';
import { DEFAULT_CONNECTION_PROPS } from 'features/Flow/Flow.consts';
import { getColorDataFromHandle } from 'utils/styles';

const selector = ({ connectionStatus }: ReactFlowState) => ({
  connectionStatus,
});

export default function NodeConnectionLine(props: ConnectionLineComponentProps) {
  const { fromX, fromY, fromPosition, toX, toY, toPosition, fromNode, fromHandle } = props;
  const { connectionStatus } = useStore(selector);
  const className = connectionStatus === 'invalid' ? 'animated' : undefined;
  const isFromOutput = fromPosition === Position.Right;

  const [edgePath] = getBezierPath({
    sourceX: isFromOutput ? fromX + 15 : fromX - 25,
    sourceY: fromY,
    sourcePosition: fromPosition,
    targetX: isFromOutput ? toX - 25 : toX + 15,
    targetY: toY,
    targetPosition: toPosition,
  });

  const markerEndId = `marker-end:connection-line`;
  const markerProp = {
    [isFromOutput ? 'markerEnd' : 'markerStart']: `url(#${markerEndId})`,
  };

  const colorData = getColorDataFromHandle({
    nodeId: fromNode?.id,
    type: isFromOutput ? 'source' : 'target',
    handleId: fromHandle?.id,
  });
  const color = colorData?.color;

  return (
    <g className={className}>
      <path
        d={edgePath}
        fill="none"
        stroke={color}
        strokeWidth={DEFAULT_CONNECTION_PROPS.strokeWidth}
        {...markerProp}
      />
      <defs>
        <marker
          id={markerEndId}
          viewBox="0 0 10 10"
          refX="1"
          refY="5"
          markerWidth="5"
          markerHeight="5"
          orient="auto-start-reverse"
          markerUnits="strokeWidth"
        >
          <path d={DEFAULT_CONNECTION_PROPS.arrowPath} fill={color} />
        </marker>
      </defs>
    </g>
  );
}
