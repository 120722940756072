import axios from 'axios';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';
import { DeletePipelineResponse, DeletePipelineConfig } from './useDeletePipeline.types';
import { DELETE_PIPELINE_KEY } from './useDeletePipeline.consts';

const deletePipelineFetcher: MutationFetcher<
  DeletePipelineResponse,
  typeof DELETE_PIPELINE_KEY,
  string
> = (_key, { arg: pipelineId }) => axios.delete(`/pipelines/${pipelineId}`);

const useDeletePipeline = (config?: DeletePipelineConfig) => {
  const {
    data: response,
    error,
    isMutating,
    trigger,
    reset,
  } = useSWRMutation(DELETE_PIPELINE_KEY, deletePipelineFetcher, config);

  const deletePipeline = (pipelineId: string, config?: DeletePipelineConfig) =>
    trigger(pipelineId, config);

  return {
    response,
    deletePipelineError: error,
    isDeletingPipeline: isMutating,
    deletePipeline,
    reset,
  };
};

export default useDeletePipeline;
