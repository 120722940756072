import CircleIcon from '@mui/icons-material/Circle';
import { Grid, MenuItem, Paper, Stack, TextField } from '@mui/material';
import { Color } from './ColorField.consts';
import { ColorFieldProps } from './ColorField.types';
import { ColorIconButton } from './ColorIconButton';

export default function ColorField(props: ColorFieldProps) {
  const { value, onColorClick, ...textFieldProps } = props;

  return (
    <TextField
      {...textFieldProps}
      value={value}
      select
      SelectProps={{
        renderValue: () => (
          <Stack direction="row" gap={1}>
            <CircleIcon htmlColor={value} />
            {value.toUpperCase()}
          </Stack>
        ),
        MenuProps: {
          slotProps: {
            paper: {
              sx: {
                background: 'transparent',
                '.MuiMenu-list': {
                  padding: 0,
                },
              },
            },
          },
        },
      }}
    >
      <MenuItem
        disableRipple
        value={value}
        sx={{
          background: 'none !important',
          padding: 0,
        }}
      >
        <Paper
          elevation={1}
          sx={{
            padding: 1,
            width: 'min-content',
          }}
        >
          <Grid display="grid" gridTemplateColumns="repeat(4, 1fr)" textAlign="center">
            {Object.values(Color).map((color) => (
              <Grid key={color} item>
                <ColorIconButton value={value} color={color} onColorClick={onColorClick} />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </MenuItem>
    </TextField>
  );
}
