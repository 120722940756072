import { useRequest } from 'api/client';
import { ManifestResponse } from './useManifests.types';

const useManifests = () => {
  const request = useRequest<ManifestResponse[]>({
    url: `/manifests`,
  });

  return {
    areManifestsLoading: request.isLoading,
    manifests: request.data ?? [],
    manifestsError: request.error,
    mutateManifests: request.mutate,
  };
};

export default useManifests;
