import { Checkbox, TableCell } from '@mui/material';
import { getNotSupportedValueProps } from 'components/DynamicTable/DynamicTable.utils';
import { InlineTextField } from 'components/DynamicTable/InlineTextField';
import { ColumnConfig } from 'components/Table/TableHead';
import { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { getValueOrDefault } from 'utils/string';

interface DynamicTableCellProps {
  value: unknown;
  column: ColumnConfig;
  columnIndex: number;
  isEditMode: boolean;
  autoFocus: boolean;
  readOnly?: boolean;
  onClick?: (columnIndex: number) => void;
  onChange?: (columnId: string, value: unknown) => void;
  onKeyDown?: (columnIndex: number, key: string) => void;
}

const DynamicTableCell = ({
  value,
  column,
  columnIndex,
  isEditMode,
  readOnly,
  onClick,
  onChange,
  onKeyDown,
}: DynamicTableCellProps) => {
  const tableCellRef = useRef<HTMLTableCellElement>(null);
  const [minHeight, setMinHeight] = useState(0);
  const notSupportedValueProps = getNotSupportedValueProps(column.valueType ?? '');

  const handleChange = useCallback(
    (value: unknown) => {
      onChange?.(column.id, value);
    },
    [onChange, column.id],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onKeyDown?.(columnIndex, e.key);
    },
    [onKeyDown, columnIndex],
  );

  const handleOnClick = useCallback(() => {
    onClick?.(columnIndex);
  }, [onClick, columnIndex]);

  useEffect(() => {
    if (tableCellRef.current) {
      setMinHeight(tableCellRef.current.offsetHeight);
    }
  }, []);

  return (
    <TableCell
      ref={tableCellRef}
      sx={{ maxWidth: column.maxWidth ?? undefined, minHeight, height: minHeight }}
      onClick={isEditMode ? undefined : handleOnClick}
    >
      {column.valueType === 'boolean' ? (
        <Checkbox
          size="small"
          name={column.id}
          checked={!!value}
          readOnly={readOnly}
          onChange={(e) => {
            const checked = e.target.checked;
            onChange?.(column.id, checked);
          }}
        />
      ) : (
        <InlineTextField
          {...notSupportedValueProps}
          InputProps={{ readOnly: readOnly ?? !isEditMode }}
          size="small"
          fullWidth
          name={column.id}
          value={isEditMode ? value : getValueOrDefault(value)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      )}
    </TableCell>
  );
};

export default DynamicTableCell;
