import DashboardPage from 'pages/DashboardPage/DashboardPage';
import ExecutionsPage from 'pages/ExecutionsPage/ExecutionsPage';
import PipelinePage from 'pages/PipelinePage/PipelinePage';
import PlatformLandingRoute from 'pages/PlatformLandingRoute/PlatformLandingRoute';
import RuntimePage from 'pages/RuntimePage/RuntimePage';
import { Navigate, RouteObject, RouterProvider } from 'react-router-dom';
import { useAppRoutes } from 'utils/routes';
import AuthenticatedRoute from './pages/AuthenticationRoute';
import { routerFactory } from './router';
import PipelineVersionsPage from 'pages/PipelineVersionsPage/PipelineVersionsPage';
import ReadOnlyPipelinePage from 'pages/ReadOnlyPipelinePage/ReadOnlyPipelinePage';
import { executionRoutes } from 'pages/ExecutionsPage/routes';
import ErrorPage from 'pages/ErrorPage/ErrorPage';

const DashboardRedirect = () => {
  const routes = useAppRoutes();

  return <Navigate to={routes.dashboard()} replace />;
};

const childrenRoutes: RouteObject[] = [
  {
    index: true,
    Component: PlatformLandingRoute,
  },
  {
    path: 'dashboard',
    Component: DashboardPage,
  },
  {
    path: 'pipelines',
    children: [
      {
        path: ':pipelineId',
        children: [
          {
            index: true,
            Component: PipelinePage,
          },
          {
            path: 'executions',
            Component: ExecutionsPage,
            children: executionRoutes(),
          },
          {
            path: 'versions',
            Component: PipelineVersionsPage,
          },
          {
            path: 'runtime',
            Component: RuntimePage,
            children: [
              {
                path: ':pipelineExecutionId',
                Component: RuntimePage,
              },
            ],
          },
          {
            path: ':versionId',
            Component: ReadOnlyPipelinePage,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    Component: DashboardRedirect,
  },
];

const createRouter = routerFactory();

const router = createRouter([
  {
    index: true,
    Component: PlatformLandingRoute,
    errorElement: <ErrorPage />,
  },
  {
    path: '/workspaces/:workspaceSlug/projects/:projectId',
    Component: AuthenticatedRoute,
    children: childrenRoutes,
    errorElement: <ErrorPage />,
  },
  {
    path: '/workspaces/:workspaceSlug',
    Component: AuthenticatedRoute,
    children: childrenRoutes,
    errorElement: <ErrorPage />,
  },
  {
    path: '*',
    Component: PlatformLandingRoute,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
