import { Box, Stack, Tab, Tabs } from '@mui/material';
import Loader from 'components/Loader/Loader';
import { SectionDivider } from 'components/Sidebar/Sidebar.styles';
import StatusChip from 'components/StatusChip/StatusChip';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import Timer from 'components/Timer/Timer';
import { useMemo } from 'react';
import { useStore } from 'reactflow';
import { FlowNode, FlowState } from 'types/reactflow';
import { getPipelineExecutionOrDefault, isPipelineExecutionFinished } from 'utils/execution';
import { getJobTitle, isJobNode } from 'utils/neurons';
import ExecutionSummary from './components/ExecutionSummary/ExecutionSummary';
import InputTabPanel from './components/InputTabPanel/InputTabPanel';
import JobDescriptionTooltip from 'features/Flow/components/JobDescriptionTooltip/JobDescriptionTooltip';
import OutputTabPanel from './components/OutputTabPanel/OutputTabPanel';
import ReviewTabPanel from './components/ReviewTabPanel/ReviewTabPanel';
import RunInProgressPanel from './components/RunInProgressPanel/RunInProgressPanel';
import usePanelTabs from './hooks/usePanelTabs';
import { PanelTabKeys } from './hooks/usePanelTabs.types';
import * as Styled from './RightSidebar.styles';
import { useAppRoutes } from 'utils/routes';
import { isSubpipelineJobResponse } from 'api/services/usePipelineJobs/usePipelineJobs.types';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';

const selector = ({ pipelineExecution, executionJobs, getNodes }: FlowState) => ({
  pipelineExecution,
  executionJobs,
  getNodes,
});

export default function RightSidebar() {
  const state = useStore(selector);
  const routes = useAppRoutes();

  const pipelineExecutionLoading = !state.pipelineExecution?.id;
  const pipelineExecution = getPipelineExecutionOrDefault(state.pipelineExecution);
  const pipelineExecutionFinished = isPipelineExecutionFinished(pipelineExecution.status);
  const pipelineExecutionInProgress = !pipelineExecutionLoading && !pipelineExecutionFinished;
  const executionPageRoute = routes.executions.view(
    pipelineExecution.pipelineId,
    pipelineExecution.id,
  );

  const nodes = state.getNodes();
  const selectedNodes: FlowNode[] = nodes.filter((node) => node.selected);
  const selectedNode = selectedNodes.at(0);

  const executionJob = useMemo(
    () => state.executionJobs?.find((job) => job.id === selectedNode?.id),
    [selectedNode?.id, state.executionJobs],
  );

  const { selectedTab, setSelectedTab, isTabSelected, isTabDisabled, isTabVisible } =
    usePanelTabs(executionJob);

  if (!selectedNode) {
    return (
      <Styled.Container>
        {pipelineExecutionLoading && (
          <Box padding={2}>
            <Loader loading height={40} width="100%" />
          </Box>
        )}
        {pipelineExecutionInProgress && <RunInProgressPanel />}
        {pipelineExecutionFinished && <ExecutionSummary execution={pipelineExecution} />}
      </Styled.Container>
    );
  }

  return (
    <Styled.Container $borderLeft>
      <Box padding={2}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center" minWidth={0}>
            <TextEllipsis variant="bodyLarge">
              {getJobTitle({
                ...selectedNode.data.metadata,
                id: executionJob?.id,
              })}
            </TextEllipsis>
            {isJobNode(selectedNode) && (
              <JobDescriptionTooltip
                job={{
                  ...selectedNode.data.metadata,
                  ...executionJob,
                }}
              />
            )}
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <Timer
              status={pipelineExecution.status}
              startedAt={pipelineExecution.createdAt}
              completedAt={pipelineExecution.completedAt}
            />
            <StatusChip status={pipelineExecution.status} />
            {isSubpipelineJobResponse(executionJob) && (
              <OpenInNewTab
                href={routes.executions.view(executionJob.pipelineId, executionJob.executionId)}
                tooltip="Open sub-pipeline"
              />
            )}
          </Stack>
        </Stack>

        <SectionDivider
          $addMargin={false}
          sx={{
            marginY: 2,
          }}
        />

        <Stack spacing={2}>
          <Tabs
            value={selectedTab}
            onChange={(_event, value: PanelTabKeys) => {
              setSelectedTab(value);
            }}
          >
            <Tab
              label="Input"
              value={PanelTabKeys.INPUT}
              disabled={isTabDisabled(PanelTabKeys.INPUT)}
            />
            <Tab
              label="Review"
              value={PanelTabKeys.REVIEW}
              disabled={isTabDisabled(PanelTabKeys.REVIEW)}
              sx={{
                ...(isTabVisible(PanelTabKeys.REVIEW)
                  ? undefined
                  : {
                      opacity: 0,
                      position: 'fixed',
                    }),
              }}
            />
            <Tab
              label="Output"
              value={PanelTabKeys.OUTPUT}
              disabled={isTabDisabled(PanelTabKeys.OUTPUT)}
            />
          </Tabs>
        </Stack>
      </Box>

      <SectionDivider $addMargin={false} />

      {isTabSelected(PanelTabKeys.INPUT) && (
        <InputTabPanel
          key={executionJob?.id}
          status={executionJob?.status}
          value={executionJob?.inputs}
        />
      )}
      {isTabSelected(PanelTabKeys.REVIEW) && (
        <ReviewTabPanel key={executionJob?.id} executionRoute={executionPageRoute} />
      )}
      {isTabSelected(PanelTabKeys.OUTPUT) && (
        <OutputTabPanel key={executionJob?.id} executionJob={executionJob} />
      )}
    </Styled.Container>
  );
}
