import { useCallback, useMemo } from 'react';
import { useNodes } from 'reactflow';
import { FlowNode } from 'types/reactflow';
import { useUpdateNode } from './useUpdateNode';

export function useNodeError(nodeId: string) {
  const updateNode = useUpdateNode();
  const nodes: FlowNode[] = useNodes();

  const nodeError = useMemo(() => {
    const node = nodes.find((node) => node.id === nodeId);
    return node?.error;
  }, [nodeId, nodes]);

  const setNodeError = useCallback(
    (error?: string) => {
      updateNode(
        {
          nodeId,
        },
        () => ({
          error,
        }),
      );
    },
    [nodeId, updateNode],
  );

  return {
    nodeError,
    setNodeError,
  };
}
