import { useParams } from 'react-router-dom';
import { formatUrlMissingParameterMessage } from 'utils/message';

export default function usePipelineExecutionId(): string {
  const { pipelineExecutionId } = useParams();

  if (!pipelineExecutionId) {
    throw new Error(formatUrlMissingParameterMessage('pipelineExecutionId'));
  }

  return pipelineExecutionId;
}
