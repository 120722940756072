import NodeEdge from 'features/Flow/nodes/Node/components/NodeEdge';
import Neuron from 'features/Flow/nodes/Neuron/Neuron';
import Checkpoint from 'features/Flow/nodes/Checkpoint/Checkpoint';
import PipelineComplete from 'features/Flow/nodes/PipelineComplete/PipelineComplete';
import { PIPELINE_COMPLETE_NODE_ID } from 'features/Flow/nodes/PipelineComplete/PipelineComplete.consts';
import PipelineStart from 'features/Flow/nodes/PipelineStart/PipelineStart';
import { PIPELINE_START_NODE_ID } from 'features/Flow/nodes/PipelineStart/PipelineStart.consts';
import {
  ConnectionLineType,
  DefaultEdgeOptions,
  EdgeTypes,
  FitViewOptions,
  NodeTypes,
  ProOptions,
} from 'reactflow';
import { FlowState, ReactFlowProp } from 'types/reactflow';
import { EdgeType, NodeType } from './Flow.types';
import Group from './nodes/Group/Group';
import { PipelineCompleteNode } from './nodes/PipelineComplete/PipelineComplete.types';
import { PipelineStartNode } from './nodes/PipelineStart/PipelineStart.types';
import Function from 'features/Flow/nodes/Function/Function';
import BatchGroup from './nodes/Batch/BatchGroup';
import BatchStart from 'features/Flow/nodes/Batch/BatchStart';
import BatchEnd from 'features/Flow/nodes/Batch/BatchEnd';
import Node from 'features/Flow/nodes/Node/Node';

export enum ConnectionColorReference {
  DEFAULT = 'default',
  SOURCE = 'source',
  TARGET = 'target',
}

export const APP_REACTFLOW_DATATRANSFER = 'application/reactflow';
export const CONNECTION_COLOR_DATA_KEY = 'data-color';
export const DEFAULT_CONNECTION_PROPS = {
  arrowPath: 'M 0 0 L 10 5 L 0 10 z',
  color: '#ffffff',
  connectionRadius: 32,
  pathBorderRadius: 10,
  reference: ConnectionColorReference.DEFAULT,
  strokeWidth: 2,
  type: ConnectionLineType.SmoothStep,
  zIndex: 1001,
};
/** Add React Flow class name to apply styles that allow selecting connections inside a group node. */
export const RF_GROUP_NODE_CLASS_NAME = 'react-flow__node-group';

export const storeSelector = ({ pipelineStartOutputs, pipelineCompleteInputs }: FlowState) => ({
  pipelineStartOutputs,
  pipelineCompleteInputs,
});

export const nodeTypes: NodeTypes = {
  [NodeType.PIPELINE_START]: PipelineStart,
  [NodeType.PIPELINE_COMPLETE]: PipelineComplete,
  [NodeType.NEURON]: Neuron,
  [NodeType.FUNCTION]: Function,
  [NodeType.CHECKPOINT]: Checkpoint,
  [NodeType.GROUP]: Group,
  [NodeType.BATCH_GROUP]: BatchGroup,
  [NodeType.BATCH_START]: BatchStart,
  [NodeType.BATCH_END]: BatchEnd,
  [NodeType.SUBPIPELINE]: Node,
};

export const edgeTypes: EdgeTypes = {
  [EdgeType.CUSTOM]: NodeEdge,
};

export const startNode = {
  id: PIPELINE_START_NODE_ID,
  type: NodeType.PIPELINE_START,
  deletable: false,
  data: {
    metadata: {
      type: NodeType.PIPELINE_START,
    },
    outputs: [],
  },
  position: {
    x: 50,
    y: 50,
  },
} satisfies PipelineStartNode;

export const completeNode = {
  id: PIPELINE_COMPLETE_NODE_ID,
  type: NodeType.PIPELINE_COMPLETE,
  deletable: false,
  data: {
    metadata: {
      type: NodeType.PIPELINE_COMPLETE,
    },
    inputs: [],
  },
  position: {
    x: 550,
    y: 50,
  },
} satisfies PipelineCompleteNode;

export const defaultEdgeOptions: DefaultEdgeOptions = {
  type: 'custom',
};

export const fitViewOptions: FitViewOptions = {
  maxZoom: 1,
  minZoom: 0.1,
};

export const proOptions: ProOptions = { hideAttribution: true };

export const panOnDrag: ReactFlowProp['panOnDrag'] = [1, 2];
