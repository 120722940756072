import {
  blue,
  cyan,
  deepPurple,
  lightGreen,
  orange,
  pink,
  red,
  yellow,
} from '@mui/material/colors';

export const Color = {
  ORANGE: orange.A700,
  RED: red.A700,
  YELLOW: yellow.A700,
  LIGHT_GREEN: lightGreen.A700,
  CYAN: cyan.A700,
  BLUE: blue.A700,
  DEEP_PURPLE: deepPurple.A700,
  PINK: pink.A700,
} as const;

export type ColorKey = keyof typeof Color;
export type ColorValue = typeof Color extends Record<ColorKey, infer Value> ? Value : never;
