import { Box, ListItemText } from '@mui/material';
import DataSchemaIcon from 'components/DataSchemaIcon/DataSchemaIcon';
import { DataTypeOption } from 'features/Flow/components/DataTypeField/DataTypeField.consts';

interface DataTypeOptionContentProps {
  option: DataTypeOption;
}

export function DataTypeOptionContent(props: DataTypeOptionContentProps) {
  const { option } = props;

  return (
    <Box display="flex" alignItems="center">
      <DataSchemaIcon size="20px" dataSchema={option.schema} />
      <ListItemText sx={{ marginLeft: 1 }} primary={option.title} />
    </Box>
  );
}
