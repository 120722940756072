import { ApproveCheckpointBody } from 'api/services/useApproveCheckpoint/useApproveCheckpoint.types';
import axios from 'axios';
import useSWRMutation from 'swr/mutation';

const useApproveCheckpoint = (checkpointId?: string) => {
  const approveCheckpointRequest = (requestUrl: string, { arg }: { arg: ApproveCheckpointBody }) =>
    axios.post(requestUrl, arg);

  const approveCheckpointMutation = useSWRMutation(
    `/checkpoints/${checkpointId}/actions/approve`,
    approveCheckpointRequest,
  );

  return {
    isApproveCheckpointLoading: approveCheckpointMutation.isMutating,
    approveCheckpoint: approveCheckpointMutation.trigger,
    ...approveCheckpointMutation,
  };
};

export default useApproveCheckpoint;
