import { FormControlLabel } from '@mui/material';
import { Box, Checkbox, FormGroup, MenuItem, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import {
  ARRAY_TYPE_MENU_ITEMS,
  DATA_MENU_ITEMS,
  OBJECT_DATA_MENU_ITEMS,
} from '../../Function.types';
import { DataManipulatorFormData } from '../DataManipulator.types';
import { requiredValidation } from 'components/ui/rhf-components/utils/validationRules';
import { FC } from 'react';
import DataSchemaIcon from 'components/DataSchemaIcon/DataSchemaIcon';

interface DataManipulatorFormProps {
  disableInputType: boolean;
  disableOutputType: boolean;
}

const DataManipulatorForm: FC<DataManipulatorFormProps> = ({
  disableOutputType,
  disableInputType,
}) => {
  const { control, watch } = useFormContext<DataManipulatorFormData>();

  return (
    <FormGroup>
      <RHFTextField
        name="query"
        control={control}
        fullWidth
        label="Command"
        multiline={true}
        minRows={4}
        required
        rules={requiredValidation}
        sx={{ my: 1 }}
        placeholder="Enter data..."
      />

      <Typography variant="labelLarge" mt={1}>
        Flags
      </Typography>
      <Controller
        name="slurp"
        defaultValue={false}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label="Slurp"
            control={<Checkbox checked={field.value} onChange={field.onChange} />}
          />
        )}
      />

      <Typography variant="labelLarge" mt={3}>
        Input
      </Typography>
      <RHFTextField
        select
        name="input"
        control={control}
        label="Data Type"
        required
        sx={{ my: 1, mt: 1 }}
        fullWidth
        rules={requiredValidation}
        disabled={disableInputType}
        helperText={
          disableInputType
            ? 'You cannot update the data type of an input with a connection.'
            : undefined
        }
      >
        {OBJECT_DATA_MENU_ITEMS.map(({ schema, title }) => (
          <MenuItem key={schema.type} value={schema.type}>
            <Box display="flex" alignItems="center">
              <DataSchemaIcon size={'20px'} dataSchema={schema} />{' '}
              <Typography variant="bodyLarge" sx={{ ml: 1 }}>
                {title}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </RHFTextField>

      <Typography variant="labelLarge" mt={3}>
        Output
      </Typography>
      <RHFTextField
        select
        name="output"
        control={control}
        label="Data Type"
        required
        sx={{ my: 1, mt: 1 }}
        fullWidth
        rules={requiredValidation}
        disabled={disableOutputType}
        helperText={
          disableOutputType
            ? 'You cannot update the data type of an output with a connection.'
            : undefined
        }
      >
        {DATA_MENU_ITEMS.map(({ schema, title }) => (
          <MenuItem key={schema.type} value={schema.type}>
            <Box display="flex" alignItems="center">
              <DataSchemaIcon size={'20px'} dataSchema={schema} />{' '}
              <Typography variant="bodyLarge" sx={{ ml: 1 }}>
                {title}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </RHFTextField>
      {watch('output') === 'array' && (
        <>
          <Typography variant="labelLarge" mt={3}>
            Output Array
          </Typography>
          <RHFTextField
            select
            name="outputArrayType"
            control={control}
            label="Data Type"
            defaultValue="text"
            required
            fullWidth
            sx={{ my: 1, mt: 1 }}
            rules={requiredValidation}
            disabled={disableOutputType}
          >
            {ARRAY_TYPE_MENU_ITEMS.map(({ schema, title }) => (
              <MenuItem key={schema.type} value={schema.type}>
                <Box display="flex" alignItems="center">
                  <DataSchemaIcon size={'20px'} dataSchema={schema} />{' '}
                  <Typography variant="bodyLarge" sx={{ ml: 1 }}>
                    {title}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </RHFTextField>
        </>
      )}
    </FormGroup>
  );
};

export default DataManipulatorForm;
