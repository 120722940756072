import { CheckCircle } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useExecutionContext } from 'pages/ExecutionsPage/contexts/execution/useExecutionContext';
import { BaseSyntheticEvent, PropsWithChildren, useEffect, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import * as Styled from './ExecutionCheckpoint.styles';
import { EXECUTION_CHECKPOINT_FORM_ID } from './ExecutionCheckpointForm';
import ExecutionCheckpointRejectModal from './modals/ExecutionCheckpointRejectModal/ExecutionCheckpointRejectModal';
import { ExecutionCheckpointRejectFormValues } from './modals/ExecutionCheckpointRejectModal/ExecutionCheckpointRejectModal.types';

interface ExecutionCheckpointActionFooterProps {
  isWaitingExecutionRefresh: boolean;
  isRejectCheckpointPending: boolean;
  onReject: SubmitHandler<ExecutionCheckpointRejectFormValues>;
  onSubmit: (event: BaseSyntheticEvent) => void;
}

const ExecutionCheckpointActionFooter = ({
  children,
  isWaitingExecutionRefresh,
  isRejectCheckpointPending,
  onReject,
  onSubmit,
}: PropsWithChildren<ExecutionCheckpointActionFooterProps>) => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const { formState } = useFormContext();
  const { isValid } = formState;
  const { setPageFooter } = useExecutionContext();

  useEffect(() => {
    setPageFooter(
      <Styled.ExecutionCheckpointApproveButtonContainer data-testid="ExecutionCheckpointActionFooter">
        <div>{children}</div>
        <Stack direction="row" spacing={2}>
          <Styled.ExecutionCheckpointRejectButton
            color="error"
            disabled={isWaitingExecutionRefresh || isRejectCheckpointPending}
            variant="outlined"
            onClick={() => {
              setIsRejectModalOpen(true);
            }}
          >
            Reject
          </Styled.ExecutionCheckpointRejectButton>
          <Styled.ExecutionCheckpointApproveButton
            color="primary"
            disabled={!isValid || isWaitingExecutionRefresh || isRejectCheckpointPending}
            form={EXECUTION_CHECKPOINT_FORM_ID}
            startIcon={<CheckCircle />}
            type="submit"
            variant="contained"
            onClick={onSubmit}
          >
            Approve & Continue
          </Styled.ExecutionCheckpointApproveButton>
        </Stack>
        <ExecutionCheckpointRejectModal
          isOpen={isRejectModalOpen}
          isLoading={isRejectCheckpointPending || isWaitingExecutionRefresh}
          onClose={() => {
            setIsRejectModalOpen(false);
          }}
          onReject={onReject}
        />
      </Styled.ExecutionCheckpointApproveButtonContainer>,
    );

    return () => {
      setPageFooter(null);
    };
  }, [
    children,
    isRejectCheckpointPending,
    isRejectModalOpen,
    isValid,
    isWaitingExecutionRefresh,
    onReject,
    onSubmit,
    setPageFooter,
  ]);

  return null;
};

export default ExecutionCheckpointActionFooter;
