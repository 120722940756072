import { ColumnConfig } from 'components/Table/TableHead';
import { useMemo, useState } from 'react';
import { Order, getComparator } from 'utils/order';
import { GenericObject } from './DynamicTable';
import {
  CELL_MAX_WIDTH,
  CELL_MIN_WIDTH,
  getAllObjectKeys,
  getDataSchemaByValue,
  getParsedRows,
  parseRow,
} from './DynamicTable.utils';

export type Row = { _id: string; _selected: boolean } & Record<string, unknown>;

export type UseDynamicTableResult = ReturnType<typeof useDynamicTable>;

interface UseDynamicTableProps {
  objects: GenericObject[];
  onChange?: (selectedRows: GenericObject[]) => void;
}

export default function useDynamicTable({ objects, onChange }: UseDynamicTableProps) {
  const columns = useMemo<ColumnConfig[]>(() => {
    if (objects.length === 0) return [];

    const columnIds = getAllObjectKeys(objects);

    const tableColumns: ColumnConfig[] = columnIds.map((columnId) => {
      const firstValue = objects.find((object) => object[columnId] != null)?.[columnId];
      const valueSchema = getDataSchemaByValue(firstValue);
      const valueType = typeof firstValue;

      return {
        id: columnId,
        label: columnId,
        orderProperty: columnId,
        minWidth: CELL_MIN_WIDTH,
        maxWidth: CELL_MAX_WIDTH,
        valueSchema,
        valueType,
      };
    });

    return tableColumns;
  }, [objects]);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selectedRows, setSelectedRows] = useState<Row[]>([]);
  const [tableRows, setTableRows] = useState<Row[]>(getParsedRows(objects, columns));
  const [displayRowForm, setDisplayRowForm] = useState<boolean>(false);

  const handleSort = (columnId: string) => {
    const isAsc = orderBy === columnId && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const handleAddRow = () => {
    setDisplayRowForm(true);
  };

  const handleRowFormCancel = () => {
    setDisplayRowForm(false);
  };

  const handleRowFormConfirm = (values: GenericObject) => {
    const someValue = Object.values(values).some((value) =>
      Boolean(typeof value === 'string' ? value.trim() : value),
    );

    if (!someValue) {
      handleRowFormCancel();
      return;
    }

    setTableRows([...tableRows, parseRow(values, columns)]);
    setDisplayRowForm(false);
  };

  const handleRowEditConfirm = (values: Record<string, unknown>, rowId: string) => {
    const { _id, _selected, ...rowValues } = values;
    const isEmptyRow = Object.values(rowValues).every(
      (value) => !(typeof value === 'string' ? value.trim() : value),
    );

    if (isEmptyRow) {
      const updatedSelectedRows = selectedRows.filter((row) => row._id !== rowId);
      setTableRows((prev) => prev.filter((row) => row._id !== rowId));
      setSelection(updatedSelectedRows);
    } else {
      const updatedRows = tableRows.map((row) => {
        if (row._id === rowId) {
          return values as Row;
        }
        return row;
      });
      const selectedRowIds = selectedRows.map((row) => row._id);
      setTableRows(updatedRows);
      setSelection(updatedRows.filter((row) => selectedRowIds.includes(row._id)));
    }
  };

  const setSelection = (rows: Row[]) => {
    onChange?.(rows.map(({ _id, _selected, ...rest }) => rest));
    setSelectedRows(rows);
  };

  const handleSelectAll = () => {
    if (selectedRows.length === tableRows.length) {
      setSelection([]);
      return;
    }

    setSelection(tableRows);
  };

  const handleSelectRow = (row: Row) => {
    if (selectedRows.some(({ _id }) => _id === row._id)) {
      const filtered = selectedRows.filter(({ _id }) => _id !== row._id);
      setSelection(filtered);
      return;
    }

    setSelection([...selectedRows, row]);
  };

  const modifiedTableRows = useMemo<Row[]>(() => {
    if (tableRows.length === 0) return [];

    const mappedWithSelected = tableRows.map((row) => {
      const isSelected = selectedRows.some(({ _id }) => _id === row._id);
      return { ...row, _selected: isSelected };
    });
    if (!orderBy) return mappedWithSelected;

    return mappedWithSelected.slice().sort(getComparator(order, orderBy));
  }, [tableRows, orderBy, order, selectedRows]);

  return {
    columns,
    tableRows: modifiedTableRows,
    selectedRows,
    order,
    orderBy,
    displayRowForm,
    handleSort,
    handleSelectAll,
    handleSelectRow,
    handleAddRow,
    handleRowFormCancel,
    handleRowFormConfirm,
    handleRowEditConfirm,
  };
}
