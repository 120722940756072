import { Config, useRequest } from 'api/client';
import { useMemo } from 'react';
import { PipelineResponse } from 'types/pipeline';
import { mapPipeline } from 'utils/mappings';

export function usePipelineFromExecution(
  pipelineId: string,
  pipelineExecutionId: string,
  config?: Config<PipelineResponse>,
) {
  const { isLoading, data, error, mutate, ...response } = useRequest<PipelineResponse>(
    {
      url: `pipelines/${pipelineId}/executions/${pipelineExecutionId}`,
    },
    config,
  );

  const pipeline = useMemo(() => {
    if (!data) return;

    return mapPipeline(data);
  }, [data]);

  return {
    ...response,
    isPipelineLoading: isLoading,
    pipeline,
    pipelineError: error,
    mutatePipeline: mutate,
  };
}
