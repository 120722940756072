import { CircularProgress, Paper, Stack, Typography } from '@mui/material';
import PanelCard from '../PanelCard/PanelCard';

export default function RunInProgressPanel() {
  return (
    <Paper
      sx={{
        paddingX: 2,
        paddingY: 5,
      }}
    >
      <PanelCard
        title={
          <Stack direction="column" spacing={2} alignItems="center">
            <CircularProgress />

            <Typography variant="titleMedium" color={({ palette }) => palette.text.secondary}>
              Run in Progress
            </Typography>
          </Stack>
        }
        description={
          <>
            A performance summary will be displayed here once the
            <br />
            execution is finished.
          </>
        }
      />
    </Paper>
  );
}
