import { Grid, List, ListItem, SxProps, useTheme } from '@mui/material';
import CopyButton from 'components/CopyButton/CopyButton';
import { ReactNode, useState } from 'react';

interface ExecutionOutputGridProps {
  title: string;
  values: string[] | number[];
}

export function ExecutionOutputGrid(props: ExecutionOutputGridProps) {
  const { title, values } = props;
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  const isSingleItem = values.length === 1;

  const hoverStyles: SxProps = {
    background: theme.palette.action.hover,
  };

  return (
    <Grid
      container
      sx={{
        '&:nth-child(even)': theme.palette.surface.level3,
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:hover': isSingleItem ? hoverStyles : undefined,
        '&:last-child': {
          borderBottom: 'none',
        },
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Grid
        item
        xs={3}
        sx={{
          display: 'flex',
          paddingLeft: 1.5,
          paddingY: 2,
          opacity: '70%',
          typography: 'labelSmall',
          alignItems: isSingleItem ? 'center' : undefined,
        }}
      >
        {title}
      </Grid>
      <Grid item xs>
        <List sx={{ padding: 0 }}>
          {values.map((value, index) => (
            <ListItemWithHoverAction
              key={index}
              divider={index < values.length - 1}
              secondaryAction={<CopyButton value={String(value)} />}
              forceHoverAction={isSingleItem && isHovered}
              sx={{
                pr: 6,
                pl: 2,
                py: 2,
                typography: 'labelSmall',
                wordBreak: 'break-word',
                '&:hover': values.length === 1 ? undefined : hoverStyles,
              }}
              value={value}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

interface ListItemCopyOnHover {
  divider: boolean;
  secondaryAction: ReactNode;
  sx: SxProps;
  value: string | number;
  forceHoverAction: boolean;
}

const ListItemWithHoverAction = ({
  divider,
  secondaryAction,
  sx,
  value,
  forceHoverAction,
}: ListItemCopyOnHover) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ListItem
      divider={divider}
      secondaryAction={forceHoverAction || isHovered ? secondaryAction : null}
      sx={sx}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {value}
    </ListItem>
  );
};
