import { DataHandle } from 'features/Flow/Handles/handle.store';
import { DataType } from '../../Node/DataType';
import { NodeInputFormData } from './ObjectBuilder.types';
import { ArrayDataSchema, DataSchema, isArrayDataSchema } from '@pathways/pipeline-schema/web';
import { getSchemaByDataType } from 'utils/dataSchema';
import { DATA_MENU_ITEMS } from 'features/Flow/nodes/Function/Function.types';

const isValidConnectableType = (type: DataType | null, isArray?: boolean) => {
  if (!type) return false;

  const validTypes = ['text', 'integer', 'number', 'object'];
  if (!isArray) {
    validTypes.push('boolean');
  }

  return validTypes.includes(type);
};

export const canBuilderCustomHandleConnect = ({ schema }: DataHandle) => {
  if (isArrayDataSchema(schema)) {
    const itemType = schema.items.type;
    return isValidConnectableType(itemType, true);
  }

  return isValidConnectableType(schema.type, false);
};

export const createBuilderDataSchema = (data: NodeInputFormData): DataSchema => {
  const dataSchema = getSchemaByDataType(data.dataType, DATA_MENU_ITEMS);
  if (isArrayDataSchema(dataSchema)) {
    return {
      ...dataSchema,
      items: {
        type: data.arrayDataType ?? null,
      },
    } as ArrayDataSchema;
  }

  return dataSchema;
};
