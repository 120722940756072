import { DataSchemaCompatibilityCheck } from '../DataType';
import { DataSchema, ImageURIDataSchema } from '@pathways/pipeline-schema';

export const imageURICompatibilityCheck: DataSchemaCompatibilityCheck<ImageURIDataSchema> = ({
  output,
}) => {
  return output.type === 'image-uri';
};

export function isImageURIDataSchema(schema: DataSchema): schema is ImageURIDataSchema {
  return schema.type === 'image-uri';
}
