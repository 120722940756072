import { DataSchema } from '@pathways/pipeline-schema/web';
import { ColumnConfig } from 'components/Table/TableHead';
import { NodeType } from 'features/Flow/Flow.types';
import { FlowNode, FlowNodeBase } from 'types/reactflow';
import { NodeData, PathwayNode } from '../../Node/Node.types';

export interface DynamicCheckpointData extends NodeData {
  metadata: NodeData['metadata'] & {
    type: NodeType.CHECKPOINT;
  };
  schemas?: {
    input: DataSchema;
    output: DataSchema;
  };
}

export interface DynamicCheckpointNode extends FlowNodeBase<DynamicCheckpointData> {
  type: NodeType.CHECKPOINT;
}

export function isDynamicCheckpointNode(
  node?: Pick<FlowNode, 'data'>,
): node is DynamicCheckpointNode {
  return (
    node?.data.metadata.type === NodeType.CHECKPOINT &&
    (node as PathwayNode).data.metadata.uses.startsWith('dynamic')
  );
}

export interface TableSchemaColumnConfig extends ColumnConfig {
  uniqueKey: string;
}
