import { Color } from 'components/ColorField/ColorField.consts';
import { createNodeId } from 'features/Flow/Flow.utils';
import { GroupNode } from 'features/Flow/nodes/Group/Group.types';
import { GROUP_CONTAINER_PADDING_PX } from 'components/GroupContainer/GroupContainer';
import { useCallback } from 'react';
import { getNodesBounds, useStoreApi } from 'reactflow';
import { FlowNode } from 'types/reactflow';
import { mapGroupToNode, mapNodeInsideGroup } from 'utils/mappings';
import useFlow from './useFlow';

export default function useAddGroupNode() {
  const storeApi = useStoreApi();
  const { getNodes, setNodes } = useFlow();

  return useCallback(
    (selectedNodes: FlowNode[]) => {
      storeApi.getState().resetSelectedElements();
      storeApi.setState({
        nodesSelectionActive: false,
      });

      let groupNode: GroupNode | undefined;
      const nodes = getNodes();
      const selectedNodesBounds = getNodesBounds(selectedNodes);
      const nextNodes = nodes.flatMap<FlowNode>((node) => {
        const isSelectedNode = selectedNodes.some((selectedNode) => selectedNode.id === node.id);

        if (!isSelectedNode) {
          return node;
        }

        const isNewGroup = !groupNode;

        if (!groupNode) {
          groupNode = mapGroupToNode({
            id: createNodeId(),
            name: 'Group',
            render: {
              position: {
                x: selectedNodesBounds.x - GROUP_CONTAINER_PADDING_PX,
                y: selectedNodesBounds.y - GROUP_CONTAINER_PADDING_PX,
              },
              color: Color.ORANGE,
            },
          });
          groupNode.selected = true;
        }

        const childNode = mapNodeInsideGroup(groupNode, node);

        if (isNewGroup) {
          return [groupNode, childNode];
        }

        return [childNode];
      });

      setNodes(nextNodes);
    },
    [getNodes, setNodes, storeApi],
  );
}
