import { PathwayNodeProps } from '../Node/Node.types';
import ObjectBuilder from './ObjectBuilder/ObjectBuilder';
import ObjectExtractor from './ObjectExtractor/ObjectExtractor';
import Node from 'features/Flow/nodes/Node/Node';
import DataManipulator from './DataManipulator/DataManipulator';

const FUNCTION_COMPONENT_MAP: Partial<Record<string, React.FC<PathwayNodeProps>>> = {
  'function/build-object': ObjectBuilder,
  'function/extract-object': ObjectExtractor,
  'function/data-manipulator': DataManipulator,
};

const Function: React.FC<PathwayNodeProps> = (props) => {
  const FnComponent = FUNCTION_COMPONENT_MAP[props.data.metadata.uses];
  if (FnComponent) {
    return <FnComponent {...props} />;
  }

  return <Node {...props} />;
};

export default Function;
