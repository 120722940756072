import { blueGrey, deepOrange, deepPurple, green, lightBlue, yellow } from '@mui/material/colors';

export const DataTypeColor = {
  TEXT: yellow.A400,
  NUMERICAL: green.A400,
  IMAGE_URL: lightBlue.A200,
  ASSET: deepPurple[300],
  ENUM: deepOrange[400],
  PIPELINE_START_OUTPUT: blueGrey[500],
  OBJECT: '#FF7AED',
  BOOLEAN: '#4DD0E1',
};
