import { forwardRef } from 'react';
import * as Styled from './GroupDescription.styles';

export const GROUP_DESCRIPTION_CLASS_NAME = 'group-description';

interface GroupDescriptionProps {
  description?: string;
}

export default forwardRef<HTMLParagraphElement, GroupDescriptionProps>(
  function GroupDescription(props, ref) {
    const { description } = props;

    if (!description) return null;

    return (
      <Styled.GroupDescription
        ref={ref}
        className={GROUP_DESCRIPTION_CLASS_NAME}
        variant="bodyMedium"
        paragraph
        title={description}
      >
        {description}
      </Styled.GroupDescription>
    );
  },
);
