import { Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import NavTabs, { NavTabsItems } from 'components/NavTabs/NavTabs';
import { matchesSomeNavTab } from 'components/NavTabs/NavTabs.utils';
import { FlowPipeline } from 'types/reactflow';
import { useAppRoutes } from 'utils/routes';

interface ExecutionsNavProps {
  pipeline: FlowPipeline;
}

const ExecutionsNav = ({ pipeline }: ExecutionsNavProps) => {
  const routes = useAppRoutes();
  const { name, id } = pipeline;

  const navTabs: NavTabsItems = [
    {
      key: 'runs',
      label: 'Runs',
      to: routes.executions.runs(id),
    },
    {
      key: 'versions',
      label: 'Versions',
      to: routes.versions(id),
    },
  ];
  const displayNavTabs = matchesSomeNavTab(navTabs);

  return (
    <NavigationBar
      title={name}
      firstBreadcrumb={{
        label: name,
        route: routes.pipelines(id),
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {displayNavTabs && <NavTabs items={navTabs} />}

        <Button component={Link} color="primary" variant="contained" to={routes.pipelines(id)}>
          Edit Pipeline
        </Button>
      </Stack>
    </NavigationBar>
  );
};

export default ExecutionsNav;
