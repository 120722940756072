import * as Styled from './PipelineAction.styles';
import React from 'react';
import { PipelineActionProps } from './PipelineAction.types';
import { Button, capitalize, CircularProgress, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import FeatureFlag from 'features/FeatureFlag/FeatureFlag';
import ConfirmPublishingVersionModal from 'features/Flow/components/PipelineAction/ConfirmPublishingVersionModal';
import NavTabs, { NavTabsItems } from 'components/NavTabs/NavTabs';
import { Link } from 'react-router-dom';
import { useAppRoutes } from 'utils/routes';

const PipelineAction: React.FC<PipelineActionProps> = ({
  isLoading,
  pipeline,
  onSave,
  onSaveCopy,
  onExport,
  pipelinePublishingActionAttributes: {
    onPublish,
    pipelineName,
    pipelineVersion,
    shouldShowPublishButton,
  },
}) => {
  const routes = useAppRoutes();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isPublishModalOpen, setIsPublishModalOpen] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    onSave?.();
    handleClose();
  };

  const handlePublishVersion = () => {
    setIsPublishModalOpen(true);
  };

  const publishVersionAction = async () => {
    await onPublish();
    setIsPublishModalOpen(false);
  };
  const navTabs: NavTabsItems = [
    {
      key: 'pipelineState',
      label: capitalize(pipeline?.pipelineState ?? ''),
      to: '#',
      sx: (theme) => ({
        backgroundColor:
          pipeline?.pipelineState === 'draft'
            ? theme.palette.action.selected
            : theme.palette.success.selected,
      }),
    },
    {
      key: 'versionNumber',
      label: `Version ${pipeline?.version}`,
      to: '#',
    },
  ];
  return (
    <Styled.PipelineActionContainer>
      <NavTabs static items={navTabs} />
      <Styled.PipelineActionButtonContainer>
        <Styled.PipelineSaveButton
          aria-describedby="save"
          variant="text"
          disabled={isLoading}
          onClick={handleSave}
        >
          {isLoading ? (
            <CircularProgress
              color="warning"
              size={18}
              sx={(theme) => ({
                color: theme.palette.action.disabled,
              })}
            />
          ) : pipeline?.pipelineState === 'draft' ? (
            'Save'
          ) : (
            'Save as draft'
          )}
        </Styled.PipelineSaveButton>
        <Styled.PipelineActionMenuButton
          data-testid="PipelineActionMenu"
          aria-describedby="save-menu-popover"
          disabled={isLoading}
          onClick={handleClick}
        >
          <ArrowDropDown
            sx={{
              transform: `rotate(${!anchorEl ? '0' : '180'}deg)`,
              transition: 'transform 0.2s',
            }}
          />
        </Styled.PipelineActionMenuButton>
      </Styled.PipelineActionButtonContainer>
      <Menu
        data-testid="ActionPopover"
        open={!!anchorEl}
        id={anchorEl ? 'save-menu-popover' : undefined}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSave}>Save changes to draft</MenuItem>
        <MenuItem
          disabled={!onSaveCopy}
          onClick={() => {
            onSaveCopy?.();
            handleClose();
          }}
        >
          Save as new pipeline
        </MenuItem>
        {shouldShowPublishButton && (
          <MenuItem $dividerTop data-testid="publish-button" onClick={handlePublishVersion}>
            Publish Version
          </MenuItem>
        )}
        <FeatureFlag feature="pipelineExport">
          <MenuItem
            disabled={!onExport}
            onClick={() => {
              onExport?.();
              handleClose();
            }}
          >
            Export pipeline
          </MenuItem>
        </FeatureFlag>
      </Menu>

      <Button
        component={Link}
        data-testid="run-pipeline-button"
        variant="contained"
        to={routes.executions.new(pipeline?.id ?? '')}
      >
        Run Pipeline
      </Button>

      <ConfirmPublishingVersionModal
        onClose={() => {
          setIsPublishModalOpen(false);
        }}
        pipelineName={pipelineName}
        pipelineVersion={pipelineVersion}
        onConfirmPublish={publishVersionAction}
        isOpen={isPublishModalOpen}
        isLoading={isLoading}
      />
    </Styled.PipelineActionContainer>
  );
};

export default PipelineAction;
