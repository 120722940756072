import { createHandleId, isEdgeOfNode } from 'features/Flow/Flow.utils';
import useFlow from 'features/Flow/hooks/useFlow';
import { useRemoveNodeEdges } from 'features/Flow/hooks/useRemoveNodeEdges';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { useCallback, useEffect, useState } from 'react';
import { Edge, useUpdateNodeInternals } from 'reactflow';

interface ConnectionToUpdateName {
  oldName: string;
  newName: string;
}

interface UseUpdateConnectionOnNameChangeProps {
  nodeId: string;
  type: 'input' | 'output';
  inputs?: NodeInput[];
  outputs?: NodeOutput[];
}

export const useUpdateConnectionOnNameChange = ({
  nodeId,
  type,
  inputs = [],
  outputs = [],
}: UseUpdateConnectionOnNameChangeProps) => {
  const [connectionsToUpdate, setConnectionsToUpdate] = useState<ConnectionToUpdateName[]>([]);
  const [edgesToAdd, setEdgesToAdd] = useState<Edge[]>([]);
  const { getEdges, deleteElements, addEdges } = useFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const removeNodeEdges = useRemoveNodeEdges({ nodeId });

  const updateHandleEdgeConnectionIdChange = (removedIds: string[]) => {
    const updatedConnections = connectionsToUpdate.filter(({ oldName, newName }) => {
      return !removedIds.some((id) => {
        if (type === 'input') {
          return id === createHandleId('input', oldName) || id === createHandleId('input', newName);
        } else {
          return (
            id === createHandleId('output', oldName) || id === createHandleId('output', newName)
          );
        }
      });
    });
    removeNodeEdges(removedIds);

    if (updatedConnections.length === 0) return;

    const nodeEdges = getEdges().filter((edge) => isEdgeOfNode(edge, nodeId));
    const edgesToDelete: Edge[] = [];

    const nextEdgesToAdd = updatedConnections.reduce<Edge[]>((edgeChanges, updateData) => {
      const oldEdge = nodeEdges.find((edge) => {
        if (type === 'input') {
          return edge.targetHandle === createHandleId('input', updateData.oldName);
        } else {
          return edge.sourceHandle === createHandleId('output', updateData.oldName);
        }
      });

      if (!oldEdge) {
        return edgeChanges;
      }
      edgesToDelete.push(oldEdge);

      return edgeChanges.concat({
        ...oldEdge,
        targetHandle:
          type === 'input' ? createHandleId('input', updateData.newName) : oldEdge.targetHandle,
        sourceHandle:
          type === 'output' ? createHandleId('output', updateData.newName) : oldEdge.sourceHandle,
      });
    }, []);

    deleteElements({
      edges: edgesToDelete,
    });
    // Queue restoring the updated input connections.
    setEdgesToAdd(nextEdgesToAdd);
  };

  const addConnectionToUpdate = useCallback((connectionToUpdate: ConnectionToUpdateName) => {
    setConnectionsToUpdate((prev) => [...prev, connectionToUpdate]);
  }, []);

  useEffect(() => {
    if (!edgesToAdd.length) return;

    const canAddEdges = edgesToAdd.every((edge) => {
      if (type === 'input') {
        return inputs.some((input) => createHandleId('input', input.name) === edge.targetHandle);
      } else {
        return outputs.some(
          (output) => createHandleId('output', output.name) === edge.sourceHandle,
        );
      }
    });

    if (!canAddEdges) return;

    addEdges(edgesToAdd);
    setEdgesToAdd([]);
    setConnectionsToUpdate([]);
  }, [addEdges, edgesToAdd, inputs, outputs, type, updateNodeInternals, nodeId]);

  return {
    addConnectionToUpdate,
    updateEdges: updateHandleEdgeConnectionIdChange,
  };
};
