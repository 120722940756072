import theme from 'config/theme';
import Base, { Background, BackgroundVariant } from 'reactflow';
import styled, { css } from 'styled-components';
import { RF_GROUP_NODE_CLASS_NAME } from './Flow.consts';

interface BaseProps {
  $isLoading?: boolean;
}

export const ReactFlow = styled(Base)<BaseProps>`
  .${RF_GROUP_NODE_CLASS_NAME} {
    height: auto;
    width: auto;
    border: 0;
    padding: 0;
    background: none;
    box-shadow: none !important;
    z-index: -1 !important;
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0;
    `};
`;

export const FlowBackground = () => (
  <Background variant={BackgroundVariant.Dots} size={2} color={theme.palette.surface.level4} />
);
