import {
  DataSchema,
  isAssetDataSchema,
  isArrayDataSchema,
  isEnumDataSchema,
  AssetDataSchema,
} from '@pathways/pipeline-schema/web';
import { DataType } from 'features/Flow/nodes/Node/DataType';

export const unsetDataSchema = <T extends DataSchema = DataSchema>(dataSchema: T): T => {
  if (isEnumDataSchema(dataSchema)) {
    return {
      ...dataSchema,
      values: [],
    };
  }

  if (isAssetDataSchema(dataSchema)) {
    return {
      ...dataSchema,
      mediaType: [],
    };
  }

  if (isArrayDataSchema<AssetDataSchema>(dataSchema, 'asset')) {
    return {
      ...dataSchema,
      items: {
        ...dataSchema.items,
        type: 'asset',
        mediaType: [],
      },
    };
  }

  return dataSchema;
};

export interface DataMenuItem {
  schema: DataSchema;
  title: string;
}

export const getSchemaByDataType = (type: DataType, dataMenuItems: DataMenuItem[]) => {
  const schema = dataMenuItems.find(({ schema }) => schema.type === type)?.schema;

  if (!schema) {
    throw new Error(`Could not find the schema for the given type: ${type}, type is not supported`);
  }

  return schema;
};
