import { SvgIcon, SvgIconProps } from '@mui/material';

const BatchEndIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
      >
        <rect x="0.000244141" width="4" height="12" rx="1" fill="#80CBC4" />
        <rect x="0.000244141" width="4" height="12" rx="1" fill="#00796B" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3432 2.88833C10.5561 2.67541 10.9013 2.67541 11.1142 2.88833L13.8403 5.61442C14.0533 5.82734 14.0533 6.17255 13.8403 6.38547L11.1142 9.11156C10.9013 9.32448 10.5561 9.32448 10.3432 9.11156C10.1303 8.89864 10.1303 8.55343 10.3432 8.34051L12.1385 6.54516L5.88555 6.54517C5.58443 6.54517 5.34033 6.30107 5.34033 5.99995C5.34033 5.69884 5.58443 5.45474 5.88555 5.45474L12.1385 5.45473L10.3432 3.65938C10.1303 3.44646 10.1303 3.10125 10.3432 2.88833Z"
          fill="#80CBC4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3432 2.88833C10.5561 2.67541 10.9013 2.67541 11.1142 2.88833L13.8403 5.61442C14.0533 5.82734 14.0533 6.17255 13.8403 6.38547L11.1142 9.11156C10.9013 9.32448 10.5561 9.32448 10.3432 9.11156C10.1303 8.89864 10.1303 8.55343 10.3432 8.34051L12.1385 6.54516L5.88555 6.54517C5.58443 6.54517 5.34033 6.30107 5.34033 5.99995C5.34033 5.69884 5.58443 5.45474 5.88555 5.45474L12.1385 5.45473L10.3432 3.65938C10.1303 3.44646 10.1303 3.10125 10.3432 2.88833Z"
          fill="#00796B"
        />
      </svg>
    </SvgIcon>
  );
};

export default BatchEndIcon;
