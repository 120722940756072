import { useCallback, useEffect, useReducer } from 'react';
import { ConnectionHandlerAction, ConnectionHandlerConnection } from '../EditorContext';
import { NodeInput, NodeOutput } from '../nodes/Node/Node.types';

interface ReducerState {
  action?: ConnectionHandlerAction;
  connection?: ConnectionHandlerConnection;
  inputName?: string;
  outputName?: string;
}

type SetAction = Partial<ReducerState> & {
  type: 'set';
};

type ReducerAction =
  | SetAction
  | {
      type: 'clear';
    };

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case 'set': {
      return {
        action: action.action ?? state.action,
        connection: action.connection ?? state.connection,
        inputName: action.inputName ?? state.inputName,
        outputName: action.outputName ?? state.outputName,
      };
    }
    case 'clear': {
      return {
        action: undefined,
        connection: undefined,
        inputName: undefined,
        outputName: undefined,
      };
    }
    default:
      throw Error(`Unknown action: ${JSON.stringify(action)}`);
  }
}

interface Params {
  inputs?: Pick<NodeInput, 'name'>[];
  outputs?: Pick<NodeOutput, 'name'>[];
}

export function useDeferredConnectionState(params: Params) {
  const { inputs, outputs } = params;
  const [state, dispatch] = useReducer(reducer, {});

  const setState = useCallback((params: Omit<SetAction, 'type'>) => {
    dispatch({ type: 'set', ...params });
  }, []);

  const clearState = useCallback(() => {
    dispatch({ type: 'clear' });
  }, []);

  const callAction = useCallback(() => {
    if (!state.action) {
      return;
    }

    state.action(state.connection);
    clearState();
  }, [clearState, state]);

  useEffect(() => {
    if (!state.inputName && !state.outputName) return;

    const deferredInput = inputs?.find((output) => output.name === state.inputName);
    const deferredOutput = outputs?.find((output) => output.name === state.outputName);

    if (deferredInput ?? deferredOutput) {
      callAction();
    }
  }, [callAction, inputs, outputs, state, state.inputName, state.outputName]);

  return {
    deferredConnection: state.connection,
    // Avoid exposing dispatch.
    setDeferredConnectionState: setState,
    /** Helper for canceling the action when there is a conflict and the user closes the modal */
    cancelDeferredConnectionAction: clearState,
  };
}
