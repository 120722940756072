import { Divider, MenuItem, Stack, Typography } from '@mui/material';
import { PipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution.types';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import Loader from 'components/Loader/Loader';
import RuntimeChip from 'components/RuntimeChip/RuntimeChip';
import { useNavigate } from 'react-router-dom';
import { formatStatusDateTime } from 'utils/execution';
import { useAppRoutes } from 'utils/routes';

interface ViewExecutionNavDetailsProps {
  loading: boolean;
  pipelineExecution: PipelineExecution;
}

export default function ViewExecutionNavDetails(props: ViewExecutionNavDetailsProps) {
  const navigate = useNavigate();
  const routes = useAppRoutes();
  const { loading, pipelineExecution } = props;
  const { createdAt, completedAt, executionNumber, status, version } = pipelineExecution;

  const startedDateTimeLabel = formatStatusDateTime(ExecutionStatus.STARTED, createdAt);
  const completedDateTimeLabel = formatStatusDateTime(ExecutionStatus.COMPLETED, completedAt);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      pl={2}
      data-testid="ExecutionSummary"
    >
      <Loader loading={loading}>
        <Stack direction="row" alignItems="center">
          <Typography variant="bodyMedium" fontWeight={(theme) => theme.typography.fontWeightBold}>
            Run {executionNumber}
          </Typography>
          <Divider
            orientation="vertical"
            sx={(theme) => ({ mx: theme.spacing(2), height: theme.spacing(3) })}
          />
          <Typography variant="bodyMedium">Pipeline Version {version}</Typography>
          <Divider
            orientation="vertical"
            sx={(theme) => ({ mx: theme.spacing(2), height: theme.spacing(3) })}
          />
          <Typography variant="bodyMedium" title={`Execution status: ${status}`}>
            {status === ExecutionStatus.COMPLETED ? completedDateTimeLabel : startedDateTimeLabel}
          </Typography>
        </Stack>
      </Loader>

      <Loader loading={loading}>
        <Stack direction="row" gap={2} alignItems="center">
          <RuntimeChip
            status={status}
            startedAt={createdAt}
            completedAt={completedAt}
            renderActions={() => (
              <MenuItem
                onClick={() => {
                  navigate(routes.runtime(pipelineExecution.pipelineId, pipelineExecution.id));
                }}
              >
                Switch to pipeline view
              </MenuItem>
            )}
          />
        </Stack>
      </Loader>
    </Stack>
  );
}
