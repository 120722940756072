import React, { useEffect, useState } from 'react';
import { UnfoldMoreSharp } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import VisibilityIconButton from 'features/Flow/components/RightSidebar/components/Inputs/VisibilityIconButton';
import { Edge } from 'reactflow';
import InputDialog from 'components/InputDialog/InputDialog';
import { VisibilityProps } from './Inputs.types';

interface RightSidebarTextInputProps extends Omit<NodeInput, 'value'> {
  edge: Edge | undefined;
  label: React.ReactNode | string;
  readOnly?: boolean;
  value?: string;
  visibilityProps: VisibilityProps;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onConfirmChange: (value: string) => void;
}

const RightSidebarTextInput = ({
  edge,
  label,
  readOnly = false,
  visibilityProps,
  onChange,
  onConfirmChange,
  ...input
}: RightSidebarTextInputProps) => {
  const [dialogTextValue, setDialogTextValue] = useState<string>(input.value ?? '');
  const [expand, setExpand] = useState<boolean>(false);

  const resetDialogTextInput = () => {
    setExpand(false);
    setDialogTextValue(input.value ?? '');
  };

  const closeDialog = () => {
    setExpand(false);
    setDialogTextValue(input.value ?? '');
  };

  const confirmDialogChange = () => {
    onConfirmChange(dialogTextValue);
    setExpand(false);
  };

  useEffect(() => {
    setDialogTextValue(input.value ?? '');
  }, [input.value]);

  return (
    <React.Fragment>
      <TextField
        {...visibilityProps.hoverEvents}
        name={input.name}
        label={label}
        required={input.config.required}
        placeholder={readOnly ? '' : 'Enter or connect data...'}
        size="small"
        fullWidth
        InputProps={{
          readOnly,
          endAdornment: (
            <>
              <VisibilityIconButton
                required={input.config.required}
                hidden={input.config.hidden}
                visible={visibilityProps.isHideIconVisible}
                edge={edge}
                onClick={visibilityProps.onVisibilityIconClick}
              />
              {!readOnly && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setExpand(true);
                  }}
                >
                  <UnfoldMoreSharp
                    sx={{
                      transform: 'rotate(45deg)',
                    }}
                  />
                </IconButton>
              )}
            </>
          ),
        }}
        value={input.value ?? ''}
        onChange={onChange}
      />
      <InputDialog
        expand={expand}
        title={input.title}
        onClose={closeDialog}
        onCancel={resetDialogTextInput}
        onConfirm={confirmDialogChange}
      >
        <TextField
          multiline
          minRows={8}
          placeholder="Enter data..."
          fullWidth
          inputProps={{ sx: { maxHeight: 185, overflowY: 'scroll!important' } }}
          value={dialogTextValue}
          onChange={(e) => {
            setDialogTextValue(e.target.value);
          }}
          InputProps={{ readOnly }}
        />
      </InputDialog>
    </React.Fragment>
  );
};

export default RightSidebarTextInput;
