import { Config, useRequest } from 'api/client';
import { handleLegacyData } from 'utils/mappings';
import { PipelineExecutionInputsResponse } from './usePipelineExecutionInputs.types';

export function usePipelineExecutionInputs(
  pipelineExecutionId?: string,
  config?: Config<PipelineExecutionInputsResponse>,
) {
  const { isLoading, data, error, mutate, ...response } =
    useRequest<PipelineExecutionInputsResponse>(
      {
        url: pipelineExecutionId ? `pipeline-executions/${pipelineExecutionId}/inputs` : undefined,
      },
      config,
    );

  return {
    ...response,
    loadingPipelineExecutionInputs: isLoading,
    pipelineExecutionInputs: handleLegacyData(data)?.inputs ?? [],
    pipelineExecutionInputsError: error,
    mutatePipelineExecutionInputs: mutate,
  };
}
