import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import { STATUS_CONFIG } from 'config/status';
import { getExecutionStatus } from './execution';

export function getStatusConfig(status: ExecutionStatus | undefined) {
  const config = STATUS_CONFIG[getExecutionStatus(status)];

  config.chip ??= {};
  config.chip.label ??= config.label;

  return config;
}
