import { pathwaysDarkTheme } from '@monksflow/monks-ui';
import { PaletteColor } from '@mui/material';
import { Palette, alpha, createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';

declare module '@mui/material/MenuItem' {
  interface MenuItemOwnProps {
    /**
     * If `true`, a 1px light border is added to the top of the menu item.
     * @default false
     */
    $dividerTop?: boolean;
  }
}

export type PaletteColorKeys = {
  [P in keyof Palette]: Palette[P] extends PaletteColor ? P : never;
}[keyof Palette];

export const ACTION_COLOR = {
  active(color: string) {
    return alpha(color, 0.56);
  },
  hover(color: string) {
    return alpha(color, 0.04);
  },
  selected(color: string) {
    return alpha(color, 0.08);
  },
};

/**
 * NOTE from MUI v6: Prefer `variants` instead of `styleOverrides`.
 * @see https://mui.com/customization/theme-components/#global-style-overrides
 * @see https://bitbucket.org/formulamonks/monks-ui/src/main/src/themes/pathwaysDarkTheme.ts
 */
const theme = createTheme(pathwaysDarkTheme, {
  palette: {
    ...pathwaysDarkTheme.palette,
    // TODO[epic=pathwaysDarkTheme]: update in base theme.
    divider: pathwaysDarkTheme.palette.surface.dividerLight,
    primary: {
      ...pathwaysDarkTheme.palette.primary,
      dark: '#7252e9',
    },
  },
  components: {
    MuiDialog: {
      variants: [
        {
          props: () => true,
          style: ({ theme }) => ({
            '.MuiDialog-paper': {
              background: theme.palette.surface.level1,
            },
          }),
        },
      ],
    },
    MuiAutocomplete: {
      variants: [
        {
          props: () => true,
          style: ({ theme }) => ({
            '.MuiAutocomplete-inputRoot': {
              background: theme.palette.surface.level2,
              padding: theme.spacing(1.125),

              '&.MuiInputBase-root': {
                height: 'auto',
                minHeight: '44px',

                '.MuiInputBase-input': {
                  padding: 0,
                  margin: theme.spacing(0, '3px'),
                },
              },
            },
          }),
        },
      ],
    },
    MuiInput: {
      variants: [
        {
          props: () => true,
          style: ({ theme }) => ({
            '&.MuiInput-root': {
              '&:not(.Mui-disabled)': {
                background: theme.palette.surface.level2,

                '&:hover': {
                  background: theme.palette.surface.level2,
                },
              },

              '.MuiInput-input': {
                textOverflow: 'ellipsis',
              },
            },
          }),
        },
      ],
    },
    MuiInputBase: {
      variants: [
        {
          props: () => true,
          style: () => ({
            '&.MuiInputBase-adornedStart': {
              paddingLeft: '3px',
            },
            '&.MuiInputBase-adornedEnd': {
              paddingRight: '3px',
            },
          }),
        },
      ],
    },
    MuiMenu: {
      // TODO[epic=pathwaysDarkTheme]: update in base theme.
      variants: [
        {
          props: () => true,
          style: ({ theme }) => ({
            '.MuiMenu-paper': {
              boxShadow: theme.shadows[8],
            },
          }),
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          variants: [
            {
              props: {
                $dividerTop: true,
              },
              style: {
                borderTop: `1px solid ${theme.palette.divider}`,
              },
            },
          ],
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation: {
          boxShadow: 'none',
        },
        elevation0: ({ theme }) => ({
          background: theme.palette.surface.level0,
        }),
        elevation1: ({ theme }) => ({
          background: theme.palette.surface.level1,
        }),
        elevation2: ({ theme }) => ({
          background: theme.palette.surface.level2,
        }),
        elevation3: ({ theme }) => ({
          background: theme.palette.surface.level3,
        }),
        elevation4: ({ theme }) => ({
          background: theme.palette.surface.level4,
        }),
      },
    },
    MuiTab: {
      variants: [
        {
          props: () => true,
          style: ({ theme }) => ({
            borderRadius: theme.shape.borderRadius * 6, // 24px
            height: '28px',
            lineHeight: theme.spacing(3),
            minHeight: 'auto',
            minWidth: 'auto',
            padding: theme.spacing(0, 2),

            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.focus,
              color: theme.palette.primary.main,
            },
          }),
        },
      ],
    },
    MuiTabs: {
      variants: [
        {
          props: () => true,
          style: ({ theme }) => ({
            minHeight: 0,
            borderRadius: theme.shape.borderRadius * 25, // 100px
            padding: theme.spacing(0.5),

            '.MuiTabs-indicator': {
              display: 'none',
            },
          }),
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: {
            select: true,
          },
          // TODO[epic=pathwaysDarkTheme]: update in base theme.
          style: () => ({
            '& .MuiSelect-select': {
              '&:focus': {
                backgroundColor: 'transparent',
              },
            },
          }),
        },
      ],
    },
  },
} satisfies Partial<ThemeOptions>);

export default theme;
