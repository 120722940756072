import {
  ArrayDataSchema,
  AssetDataSchema,
  DataSchema,
  isArrayDataSchema,
} from '@pathways/pipeline-schema/web';
import { DUMMY_NEURON_DATA_MENU_ITEMS } from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuron.consts';
import { DummyNodeInputDataSchema } from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuron.types';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { getSchemaByDataType } from 'utils/dataSchema';

export const createDummyFieldDataSchema = (data: DummyNodeInputDataSchema) => {
  if (data.dataType === 'asset' && data.mediaType) {
    const dataSchema: AssetDataSchema = {
      type: 'asset',
      mediaType: [data.mediaType],
    };

    return dataSchema;
  }

  const dataSchema = getSchemaByDataType(data.dataType, DUMMY_NEURON_DATA_MENU_ITEMS);
  if (isArrayDataSchema(dataSchema)) {
    return {
      ...dataSchema,
      items: {
        type: data.arrayDataType ?? null,
        mediaType: data.arrayDataType === 'asset' ? [data.mediaType] : undefined,
      },
    } as ArrayDataSchema;
  }

  return dataSchema;
};

const createDefaultMediaType = (dataSchema: DataSchema | undefined): string | undefined => {
  if (dataSchema?.type === 'array' && dataSchema.items.type === 'asset') {
    return dataSchema.items.mediaType[0];
  }

  if (dataSchema?.type === 'asset') {
    return dataSchema.mediaType[0];
  }

  return undefined;
};

export const createDefaultValues = (item: NodeInput | NodeOutput | null) => ({
  name: item?.name ?? '',
  title: item?.title ?? '',
  dataType: item?.dataSchema.type ?? 'text',
  arrayDataType:
    item?.dataSchema && isArrayDataSchema(item.dataSchema) ? item.dataSchema.items.type : undefined,
  mediaType: createDefaultMediaType(item?.dataSchema),
});
