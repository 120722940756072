import { FlowNode } from 'types/reactflow';
import { Edge } from 'reactflow';
import { isPipelineStartNode } from 'features/Flow/nodes/PipelineStart/PipelineStart.types';
import { isPipelineCompleteNode } from 'features/Flow/nodes/PipelineComplete/PipelineComplete.types';
import { isPathwayNode, PathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { GroupNode, isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import {
  BatchGroupNode,
  BatchGroupSaveData,
  isBatchGroupNode,
  isBatchJobNode,
} from 'features/Flow/nodes/Batch/Batch.types';
import { formatRequiredMessage } from 'utils/message';
import { addLegacyType } from 'utils/mappings';
import {
  Pipeline,
  PipelineCompleteJob,
  PipelineConnection,
  PipelineConnectionData,
  PipelineGroup,
  PipelineNeuronJob,
  PipelineTriggerJob,
} from 'types/pipeline';
import {
  formatNodeData,
  formatPipelineCompleteInputsAsOutputs,
} from 'api/services/useSavePipeline/useSavePipeline.utils';
import { mapPipelineBatchJobs } from 'features/Flow/nodes/Batch/Batch.utils';

export function formatPipelineContent(nodes: FlowNode[], edges: Edge[]) {
  const pipelineStartNode = nodes.find(isPipelineStartNode);
  const pipelineCompleteNode = nodes.find(isPipelineCompleteNode);
  const pathwayNodes: PathwayNode[] = [];
  const groupNodes: GroupNode[] = [];
  const batchGroups = new Map<BatchGroupNode['id'], BatchGroupSaveData>();

  if (!pipelineStartNode) throw new Error(formatRequiredMessage('pipelineStartNode'));
  if (!pipelineCompleteNode) throw new Error(formatRequiredMessage('pipelineCompleteNode'));

  nodes.forEach((node) => {
    if (isGroupNode(node)) {
      groupNodes.push(node);
    }

    if (isBatchGroupNode(node)) {
      batchGroups.set(node.id, { ...node, data: { ...node.data, jobs: [] } });
    }

    if (node.parentNode) {
      const groupNode = groupNodes.find((groupNode) => groupNode.id === node.parentNode);
      const batchGroupNode = batchGroups.get(node.parentNode);

      if (groupNode && isPathwayNode(node)) {
        pathwayNodes.push(node);
        return;
      }

      if (batchGroupNode && isBatchJobNode(node)) {
        batchGroupNode.data.jobs.push(node);
        return;
      }
    }

    if (isPathwayNode(node)) {
      pathwayNodes.push(node);
    }
  });

  const batchNodes = [...batchGroups.values()];

  return {
    jobs: [
      {
        id: pipelineStartNode.id,
        data: {
          metadata: {
            type: pipelineStartNode.data.metadata.type,
          },
          outputs: pipelineStartNode.data.outputs.map(addLegacyType),
        },
        render: {
          position: pipelineStartNode.position,
          deletable: false,
        },
      } satisfies PipelineTriggerJob,
      ...pathwayNodes.map(
        (node): PipelineNeuronJob => ({
          id: node.id,
          data: formatNodeData(node, pipelineStartNode.data.outputs),
          render: {
            error: node.error,
            parentNode: node.parentNode,
            position: node.position,
          },
        }),
      ),
      ...mapPipelineBatchJobs(batchNodes, edges),
      {
        id: pipelineCompleteNode.id,
        data: {
          metadata: {
            type: pipelineCompleteNode.data.metadata.type,
          },
          outputs: formatPipelineCompleteInputsAsOutputs({
            edges,
            pipelineCompleteNode,
            nodes,
          }),
        },
        render: {
          position: pipelineCompleteNode.position,
          deletable: false,
        },
      } satisfies PipelineCompleteJob,
    ],
    connections: edges.map(
      ({ id, ...render }: Edge<PipelineConnectionData>): PipelineConnection => ({
        id,
        render: {
          type: render.type ?? '',
          source: render.source,
          sourceHandle: render.sourceHandle ?? '',
          target: render.target,
          targetHandle: render.targetHandle ?? '',
          // For custom handles.
          data: render.data,
        },
      }),
    ),
    groups: groupNodes.map((node): PipelineGroup => {
      return {
        id: node.id,
        name: node.data.name,
        description: node.data.description,
        render: {
          position: node.position,
          color: node.data.color,
        },
      };
    }),
  } satisfies Pipeline;
}
