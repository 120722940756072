import { SvgIcon, SvgIconProps } from '@mui/material';

const AddFunctionIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M4.27083 6.0625L8.29167 10L4.1875 14.0208C4.11806 14.0903 4.06944 14.1632 4.04167 14.2396C4.01389 14.316 4 14.3993 4 14.4894V15.3462C4 15.5265 4.0631 15.6806 4.18929 15.8084C4.31549 15.9361 4.46767 16 4.64583 16H10C10.2778 16 10.5139 15.9028 10.7083 15.7083C10.9028 15.5139 11 15.2778 11 15C11 14.7222 10.9028 14.4861 10.7083 14.2917C10.5139 14.0972 10.2778 14 10 14H6.83333L10.3125 10.5208C10.4653 10.3681 10.5417 10.1944 10.5417 10C10.5417 9.80556 10.4653 9.63194 10.3125 9.47917L6.83333 6H11C11.2778 6 11.5139 5.90278 11.7083 5.70833C11.9028 5.51389 12 5.27778 12 5C12 4.72222 11.9028 4.48611 11.7083 4.29167C11.5139 4.09722 11.2778 4 11 4H4.89583C4.64201 4 4.42925 4.0862 4.25754 4.2586C4.08585 4.43099 4 4.64462 4 4.89948V5.41667C4 5.53544 4.02431 5.65051 4.07292 5.76185C4.12153 5.8732 4.1875 5.97342 4.27083 6.0625ZM13 14H11.75C11.5375 14 11.3594 13.9285 11.2156 13.7856C11.0719 13.6427 11 13.4656 11 13.2544C11 13.0431 11.0719 12.8646 11.2156 12.7188C11.3594 12.5729 11.5375 12.5 11.75 12.5H13V11.25C13 11.0375 13.0715 10.8594 13.2144 10.7156C13.3573 10.5719 13.5344 10.5 13.7456 10.5C13.9569 10.5 14.1354 10.5719 14.2812 10.7156C14.4271 10.8594 14.5 11.0375 14.5 11.25V12.5H15.75C15.9625 12.5 16.1406 12.5715 16.2844 12.7144C16.4281 12.8573 16.5 13.0344 16.5 13.2456C16.5 13.4569 16.4281 13.6354 16.2844 13.7812C16.1406 13.9271 15.9625 14 15.75 14H14.5V15.25C14.5 15.4625 14.4285 15.6406 14.2856 15.7844C14.1427 15.9281 13.9656 16 13.7544 16C13.5431 16 13.3646 15.9281 13.2188 15.7844C13.0729 15.6406 13 15.4625 13 15.25V14Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default AddFunctionIcon;
