import { NodeType } from 'features/Flow/Flow.types';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import { useCallback, useLayoutEffect, useState } from 'react';
import { FlowExecutionJob } from 'types/reactflow';
import { PanelTabKeys } from './usePanelTabs.types';

export default function usePanelTabs(executionJob: FlowExecutionJob | undefined) {
  const [selectedTab, setSelectedTab] = useState(PanelTabKeys.INPUT);
  const isTabSelected = useCallback((key: PanelTabKeys) => key === selectedTab, [selectedTab]);
  const isTabVisible = useCallback(
    (key: PanelTabKeys) => {
      if (key === PanelTabKeys.REVIEW) return executionJob?.status === ExecutionStatus.REVIEW;

      return true;
    },
    [executionJob?.status],
  );
  const isTabDisabled = useCallback(
    (key: PanelTabKeys) => {
      if (key === PanelTabKeys.INPUT) return executionJob?.type === NodeType.PIPELINE_START;
      if (key === PanelTabKeys.OUTPUT) return executionJob?.type === NodeType.PIPELINE_COMPLETE;

      return !isTabVisible(key);
    },
    [executionJob?.type, isTabVisible],
  );

  useLayoutEffect(() => {
    if (isTabSelected(PanelTabKeys.INPUT) && isTabDisabled(PanelTabKeys.INPUT)) {
      setSelectedTab(PanelTabKeys.OUTPUT);
    }

    if (isTabSelected(PanelTabKeys.OUTPUT) && isTabDisabled(PanelTabKeys.OUTPUT)) {
      setSelectedTab(PanelTabKeys.INPUT);
    }

    if (isTabSelected(PanelTabKeys.REVIEW) && !isTabVisible(PanelTabKeys.REVIEW)) {
      setSelectedTab(PanelTabKeys.INPUT);
    }
  }, [isTabDisabled, isTabSelected, isTabVisible]);

  return {
    selectedTab,
    setSelectedTab,
    isTabSelected,
    isTabDisabled,
    isTabVisible,
  };
}
