import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import { DataType } from '../../Node/DataType';
import { ArrayDataSchema } from '@pathways/pipeline-schema';

export interface NodeInputFormData {
  name: string;
  dataType: DataType;
  arrayDataType?: ArrayDataSchema['items']['type'];
  title?: string;
}

export interface ConnectionToUpdateName {
  oldName: string;
  newName: string;
}

/**
 * @param updated New list of neuron inputs for the node.
 * @param handleIdsToRemove On commit, we need to remove the connections of the handles that have been removed before updating with the new list of inputs.
 */
export type ObjectBuilderCommitFn = (
  updated: NodeInput[],
  handleIdsToRemove: string[],
  connectionsToUpdate: ConnectionToUpdateName[],
) => void;

export const DEFAULT_VIEW = 'list';

export type ViewStates =
  | {
      view: 'list';
    }
  | {
      view: 'edit';
      inputToEdit: NodeInput;
      updateIndex: number;
    }
  | {
      view: 'create';
    };
