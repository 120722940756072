import { Fade, Stack, Typography } from '@mui/material';
import { FullPageContainer } from 'components/FullPageContainer/FullPageContainer.styles';
import PathwaysProject from '../Icons/PathwaysProject';

function Splash() {
  return (
    <Fade in timeout={400}>
      <FullPageContainer
        $centerContent={true}
        style={{
          pointerEvents: 'none',
        }}
      >
        <Stack direction="row" spacing={2}>
          <PathwaysProject />
          <Typography variant="titleMedium">Pathways</Typography>
        </Stack>
      </FullPageContainer>
    </Fade>
  );
}

export default Splash;
