import mime from 'mime-types';
import { formatJoin } from 'utils/string';

export function generateAssetInputHelperText(mediaTypes: string[]) {
  const mediaTypeExtensions = mediaTypes
    .map((type) => {
      const extension = mime.extension(type);
      if (extension) {
        return extension.toUpperCase();
      }

      console.warn(`[AssetInput] Could not find extension for ${type}`);
      return null;
    })
    .filter((type): type is string => !!type);

  if (!mediaTypeExtensions.length) {
    return '';
  }

  return `Only ${formatJoin(mediaTypeExtensions, ', ', 'and')} file${
    mediaTypeExtensions.length === 1 ? '' : 's'
  } can be supported.`;
}
